import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React,{lazy, Suspense, useEffect, useRef, useState} from 'react';
import styled from "styled-components/macro";
import { LogoContainer, MainLayout, MenuItem, SiderItem } from '../StyledComponents/LayoutComponents';
import { useLocation, useNavigate, Route, Routes, Navigate } from "react-router-dom";
import { getActive } from '../functions/Utils';
import { menus } from './routes';
import {Outlet } from 'react-router-dom'
import { getfirebasetoken } from '../store/Common/CommonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userlogout } from '../store/Authentication/AuthSlice';


const { Header, Content, Footer, Sider } = Layout;

const Navigation = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const firebaseToken = useSelector(getfirebasetoken)
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const user_role = JSON.parse(localStorage.getItem('user'))?.userroles
    const [active, setActive] = useState(getActive(pathname, user_role));
    const [hoverKey, setHoverKey] = useState(getActive(pathname, user_role));
    // const user_role = 'KAM'

    useEffect(() => {
      setActive(getActive(pathname, user_role))
      setHoverKey(getActive(pathname, user_role))
  }, [pathname]);

  useEffect(()=> {
    const isRoleOk = menus.filter((i)=> i.path === pathname &&  i.role.includes(user_role))
    console.log(isRoleOk.length);
    if(!isRoleOk.length){
      let values = {
        fcmId: firebaseToken
      }
      console.log("hi")
      dispatch(userlogout(values))
      if(user_role === 'admin' || user_role === 'air_admin' || user_role === 'ocean_admin'){
        navigate('/auth/admin-login')
      }else {
        navigate('/auth/login')
      }
    }
  },[user_role])

    const handleNavigation = (key) => {
        // setActive(e.key)
        const selectedMenuIndex = Number(key);
        const role_menus = menus.filter((i)=> i.role.includes(user_role))
        const selectedMenuPath = role_menus[selectedMenuIndex].path;
        if(selectedMenuPath){
          navigate(selectedMenuPath)
        }
        
    }
    const handleHover = (key) => {
        setHoverKey(key);
      };

    const RemoveClass = (index) => {
      try {
        const element = document.querySelectorAll('.ant-menu-item')[index]
        element.classList.replace('ant-menu-item-selected', '');
      }catch {
        // console.log("error");
      }
      return ""
    }
      
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const currentYear = new Date().getFullYear();
  return (
    <MainLayout>
      <SiderItem
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        collapsible 
        collapsed={collapsed}
      >
        <LogoContainer className={collapsed? "collapsed-logo": "logo"}>
            <img src={require('../assets/images/logo.png')} />
            <img src={require('../assets/images/collapse_icon.png')} style={{ cursor: 'pointer', transform: collapsed? 'rotate(180deg' : 'none'}} onClick={()=> setCollapsed(!collapsed)} />
        </LogoContainer>
        <MenuItem
          onClick={(e)=> handleNavigation(e.key)}
          theme="dark"
          mode="inline"
          selectedKeys={[active]}
          items={menus.filter((m)=> m.role.includes(user_role)).map(
            (i, index) =>
            ({
              key: String(index),
              icon: <img src={String(index) === active || String(index) === hoverKey ? i.active_icon : i.icon} />,
              label: i.label,
              path: index,
              className: active === String(index) ? "ant-menu-item-active ant-menu-item-selected" : "",
              onMouseEnter: () => handleHover(String(index)),
              onMouseLeave: () => handleHover(null),
            }),
          )}
        />
      </SiderItem>
      <MainLayout className='secondary'>
        {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        /> */}
        <Content
          style={{
            margin: '24px 16px 0',
          }}
        >
          <div
            style={{
            //   minHeight: 500,
              background: "#F3F6F9",
            }}
          >
           
            <Outlet/>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            background: '#ffff',
            borderRadius: '8px',
            marginTop: '20px'
          }}
        >
          Copyright © {currentYear} SGate. All rights reserved.
        </Footer>
      </MainLayout>
    </MainLayout>
  );
};
export default Navigation;