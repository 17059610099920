import React,{ useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ContentRow, ContentTitle, ContentValue, DetailContainer } from '../../StyledComponents/CreateKeyAccountManager'


function ArchiveView(props) {
    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/archives'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

  return (
    <DetailContainer className='user-management'>
        <ContentRow className='mb-5'>
            <ContentTitle>
                User Name
            </ContentTitle>
            <ContentValue>
                {props.instance.full_name}
            </ContentValue>
        </ContentRow>
        <ContentRow className='mb-5'>
            <ContentTitle>
                Email
            </ContentTitle>
            <ContentValue>
                {props.instance.email}
            </ContentValue>
        </ContentRow>
        <ContentRow className='mb-5'>
            <ContentTitle>
                Phone Number
            </ContentTitle>
            <ContentValue>
                {props.instance.country_code? "+" + props.instance.country_code : ""} {props.instance.phone_number}
            </ContentValue>
        </ContentRow>
        <ContentRow className='mb-5'>
            <ContentTitle>
                Whats app Number
            </ContentTitle>
            <ContentValue>
            {props.instance.wcountrycode? "+" + props.instance.wcountrycode : "+91"} {props.instance.whatsapp}
            </ContentValue>
        </ContentRow>
        {props.instance?.company_name ?
            <ContentRow className='mb-5'>
                <ContentTitle>
                    Company Name
                </ContentTitle>
                <ContentValue>
                {props.instance?.company_name}
                </ContentValue>
            </ContentRow>
        : null}
        {props.instance?.kam_user?.full_name ?
            <ContentRow className='mb-5'>
                <ContentTitle>
                    Key account manager
                </ContentTitle>
                <ContentValue>
                {props.instance?.kam_user?.full_name}
                </ContentValue>
            </ContentRow>
        : null}
        {/* <ContentRow className='mb-5'>
            <ContentTitle>
                Registration Mode 
            </ContentTitle>
            <ContentValue>
                Mobile
            </ContentValue>
        </ContentRow> */}
    </DetailContainer>
  )
}

export default ArchiveView
