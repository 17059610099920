import { Content } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allcountrycode, countrycode, updatenotificationdata } from '../../store/Common/CommonSlice'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer, FormikForm, FormItem } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SubmitButton from '../Common/SubmitButton'
import * as Yup from 'yup'
import { ErrorMessage } from 'formik'
import { Input } from 'antd'
import { PhoneInput } from '../Common/PhoneInput'
import { TextInputItem } from '../../StyledComponents/Common'
import { update_kam } from '../../store/KAM/KAMSlice'

function ProfileSection(props) {
    const dispatch = useDispatch()
    const [spinner, setSpinner] = useState(false);

    useEffect(()=>{
        dispatch(countrycode())
    },[])

    useEffect(()=>{
        if(props.is_edit === false){
            setSpinner(false)
        }
    },[props.is_edit])
    
    const getAllCountryCode = useSelector(allcountrycode)

    const handleSelectChange = (e,setFieldValue,name)=> {
        setFieldValue([name], e)
    }

    const handleArchive = () => {
        console.log("archive");
    }
    const handleCancel = (resetForm) => {
        props.handleCancel()
        resetForm()
    }

    const initialValues = {
        full_name: props.instance?.full_name,
        phone_number: props.instance?.phone_number,
        email: props.instance?.email,
        whatsapp_number: props.instance?.whatsapp,
        country_code: String(props.instance?.country_code),
        wcountrycode: String(props.instance?.wcountrycode),
        id: props.instance?.id
      }
    const validationSchema = Yup.object().shape({
        full_name:Yup.string().required("Full Name is required"),
        phone_number: Yup.number()
            .typeError('Phone number must be a number')
            .test('len', 'Phone number must be exactly 10 digits', (val) => {
            if (val) {
                return val.toString().length === 10;
            }
            return false;
            })
            .required('Phone number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        whatsapp_number: Yup.number()
            .typeError('Whatsapp number must be a number')
            .test('len', 'Whatsapp number must be exactly 10 digits', (val) => {
            if (val) {
                return val.toString().length === 10;
            }
            return false;
            })
            .required('Whatsapp number is required'),
    })
  return (
    <>
        <FormikForm
            initialValues={initialValues}
            validationSchema={validationSchema}

            onSubmit={(values,{resetForm}) => {
                setSpinner(true)
                dispatch(update_kam(values))
                .then((res)=>{
                    // console.log('login res => ',res);
                    dispatch(updatenotificationdata(res?.payload?.data))
                    setSpinner(false);
                    if(res?.payload?.status === 200){
                        resetForm();
                        props.setPageType("table")
                    }
                })
                .catch(err=>{
                    console.error(err);
                })
                
        }}
        >
        {({ values, setFieldValue, handleChange, handleBlur,resetForm }) => (
            <FormItem className='no-margin'>
                <DetailContentContainer>
                    <ContentRow>
                        <ContentTitle>
                            Name of the User
                        </ContentTitle>
                        {props.is_edit ? 
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <TextInputItem
                                    id="full_name"
                                    name="full_name"
                                    value={values.full_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Full Name'
                                />
                                <ErrorMessage name="full_name" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div> 
                        : 
                        <ContentValue>
                            {props.instance?.full_name}
                        </ContentValue>
                        }
                        
                    </ContentRow>
                    <ContentRow>
                        <ContentTitle>
                        Email
                        </ContentTitle>
                        {props.is_edit ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <TextInputItem
                                    id="email"
                                    name="email"
                                    type='email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Email'
                                />
                                <ErrorMessage name="email" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div> 
                        :
                            <ContentValue>
                                {props.instance?.email}
                            </ContentValue>
                        }
                        
                    </ContentRow>
                    <ContentRow>
                        <ContentTitle>
                            Phone Number
                        </ContentTitle>
                        {props.is_edit ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <PhoneInput getAllCountryCode={getAllCountryCode} name="phone_number" id="phone_number" value={values.phone_number}
                                    handleSelectChange={handleSelectChange} setFieldValue={setFieldValue} handleChange={handleChange} handleBlur={handleBlur} placeholder="Mobile Number" code_name="country_code"
                                    code_value={values.country_code}
                                    />
                                <ErrorMessage name="phone_number" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                        :
                        <ContentValue>
                            {props.instance?.country_code? "+" : null}{props.instance?.country_code} {props.instance?.phone_number}
                        </ContentValue>
                        }
                        
                    </ContentRow>
                    <ContentRow>
                    <ContentTitle>
                        Whatsapp Number
                    </ContentTitle>
                    
                        {props.is_edit ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <PhoneInput getAllCountryCode={getAllCountryCode} name="whatsapp_number" id="whatsapp_number" value={values.whatsapp_number}
                                    handleSelectChange={handleSelectChange} setFieldValue={setFieldValue} handleChange={handleChange} handleBlur={handleBlur} placeholder="Whatsapp Number" code_name="wcountrycode"
                                    code_value={values.wcountrycode}
                                    />
                                <ErrorMessage name="whatsapp_number" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                        :
                        <ContentValue>
                            {props.instance?.wcountrycode? "+" : null}{props.instance?.wcountrycode} {props.instance?.whatsapp}
                        </ContentValue>
                        }
                </ContentRow>
            </DetailContentContainer>
            <ButtonSection>
                {props.is_edit ? 
                    <>
                        {/* <AddButton title="Move To Archive" icon_type="archive" handleClick={handleArchive}  /> */}
                        <SubmitButton type='button' handleClick={()=> handleCancel(resetForm)} title="Cancel" ml="28px" is_icon={false} />
                        <SubmitButton type='submit' title="save" is_save={true} ml="28px" loading={spinner} />
                    </>
                    
                : null}
            </ButtonSection>
        </FormItem>
        )}
        
        </FormikForm>
        
        
        
    </>
    
  )
}

export default ProfileSection
