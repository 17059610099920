import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components/macro';
import SubmitButton from '../Common/SubmitButton';
import { IconImg } from '../../StyledComponents/Header';
import UploadButton from '../Common/UploadButton';
import { upload_rate } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

function RateUploadModal(props) {
    const token = localStorage.getItem('accessToken');
    const dispatch = useDispatch()
    const [state, setState] = useState({
        file: null,
        file_name: ""
    });
    const [spinner, setSpinner] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(()=>{
        if(props.modalOpen === true){
            setSelectedFile(null)
            setState((prevState) => {
                return {
                    ...prevState,
                    file: null,
                    file_name: "",
                    rateType: props.rateType
                }
            })
        }
    },[props.modalOpen])

    const handleClose = () => {
        props.setModalOpen()
        setSelectedFile(null)
    }



    const handleChange = (event) => {
        // if (info.file.status === 'done') {
        //     setSpinner(true)
        //     setState((prevState) => {
        //         return {
        //             ...prevState,
        //             file: info.file,
        //             file_name: info.file.name,
        //         }
        //     })
        //     setSpinner(false)
        // }
        setSelectedFile(event.target.files[0]);
      };
    const handleUpload = async() => {
        setSpinner(true)
        if((props.tabType === 'air' && props.subTabType === 'freight_rate') || (props.tabType === 'ocean' && (props.subOceanTabType === 'FCL' || props.subOceanTabType === 'LCL'))){
            let ratesheetfiletype = "Air"
            let oceanType = ""
            if(props.rateType === 'ocean'){
                ratesheetfiletype = "Ocean"
                oceanType = props.subOceanTabType
            }
            try {
                const formData = new FormData();
                formData.append('ratesheetfile', selectedFile);
                formData.append('ratesheetfiletype', ratesheetfiletype);
                formData.append('oceanType', oceanType);
                const result = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL_NODE}query/addFile`,
                formData,
                { headers: { Authorization: `Bearer ${token}`,"Content-Type": "multipart/form-data" } }
                );
                if(result.status === 200){
                    props.setCallList(true)
                    props.setModalOpen(false)
                    let data = {
                        message : result.data.message,
                        statusCode: 200,
                        success: result.data.success
                    }
                    dispatch(updatenotificationdata(data))
                    setSpinner(false)
            }
            } catch (error) {
                console.log(error);
                let data = {
                    message : error.response.data.message,
                    statusCode: 200,
                    success: error.response.data.success
                }
                dispatch(updatenotificationdata(data))
                setSpinner(false)
            }
        } else if(props.subTabType === 'value_added_service_rate' || props.subOceanTabType === 'value_added_service_rate'){
            let ratesheetfiletype = "Air"
            if(props.tabType === 'ocean'){
                ratesheetfiletype = "Ocean"
            }
            try {
                const formData = new FormData();
                formData.append('vasratesheetfile', selectedFile);
                formData.append('ratesheetfiletype', ratesheetfiletype);
                const result = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL_NODE}query/uploadvas`,
                formData,
                { headers: { Authorization: `Bearer ${token}`,"Content-Type": "multipart/form-data" } }
                );
                // console.log(result);
                if(result.status === 200){
                    props.setCallList(true)
                    props.setModalOpen(false)
                    let data = {
                        message : result.data.message,
                        statusCode: 200,
                        success: result.data.success
                    }
                    dispatch(updatenotificationdata(data))
                    setSpinner(false)
            }
            } catch (error) {
                console.log(error);
                let data = {
                    message : error.response.data.message,
                    statusCode: 200,
                    success: error.response.data.success
                }
                dispatch(updatenotificationdata(data))
                setSpinner(false)
            }
        }else if(props.subTabType === 'sur_charge' || props.subOceanTabType === 'sur_charge'){
            let ratesheetfiletype = "Air"
            if(props.tabType === 'ocean'){
                ratesheetfiletype = "Ocean"
            }
            try {
                const formData = new FormData();
                formData.append('surchargesheetfile', selectedFile);
                formData.append('surchargefiletype', ratesheetfiletype);
                const result = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL_NODE}query/uploadsurcharge`,
                formData,
                { headers: { Authorization: `Bearer ${token}`,"Content-Type": "multipart/form-data" } }
                );
                // console.log(result);
                if(result.status === 200){
                    props.setCallList(true)
                    props.setModalOpen(false)
                    let data = {
                        message : result.data.message,
                        statusCode: 200,
                        success: result.data.success
                    }
                    dispatch(updatenotificationdata(data))
                    setSpinner(false)
            }
            } catch (error) {
                console.log(error);
                let data = {
                    message : error.response.data.message,
                    statusCode: 200,
                    success: error.response.data.success
                }
                dispatch(updatenotificationdata(data))
                setSpinner(false)
            }
        }else if(props.tabType === 'transportation'){
            try {
                const formData = new FormData();
                formData.append('transportratesheet', selectedFile);
                const result = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL_NODE}query/transportrate`,
                formData,
                { headers: { Authorization: `Bearer ${token}`,"Content-Type": "multipart/form-data" } }
                );
                if(result.status === 200){
                    props.setCallList(true)
                    props.setModalOpen(false)
                    let data = {
                        message : result.data.message,
                        statusCode: 200,
                        success: result.data.success
                    }
                    dispatch(updatenotificationdata(data))
                    setSpinner(false)
            }
            } catch (error) {
                console.log(error);
                let data = {
                    message : error.response.data.message,
                    statusCode: 200,
                    success: error.response.data.success
                }
                dispatch(updatenotificationdata(data))
                setSpinner(false)
            }
        }
        
        // dispatch(upload_rate(state))
        // .then((res)=>{
        // })
        // .catch(err=>{
        //     console.error(err);
        // })

    }

    let title = ""
    if(props.tabType === 'transportation'){
        title = "Transportation Rate"
    }
    else if(props.tabType === 'air'){
        if(props.subTabType === 'freight_rate'){
            title = "Air Freight Rate"
        }else {
            title = "Air VAS Rate"
        }
    }else {
        if(props.subOceanTabType === 'FCL'){
            title = "Ocean FCL Rate"
        }else if(props.subOceanTabType === 'LCL'){
            title = "Ocean LCL Rate"
        }else {
            title = "Ocean VAS Rate"
        }
    }
    
  return (
    <ModalItem
        centered
        open={props.modalOpen}
        title={null}
        closable={false}
        footer={null}
      >
        <Container>
            <HeadDiv>
                <Title></Title>
                <Title>Upload</Title>
                <IconImg src={require('../../assets/images/close-circle.png')} onClick={()=> handleClose()} />
            </HeadDiv>
            <span>{title}</span>
            {/* <SubmitButton type='file' title="Add Rates" /> */}
            <UploadButton modalOpen={props.modalOpen} handleChange={handleChange} loading={spinner} file_name={selectedFile?.name} rateType={props.rateType} handleClick={handleUpload} />
            <span>{selectedFile?.name}</span>
            <UploadDetail>
                Upload latest rates here. Only xls,xlsx,csv files are accepted.
            </UploadDetail>
        </Container>
    </ModalItem>
  )
}

export default RateUploadModal


const ModalItem = styled(Modal)`
    &.ant-modal.css-dev-only-do-not-override-1i9hnpv {
        width: 592px !important;
        border-radius: 24px !important;
    }
    & .ant-modal-content {
        background: #FFFFFF !important;
        border-radius: 24px !important;
        padding: 25px 24px !important;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 160px !important;
`
const HeadDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
const UploadDetail = styled.span`
    display: inline-block;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #242424;
`
const Title = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #242424;
    margin-left: 10px;
`