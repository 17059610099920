import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { Content } from 'antd/es/layout/layout'
import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ShowComingSoon } from '../../functions/Utils'
import { updatenotificationdata } from '../../store/Common/CommonSlice'
import { kyc_verification } from '../../store/KYC/KYCVerificationSlice'
import { ButtonSubmit, ButtonView, CustomeButton, DocButton } from '../../StyledComponents/Common'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer } from '../../StyledComponents/CreateKeyAccountManager'
import { IconImg } from '../../StyledComponents/Header'
import { DocInput } from '../../StyledComponents/KYCVerifications'
import AddButton from '../Common/AddButton'
import SubmitButton from '../Common/SubmitButton'
import UploadButton from '../Common/UploadButton'
import UploadDocs from '../Common/UploadDocs'

function DocumentDetails(props) {
    const dispatch = useDispatch()
    const [activeButton, setActiveButton] = useState("");
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(null);
    const [rejectReason, setRejectReason] = useState("");
    const [showValidation, setValidation] = useState(false);
    const downloadLinkRef = useRef(null);
    const handleArchive = () => {
        console.log("archive");
    }
    useEffect(()=> {
        if(props.instance?.kyc_user?.reason_rejection){
            setRejectReason(props.instance?.kyc_user?.reason_rejection)
        }
    },[props.instance])
    const handleViewDoc = (imageUrl) => {
        if(imageUrl){
            window.open(imageUrl, '_blank');
        }
      };

    
    const handleDownload = async(imageUrl, file,index) => {
        setDownloadLoading(index)
        console.log(imageUrl);
        console.log(file);
        if(imageUrl){
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = 'image.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setDownloadLoading(null)
            });
        }else {
            setDownloadLoading(null)
        }
        
      };
    const handleDoc = (type) => {
        if(type === 'reject_kyc' && rejectReason === ""){
            setValidation(true)
        }else {
            setActiveButton(type)
            let values = {
                kyc_action: type,
                user_id: props.instance?.kyc_user?.id
            }
            if(type === 'accept_kyc'){
                setAcceptLoading(true)
            } else if(type === 'reject_kyc'){
                setRejectLoading(true)
                values.reason_for_rejection = rejectReason
            }
            dispatch(kyc_verification(values))
            .then(res=>{
                if(res?.payload?.data?.statusCode === 200 ){
                    // console.log(res?.payload?.data?.data);
                    dispatch(updatenotificationdata(res?.payload?.data))
                    setAcceptLoading(false)
                    setRejectLoading(false)
                    setRejectReason("")
                    props.setPageType("table")
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        
    }

  return (
    <>
        <DetailContentContainer>
            {props.instance?.documents? 
                JSON.parse(props.instance?.documents).map((i,index)=> 
                    <ContentRow className='documents mb-5' key={index} >
                        <ContentTitle className='document-title'>
                            {i.proof}
                        </ContentTitle>
                        <DocInput placeholder="Document Name" size='large' value={i.document_name} disabled />
                        <ButtonView onClick={()=> handleViewDoc(i?.full_path_name)}>View</ButtonView>
                        <ButtonView onClick={()=> handleDownload(i?.full_path_name,i?.proof,index)} loading={downloadLoading === index}>Download</ButtonView>
                        <a ref={downloadLinkRef} style={{ display: 'none' }}>
                            {/* This <a> tag will be hidden from the user */}
                        </a>
                        {/* <ButtonView>Accept</ButtonView>
                        <ButtonView>Reject</ButtonView> */}
                    </ContentRow>
                )
            : null}
            <ContentRow className='documents mb-5' >
                <ContentTitle className='doc-status'>
                    KYC Status
                </ContentTitle>
                <StatusText status={props.instance?.kyc_user?.accept_or_reject}>
                    {props.instance?.kyc_user?.accept_or_reject}
                </StatusText>
            </ContentRow>
            {props.instance?.kyc_user?.accept_or_reject === "Processing" ? 
                <ContentRow className='flex-end mt-9 gap-4'>
                    {/* <UploadDocs class='active-button' /> */}
                    <DocButton className={activeButton === 'accept_kyc'? 'active-button': ''} onClick={()=> handleDoc("accept_kyc")} size='large' loading={acceptLoading}>Accept KYC</DocButton>
                    <DocButton className={activeButton === 'reject_kyc'? 'active-button': ''} onClick={()=> setActiveButton("reject_kyc")} size='large'>Reject KYC</DocButton>
                    {/* <DocButton className={activeButton === 'move_archives'? 'active-button': ''} onClick={()=> setActiveButton("move_archives")} size='large'>Move to Archives</DocButton> */}
                </ContentRow>
            : 
                null
            }
            
        </DetailContentContainer>
        {activeButton === 'reject_kyc' || props.instance?.kyc_user?.accept_or_reject === "Rejected" ? 
            <div className='mt-8'>
                <SmallHead>State Reason for Rejection</SmallHead>
                <DetailContentContainer className='mt-2'>
                    <TextArea rows={6} maxLength={150} value={rejectReason? rejectReason : ""} disabled={props.instance?.kyc_user?.accept_or_reject === "Rejected"} onChange={(e)=> (setRejectReason(e.target.value),setValidation(false))} placeholder="Type your message" style={{ position: 'relative' }} />
                    {showValidation ? <ValMsg>Reason is Required</ValMsg>: null}
                    {/* <div
                        style={{
                            position: 'absolute',
                            bottom: '82px',
                            right: '29px',
                        }}
                        >
                        <IconImg src={require('../../assets/images/folder-add.png')} />
                    </div> */}
                </DetailContentContainer>
                {props.instance?.kyc_user?.accept_or_reject === "Rejected" ? null :
                    <SendButtonSection className='mt-3'>
                        <SubmitButton type='submit' title="Send" handleClick={()=> handleDoc("reject_kyc")} loading={rejectLoading} />
                    </SendButtonSection>
                }
            </div>
        : null}
        
    </>
    
  )
}

export default DocumentDetails

const SmallHead = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #242424;
`
const SendButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const ValMsg = styled.span`
    color: red;
`
const StatusText = styled.span`
    color: ${({status})=> status === 'Accepted' ? '#08C004': status === 'Rejected'? '#FF3F3B' :status === 'Processing'? '#FFAF00':'#3F4254' };
    font-weight: 700;
`