import React,{ useEffect, useState } from 'react'
import { Divider, Input, Select, Spin, Tabs } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableInput, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem, TableFilter } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { ErrorMessage } from 'formik';
import { create_rate, move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { countries_list, shipment_list, shipport_list, updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
import SelectSearchAirline from '../Common/SelectSearchAirline';
import SelectSearchPort from '../Common/SelectSearchPort';
import styled from 'styled-components';
import * as Yup from 'yup'
import { formatDate, GetContainerSizeLabel, handleKeyPress } from '../../functions/Utils';
import { SpinContainer, TextAreaItem } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { TableTab } from '../../StyledComponents/Common';
import SelectSearchOceanPort from '../Common/SelectSearchOceanPort';
import SelectSearchShipline from '../Common/SelectSearchShipline';
import DateInput from '../Common/DateInput';
import FormikDateInput from '../Common/FormikDateInput';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import RadioGroups from '../Common/RadioGroups';
import SelectSearchShiplineSurcharge from '../Common/SelectSearchShiplineSurcharge';
import SelectSearchCountry from '../Common/SelectSearchCountry';
import SelectSearchVASOceanPort from '../Common/SelectSearchVASOceanPort';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

const { Option } = Select;

const validationSchema = Yup.object().shape({
    shipper_line: Yup.string().nullable().required('Shipper Line is required'),
    country: Yup.string().nullable().required('Country required'),
    chargeName: Yup.string().nullable().required('Charge Name is required'),
    charge: Yup.string().nullable().required('Charge is required'),
    chargeBasis: Yup.string().nullable().required('Charge Basis required'),
    gst: Yup.string().nullable().required('GST is required'),
    gstBasis: Yup.string().nullable().required('GST Basis is required'),
    POL: Yup.string().nullable().required('POL is required'),
    POD: Yup.string().nullable().required('POD is required'),
})

function OceanSurchargeDetailsPage(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [archieve_loading, setArchiveLoading] = useState(false);
    const [type, setType] = useState(props.type);
    // const [tabType, setTabType] = useState("air");
    const [state, setState] = useState({
        shipline_list: [],
        countrie: [],
        shipport_list: [],
    });

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/rate-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=> {
        setState((prevState) => {
            return {
                ...prevState,
                shipline_list: props?.shipline_list,
                shipport_list: props?.shipport_list
            }
        })
    },[props?.shipline_list,props?.shipport_list])

    useEffect(()=>{
        // dispatch(shipment_list())
        // .then(res=>{
        //     // console.log('login res => ',res);
        //     if(res?.payload?.status === 200 ){
        //         setState((prevState) => {
        //             return {
        //                 ...prevState,
        //                 shipline_list: res?.payload?.data?.data?.data
        //             }
        //         })
        //     }
        // })
        // .catch(err=>{
        //     console.error(err);
        // })

        dispatch(countries_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                const countries = res?.payload?.data?.data.filter((item, index, arr) => {
                    return arr.findIndex(t => t.country_name === item.country_name) === index;
                  });
                //   console.log(countries);
                setState((prevState) => {
                    return {
                        ...prevState,
                        countries: countries
                    }
                })
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })

        // dispatch(shipport_list())
        // .then(res=>{
        //     // console.log('login res => ',res);
        //     if(res?.payload?.status === 200 ){
        //         const shipports = res?.payload?.data?.data
        //         const indianShipports = shipports.filter(shipport => shipport.country_name === 'INDIA' || shipport.country_name === 'India');
        //         indianShipports.sort((a, b) => a.port_name.localeCompare(b.port_name));
                
        //         const foreignShipports = shipports.filter(shipport => shipport.country_name !== 'India' || shipport.country_name !== 'INDIA');
        //         foreignShipports.sort((a, b) => {
        //             if (a.country === b.country) {
        //                 return a.port_name.localeCompare(b.port_name);
        //             }
        //             return a.country.localeCompare(b.country);
        //         });
        //         const sortedShipports = indianShipports.concat(foreignShipports);
        //         setState((prevState) => {
        //             return {
        //                 ...prevState,
        //                 shipport_list: sortedShipports
        //             }
        //         })
        //         setLoading(false)
        //     }
        // })
        // .catch(err=>{
        //     console.error(err);
        // })
        // setLoading(false)
    },[])

    const handleCancel = (resetForm) => {
        setType("view")
        props.setType("view")
        resetForm()
    }

    const handleEdit = () => {
        setType("edit")
        props.setType("edit")
    }
    const handleSelectChange = (e,name,setFieldValue)=> {
        setFieldValue([name],e)
    }

    const handleArchive = () => {
        setArchiveLoading(true)
        let rate_id = props.instance._id
          const values = {
            rate_id : rate_id,
            customType: "Ocean"
          }
          dispatch(move_rate_archive(values))
          .then(res=>{
              if(res?.payload?.data?.statusCode === 200 ){
                //   console.log(res?.payload?.data?.data);
                  dispatch(updatenotificationdata(res?.payload?.data))
                  setArchiveLoading(false)
                  props.setPageType("table")
                  props.setCallList(true)
              }
          })
          .catch(err=>{
              console.error(err);
          })
      }

    const DateChange = (date, dateString,setFieldValue) => {
        setFieldValue("validity", date)
    };

    const initialValues = {
        gstBasis: props.instance?.gstBasis ? props.instance?.gstBasis : null,
        gst: props.instance?.gst ? props.instance?.gst : null,
        chargeBasis: props.instance?.chargeBasis ? props.instance?.chargeBasis : null,
        charge: props.instance?.charge ? props.instance?.charge : null,
        chargeName: props.instance?.chargeName ? props.instance?.chargeName : null,
        country: props.instance?.country ? props.instance?.country : null,
        shipper_line: props.instance?.shipper_line ? props.instance?.shipper_line : null,
        customType: "Ocean",
        id: props.instance?._id ? props.instance?._id : '',
        POL: props.instance?.pol ? props.instance?.pol : null,
        POD: props.instance?.pod ? props.instance?.pod : null,
      }
   
  

    const chargeBasises = [
        {
            value: "FLAT",
            name: "FLAT"
        },
        {
            value: "HBL",
            name: "HBL"
        },{
            value: "Shipment",
            name: "Shipment"
        }
    ]

    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
        return (
            <FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async(values,{resetForm}) => {
                    setSpinner(true)
                    let data = {
                        "customType": "Ocean",
                        "GST Basis": values.gstBasis,
                        "GST": values.gst,
                        "Charge Basis": values.chargeBasis,
                        "Charge": values.charge,
                        "Charge Name": values.chargeName,
                        "Country": values.country,
                        "Shipper Line": values.shipper_line,
                        "POL": values.POL,
                        "POD": values.POD
                    }
                    try {
                        const result = await axios.post(
                            `${process.env.REACT_APP_API_BASE_URL_NODE}query/addsurcharge`,
                            data,
                            { headers: { Authorization: `Bearer ${token}` } }
                            );
                            if(result.status === 200){
                                let data = {
                                    message : result.data.message,
                                    statusCode: 200,
                                    success: result.data.status
                                }
                                dispatch(updatenotificationdata(data))
                                setSpinner(false);
                                resetForm()
                                props.setCallList(true)
                                props.setPageType("table")
                            }
                    } catch (error) {
                        console.log(error);
                        setSpinner(false);
                    }
                    
            }}
            >
            {({ values,setFieldValue, handleChange, handleBlur, resetForm,errors }) => (
                <FormItem>
                    <DetailContainer>
                    <HeadContainer>
                    <TableFilter>
                        <>
                        {/* {type === 'create'? 
                            <>
                                <TableTab className='first' active={props.tabType === 'air'} onClick={()=> props.setTabType('air')}>Air</TableTab>
                                <Divider type="vertical" />
                            </>
                        : null} */}
                        <TableTab className={type === 'create'? "first" : 'single-tab'} active={props.tabType === 'ocean'} onClick={()=> props.setTabType('ocean')}>Ocean</TableTab>
                        </>
                        <TableFilter className='mb-2'>
                            <RadioGroups datas={props.sub_filter} value={props.subTabType} handleClick={Object.keys(props.instance).length === 0 ? props.handleRadioClick : null} name="ocean" />
                        </TableFilter>
                    </TableFilter>
                    {/* <Title>Air</Title> */}
                    <TopButtonDiv className='mb-3'>
                            {/* {type === 'view'?
                                <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} />
                            : null} */}
                        {type === 'edit' || type === 'create' ? 
                        <>  {type === 'edit' ? <SubmitButton type='button' title="Cancel" is_icon={false} handleClick={()=> handleCancel(resetForm)} /> : null}
                            <SubmitButton type='submit' title="save" is_save={true} loading={spinner} />
                        </>
                        : <SubmitButton type="button" title="Edit" icon_type="edit" handleClick={()=> handleEdit()} /> }
                    </TopButtonDiv>
                    
            </HeadContainer>
                <TopContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>POL*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="POL" setFieldValue={setFieldValue} value={values.POL} options={state.shipport_list} width="100%" bradius="8px" placeholder="POL" />
                                <ErrorMessage name="POL" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.POL}</RowValue> }
                            
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Shipper Line*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchShiplineSurcharge name="shipper_line" value={values.shipper_line} setFieldValue={setFieldValue} options={state.shipline_list} 
                                width="100%" bradius="8px" placeholder="Shipper Line" />
                                <ErrorMessage name="shipper_line" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.shipper_line}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Charge Name*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="chargeName"
                                    name="chargeName"
                                    value={values.chargeName}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Charge Name'
                                    onChange={handleChange}
                                    // onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="chargeName" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.chargeName}</RowValue> }
                        </DetailRow>
                        
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Charge Basis*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <ItemSelect
                                    size="large"
                                    id="chargeBasis"
                                    name="chargeBasis"
                                    placeholder="Charge Basis"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => handleSelectChange(e,"chargeBasis",setFieldValue)}
                                    options={chargeBasises}
                                    value={values.chargeBasis}
                                />
                                <ErrorMessage name="chargeBasis" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.chargeBasis}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>GST Basis*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <ItemSelect
                                    size="large"
                                    id="gstBasis"
                                    name="gstBasis"
                                    placeholder="GST Basis"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => handleSelectChange(e,"gstBasis",setFieldValue)}
                                    options={chargeBasises}
                                    value={values.gstBasis}
                                />
                                <ErrorMessage name="gstBasis" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.gstBasis}</RowValue> }
                        </DetailRow>
                        
                        
                    </LeftContent>
                    <LeftContent>
                    <DetailRow>
                            <TitleDiv>
                                <TitleRow>POD*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="POD" setFieldValue={setFieldValue} value={values.POD} options={state.shipport_list} width="100%" bradius="8px" placeholder="POD" />
                                <ErrorMessage name="POD" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.POD}</RowValue> }
                            
                        </DetailRow>

                        <DetailRow>
                                <TitleDiv>
                                <TitleRow>Country*</TitleRow>
                                </TitleDiv>
                                {type === 'edit' || type === 'create' ?
                                <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                    <SelectSearchCountry name="country" value={values.country} setFieldValue={setFieldValue} options={state.countries} 
                                    width="100%" bradius="8px" placeholder="Country" />
                                    <ErrorMessage name="country" component="div" style={{ color: 'red',fontSize: '12px' }} />
                                </div>
                                :
                                <RowValue>{values.country}</RowValue> }
                            </DetailRow>

                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Charge*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="charge"
                                    name="charge"
                                    value={values.charge}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Charge'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="charge" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.charge}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>GST*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="gst"
                                    name="gst"
                                    value={values.gst}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='GST'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="gst" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.gst}</RowValue> }
                        </DetailRow>
                        <DetailRow></DetailRow>
                    </LeftContent>
                </TopContent>
                
            </DetailContainer>
                </FormItem>
            )}
            </FormikForm>
            
        )
    }
}

export default OceanSurchargeDetailsPage


const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important
    }
    /* & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    } */
`