import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Authentication/AuthSlice";
import CommonSlice from "./Common/CommonSlice";

export const store = configureStore({
    reducer:{
        userlogin : AuthSlice,
        commonapi: CommonSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })    
})