import React, { useEffect } from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

function GroupBarChart() {
  var barChartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    datasets: [
      {
        barPercentage: 1,
        categoryPercentage: 0.8,
        label: "Past Month",
        backgroundColor: "#165DFF",
        borderColor: "#165DFF",
        borderWidth: 1,
        data: [4, 7, 3, 6, 10, 7, 4, 6],
      },
      {
        barPercentage: 1,
        categoryPercentage: 0.8,
        label: "This Month",
        backgroundColor: "#14C9C9",
        borderColor: "#14C9C9",
        borderWidth: 1,
        data: [3, 5, 6, 7, 3, 5, 6, 7],
      },
    ],
  };

  var chartOptions = {
    // maintainAspectRatio: false,
    responsive: true,
    layout: {
        autoPadding: true,
        padding: {
          top: 50
        }
      },
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Growth Chart",
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Growth →",
          font: {
            size: 13,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Time →",
          font: {
            size: 13,
          },
        //   padding: {
        //     top: 20,
        //   },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
      tooltip: {
        enabled: true,
        position: "average",
        yAlign: "bottom",
        bodyAlign: "center",
        intersect: true,
        backgroundColor: "#FFFFFF",
        bodyColor: "#242424",
        titleColor: "#242424",
        borderColor: "rgba(222, 200, 2)",
        borderWidth: "2",
        bodySpacing: "4",
        padding: "14",
        cornerRadius: "5",
        displayColors: false,
        // xAlign: 'bottom'
      },
    },
  };

  return (
    <ChartContainer>
      <BarItem data={barChartData} options={chartOptions} height={200} />
    </ChartContainer>
  );
}

export default GroupBarChart;

const ChartContainer = styled.div`
  height: 100%;
`;
const BarItem = styled(Bar)`
  /* height: 400px !important; */
`