import React, { useState } from 'react';
import { Table, Pagination } from 'antd';
import { TableItem } from '../../StyledComponents/Common';
import styled from 'styled-components';



const PaginationTable = (props) => {
    const handlePageChange = (page) => {
        props.setCurrentPage(page);
    };
    const paginationConfig = {
        current: props.currentPage,
        pageSize: props.pageSize,
        total: props.data.length,
        onChange: handlePageChange,
    };
    const slicedData = props?.data.slice((props.currentPage - 1) * props.pageSize, props.currentPage * props.pageSize);

  return (
    <TableContainer>
      <TableItem 
        rowKey={props.rowKey ? props.rowKey : "id"}
        name={props.name}
        columns={props.columns}
        dataSource={slicedData} 
        scroll={props.scroll ? {x: 1300,} : null}
        pagination={paginationConfig}
        fpadding={props.fpadding}
        showScroll={props.showScroll} 
        className={props.className? props.className : ""} />
    </TableContainer>
  );
};

export default PaginationTable;

const TableContainer = styled.div`
  /* overflow-x: hidden;
  width: 100%; */
  
`