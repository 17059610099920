import TextArea from "antd/es/input/TextArea";
import styled from "styled-components/macro";


export const RightSection = styled.div`
    /* width: 65%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 1188px) {
      flex-wrap: wrap;
    }
`

export const FilterSection = styled.div`
    margin-right: 22px;
    &.button-section {
        margin-right: 10px;
    }
    display: flex;
    gap: 10px;
    align-items: center;
    @media (max-width: 1188px) {
        &.filter {
            margin-bottom: 10px;
        }
    }
`
export const DateSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const LabelItem = styled.span`
    margin-right: 15px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #181C32;
`

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.column {
        flex-direction: column;
    }
`
export const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
export const QuoteContainer = styled.form`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 20px 20px;
    margin-top: 35px;
    &.VAS {
        height: 100%;
    }
`
export const SummaryContainer = styled.div`
    width: 34%;
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 20px 0px;
    margin-top: 35px;
`
export const ContentSection = styled.div`
    /* margin-top: 25px; */
    margin-bottom: 224px;
`
export const ContentRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: 22px;
    &.flex-start {
        justify-content: flex-start;
    }
    &.summary {
        margin-top: 12px;
    }
    &.wid-50 {
        width: 50%;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.sub-head {
        width: 100%;
    }
    &.align-start {
        align-items: flex-start;
    }
`
export const Seperator = styled.div`
    width: 100%;
    margin: 20px 0px;
    border-bottom: 3px dotted #E4E6EF;
`
export const LableText = styled.span`
    width: 35%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #B5B5C3;
    &.summary {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #424242;
        width: 55%;
    }
    &.summary-value {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #242424;
        width: 45%;
        word-break: break-all;
    }
    &.content {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #3F4254;
    }
    &.services {
        width: 50%;
    }
    &.Airline {
        width: 36%;
    }
    &.sum-rate {
        width: 39%;
    }
    &.remarks {
        width: 22.5%;
    }
    &.wdt-28 {
        width: 28%;
    }
    &.break-word {
        word-break: break-all;
    }
    &.wd-20 {
        width: 20%;
    }
    &.wd-33 {
        width: 33%;
    }
    &.full-width {
        width: 100%;
    }
`
export const AmountDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 65%;
`
export const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
`
export const SummaryContent = styled.div`
    /* padding: 0px 35px; */
`
export const SubheadTitle = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFAF00;
    margin: 30px 0px;
    &.head {
        font-weight: 500;
        font-size: 13px;
    }
`
export const SubTitleDiv = styled.div`
    margin: 15px 0px;
    &.head {
        margin: 25px 0px;
    }
`
export const TopContent = styled.div`
    padding: 0px 35px;
    &.bottom {
        padding: 0px 10px;
    }
`
export const BottomContent = styled.div`
    padding: 0px 25px;
`
export const AirlineContainer = styled.div`
    
`
export const ServiceSection = styled.div`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 20px 20px;
    margin-top: 35px;
`
export const ServiceHeadContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const ServiceHead = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
`

export const RateSection = styled.div`
    display: flex;
    align-items: center;
    gap: 50;
    &.button-section {
        justify-content: space-between;
        width: 100%;
    }
`
export const FrightRateSection = styled.div`
    width: 33%;
`
export const VASRateSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items: center;
`
export const RateValue = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #B5B5C3;
`
export const SpinContainer = styled.div`
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.user-quote {
        height: 20vh;
    }
`
export const ExpirySection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
`

export const RemarksLabel = styled.span`
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 700;
    color: #FFAF00;
    cursor: pointer;
`
export const TextAreaItem = styled(TextArea)`
    &.ant-input {
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
        &.container {
            width: 40% !important;
        }
    }
    
`