import { Content } from 'antd/es/layout/layout'
import React, {useEffect, useState} from 'react'
import { ButtonSubmit, TableTab } from '../../StyledComponents/Common'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer, TableHead } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SimpleTable from '../Common/SimpleTable'
import SubmitButton from '../Common/SubmitButton'
import { Divider, Modal, Spin } from 'antd';
import { ShowComingSoon } from '../../functions/Utils'
import { get_query_list_kam } from '../../store/KAM/KAMSlice'
import { useDispatch } from 'react-redux'
import { SpinContainer } from '../../StyledComponents/Queries'
import SimpleTableScroll from '../Common/SimpleTableScroll'

const { confirm } = Modal;

function QuoteSection(props) {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "air_admin"
    const dispatch = useDispatch()
    const [tabType, setTabType] = useState(userroles === 'ocean_admin'?"ocean" : "air");
    const [loading, setLoading] = useState(true)
    const [quoted_data, setQoutedData] = useState([]);
    const [max_length, setMaxLength] = useState([]);
    
    const handleArchive = () => {
        console.log("archive");
    }

    useEffect(()=>{
        if(props.instance) {
          let admin_role = ""
          if(userroles === 'air_admin'){
            admin_role = "Air"
          }else if(userroles === 'ocean_admin'){
            admin_role = "Ocean"
          }
          let quoted_params = {
            kam_id: props.instance.id,
            admin_role: admin_role
          }
          dispatch(get_query_list_kam(quoted_params))
          .then(res=>{
              // console.log('login res => ',res);
              if(res?.payload?.status === 200 ){
                let data = res?.payload?.data?.nodeData?.data
                setQoutedData(data)
                if(data?.filter((i)=> i.status === 'Quoted').length > data?.filter((j)=> j.status === 'Booked').length){
                  setMaxLength(data?.filter((i)=> i.status === 'Quoted').length)
                }else {
                  setMaxLength(data?.filter((j)=> j.status === 'Booked').length)
                }
                setLoading(false)
              }
          })
          .catch(err=>{
              console.error(err);
          })

        }
        
      },[props.instance])

    const handleView = (record) => {
        props.setQueryInstance(record)
    }
    const columns = [
        {
            title: 'SL No.',
            dataIndex: 'sl_no',
            // align: 'center',
            // width: '20%',
            render: (text, record,index) => (
                <span style={{ fontSize: '12px',fontWeight: '500',fontFamily: 'Rubik',fontStyle: 'normal', color: '#B5B5C3' }}>{index + 1}</span>
              ),
        },
        {
            title: 'Link To Quotes',
            dataIndex: 'link_to_quotes',
            width: '45%',
            // align: 'center',
            render: (text, record, index) => {
                try {
                  if (quoted_data.filter((i)=> i.status === 'Quoted')[index]) {
                    return <AddButton  data={record} title="View Quote" handleClick={()=> handleView(quoted_data.filter((i)=> i.status === 'Quoted')[index])} is_icon={false} />;
                  }
                } catch(error) {
                  return null;
                }
                return null;
              },
        },
        {
            title: 'Link To Accepted Quotes',
            dataIndex: 'link_to_accepted_quotes',
            width: '45%',
            // align: 'center',
            render: (text, record, index) => {
              try {
                if (quoted_data.filter((i)=> i.status === 'Booked')[index]) {
                  return <AddButton  data={record} title="View Quote" handleClick={()=> handleView(quoted_data.filter((i)=> i.status === 'Booked')[index])} is_icon={false} />;
                }
              } catch(error) {
                return null;
              }
              return null;
            },
            // render: (text, record, index) => {
            //     if (record.status === 'Booked') {
            //       return <AddButton  data={record} title={record.queryId} handleClick={()=> ShowComingSoon()} is_icon={false} />;
            //     }else {
            //         for (let i = index + 1; i < quoted_data.length; i++) {
            //             if (quoted_data[i].status === 'Booked') {
            //               return <AddButton  data={record} title={record.queryId} handleClick={()=> ShowComingSoon()} is_icon={false} />
            //             }
            //           }
            //     }
            //     return null;
            //   },
        }
      ];

      const data = [
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
       
        // ... additional data
      ];

  return (
    <>
        <DetailContentContainer className='quote_section'>
            {userroles === 'air_admin'?
                <TableHead>
                    <TableTab active={tabType === 'air'} onClick={()=> setTabType('air')}>Air</TableTab>
                </TableHead>
            : userroles === 'ocean_admin'?
              <TableHead>
                  <TableTab active={tabType === 'ocean'} onClick={()=> setTabType('ocean')}>Ocean</TableTab>
              </TableHead>
            :
              <TableHead>
                  <TableTab active={tabType === 'air'} onClick={()=> setTabType('air')}>Air</TableTab>
                      <Divider type="vertical" />
                  <TableTab active={tabType === 'ocean'} onClick={()=> setTabType('ocean')}>Ocean</TableTab>
              </TableHead>
            }
            
            {tabType === 'air' ?
                loading ? 
                <SpinContainer className='user-quote' >
                  <Spin />
                </SpinContainer>
                :
                <SimpleTableScroll currentPage={1} columns={columns} data={quoted_data} pageSize={max_length} scroll={true} showScroll={true} rowKey="queryId" fpadding="15px 16px" className="quote_section" />
             :
            //  <div style={{ padding: '25px 0px'}}>
            //     <h1>Coming Soon...</h1>
            //  </div>
                loading ? 
                  <SpinContainer className='user-quote' >
                    <Spin />
                  </SpinContainer>
                  :
                  <SimpleTableScroll currentPage={1} columns={columns} data={quoted_data} pageSize={max_length} scroll={true} showScroll={true} rowKey="queryId" fpadding="15px 16px" className="quote_section" />
              }
        {/* <div style={{ position: 'absolute', bottom: '10px',left: '50%', transform: 'translateX(-50%'}}>
          <ButtonSubmit type='button'>Load More</ButtonSubmit>
        </div> */}
        </DetailContentContainer>
        
        <ButtonSection>
            {/* <AddButton title="Move To Archive" icon_type="archive" handleClick={handleArchive}  />
            <SubmitButton type='button' title="save" is_save={true} ml="28px" /> */}
        </ButtonSection>
    </>
    
  )
}

export default QuoteSection
