import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile } from "../../axios/api"

export const get_kam_list = createAsyncThunk('get_kam_list', async ()=>{
    try{
        const url = 'admin/list_kam'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const create_kam = createAsyncThunk('create_kam', async (values)=>{
    try{
        const url = 'admin/create_kam'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_query_list_kam = createAsyncThunk('get_query_list_kam', async (values)=>{
    try{
        const url = 'admin/get_create_accept_quotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})


export const update_kam = createAsyncThunk('update_kam', async (values)=>{
    try{
        const url = 'admin/update_kam'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const move_kam_archive = createAsyncThunk('move_kam_archive', async (values)=>{
    try{
        const url = 'admin/kam_move_to_archive'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
}

const kamSlice = createSlice({
    name : 'kam',
    initialState,
})
export default kamSlice.reducer