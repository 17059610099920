import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_archive_list = createAsyncThunk('get_archive_list', async ()=>{
    try{
        const url = 'admin/archive_list_for_users'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const get_rate_archive_list = createAsyncThunk('get_rate_archive_list', async ()=>{
    try{
        const url = 'admin/archiverates'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const change_unarchive = createAsyncThunk('change_unarchive', async (values)=>{
    try{
        const url = 'admin/unarchive'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const change_unarchive_rate = createAsyncThunk('change_unarchive_rate', async (values)=>{
    try{
        const url = 'admin/unarchiverate'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const delete_archive = createAsyncThunk('delete_archive', async (values)=>{
    try{
        const url = 'admin/delete_from_archive'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const delete_rate_archive = createAsyncThunk('delete_rate_archive', async (values)=>{
    try{
        const url = 'admin/rate_delete_form_archive'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})



const initialState = {
    data:{},
    loading:false,
}

const archiveSlice = createSlice({
    name : 'archive',
    initialState,
})
export default archiveSlice.reducer