import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { IconImg } from '../../StyledComponents/Header'

function ButtonRightImg(props) {
  return (
    <div>
      <CustomeButton onClick={props.handleClick ? props.handleClick: null} loading={props.loading? props.loading : false}>
       {props.title}
        <IconImg src={require('../../assets/images/arrow-right.png')} />
      </CustomeButton>
    </div>
  )
}

export default ButtonRightImg

const CustomeButton = styled(Button)`
    color: #FFAF00;
    border-color: #FFAF00;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 400;
    position: relative;
    width: 110px;
    padding-right: 35px;
    :hover {
        color: #FFAF00 !important;
        border-color: #FFAF00 !important;
    }
    img {
        margin-left: 10px;
        /* margin: 0px 10px 4px 0px; */
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
    }
`