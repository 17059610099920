import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import AcceptedQuoteOceanUserDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteOceanUserDetails'
import AcceptedQuoteOceanUserDetailsKAM from '../components/AcceptedQuoteDetails/AcceptedQuoteOceanUserDetailsKAM'
import AcceptedQuoteUserDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteUserDetails'
import AcceptedQuoteUserDetailsKAM from '../components/AcceptedQuoteDetails/AcceptedQuoteUserDetailsKAM'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import OceanViewPage from '../components/Queries/OceanViewPage'
import ViewPage from '../components/Queries/ViewPage'
import UserDetails from '../components/UserManagement/UserDetails'
import { get_user_list } from '../store/UserManagement/UserManagementSlice'
import { LeftHead, SubHeadContainer, SubHeadTitle, TablePagination } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { SpinContainer } from '../StyledComponents/Queries'

function UserManagement() {
    const dispatch = useDispatch()
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState("1");
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=>{
        if(pageType === 'table'){
            setQueryInstance({})
            dispatch(get_user_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.data)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[pageType])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const handleBackArrow = () => {
        if(query_instance.status === 'Booked'){
            setCurrentTab("2")
          }else if(query_instance.status === 'Quoted'){
            setCurrentTab("3")
          }
          setQueryInstance({})
      }


    const columns = [
        {
            title: 'NAME OF THE USER',
            dataIndex: 'full_name',
        },
        {
            title: 'EMAIL ADDRESS',
            dataIndex: 'email',
        },
        {
            title: 'MOBILE NUMBER',
            dataIndex: 'phone_number',
        },
        {
            title: 'WHATSAPP NUMBER',
            dataIndex: 'whatsapp',
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];
    console.log(query_instance,"query_instancequery_instance");
    
  return (
    <MainContainer>
      <CustomeHeader title="User List" description="View all users assigned  to you" />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>User List</TableTitle>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} />
            }
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
        <>
            {query_instance?.queryId? 
                query_instance.status === "Quoted" ? 
                    query_instance.queryType === 'Ocean Manual' || query_instance.queryType === 'Ocean Automatic'?
                        <OceanViewPage setPageType={setPageType} instance={query_instance} parent_type="user_management" setQueryInstance={setQueryInstance} setCurrentTab={setCurrentTab} />:
                        <ViewPage setPageType={setPageType} instance={query_instance} parent_type="user_management" setQueryInstance={setQueryInstance} setCurrentTab={setCurrentTab} />
                         :
                    <ContentContainer>
                        <SubHeadContainer className='flex-column'>
                            <LeftHead>
                                <IconImg onClick={()=> handleBackArrow()} src={require('../assets/images/back_page_arrow.png')} />
                                <SubHeadTitle>Details</SubHeadTitle>
                            </LeftHead>
                        </SubHeadContainer>
                        {query_instance.queryType === 'Ocean Manual' || query_instance.queryType === 'Ocean Automatic'?
                            <AcceptedQuoteOceanUserDetailsKAM instance={query_instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                        :
                            <AcceptedQuoteUserDetailsKAM instance={query_instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                        }
                        {/* <AcceptedQuoteUserDetailsKAM instance={query_instance} setQueryInstance={setQueryInstance} setPageType={setPageType} /> */}
                    </ContentContainer>
            : 
            <ContentContainer>
                <ContentHead title="User Details"  handleClick={()=> setPageType("table")} />
                <UserDetails instance={instance} setQueryInstance={setQueryInstance} tab={currentTab} setPageType={setPageType} />
            </ContentContainer> }
            
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default UserManagement
