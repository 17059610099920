import styled from "styled-components";

 export const HeadName = styled.span`
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #181C32;
    font-family: 'rubik';
    font-style: normal;
    /* margin-left: 10px; */
 `
export const HeadShort = styled.span`
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #B5B5C3;
    font-family: 'rubik';
    font-style: normal;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 10px 20px;
`

export const IconImg = styled.img`
    cursor: pointer;
    display: inline-block;
    &.rotation {
        transform: rotate(180deg);
    }
`
export const TopSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
export const RightHeader = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 10px;
    /* width: 17%; */
`
export const NameDiv = styled.div`
    display: flex;
    flex-direction: column;
`
export const NameSpan = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #FFAF00;
    text-align: right;
`
export const ProfileDiv = styled.div`
    padding: 4px;
    border: 1px solid #FFAF00;
    color: #FFAF00;
    width: 38px;
    text-align: center;
    border-radius: 4px;
`
export const ProfileSpan = styled.span`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
`