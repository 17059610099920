import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import backarrow from '../../assets/images/back_arrow.png'
import arrowswap from '../../assets/images/arrow-swap.png'
import { Collapse } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import airline from '../../assets/images/active-flight-icon.svg'
import styled from 'styled-components'
import { view_query, view_query_user, view_query_user_kam } from '../../store/Queries/QuerySlice'
import { useDispatch } from 'react-redux'
import { getFormattedDateTime } from '../../functions/Utils'


const AcceptedQuoteOceanUserDetailsKAM = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    const [flightData, setFlightData] = useState([])
    const [nodeData, setNodeData] = useState([])
    const [querySearch, setQuerySearch] = useState([])
    useEffect(()=> {
        if(location.pathname === '/user-management-admin'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=>{
        let data = {
          queryId: props.instance.queryId,
          type: props.instance.queryType,
          status: props.instance.status,
        }
        dispatch(view_query_user_kam(data))
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.data?.statusCode === 200){
                const nodeData = res?.payload?.data?.result
                let flightData = JSON.parse(nodeData?.bookeditem)
                let querySearch = JSON.parse(nodeData?.search_query_data)
                setFlightData(flightData)
                setNodeData(nodeData)
                setQuerySearch(querySearch)
            }
        })
        .catch(err=>{
            console.error(err);
        })
        
      },[props.instance])


    // const nodeData = props.instance
    // const flightData = nodeData?.output
    const originCharges = flightData?.individualVASRate?.originCharges
    const destinationCharges = flightData?.individualVASRate?.destinationCharges

    let OriginSum = 0
    if(originCharges && originCharges.length){
        OriginSum = originCharges.reduce((acc, cur) => acc + parseInt(cur.originValue), 0);
    }
    let DestinationSum = 0
    if(destinationCharges && destinationCharges.length){
        DestinationSum = destinationCharges.reduce((acc, cur) => acc + parseInt(cur.destValue), 0);
    }

    const CustomHeader = ({ heading, value }) => {
        return (
          <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'space-between',fontWeight: '700' }}>
            <p>{heading}</p>
            <p>$ {Number(value).toFixed(2)}</p>
          </div>
        );
      };
    return (
        <div className="grid grid-cols-12 gap-4 relative">
            {
                flightData && 
                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-12 relative" >
                    <div className="w-full rounded-2xl shadow-cardsection my-5">
                        <div className="flex items-center gap-5 bg-headerbg rounded-t-2xl px-10 py-5" style={{background: 'rgba(255,175,0,.47)'}}>
                            <div className="rounded-lg w-14 h-14 flex items-center justify-center bg-white p-1">
                                <img src={flightData?.logo ? flightData?.logo : airline} alt={(flightData?.airlines)?.split('-(')[0]} className="rounded-lg overflow-hidden w-full" />
                            </div>
                            <div className="flex items-center gap-5">
                                <p className="flex text-secondary font-medium text-base leading-5">{flightData?.originPortShort}</p>
                                <img src={arrowswap} alt="Arrow Swap" className='w-7 h-7'/>
                                <p className="flex text-secondary font-medium text-base leading-5">{flightData?.destinationPortShort}</p>
                            </div>
                        </div>
                        <div className="text-secondary px-10 py-5 w-full">
                            <div className="grid grid-cols-12 gap-6 relative">
                                <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 col-span-12">
                                    <p className="flex font-bold text-base leading-5">Booking Details</p>
                                </div>
                                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 col-span-3 pr-5 gap-5 border-r-4 ">

                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Cargo Name</p>
                                        <p className="text-sm font-normal leading-4 break-words">{(flightData?.line)}</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Origin</p>
                                        <p className="text-sm font-normal leading-4 break-words">{querySearch?.originShipment?.originShipment }</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Destination</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ querySearch?.destinationShipment?.destinationShipment }</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Form of Payment</p>
                                        <p className="text-sm font-normal leading-4 break-words">{nodeData?.payment_type}</p>
                                    </div>
                                </div>
                                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 col-span-3 pr-5 gap-5 border-r-4 ">

                                    {/* <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">{ nodeData?.UTR_number ? "UTR Number" : "Booking ID" }</p>
                                        <p className="text-sm font-normal leading-4 break-all">{ nodeData?.UTR_number ? nodeData?.UTR_number : nodeData?.transactionId}</p>
                                    </div> */}
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Incoterms</p>
                                        <p className="text-sm font-normal leading-4 break-all">{querySearch?.containerDetails?.incoTerms}</p>
                                    </div>
                                    {/* <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Date</p>
                                        <p className="text-sm font-normal leading-4 break-all">{getFormattedDateTime(nodeData?.booking_confirm_date) }</p>
                                    </div> */}
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Container Load</p>
                                        <p className="text-sm font-normal leading-4 break-all">{querySearch?.containerLoad}</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Cargo Type</p>
                                        <p className="text-sm font-normal leading-4 break-all">{ nodeData?.carrier_type}</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Booking Status</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ nodeData?.booking_status}</p>
                                    </div>
                                </div>
                                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 col-span-3 pr-5 gap-5 border-r-4 ">

                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Commodity Type</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ querySearch?.containerDetails?.packagingDetails?.commodity ? querySearch?.containerDetails?.packagingDetails?.commodity : "NA" }</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Origin Location</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ querySearch?.originShipment?.originLocation ? querySearch?.originShipment?.originLocation : 'NA' }</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Destination Location</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ querySearch?.destinationShipment?.destinationLocation ? querySearch?.destinationShipment?.destinationLocation : 'NA' } </p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Payment Status</p>
                                        <p className="text-sm font-normal leading-4 break-words">{ nodeData?.payment_status } </p>
                                    </div>
                                </div>
                                <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 col-span-3 pe-5 gap-5">

                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Total Shipment Rate</p>
                                        <p className="text-sm font-normal leading-4 break-all">${ flightData?.USD_totalShipmentRate }</p>
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Total VAS</p>
                                        <p className="text-sm font-normal leading-4 break-all">${ flightData?.USD_totalVAS }</p>
                                    </div>
                                    
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Total Weight</p>
                                        <p className="text-sm font-normal leading-4 break-all">{ flightData?.totalWeight } KG</p>
                                        {/* <p className="text-sm font-normal leading-4 break-all">{ querySearch?.totalWeight }</p> */}
                                    </div>
                                    <div className="flex items-center justify-between gap-5 my-5">
                                        <p className="text-sm font-semibold leading-4">Total Rate</p>
                                        <p className="text-sm font-normal leading-4 break-all">${ flightData?.USD_totalFreightRate }</p>
                                    </div>
                                    
                                </div>
                                
                                <div className="col-span-12">
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 50px', backgroundColor: '#FFF9EB'}}>
                                        <span>Origin Value Added Service Charges</span>
                                        <span>${Number(OriginSum).toFixed(2)}</span>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 50px', backgroundColor: '#FFF9EB'}}>
                                        <span>Destination Value Added Service Charges</span>
                                        <span>${Number(DestinationSum).toFixed(2)}</span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 50px', backgroundColor: '#FFF9EB'}}>
                                        <span>Total Value Added Service Charges</span>
                                        <span>${Number(flightData?.USD_totalVAS).toFixed(2)}</span>
                                    </div> */}
                                    <CollapseItem accordion expandIconPosition='end' className='border-none rounded-none bg-bgbox' expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}>
                                        <Collapse.Panel className='border-none rounded-none' header={<CustomHeader heading="Ship Freight Charges" value={ flightData?.USD_totalFreightRate } />} key="2">
                                            <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }}>
                                                <p>Total Shipment Rate</p>
                                                <p>$ {Number(flightData?.USD_totalShipmentRate).toFixed(2)}</p>
                                            </div>
                                            <div className="flex items-center justify-between px-4 py-2 bg-[#F3F3F3]">
                                                <p>Total Origin Charges</p>
                                                {Number(OriginSum)?
                                                    <p>$ {Number(OriginSum).toFixed(2)}</p>
                                                : 
                                                    <p>No Origin Charges</p>
                                                }
                                                
                                            </div>
                                            <div className="flex items-center justify-between px-4 py-2 bg-[#F3F3F3]">
                                                <p>Total Destination Charges</p>
                                                {Number(DestinationSum)?
                                                    <p>$ {Number(DestinationSum).toFixed(2)}</p>
                                                : 
                                                    <p>No Destination Charges</p>
                                                }
                                                
                                            </div>
                                            <div className="flex items-center justify-between px-4 py-2 bg-[#F3F3F3]" style={{ fontWeight: '700' }}>
                                                <p>Total Ship Freight Charges</p>
                                                {Number(flightData?.USD_totalFreightRate) ? 
                                                    <p>$ {Number(flightData?.USD_totalFreightRate).toFixed(2)}</p>
                                                : 
                                                    <p>No Ship Freight Charges</p>
                                                }
                                            </div>
                                        </Collapse.Panel>
                                        <Collapse.Panel className='border-none rounded-none' header="Container Details" key="3">
                                            {
                                            flightData?.containerDetails?
                                                flightData?.containerDetails?.map((i,index)=> 
                                                <>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Container Size</p>
                                                        <p>{i.containerSize}</p>
                                                    </div>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Freight Rate</p>
                                                        <p>$ {Number(i.freight).toFixed(2)}</p>
                                                    </div>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Total Rate</p>
                                                        <p>$ {Number(i.totalRate).toFixed(2)}</p>
                                                    </div>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Validity</p>
                                                        <p>{i.validity? getFormattedDateTime(i.validity): ""}</p>
                                                        {/* <p>{i.validity? i.validity : ""}</p> */}
                                                    </div>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Validity Terms</p>
                                                        <p>{i.validity_terms}</p>
                                                    </div>
                                                    <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }} key={index}>
                                                        <p>Remarks</p>
                                                        <p>{i.remarks}</p>
                                                    </div>
                                                </>
                                                )
                                            :
                                                <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }}>
                                                    <p>No Container Details</p>
                                                    <p></p>
                                                </div>
                                            }
                                            {/* <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }}>
                                                <p>Freight Rate</p>
                                                <p>$ {Number(flightData?.USD_totalShipmentRate).toFixed(2)}</p>
                                            </div>
                                            <div className="flex items-center justify-between px-4 py-2 pt-2 bg-[#F3F3F3]" style={{ paddingBottom: '4px !important' }}>
                                                <p>Total Rate</p>
                                                <p>$ {Number(flightData?.USD_totalFreightRate).toFixed(2)}</p>
                                            </div> */}
                                        </Collapse.Panel>
                                    </CollapseItem>
                                    <hr className='border-[#F5F5F5] my-5'/>
                                        <p className="text-secondary text-sm font-normal leading-4 break-all my-5">This is a computer generated invoice. No signature is necessary.</p>
                                    <hr className='border-[#F5F5F5] my-5'/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AcceptedQuoteOceanUserDetailsKAM

const CollapseItem = styled(Collapse)`
    &.ant-collapse {
        background: #ffaf0014!important;
        border-radius: 9px !important;
    }
    & .ant-collapse-content-box {
        padding: 0px !important;
        border-radius: 5px !important;
    }
`