import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_rate_list = createAsyncThunk('get_rate_list', async ()=>{
    try{
        const url = 'admin/list_rateplan'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const get_vas_rate_list = createAsyncThunk('get_vas_rate_list', async ()=>{
    try{
        const url = 'admin/vas_rate_list'
        const response = await httpinstance.get(url)
        console.log(response)
        return response
    }catch (err){
        return err.response
    }
})

export const get_surcharge_rate_list = createAsyncThunk('get_surcharge_rate_list', async ()=>{
    try{
        const url = 'admin/surchargelist'
        const response = await httpinstance.get(url)
        console.log(response);   
        return response
    }catch (err){
        return err.response
    }
})

export const create_vas_rate = createAsyncThunk('create_vas_rate', async (values)=>{
    try{
        const url = 'admin/add_single_vas_rate'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const move_rate_archive = createAsyncThunk('move_rate_archive', async (values)=>{
    try{
        const url = 'admin/rate_move_to_archive'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_transport_rate_list = createAsyncThunk('get_transport_rate_list', async ()=>{
    try{
        const url = 'admin/transportationlist'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
}

const rate_managementSlice = createSlice({
    name : 'rate_management',
    initialState,
})
export default rate_managementSlice.reducer