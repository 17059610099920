import React from 'react'
import styled from 'styled-components'
import { CustomeButton, IconSection, LeftHead, SubHeadContainer, SubHeadTitle } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import AddButton from './AddButton'
import ButtonRightImg from './ButtonRightImg'
import SubmitButton from './SubmitButton'

function ContentHead(props) {
  return (
    <SubHeadContainer border={props.border} pb={props.pb} pt={props.pt} className={props.class}>
      <LeftHead>
        {props.is_back_icon === false ? null: <IconImg onClick={props.handleClick} src={require('../../assets/images/back_page_arrow.png')} />}
        <SubHeadTitle ml={props.ml} className={props.class}>{props.title}</SubHeadTitle>
      </LeftHead>
      <HeadButtonSection is_cancel={props.is_cancel}>
        {props.is_button && props.is_edit === false ? <AddButton title="Edit" handleClick={props.handleEdit} icon_type="edit"  />: null}
        {props.is_button && props.is_add_rate ? 
          <ButtonRightImg title={props.add_title} handleClick={props.handleAddRate} loading={props.loading} />
        : null}
      </HeadButtonSection>
      
      {props.is_icon && props.is_add_rate === false?
        <IconSection>
            <IconImg src={require('../../assets/images/trashicon.png')} />
            <IconImg onClick={()=> props.setPageType("add_quote")} src={require('../../assets/images/add-circle.png')} />
        </IconSection>
        : null}
    </SubHeadContainer>
  )
}

export default ContentHead

const HeadButtonSection = styled.div`
  display: flex;
  gap: 20px;
  /* width: 20%; */
  /* justify-content: ${({is_cancel}) => is_cancel === true ? 'space-between' : 'flex-end'}; */
`