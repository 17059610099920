import { Upload, Button } from 'antd'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { CustomeButton } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import AddButton from './AddButton'

function UploadButton(props) {
  const hiddenFileInputAdrs = useRef(null);
  let icon_img = require('../../assets/images/export.png')
  return (
    <div style={{margin: '15px 0px'}}>
      <>
        <input type="file" ref={hiddenFileInputAdrs} onClick={(event) => { event.target.value = null }} onChange={props.handleChange} className='mr-5 hidden' accept=".xls, .xlsx, .csv" />
        <ButtonSubmit htmlType="button" onClick={()=>hiddenFileInputAdrs.current.click()} className={props.button_class? props.button_class : 'mr-5'} size='large' icon={<IconImg src={icon_img} />}>
            Upload Rates
        </ButtonSubmit>
      </>
      {/* {props.rateType === 'air'? 
      <>
        <input type="file" ref={hiddenFileInputAdrs} onChange={props.handleChange} className='mr-5 hidden' accept=".xls, .xlsx, .csv" />
        <ButtonSubmit htmlType="button" onClick={()=>hiddenFileInputAdrs.current.click()} className='mr-5' size='large' icon={<IconImg src={icon_img} />}>
              Upload Rates
          </ButtonSubmit>
      </>
      : <span>Coming Soon...</span>} */}
      
      {props.file_name? 
        <CustomeButton onClick={()=> props.handleClick()} size='large' loading={props.loading}>Add Rate</CustomeButton>
      : null}
      
    </div>
    
  )
}

export default UploadButton


const ButtonSubmit = styled(Button)`
    background-color: #FFAF00;
    color: #242424;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 8px !important;
    :hover {
        border-color: #FFAF00 !important;
        color: #242424 !important;
    }
    img {
        margin-right: 10px;
    }
    margin-left: ${({ml}) => ml ? ml : '0px'};

    &.custom-file-upload:not(.selected) {
      background-color: #007bff;
      color: #fff;
    }
`