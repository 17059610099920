import React from 'react'
import { Radio } from 'antd';
import { IconImg } from '../../StyledComponents/Header';
import styled from 'styled-components';

function RadioGroups(props) {
  return (
    <Container>
         {props.datas.map((i)=> 
            i.id === props.value ? 
            <RadioContainer onClick={()=> props.handleClick? props.handleClick(i.id,props.name) : null}>
                <IconImg src={require('../../assets/images/active_radio.png')} height="18px" width="18px" />
                <ItemLabel className='active'>{i.name}</ItemLabel>
            </ RadioContainer>
             :
             <RadioContainer onClick={()=> props.handleClick? props.handleClick(i.id,props.name): null}>
                <IconImg src={require('../../assets/images/radio_icon.png')} height="18px" width="18px" />
                <ItemLabel>{i.name}</ItemLabel>
             </ RadioContainer>
            )}
    </Container>
  )
}

export default RadioGroups

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
`
const Container =styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    /* justify-content: space-between; */
`
export const ItemLabel = styled.span`
    margin: 0px 14px 0px 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #B5B5C3;
    /* color: #FFAF00; */
    cursor: pointer;
    &.active {
        /* color: #B5B5C3; */
        color: #FFAF00;
    }
`