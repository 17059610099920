import { Divider, Radio, Table } from 'antd';
import { useState } from 'react';
import { TableItem } from '../../StyledComponents/Common';


// rowSelection object indicates the need for row selection

const RowSelectionTable = (props) => {
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          props.setSelectArchive(selectedRowKeys)
        },
      };
    const startIndex = (props.currentPage - 1) * props.pageSize;
    const endIndex = startIndex + props.pageSize;
    const currentData = props?.data?.slice(startIndex, endIndex);
  return (
    <div>
      

      <TableItem
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: props.checkedValues,
          ...rowSelection,
        }}
        rowKey={props.rowKey}
        columns={props.columns}
        dataSource={currentData}
        pagination={false}
      />
    </div>
  );
};
export default RowSelectionTable;