import { DatePicker } from 'antd'
import React from 'react'
import moment from 'moment';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

function FormikDateInput(props) {
  let value = props.value
  try {
    const dateParts = props.value.split(".");
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    value = new Date(formattedDate);
    value = dayjs(new Date(value))
    props.setFieldValue(props.name,value)
  } catch {
    console.log(value);
  }
  
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current <= moment().endOf('day').subtract(1, 'day');
  }
  return (
    <div>
      <DatePickerItem onChange={(date, dateString)=> props.setFieldValue(props.name,date)} placeholder={props.placeholder ? props.placeholder : "Select Date"}
        value={value} size="large" disabled={props.disabled? props.disabled : false} disabledDate={props.disabledate? disabledDate : null}
       />
    </div>
  )
}

export default FormikDateInput

const DatePickerItem = styled(DatePicker)`
    &.ant-picker {
        width: 100% !important;
    }
  :hover,:focus,:not(:focus) {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
  }
`