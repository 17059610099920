import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import AddButton from './AddButton';
import { CustomeButton } from '../../StyledComponents/Common';
import { IconImg } from '../../StyledComponents/Header';


const DropDownButton1 = (props) => {
    const handleItemClick = ({key}) => {
        props.handleDropDownChange(key)
      };
    let icon_img = require('../../assets/images/addplus_icon.png')
    const items = props.items
  return (
    <div>
      <Dropdown
        menu={{
            items,
            onClick: handleItemClick,
          }}
        placement="bottom"
        trigger={['click']}
      >
        <CustomeButton size='large' icon={<IconImg src={icon_img} />}>Add Rates</CustomeButton>
      </Dropdown>
    </div>
  );
};

export default DropDownButton1;
