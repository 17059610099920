import { Collapse } from "antd";
import styled from "styled-components";


export const MainContainer = styled.div``
export const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.flexEnd {
        justify-content: flex-end;
    }
`
export const NameSection = styled.div``
export const ContentSection = styled.div`
    width: 50%;
`
export const TopSection = styled.div`
    display: flex;
    gap: 25px;
    width: 100%;
`
export const BottomSection = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 25px;
    color: #fff;
`
export const BottomHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const TitleBottom = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #242424;
`
export const FilterDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`
export const CardSection = styled.div`
    /* height: 330px; */
`

export const UserListDiv = styled.div`
    
`
export const UserNameTitle = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #3F4254;
    &.sub-title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #B5B5C3;
    }
`
export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
`
export const CollapseItem = styled(Collapse)`
    & .ant-collapse-item {
            background: #fff !important;
        }
    & .ant-collapse-header {
        background: #fff !important;
    }
    & .ant-collapse-item.ant-collapse-item-active {
        background-color: white;
    }
`
export const TableFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`
export const ViewAll = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFAF00;
    cursor: pointer;
`

export const AmountSection = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    cursor: pointer;
`
export const AmountText = styled.span`
    
`