import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import { IconImg } from '../../StyledComponents/Header'

function RemoveButton(props) {
    let icon_img = require('../../assets/images/trash-red.png')
    
  return (
    <div>
      <CustomeButton onClick={()=> props.handleClick? props.handleClick() : null} size='large' icon={<IconImg src={icon_img} />}>{props.title}</CustomeButton>
    </div>
  )
}

export default RemoveButton

const CustomeButton = styled(Button)`
    color: #FF3F3B;
    border-color: #FF3F3B;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 700;
    :hover {
        color: #FF3F3B !important;
        border-color: #FF3F3B !important;
    }
    img {
        margin: 0px 10px 4px 0px;
    }
`