import React from 'react'
import { Select, Space } from 'antd';
import styled from 'styled-components';
import { IconImg } from '../../StyledComponents/Header';
import { UserOutlined } from '@ant-design/icons';

function SelectItem(props) {
  return (
    <SpaceItem wrap gap={props.gap} mr={props.mr} mb={props.mb}>
        {props.is_icon? <SelectIcon gap={props.gap}>₹</SelectIcon> : null}
        <ItemSelect
            is_icon={props.is_icon}
            size={props.size ? props.size : "large"}
            placeholder={props.placeholder ?props.placeholder : "Select"}
            style={{
                width: props.width? props.width : 230,
            }}
            onChange={props.handleChange}
            options={props.options}
            value={props.value}
            bradius={props.bradius}
        />
        
       
    </SpaceItem>
  )
}

export default SelectItem

const SpaceItem = styled(Space)`
    position: relative;
    gap: ${({gap})=> gap ? gap : '8px'};
    margin-right: ${({mr})=> mr? mr : "unset"};
    margin-bottom: ${({mb})=> mb? mb : "unset"};
`
const SelectIcon = styled.span`
    z-index: 9;
    position: absolute;
    top: 10px;
    left: ${({gap})=> gap ? "9px !important" : '17px'};
    color: #242424 !important;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto';
    font-style: normal;
`
const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: ${({bradius}) => bradius? bradius : "4px !important;"};
        padding-left: ${({ is_icon })=> is_icon ? "20px !important": 'unset'};
    }
    & .ant-select-selection-placeholder {
        color: #242424 !important;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Roboto';
        font-style: normal;
    }
    & .ant-select-arrow {
        color: #242424 !important;
    }
`