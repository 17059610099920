import { Dropdown, Menu,Avatar,Badge } from 'antd';
import React,{ useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ItemMenu } from '../../StyledComponents/Common';
import { NameSection } from '../../StyledComponents/Dashboard'
import { IconImg, HeaderContainer, HeadName, HeadShort, RightHeader, TopSection, NameDiv, NameSpan, ProfileSpan, ProfileDiv } from '../../StyledComponents/Header'
import AppDrawer from './AppDrawer';
import shipline from '../../assets/images/active-ship-icon.svg'
import airline from '../../assets/images/flight_icon.svg'
import kamLogo from '../../assets/images/kam_logo.png'
import NotificationBell from './NotificationBell';
import { BellOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getfirebasetoken, getNotificationCount, notificationlist, updatenotificationCount, updatenotificationneworold } from '../../store/Common/CommonSlice';
import { userlogout } from '../../store/Authentication/AuthSlice';

function CustomeHeader(props) {
  const dispatch = useDispatch();
  const firebaseToken = useSelector(getfirebasetoken)
  const notificationCount = useSelector(getNotificationCount)
  const userroles = JSON.parse(localStorage.getItem('user')).userroles;
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user'));
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [defaultLogo, setDefaultLogo] = useState(kamLogo);
  const [notificationData, setNotificationData] = useState();
  // const [notificationCount, setNotificationCount] = useState(notiCount);  
  const count = 10
  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(()=> {
    let admin_role = ""
    let username = user?.full_name
      if(userroles === 'air_admin'){
        admin_role = "Air"
        username = "Air Administrator"
        setDefaultLogo(airline)
      }else if(userroles === 'ocean_admin'){
        admin_role = "Ocean"
        username = "Ocean Administrator"
        setDefaultLogo(shipline)
      }
    setUserName(username)
  },[userroles])

  useEffect(()=>{
    dispatch(notificationlist(count))
    .then((res)=>{
        if(res?.payload?.data?.statusCode === 200){
          // console.log(res?.payload?.data);
            setNotificationData(res?.payload?.data)
            dispatch(updatenotificationCount(res?.payload?.data?.newCount))
            if(open){
              dispatch(updatenotificationneworold())
            }
        }
    })
    .catch(err=>{
        console.error(err);
    })
},[open])


  const handleAdd = (key) => {
    if(key === 'logout'){
      let values = {
        fcmId: firebaseToken
      }
      dispatch(userlogout(values))
      if(user?.userroles === 'admin' || user?.userroles === 'air_admin' || user?.userroles === 'ocean_admin'){
        navigate('/auth/admin-login')
      }else {
        navigate('/auth/login')
      }
    }
  }
  // const items = (
  //   <Menu onClick={({ key }) => handleAdd(key)}>
  //     <Menu.Item key="settings">
  //       <ItemMenu>Settings</ItemMenu>
  //     </Menu.Item>
  //     <Menu.Item key="">
  //       <ItemMenu>Logout</ItemMenu>
  //     </Menu.Item>
  //   </Menu>
  // );

  const onClick = ({ key }) => {
    handleAdd(key)
  };

  const items = [
    // {
    //   label: <ItemMenu>Settings</ItemMenu>,
    //   key: 'settings',
    // },
    {
      label: <ItemMenu>Logout</ItemMenu>,
      key: 'logout',
    },
  ];
  
  return (
    <HeaderContainer>
      <NameSection>
          <TopSection>
            {/* <IconImg src={require('../../assets/images/back_arrow.png')} /> */}
            <HeadName>{props.title}</HeadName>
          </TopSection>
          <HeadShort>{props.description}</HeadShort>
      </NameSection>
      <RightHeader>
        {/* <NotificationBell /> */}
        <Badge count={notificationCount} className="text-primary">
          <Avatar shape="circle" onClick={()=> showDrawer()} size='large' style={{display:'flex',alignItems:'center',justifyContent:'center', width: '32px', height: '32px', padding: '4px' }} icon={<BellOutlined />} className="bg-primary hover:bg-secondary cursor-pointer"/>
        </Badge>
        {/* <IconImg src={require('../../assets/images/header_square.png')} onClick={()=> showDrawer()} /> */}
        <NameDiv>
            <NameSpan>{userName}</NameSpan>
            {/* {user?.userroles === "admin" ? <NameSpan>{user?.userroles}</NameSpan> : null} */}
        </NameDiv>
        <ProfileDiv>
          {/* <ProfileSpan>
           {user?.full_name? user?.full_name.charAt(0) : ""}
          </ProfileSpan> */}
          <IconImg src={defaultLogo} />

          <AppDrawer onClose={onClose} open={open}setOpen={setOpen} userName={userName} notificationData={notificationData} count={count} />
        </ProfileDiv>
        <Dropdown
          menu={{
            items,
            onClick,
          }}
          placement="bottomLeft"
          trigger={['click']}
        >
          <IconImg src={require('../../assets/images/down_arrow.png')} />
      </Dropdown>
      </RightHeader>
    </HeaderContainer>
  )
}

export default CustomeHeader
