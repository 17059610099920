import {Line} from 'react-chartjs-2'
import {
    Chart as ChartJs,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js';
import styled from 'styled-components';
import { useState,useEffect,memo } from 'react';
import { ConvertAmountCurrency } from '../../functions/Utils';
import { SpinContainer } from '../../StyledComponents/Queries';
import { Skeleton, Spin } from 'antd';

ChartJs.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
)


const DashboardChart = (props) => {
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        if(props.chartData?.length){
            let label = []
            let data = []
            props.chartData?.map((i,index)=> {
                data.push(ConvertAmountCurrency(props.exchangeRateList,props.currency,Number(i.total)))
                if(props.filter_date === 'this_month'){
                    label.push(i.date)
                }else if(props.filter_date === 'this_year'){
                    label = ['Jan','Feb','Mar','Apr','May', 'Jun','Jul','Aug','Sep','Oct','Nov','Dec']
                }else {
                    label = ['Mon','Tue','Wed','Thur','Fri', 'Sat','Sun']
                }
            })
            setLabels(label)
            setChartData(data)
            setLoading(false)
        }
        
    },[props.chartData])
    
    const data = {
        labels: labels,
        datasets: [{
            labels: 'Earnings',
            data: chartData,
            backgroundColor: 'white',
            borderColor: 'white',
            pointBorderColor: 'white',
            fill: false,
            cubicInterpolationMode: 'monotone',
            tension: 0.4
        }]
    }
    

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
            axis: 'x' 
          },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
              callbacks: {
                title: function (context) {
                  return 'Earnings on ' + context[0].label;
                }
              }
            },
            legend: { display: false },
            title: {
              display: false,
            //   text: "Test chart",
              position: "top"
            },
          },
        hover: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                },
            },
            y: {
                display: false,
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                  },
                  beginAtZero: true
            }
        }
    }
    if(loading){
        <SpinContainer>
                <Skeleton active />
        </SpinContainer>
    }else {
        return (
            <div style={{ width: '100%', height: "220px"}} >
                <LineItem 
                    data={data}
                    options={options}
                    >
                </LineItem>
            </div>
        )
    }
}

export default memo(DashboardChart);

const LineItem = styled(Line)`
    width: 100% !important;
    /* margin-bottom: 10px; */
    cursor: pointer;
`