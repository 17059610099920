import React from 'react'
import styled from 'styled-components/macro'
import { ConvertAmountCurrency } from '../../functions/Utils'
import { IconImg } from '../../StyledComponents/Header'
import DashboardChart from './DashboardChart'

function PaymentCard(props) {
  return (
    <Container>
      <Title>Earnings</Title>
      {/* <IconImg src={require('../../assets/images/payment_bg.png')} /> */}
      <DashboardChart filter_date={props.filter_date} chartData={props.chartData} currency={props.currency} exchangeRateList={props.exchangeRateList} />
      <PaymentAmount>
        {/* {props.currency} {Number(props.total_amount).toFixed(2)} */}
        {props.currency}
        {props.currency === 'INR'? Number(props.total_amount).toFixed(2) : ConvertAmountCurrency(props.exchangeRateList,props.currency,Number(props.total_amount))}
      </PaymentAmount>
    </Container>
  )
}

export default PaymentCard

const Container = styled.div`
    padding: 25px;
    background: #F7D9E3;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
`
const Title = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #181C32;
`
const PaymentAmount = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #181C32;
`