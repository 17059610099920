import { Content } from 'antd/es/layout/layout'
import React, {useEffect, useState} from 'react'
import { TablePagination, TableTab } from '../../StyledComponents/Common'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer, TableHead } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SimpleTable from '../Common/SimpleTable'
import SubmitButton from '../Common/SubmitButton'
import { Divider, Spin } from 'antd';
import { ShowComingSoon } from '../../functions/Utils'
import { get_query_list_user } from '../../store/UserManagement/UserManagementSlice'
import { useDispatch } from 'react-redux'
import { SpinContainer } from '../../StyledComponents/Queries'

function QuoteSection(props) {
    const dispatch = useDispatch()
    const kam_id = JSON.parse(localStorage.getItem('user'))?.id
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [tabType, setTabType] = useState("ocean");
    const [instance, setInstance] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
      setPageSize(size);
      setCurrentPage(1);
    };

    const handleArchive = () => {
        console.log("archive");
    }

    useEffect(()=>{
      if(props.instance) {
        let data = {
          user_id: props.instance.id,
          type: props.type,
          kam_id: kam_id,
        }
        dispatch(get_query_list_user(data))
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                setData(res?.payload?.data?.nodeData?.data)
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })
      }
      
      
    },[props.instance])

    const handleView = (record) => {
      // setInstance(record)
      props.setQueryInstance(record)
  }
    
    const columns = [
        {
            title: 'SL No.',
            dataIndex: 'sl_no',
            render: (text, record,index) => (
                <span style={{ fontSize: '12px',fontWeight: '500',fontFamily: 'Rubik',fontStyle: 'normal', color: '#B5B5C3' }}>Quote {(currentPage -1) * pageSize + index + 1}</span>
              ),
        },
        {
            title: props.type === 'quoted' ? 'Link To Created Quotes' :'Link To Accepted Quotes',
            dataIndex: 'link_to_accepted_quotes',
            render: (text, record) => (
                <AddButton  data={record} title="View Quote" handleClick={()=> handleView(record)} is_icon={false} />
              ),
        }
      ];

  if(loading){
    return (
      <SpinContainer className='user-quote' >
        <Spin />
      </SpinContainer>
    )
  }else {
    return (
      <>
          <DetailContentContainer>
              <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} fpadding="15px 16px" />
          </DetailContentContainer>
          {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'right' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={pageSize}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
      </>
      
    )
  }
  
}

export default QuoteSection
