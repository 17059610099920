import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Divider, Modal, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import ArchiveOceanRateView from '../components/Archives/ArchiveOceanRateView'
import ArchiveRateView from '../components/Archives/ArchiveRateView'
import ArchiveView from '../components/Archives/ArchiveView'
import CustomeHeader from '../components/Common/CustomeHeader'
import RowSelectionTable from '../components/Common/RowSelectionTable'
import ViewButton from '../components/Common/ViewButton'
import { getFormattedDateTime } from '../functions/Utils'
import { change_unarchive, delete_archive, delete_rate_archive, get_archive_list, get_rate_archive_list } from '../store/Archives/ArchiveSlice'
import { updatenotificationdata } from '../store/Common/CommonSlice'
import { get_user_list } from '../store/UserManagement/UserManagementSlice'
import { CustomeButton, LeftHead, SubHeadContainer, SubHeadTitle, SubTabDiv, TablePagination, TableTab } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import {  SubHeadSection, UserIDContainer, UserText } from '../StyledComponents/KYCVerifications'
import { SpinContainer } from '../StyledComponents/Queries'
import { TableFilter } from '../StyledComponents/RateManagement'

const { confirm } = Modal;

function Archives() {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "ocean_admin"
    const dispatch = useDispatch()
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRatePage, setCurrentRatePage] = useState(1);
    const [currentOceanRatePage, setCurrentOceanRatePage] = useState(1);
    const [currentTab, setCurrentTab] = useState("1");
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [tabType, setTabType] = useState("users");
    const [sub_tabType, setSubTabType] = useState(userroles === 'ocean_admin'?"ocean" : "air");
    const [data, setData] = useState([]);
    const [rateData, setRateData] = useState([]);
    const [rateOceanData, setRateOceanData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [selectedArchives, setSelectArchive] = useState([])
    const [selectedRateArchives, setSelectRateArchive] = useState([])
    const [selectedOceanRateArchives, setSelectOceanRateArchive] = useState([])
    const [getList, setGetList] = useState(true)

    const handlePageChange = (page) => {
        if(tabType === 'rates'){
          if(sub_tabType === 'ocean'){
            setCurrentOceanRatePage(page)
          }else {
            setCurrentRatePage(page)
          }
        }else {
          setCurrentPage(page);
        }
    };

    useEffect(()=>{
        if(pageType === 'table' && getList){
            setLoading(true)
            setQueryInstance({})
            setSelectArchive([])
            setSelectRateArchive([])
            setSelectOceanRateArchive([])
            dispatch(get_archive_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.data)
                    // setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })

            dispatch(get_rate_archive_list())
            .then(res=>{
                // console.log('login res =>rate ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setRateData(res?.payload?.data?.nodeData.data)
                    setRateOceanData(res?.payload?.data?.nodeData.oceanData)
                    setLoading(false)
                    setGetList(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[getList])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        if(tabType === 'rates'){
          if(sub_tabType === 'ocean'){
            setCurrentRatePage(1)
          }else {
            setCurrentOceanRatePage(1)
          }
        }else {
          setCurrentPage(1);
        }
    };

    const handleView = (record) => {
        setInstance(record)
        if(tabType === 'rates'){
          if(sub_tabType === 'ocean'){
            setPageType("ocean_rate_view_page")
          }else {
            setPageType("rate_view_page")
          }
        }else {
          setPageType("view_page")
        }
    }

    const DeleteArchive = (type)=> {
      let rate_ids = []
      let user_ids = []
      if(tabType === 'rates'){
        if(type === "list"){
          if(sub_tabType === 'ocean'){
            rate_ids = selectedOceanRateArchives
          }else {
            rate_ids = selectedRateArchives
          }
        }else if(type === "single"){
          rate_ids = [instance._id]
        }
      }else {
        if(type === "list"){
          user_ids = selectedArchives
        }else if(type === "single"){
          user_ids = [instance.id]
        }
      }

      if((tabType === 'rates' && rate_ids.length) || (tabType === 'users' && user_ids.length)){
        confirm({
          title: 'Are you sure you want to delete Archive?',
          icon: <ExclamationCircleFilled />,
          // content: 'Some descriptions',
          okText: 'Confirm',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            if(tabType === 'rates'){
              // let rate_ids = []
              // if(type === "list"){
              //   if(sub_tabType === 'ocean'){
              //     rate_ids = selectedOceanRateArchives
              //   }else {
              //     rate_ids = selectedRateArchives
              //   }
              // }else if(type === "single"){
              //   rate_ids = [instance._id]
              // }
              setDeleteLoading(true)
              let customType = 'Air'
              if(sub_tabType === 'ocean'){
                customType = "Ocean"
              }
              const values = {
                rate_id : rate_ids,
                customType: customType
              }
              dispatch(delete_rate_archive(values))
              .then(res=>{
                  if(res?.payload?.data?.statusCode === 200 ){
                      // console.log(res?.payload?.data?.data);
                      dispatch(updatenotificationdata(res?.payload?.data))
                      setDeleteLoading(false)
                      setPageType("table")
                      let airRate = rateData
                      let oceanRate = rateOceanData
                      if(sub_tabType === 'ocean'){
                        oceanRate = oceanRate.filter((i)=> !rate_ids.includes(i._id))
                        setRateOceanData(oceanRate)
                      }else {
                        airRate = airRate.filter((i)=> !rate_ids.includes(i._id))
                        setRateData(airRate)
                      }
                  }
              })
              .catch(err=>{
                  console.error(err);
              })
            }else {
              // let user_ids = []
              // if(type === "list"){
              //   user_ids = selectedArchives
              // }else if(type === "single"){
              //   user_ids = [instance.id]
              // }
              setDeleteLoading(true)
              const values = {
                delete_from_archive:"delete",
                user_id : user_ids
              }
              dispatch(delete_archive(values))
              .then(res=>{
                  if(res?.payload?.data?.statusCode === 200 ){
                      // console.log(res?.payload?.data?.data);
                      dispatch(updatenotificationdata(res?.payload?.data))
                      setDeleteLoading(false)
                      setPageType("table")
                      let userData = data
                      userData = userData.filter((i)=> !user_ids.includes(i.id))
                      setData(userData)
                  }
              })
              .catch(err=>{
                  console.error(err);
              })
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    }
    

    const handleUnArchive = () => {
      setArchiveLoading(true)
      const values = {
        unarchive: "unarchive",
        user_id : instance.id
      }
      dispatch(change_unarchive(values))
      .then(res=>{
          if(res?.payload?.data?.statusCode === 200 ){
              // console.log(res?.payload?.data?.data);
              dispatch(updatenotificationdata(res?.payload?.data))
              setArchiveLoading(false)
              setPageType("table")
              let userData = data
              userData = userData.filter((i)=> i.id !== instance.id)
              setData(userData)
              setGetList(true)
          }
      })
      .catch(err=>{
          console.error(err);
      })
    }


    const columns = [
        {
          title: 'NAME',
          dataIndex: 'full_name',
        },
        {
          title: 'TYPE',
          dataIndex: 'type',
          render: (text, record,index) => (
            <span>{record?.userroles}</span>
          ),
        },
        {
          title: 'DATE ARCHIVED',
          dataIndex: 'date_for_archive',
          render: (text, record,index) => (
            <span>{getFormattedDateTime(text)}</span>
          ),
        },
        {
        title: 'ARCHIVED BY',
        dataIndex: 'archived_by',
        render: (text, record,index) => (
          <span>{record?.archive__detail?.full_name}</span>
        ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                return <ViewButton handleClick={handleView} data={record} />;
                },
        }
      ];

      const Ratecolumns = [
        {
          title: 'AIRLINE',
          dataIndex: 'airline',
        },
        {
          title: 'ORIGIN',
          dataIndex: 'origin_port',
        },
        {
          title: 'DESTINATION',
          dataIndex: 'destination_port',
        },
        {
          title: 'DATE ARCHIVED',
          dataIndex: 'createdAt',
          render: (text, record,index) => (
            <span>{getFormattedDateTime(text)}</span>
          ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                return <ViewButton handleClick={handleView} data={record} />;
                },
        }
      ];

      const RateOceancolumns = [
        {
          title: 'SHIPPER',
          dataIndex: 'shipper_name',
          render: (text, record,index) => (
            <span>{record.line}</span>
          ),
        },
        {
          title: 'ORIGIN',
          dataIndex: 'POR',
        },
        {
          title: 'DESTINATION',
          dataIndex: 'POD',
        },
        {
          title: 'DATE ARCHIVED',
          dataIndex: 'createdAt',
          render: (text, record,index) => (
            <span>{getFormattedDateTime(text)}</span>
          ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                return <ViewButton handleClick={handleView} data={record} />;
                },
        }
      ];

      
  return (
    <MainContainer>
      <CustomeHeader title="Archives" description="All details archived by admin will be shown in here" />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>Archives List</TableTitle>
                <CustomeButton size='large' onClick={()=> DeleteArchive("list")} loading={deleteLoading}>Delete From Archives</CustomeButton>
            </TableTop>
            <TableFilter className='sub-tab'>
                <TableTab className='first' active={tabType === 'users'} onClick={()=> setTabType('users')}>Users</TableTab>
                  <Divider type="vertical" />
                <SubTabDiv>
                  <TableTab active={tabType === 'rates'} onClick={()=> setTabType('rates')}>Rates</TableTab>
                  {tabType === 'rates'?
                      userroles === 'air_admin'?
                        <TableFilter className='sub-tab'>
                          <TableTab active={sub_tabType === 'air'} onClick={()=> setSubTabType('air')}>Air</TableTab>
                       </TableFilter>
                      : userroles === 'ocean_admin'?
                        <TableFilter className='sub-tab'>
                            <SubTabDiv>
                              <TableTab active={sub_tabType === 'ocean'} onClick={()=> setSubTabType('ocean')} className='sub-tab'>Ocean</TableTab>
                            </SubTabDiv>
                        </TableFilter>
                      :
                        <TableFilter className='sub-tab'>
                            <TableTab className='first sub-tab' active={sub_tabType === 'air'} onClick={()=> setSubTabType('air')}>Air</TableTab>
                              <Divider type="vertical" />
                            <SubTabDiv>
                              <TableTab active={sub_tabType === 'ocean'} onClick={()=> setSubTabType('ocean')} className='sub-tab'>Ocean</TableTab>
                            </SubTabDiv>
                        </TableFilter>
                      
                    
                  : null}
                    
                </SubTabDiv>
            </TableFilter>
            {tabType === 'users' ?
              <>
                 {loading? 
                    <SpinContainer className='user-quote'>
                        <Spin />
                    </SpinContainer>: 
                    <RowSelectionTable rowKey="id" currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} setSelectArchive={setSelectArchive} checkedValues={selectedArchives} />
                  }
              </>
              
             : 
              <>
                 {loading? 
                    <SpinContainer className='user-quote'>
                        <Spin />
                    </SpinContainer>: 
                    sub_tabType === 'ocean' ? 
                    <RowSelectionTable rowKey="_id" currentPage={currentOceanRatePage} pageSize={pageSize} columns={RateOceancolumns} data={rateOceanData} setSelectArchive={setSelectOceanRateArchive} checkedValues={selectedOceanRateArchives} />
                    :
                    <RowSelectionTable rowKey="_id" currentPage={currentRatePage} pageSize={pageSize} columns={Ratecolumns} data={rateData} setSelectArchive={setSelectRateArchive} checkedValues={selectedRateArchives} />
                  }
              </>
             
             }
           
        </ContentContainer>
        {tabType === 'users' ? 
          data?.length ? 
          <TablePagination
              style={{ marginTop: 16, textAlign: 'center' }}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              defaultCurrent={currentPage}
              defaultPageSize={10}
              total={data?.length? data.length : 10}
              showSizeChanger={false}
              showQuickJumper={false}
          />
      : null
        : 
          sub_tabType === 'ocean'?
            rateOceanData?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentOceanRatePage}
                defaultPageSize={10}
                total={rateOceanData?.length? rateOceanData.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null
          : 
            rateData?.length ? 
              <TablePagination
                  style={{ marginTop: 16, textAlign: 'center' }}
                  onChange={handlePageChange}
                  onShowSizeChange={handlePageSizeChange}
                  defaultCurrent={currentPage}
                  defaultPageSize={10}
                  total={rateData?.length? rateData.length : 10}
                  showSizeChanger={false}
                  showQuickJumper={false}
              />
          : null
        }
        {/* {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null} */}
        
      </>
        :pageType === 'view_page'? 
        <>
           <ContentContainer>
                {/* <ContentHead title="User Details"  handleClick={()=> setPageType("table")} /> */}
                <SubHeadContainer className='flex-column'>
                    <LeftHead>
                        <IconImg onClick={()=> setPageType("table")} src={require('../assets/images/back_page_arrow.png')} />
                        <SubHeadTitle>Archive Detail</SubHeadTitle>
                    </LeftHead>
                    <SubHeadSection>
                        <UserIDContainer className='wdt-27'>
                            <UserText>USER ID</UserText>
                            <UserText className='value'>{instance.id}</UserText>
                        </UserIDContainer>
                        <div>
                            <CustomeButton size='large' loading={archiveLoading} onClick={()=> handleUnArchive()}>Un-archive</CustomeButton>
                            <CustomeButton size='large' className='ml-3' onClick={()=> DeleteArchive("single")} loading={deleteLoading}>Delete From Archives</CustomeButton>
                        </div>
                    </SubHeadSection>
                </SubHeadContainer>
                <ArchiveView instance={instance} setQueryInstance={setQueryInstance} tab={currentTab} setPageType={setPageType} setGetList={setGetList} />
            </ContentContainer>
        </>
       : pageType === 'rate_view_page'? 
        <ContentContainer>
              <SubHeadContainer className='flex-column' border={false}>
                  <LeftHead>
                      <IconImg onClick={()=> setPageType("table")} src={require('../assets/images/back_page_arrow.png')} />
                      <SubHeadTitle>Archive Detail</SubHeadTitle>
                  </LeftHead>
              </SubHeadContainer>
              <ArchiveRateView data={rateOceanData} setRateOceanData={setRateOceanData} instance={instance} setPageType={setPageType} setQueryInstance={setQueryInstance} tab={currentTab} DeleteArchive={()=> DeleteArchive("single")} deleteLoading={deleteLoading} setGetList={setGetList} />
          </ContentContainer>
        : pageType === 'ocean_rate_view_page'? 
        <ContentContainer>
              <SubHeadContainer className='flex-column' border={false}>
                  <LeftHead>
                      <IconImg onClick={()=> setPageType("table")} src={require('../assets/images/back_page_arrow.png')} />
                      <SubHeadTitle>Archive Detail</SubHeadTitle>
                  </LeftHead>
              </SubHeadContainer>
              <ArchiveOceanRateView data={rateData} setRateData={setRateData} instance={instance} setPageType={setPageType} setQueryInstance={setQueryInstance} tab={currentTab} DeleteArchive={()=> DeleteArchive("single")} deleteLoading={deleteLoading} setGetList={setGetList} />
          </ContentContainer>
        : null}
      
      
    </MainContainer>
  )
}

export default Archives
