import { Skeleton } from 'antd'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './TransactionHistory.scss';
const TransactionDetails = lazy(()=> import('./TransactionDetails'))
const RepaymentsDetails = lazy(()=> import('./RepaymentsDetails'))

const TransactionHistory = (props) => {
    const [tabVal, setTabVal] = useState('Transaction')
    const [loading, setLoading] = useState(false)
    // const [queriesData, setQueriesData] = useState(props.transactionData)
    // const [repaymentData, setRepaymentData] = useState(props.repaymentData)
    const dispatch = useDispatch()
    return (
        <div id='transaction-history' className='w-full'>
            <div className="flex items-center gap-5 w-full my-5">
                <span className={`text-sm cursor-pointer ${tabVal === 'Transaction' ? 'text-primary font-bold ' : 'text-[#B5B5C3] font-normal'}`}onClick={()=> setTabVal('Transaction')}>Transaction Details</span>
                <span className={`text-sm cursor-pointer ${tabVal === 'Repayments' ? 'text-primary font-bold ' : 'text-[#B5B5C3] font-normal'}`}onClick={()=> setTabVal('Repayments')}>Repayments</span>
            </div>
            {
                tabVal === 'Transaction' &&
                <Suspense fallback={<Skeleton active/>}>
                    <TransactionDetails data={props.transactionData} loading={loading}/>
                </Suspense>
            }
            {
                tabVal === 'Repayments' &&
                <Suspense fallback={<Skeleton active/>}>
                    <RepaymentsDetails data={props.repaymentData} />
                </Suspense>
            }
        </div>
    )
}

export default React.memo(TransactionHistory)