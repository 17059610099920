import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_user_list = createAsyncThunk('get_user_list', async ()=>{
    try{
        const url = 'kam/kam_users_list'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const get_query_list_user = createAsyncThunk('get_query_list_user', async (values)=>{
    try{
        const url = 'kam/get_create_accept_quotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_admin_user_list = createAsyncThunk('get_admin_user_list', async ()=>{
    try{
        const url = 'admin/list_user'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const get_admin_user_list_filter = createAsyncThunk('get_admin_user_list_filter', async (values)=>{
    try{
        const url = 'admin/users_filter_by_kam'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const kam_reassign_user = createAsyncThunk('kam_reassign_user', async (values)=>{
    try{
        const url = 'admin/reassign_filter_kam'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const update_user_kam = createAsyncThunk('update_user_kam', async (values)=>{
    try{
        const url = 'admin/reassign_kam'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const change_status = createAsyncThunk('change_status', async (values)=>{
    try{
        const url = 'admin/change_status'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})


export const get_booked_query_list_user = createAsyncThunk('get_booked_query_list_user', async (values)=>{
    try{
        const url = 'admin/listuserquotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_user_credit_details = createAsyncThunk('get_user_credit_details', async (id)=>{
    try{
        const url = 'admin/user_credit_list' + '?user_id=' + `${id}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const get_user_repayment_details = createAsyncThunk('get_user_repayment_details', async (values)=>{
    try{
        const url = 'admin/get_all_debit_repayment_lists'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const user_credit_add = createAsyncThunk('user_credit_add', async (values)=>{
    try{
        const url = 'admin/assign_credit_limit'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
}

const user_managementSlice = createSlice({
    name : 'user_management',
    initialState,
})
export default user_managementSlice.reducer