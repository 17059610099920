import React,{useCallback, useState} from 'react'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import TextError from '../TextErrot'
import { Link, useNavigate } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector, } from 'react-redux'
import {  userlogin  } from '../../store/Authentication/AuthSlice'
import Notification from '../../shared/notification/Notification'
import { Spin, } from 'antd'
import { getfirebasetoken, updatenotificationdata } from '../../store/Common/CommonSlice'

const initialValuesEmail = {
    email: '',
    password: ''
}
const validationSchemaEmail = Yup.object({
    email: Yup.string().required('Email ID is required').email('Please enter your valid Email ID').matches(/^(?!\s+$).*/, 'This field cannot contain only blankspaces'),
    password: Yup.string().required('Password is required')
})


const LoginAdmin = () => {
    const firebaseToken = useSelector(getfirebasetoken)
    const [resData, setResData] = useState({})
    const [spinner, setSpinner] = useState(false);
    const [passwordType, setPasswordType] = useState("password");



    const dispatch = useDispatch()
    const navigate = useNavigate()



    const onSubmitEmail = (values, submitProps) => {
        values.userRole = 'admin'
        values.fcmToken = firebaseToken
        setSpinner(true);
        setResData('')
        // values.login_type = loginType
        dispatch(userlogin(values))
        .then((res)=>{
            // console.log('login res => ',res);
            dispatch(updatenotificationdata(res?.payload?.data))
            setResData(res?.payload?.data)
            setSpinner(false);
            if(res?.payload?.data?.data?.original?.access_token && res?.payload?.data?.data?.original?.user?.userroles){
                const user_role = res?.payload?.data?.data?.original?.user?.userroles
                if(user_role === 'admin' || user_role === 'air_admin' || user_role === 'ocean_admin'){
                    navigate('/dashboard')
                }
                else if(user_role === 'kam'){
                    let data = {
                        message : "KAM user can't Login",
                        statusCode: 200,
                        success: "error"
                    }
                    dispatch(updatenotificationdata(data))
                    // navigate('/auth/login')
                }
                else {
                    let data = {
                        message : "user can't Login",
                        statusCode: 200,
                        success: "error"
                    }
                    dispatch(updatenotificationdata(data))
                }
            }
        })
        .catch(err=>{
            console.error(err);
        })
        
    }
    
    const togglePassword = useCallback(()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    },[passwordType]);
    
    
    return (
        <>
            <div className="bg-[url('assets/images/formbackground.svg')] bg-cover bg-center">
                <p className="font-semibold text-xl leading-6 text-black">Hello! </p>
                <p className="font-normal text-sm leading-6 text-black my-2">Please sign in to see your latest queries</p>
                <Formik
                    initialValues={initialValuesEmail}
                    validationSchema={validationSchemaEmail}
                    onSubmit={onSubmitEmail}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {
                        formik =>{
                            return(
                                <Form autoComplete='off'>
                                    <div className="bg-whites my-5">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor='email' className="py-2 block text-xs font-semibold leading-5 text-formlabel">Email*</label>
                                                <Field type='text' id='email' name='email' placeholder="Enter Your Email Id" className="mt-2 leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border rounded-md"/>
                                                <ErrorMessage name='email' component={TextError} />
                                            </div>

                                            <div className="col-span-6">
                                                <label htmlFor='password' className="py-2 block text-xs font-semibold leading-5 text-formlabel">Password*</label>
                                                <div className="relative">
                                                    <Field type={passwordType} id='password' name='password' placeholder="Password" className="mt-2 leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border rounded-md relative"/>
                                                    <button onClick={togglePassword} type='button' className='absolute right-4 top-2'>
                                                    {
                                                        passwordType === 'password' ? <EyeInvisibleOutlined/> : <EyeTwoTone/>
                                                    }
                                                    </button>

                                                </div>
                                                <ErrorMessage name='password' component={TextError} />
                                            </div>
                                            <div className="col-span-6">
                                                <button type='submit' className='w-full text-white bg-primary py-3 rounded-md px-5 text-sm font-bold hover:bg-secondary shadow-loginbutton mt-3' disabled={spinner}>
                                                    {
                                                        spinner ?
                                                            <Spin/>
                                                        :
                                                            'Sign In'
                                                    }
                                                </button>
                                            </div>
                                            {/* <div className="col-span-6 flex justify-end text-black font-bold text-sm leading-6">
                                                <Link to="/forgotpassword" className='hover:text-primary cursor-pointer'>Forgot Passsword?</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
            {/* {
                (resData.success && resData.message) &&
                <>
                    <Notification type={resData?.success} msg={resData?.message}/>
                </>
            } */}
        </>
    )
}

export default LoginAdmin