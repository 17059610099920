import React, { memo, useState } from 'react'
import {Input, Spin, Switch,message } from 'antd'
import * as Yup from 'yup'
import { ErrorMessage, Field, Formik, Form, useFormik } from 'formik'
import TextError from '../../forms/TextErrot'
import TransactionHistory from './TransactionHistory/TransactionHistory'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get_user_credit_details, get_user_repayment_details, user_credit_add } from '../../store/UserManagement/UserManagementSlice'
import { SpinContainer } from '../../StyledComponents/Queries'
import { updatenotificationdata } from '../../store/Common/CommonSlice'

const CreditDetails = (props) => {
    const dispatch = useDispatch()
    const [creditSwitch, setCreditSwitch] = useState(false)
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [initialCreditLimit, setInitialCreditLimit] = useState('');
    const [credit_id, setCredit_id] = useState(null);
    const [transactionData, setTransactionData] = useState([]);
    const [repaymentData, setRepaymentData] = useState([]);
    const [is_editLimit, setEditLimit] = useState(false);
    const [formikData, setFormikData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [isToggle, setIsToggle] = useState(false);

    const initialValues = {
        creditLimit: '',
    }
    const validationSchema = Yup.object().shape({
        creditLimit:Yup.string().required("Credit Limit is required"),
    })

    const onSubmit = (values)=>{
        console.log(isToggle,"isToggle??????????");
        if(isToggle === false){
            setSpinner(true)
        }
        let creditLimit = initialCreditLimit
        if(formikData.values){
            creditLimit = formikData.values.creditLimit
        }
        let data = {
            credit_id: credit_id,
            // assigned_credit: creditLimit,
            active: creditSwitch? "active" : "inactive"
        }
        if(isToggle === false){
            data.assigned_credit = creditLimit
        }

        setIsToggle(false)
        // if(!creditSwitch){
        //     creditLimit = initialCreditLimit
        // }
        // let data = {
        //     credit_id: credit_id,
        //     assigned_credit: creditLimit,
        //     active: creditSwitch? "active" : "inactive"
        // }
        dispatch(user_credit_add(data))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    dispatch(updatenotificationdata(res?.payload?.data))
                }
                if(creditLimit && Number(creditLimit) > 0){
                    setEditLimit(true)
                }
                setSpinner(false)
                setIsSubmit(false)
            })
            .catch(err=>{
                console.error(err);
            })
    }

    useEffect(()=> {
        if(props.instance?.id){
            dispatch(get_user_credit_details(props.instance?.id))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    const data = res?.payload?.data?.data[0]
                    let active = data?.active
                    let creditLimit = data?.assigned_credit
                    let credit_id = data?.id
                    setData(data)
                    if(creditLimit && Number(creditLimit) > 0){
                        setEditLimit(true)
                    }
                    setInitialCreditLimit(creditLimit);
                    // setFormikData({"creditLimit": creditLimit})
                    if(active === "active"){
                        active = true
                    }else {
                        active = false
                    }
                    setCreditSwitch(active)
                    setCredit_id(credit_id)
                    const transaction = res?.payload?.data?.transaction
                    setTransactionData(transaction)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            
            let params = {
                user_id: props.instance?.id
            }

            dispatch(get_user_repayment_details(params))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setRepaymentData(res?.payload?.data?.data)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }

        
    },[props.instance?.id])

    useEffect(()=> {
        if(isSubmit){
            onSubmit(formikData)
        }
    },[isSubmit])

    const handleSave = (formik)=> {
        setFormikData(formik)
        setIsSubmit(true)
    }

    const onChange = (checked,formik) => {
        let assigned_credit = data?.assigned_credit
        let available_amount = data?.available_amount
        if(!checked && (assigned_credit != available_amount)){
            messageApi.open({
                type: 'warning',
                content: "Oops! Credit limit cannot be updated for users having pending repayments!",
              });
            return false
        }
        setCreditSwitch(checked)
        setIsToggle(true)
        setIsSubmit(true)
        let value = initialCreditLimit
        if(formikData.values){
            value = formikData.values.creditLimit
        }
        if(value){
            formik.setFieldValue("creditLimit", value)
        }
    };

    const handleEdit = () => {
        let assigned_credit = data?.assigned_credit
        let available_amount = data?.available_amount
        if(assigned_credit != available_amount){
            messageApi.open({
                type: 'warning',
                content: "Oops! Credit limit cannot be updated for users having pending repayments!",
              });
            return false
        }
        setEditLimit(false) 
    }
    if(loading){
        return (
            <SpinContainer className='user-quote'>
                <Spin />
            </SpinContainer>
        )
    }else {
        return (
            <>
            {contextHolder}
            <div className='flex flex-col items-start gap-5'>
                <div className="p-5 flex flex-col items-start w-full border rounded-lg">
                    {/* <div className="flex items-center w-full gap-10">
                        <p>Assign credit limit</p>
                        <Switch checked={creditSwitch} checkedChildren="ON" unCheckedChildren="OFF" size="small" className='bg-primary' onChange={onChange}/>
                    </div> */}
                    {
                        
                        <Formik
                            onSubmit={onSubmit}
                            initialValues={{ creditLimit: initialCreditLimit }} 
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {
                                formik =>{
                                    return(
                                        <Form className='w-full my-5'>
                                            <div className="flex items-center w-full gap-10 mb-2">
                                                <p>Assign credit limit</p>
                                                <Switch checked={creditSwitch} checkedChildren="ON" unCheckedChildren="OFF" size="small" className='bg-primary' onChange={(e)=> onChange(e,formik)}/>
                                            </div>
                                            <div className="grid grid-cols-12 gap-6 w-full">
                                                {creditSwitch &&
                                                    <>
                                                    <div className="col-span-4">
                                                        <label htmlFor='creditLimit' className="py-3 block text-xs font-medium leading-4 text-[#B5B5C3]">Enter Credit Limit *</label>
                                                    
                                                    </div>
                                                    <div className="col-span-4">
                                                        <Field component={TextInput} id='creditLimit' name='creditLimit' value={formik.values.creditLimit} disabled={is_editLimit} placeholder="Enter Company Name"  className="leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border-2 rounded-md disabled:bg-white"/>
                                                        <ErrorMessage name='creditLimit' component={TextError}/>
                                                    </div>

                                                    <div className="col-span-4 flex items-center justify-end">
                                                        {is_editLimit && creditSwitch ? 
                                                            <button type='button' onClick={handleEdit} className='rounded-md  p-2 px-5 flex items-center gap-2 bg-primary hover:bg-secondary text-white shadow-editbtn'>
                                                                <span>Edit</span>
                                                            </button> 
                                                            :
                                                            // null
                                                            <button type='button' onClick={() => handleSave(formik)} className='rounded-md  p-2 px-5 flex items-center gap-2 bg-primary hover:bg-secondary text-white shadow-editbtn'>
                                                                {
                                                                    spinner? <Spin/> :
                                                                    <>
                                                                        <span>Save</span>
                                                                    </>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                    </>
                                                }
                                                
                                                
                                                {/* <div className="col-span-4 flex items-center justify-end">
                                                    <button type='submit' className='rounded-md  p-2 px-5 flex items-center gap-2 bg-primary hover:bg-secondary text-white shadow-editbtn'>
                                                        {
                                                            spinner? <Spin/> :
                                                            <>
                                                                <span>Save</span>
                                                            </>
                                                        }
                                                    </button>
                                                </div> */}

                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    }
                </div>
                <TransactionHistory transactionData={transactionData} repaymentData={repaymentData} />
            </div>
            </>
        )
    }
}
const TextInput = memo(({form,field,disabled})=>{
    const onChange = (e) => {
        form.setFieldValue(field.name, e.target.value);
    };
    return(
        <Input
        id={field.name} 
        name={field.name}
        prefix="₹"
        onChange={onChange}
        className='px-5'
        value={field.value}
        disabled={disabled}
        />
    )
})
export default CreditDetails