import React from 'react'
import { Input, Select } from 'antd';
import styled from 'styled-components/macro';
import { IconImg } from '../../StyledComponents/Header';
import SubmitButton from './SubmitButton';
import AddButton from './AddButton';
import { LableText } from '../../StyledComponents/Queries';
import { handleKeyPress } from '../../functions/Utils';
const { Option } = Select;
const {Group} = Input 

function InputWithSelectContainer(props) {
  return (
    
    <>  
        <GroupItem compact bordered={props.is_input} >
            {props.is_input ? 
             <InputItem style={{ width: props.width, padding: '5px 5px 5px 12px' }} className={props.first_class} value={props.input_val ? props.input_val : "₹"} disabled_class={props.disabled_class} disabled={props.disabled_class? props.disabled_class : false} />
            : 
            <SelectItem defaultValue="" style={{ width: props.width }}>
                {props.options?.map((i,index)=>
                    <Option key={index} value={i.currency}>{i.currency}</Option>
                )}
                {/* <Option value="$">$</Option>
                <Option value="€">€</Option> */}
            </SelectItem>}
            
            <InputItem 
                onChange={(e) => props.handleChange(e,props.index)}
                name={props.name}
                placeholder={props.placeholder}
                style={{ width: `calc(100% - ${props.width})`, padding: '5px' }} className={props.second_class}
                 value={props.value} 
                disabled_class={props.disabled_class} disabled={props.disabled_class? props.disabled_class : false} onKeyPress={handleKeyPress} />
        </GroupItem>
        {props.remarks? <LableText className='wdt-28 break-word'>{props.remarks}</LableText> : null}
        <IconSection className={props.icon_class}>
            {props.close_icon ? <IconImg src={require('../../assets/images/close-square.png')} onClick={()=> props.handleAction? props.handleAction(props.index,"remove",props.output_index) : null}  />: null}
            {props.edit_icon ? <IconImg src={require('../../assets/images/editicon.png')} onClick={()=> props.handleAction? props.handleAction(props.index,"edit",props.output_index) : null} /> : null}
            {props.is_plus_icon ? <IconImg src={require('../../assets/images/add-square.png')} onClick={()=> props.handlePlusClick? props.handlePlusClick() : null} />: null}
            {props.is_save ? <AddButton type="button" title="Save"  is_icon={false} handleClick={props.handleSave? props.handleSave : null} /> : null}
        </IconSection>
    </>
  )
}

export default InputWithSelectContainer

const IconSection = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    &.flex-end {
        justify-content: flex-end;
    }
`
export const GroupItem = styled(Group)`
    width: 171px !important;
    margin-right: 20px;
    border: ${({bordered}) => bordered ? 'none !important' : 'unset'};
`
const SelectItem = styled(Select) `
    & .ant-select-selector {
        height: 35px !important;
        display: flex;
        align-items: center;
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    
    span {
        &.ant-select-selection-item {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #3F4254;
        }
    }
`
export const InputItem = styled(Input) `
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* color: #3F4254; */
    color: ${({disabled_class}) => disabled_class ? '#B5B5C3': '#3F4254' };
    box-shadow: none !important;
    &.first-class {
        border-right: none !important;
        :hover, :focus-visible {
            border: 1px solid #d9d9d9;
            border-right: none;
        }
    }
    &.second-class {
        border-left: none !important;
        :hover, :focus-visible {
            border: 1px solid #d9d9d9;
            border-left: none;
        }
    }
    &.normal-paceholder {
        ::placeholder {
            color: #B5B5C3 !important;
            font-size: 13px;
            font-weight: 500;
            font-family: 'Roboto';
            font-style: normal;
        }
    }
    &.ant-input {
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    /* :hover {
        border: 1px solid #d9d9d9 !important;
        border-left: ${({is_borderleft_none}) => is_borderleft_none ? 'none !important' : '1px solid #d9d9d9'};
    } */
    /* border-left: ${({is_borderleft_none}) => is_borderleft_none ? 'none !important' : '1px solid #d9d9d9'}; */
`