import { Content } from 'antd/es/layout/layout'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ShowComingSoon } from '../../functions/Utils'
import { password_reset_link_user, updatenotificationdata } from '../../store/Common/CommonSlice'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SubmitButton from '../Common/SubmitButton'

function ProfileSection(props) {
    const dispatch = useDispatch()
    const [reset_loading, setResetLoading] = useState(false)
    const handleArchive = () => {
        console.log("archive");
    }

    const handleResetPassword = () => {
        setResetLoading(true)
        const id = props.instance?.id
        const value = {id: id}
        dispatch(password_reset_link_user(value))
        .then((res)=>{
            // console.log('login res => ',res);
            dispatch(updatenotificationdata(res?.payload?.data))
            setResetLoading(false)
        })
        .catch(err=>{
            console.error(err);
        })
    }

  return (
    <>
        <DetailContentContainer>
        <ContentRow>
            <ContentTitle>
                User Name
            </ContentTitle>
            <ContentValue>
               {props.instance?.full_name}
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
            Email
            </ContentTitle>
            <ContentValue>
            {props.instance?.email}
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
                Phone Number
            </ContentTitle>
            <ContentValue>
            +{props.instance?.country_code}-{props.instance?.phone_number}
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
                Whats App Number
            </ContentTitle>
            <ContentValue>
            {props.instance?.wcountrycode ? `+ ${ props.instance?.wcountrycode}-` : null}{props.instance?.whatsapp}
            </ContentValue>
        </ContentRow>
        </DetailContentContainer>
        <ButtonSection className='user-management'>
            <SubmitButton type='button' title="Send Reset Password Link" handleClick={()=> handleResetPassword()} is_icon={false} loading={reset_loading} ml="28px" />
        </ButtonSection>
    </>
    
  )
}

export default ProfileSection
