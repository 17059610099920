import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"



export const exchange_rate = createAsyncThunk('exchange_rate', async (values)=>{
    try{
        const url = 'admin/add_exchange_rate'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})



const initialState = {
    data:{},
    loading:false,
}

const exchangeRateSlice = createSlice({
    name : 'exchangeRate',
    initialState,
})
export default exchangeRateSlice.reducer