import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_query_list = createAsyncThunk('get_query_list', async ()=>{
    try{
        const url = 'kam/list_kam_query'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const view_query = createAsyncThunk('view_query', async (values)=>{
    try{
        const url = 'kam/list_queryby_id'
        const response = await httpinstance.post(url,values)
        console.log("",response);
        return response
    }catch (err){
        return err.response
    }
})

export const send_query_rate = createAsyncThunk('send_query_rate', async (values)=>{
    try{
        const url = 'kam/kam_set_quote'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_accepted_list = createAsyncThunk('get_accepted_list', async (values)=>{
    try{
        const url = 'admin/listacceptedquotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_rejected_list = createAsyncThunk('get_rejected_list', async ()=>{
    try{
        const url = 'admin/rejected_quotation_list'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const refund_file_upload = createAsyncThunk('refund_file_upload', async (values)=>{
    try{
        const url = 'admin/refund_complete_file_upload'
        const response = await httpinstancefile.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const refund_status_submission = createAsyncThunk('refund_status_submission', async (values)=>{
    try{
        const url = 'admin/refund_status_submission'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const cancel_booked_quote = createAsyncThunk('cancel_booked_quote', async (values)=>{
    try{
        const url = 'admin/accepted_quotation_cancellation'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const view_query_user = createAsyncThunk('view_query_user', async (values)=>{
    try{
        const url = 'admin/single_acceptedquotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const view_query_user_kam = createAsyncThunk('view_query_user_kam', async (values)=>{
    try{
        const url = 'kam/single_acceptedquotes'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_single_query = createAsyncThunk('get_single_query', async (values)=>{
    try{
        const url = 'kam/get_single_query'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
}

const querySlice = createSlice({
    name : 'query',
    initialState,
})
export default querySlice.reducer