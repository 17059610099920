import { Button, Empty, Menu, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import ViewButton from '../components/Common/ViewButton'
import { ItemMenu, TablePagination, TableTab } from '../StyledComponents/Common'
import {  TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { ContentContainer, TableFilter } from '../StyledComponents/RateManagement'
import { Divider, Modal } from 'antd';
import RateUploadModal from '../components/RateManagement/RateUploadModal'
import DropDownButton1 from '../components/Common/DropDownButton1'
import { get_rate_list, get_surcharge_rate_list, get_transport_rate_list, get_vas_rate_list } from '../store/RateManagement/RateManagementSlice'
import { useDispatch } from 'react-redux'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { shortenString } from '../functions/Utils'
import { SpinContainer } from '../StyledComponents/Queries'
import TransportationTable from '../components/Common/TransportationTable'
import TransportationRateDetailPage from '../components/RateManagement/TransportationRateDetailPage'


function TransportationRateManagement() {
    const dispatch = useDispatch()
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "air_admin"
    const [tabType, setTabType] = useState(userroles === 'ocean_admin'?"ocean" : "air");
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [rateType, setRateType] = useState("");
    const [type, setType] = useState("view");
    const [data, setData] = useState([]);
    const [call_list, setCallList] = useState(true);
    const [loading, setLoading] = useState(true)

    const { confirm } = Modal;

    useEffect(()=>{
        if(call_list){
            dispatch(get_transport_rate_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                    setData(res?.payload?.data?.nodeData?.data)
                    setCallList(false)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            
        }
        // setIsEdit(false)
    },[call_list])

    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1)
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
        setType("view")
    }


    const handleAdd = (key) => {
        setRateType(key)
        if(key === 'individual_rate'){
            setInstance({})
            setPageType("view_page")
            setType("create")
        }else {
            setModalOpen(true)
        }
    }

   
    const handleBack = () => {
      if(type === 'edit'){
        confirm({
          title: 'Do you want to exit?',
          icon: <ExclamationCircleFilled />,
          content: 'Your changes will not be saved.',
          okButtonProps: {
            style: {
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              borderRadius: '4px',
              color: '#fff',
            },
          },
          onOk() {
            setCallList(true)
            setPageType("table")
            setInstance({})
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }else {
        // setCallList(true)
        setPageType("table")
        setInstance({})
      }
      
    }


      const columns = [
        {
            title: 'COUNTRY',
            dataIndex: 'country',
        },
        {
            title: 'STATE',
            dataIndex: 'state',
            render: (text, record, index) => {
                return text? text : "-";
              },
        },
        {
            title: 'DISTANCE LOWER SLAB',
            dataIndex: 'distLowerSlab',
        },
        {
          title: 'DISTANCE UPPER SLAB',
          dataIndex: 'distUpperSlab',
        },
        {
          title: 'MIN RATE',
          dataIndex: 'minRate',
        },
        {
            title: 'WSR-1(0-50)',
            dataIndex: 'WSR1',
        },
        {
            title: 'WSR-2(50-100)',
            dataIndex: 'WSR2',
        },
        {
            title: 'WSR-3(100-200)',
            dataIndex: 'WSR3',
        },
        {
            title: 'WSR-4(200-300)',
            dataIndex: 'WSR4',
        },
        {
            title: 'WSR-5(300-400)',
            dataIndex: 'WSR5',
        },
        {
            title: 'WSR-6(400-500)',
            dataIndex: 'WSR6',
        },
        {
            title: 'WSR-7(500-750)',
            dataIndex: 'WSR7',
        },
        {
            title: 'WSR-8(750-1000)',
            dataIndex: 'WSR8',
        },
        {
            title: 'WSR-9(1000-2000)',
            dataIndex: 'WSR9',
        },
        {
            title: 'WSR-10(2000-3500)',
            dataIndex: 'WSR10',
        },
        {
            title: 'WSR-11(3500-5000)',
            dataIndex: 'WSR11',
        },
        {
            title: 'WSR-12(5000-7500)',
            dataIndex: 'WSR12',
        },
        {
            title: 'WSR-13(7500-9000)',
            dataIndex: 'WSR13',
        },
        {
            title: 'WSR-14(>9000)',
            dataIndex: 'WSR14',
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
                },
        }
      ];

     


      const items = [
        {
          label: <ItemMenu>Upload File</ItemMenu>,
          key: tabType,
        },
        {
          label: <ItemMenu>Individual Rate</ItemMenu>,
          key: 'individual_rate',
        },
      ];

      

     

    
  return (
    <MainContainer>
      <CustomeHeader title="Transportation Rate Management" description="Manage rates" />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>View Rates</TableTitle>
                <DropDownButton1 items={items} handleDropDownChange={handleAdd} />
                <RateUploadModal modalOpen={modalOpen} setModalOpen={setModalOpen} rateType={rateType} setCallList={setCallList} tabType="transportation" />
            </TableTop>

            {loading? 
                  <SpinContainer className='user-quote'>
                      <Spin />
                  </SpinContainer>: 
                  <TransportationTable name="rate_management" currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} rowKey="_id"  setCallList={setCallList} scroll={true} showScroll={true} />
                }
        </ContentContainer>
        
        <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange} 
            defaultCurrent={currentPage}
            defaultPageSize={10}
            total={data?.length? data?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
            />
        </>
        :pageType === 'view_page' ? 
            <ContentContainer>
                <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
                <TransportationRateDetailPage type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
                 />
            </ContentContainer>
       
            : null}
    </MainContainer>
  )
}

export default TransportationRateManagement
