import { Content } from 'antd/es/layout/layout'
import React from 'react'
import { ShowComingSoon } from '../../functions/Utils'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SubmitButton from '../Common/SubmitButton'

function ProfileDetails(props) {
    const handleArchive = () => {
        console.log("archive");
    }
  return (
    <>
        <DetailContentContainer>
        <ContentRow>
            <ContentTitle>
                Full Name
            </ContentTitle>
            <ContentValue>
               {props.instance?.kyc_user?.full_name}
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
                Company Name    
            </ContentTitle>
            <ContentValue>
               {props.instance.company_name}
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
            Company Type
            </ContentTitle>
            <ContentValue>
               {props.instance?.company_type}
            </ContentValue>
        </ContentRow>
       
        </DetailContentContainer>
        {/* <ButtonSection className='user-management'>
            <SubmitButton type='button' title="Send Reset Password Link" handleClick={()=> ShowComingSoon()} is_icon={false} ml="28px" />
        </ButtonSection> */}
    </>
    
  )
}

export default ProfileDetails
