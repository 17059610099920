import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomeHeader from "../components/Common/CustomeHeader";
import PaginationTable from "../components/Common/PaginationTable";
import SimpleSelect from "../components/Common/SimpleSelect";
import SimpleTable from "../components/Common/SimpleTable";
import ViewButton from "../components/Common/ViewButton";
import GroupBarChart from "../components/report/GroupBarChart";
import HeaderFilter from "../components/report/HeaderFilter";
import KAMTable from "../components/report/KAMTable";
import LineMultiChart from "../components/report/LineMultiChart";
import QueryBarChart from "../components/report/QueryBarChart";
import ReportHeader from "../components/report/ReportHeader";
import SelectSearch from "../components/report/SelectSearch";
import { get_kam_list } from "../store/KAM/KAMSlice";
import { TableTab } from "../StyledComponents/Common";
import {
  ContentContainer,
  TableTitle,
  TableTop,
} from "../StyledComponents/CreateKeyAccountManager";
import { TableFilter } from "../StyledComponents/RateManagement";

function Report() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [mode, setMode] = useState("week");
  const [kam_list, setKamList] = useState([]);
  const [rateCurrentPage, setRateCurrentPage] = useState(1)
  const [enqCurrentPage, setEnqCurrentPage] = useState(1)
  const [kamCurrentPage, setKamCurrentPage] = useState(1)
  const [customerCurrentPage, setCustomerCurrentPage] = useState(1)
  const [state, setState] = useState({
    from_date: null,
    to_date: null,
    date_range: null,
    kam_filter: null
  });

  useEffect(()=> {
    dispatch(get_kam_list())
      .then(res=>{
          // console.log('login res => ',res);
          if(res?.payload?.data?.statusCode === 200 ){
              setKamList(res?.payload?.data?.data)
          }
      })
      .catch(err=>{
          console.error(err);
      })
  },[])

  const handleView = (record) => {
    console.log(record);
  };

  const customerView = (record) => {
    navigate('/reports/customer-view')
  }

  const handleFilterChange = (value)=> {
    console.log(value);
  }

  const onKamChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onKamSearch = (value) => {
    console.log('search:', value);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "SEARCH DATE",
      dataIndex: "search_rate",
    },
    {
      title: "CARGO  ORIGIN",
      dataIndex: "cargo_origin",
    },
    {
      title: "CARGO  DESTINATION",
      dataIndex: "cargo_destination",
    },
    {
      title: "RATE",
      dataIndex: "rate",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => (
        <ViewButton handleClick={handleView} data={record} />
      ),
    },
  ];

  const data = [
    {
      name: "Courtney Henry",
      search_rate: "22/05/2023",
      cargo_origin: "Kochin - COK",
      cargo_destination: "Bengaluru - BLR",
      rate: "INR 25639.26",
    },
    {
      name: "Courtney Henry",
      search_rate: "22/05/2023",
      cargo_origin: "Kochin - COK",
      cargo_destination: "Bengaluru - BLR",
      rate: "INR 25639.26",
    },
    {
      name: "Courtney Henry",
      search_rate: "22/05/2023",
      cargo_origin: "Kochin - COK",
      cargo_destination: "Bengaluru - BLR",
      rate: "INR 25639.26",
    },
  ];

  const enquiry_columns = [
    {
      title: "USER NAME",
      dataIndex: "username",
      render: (text, record) => (
        <div className="flex items-center gap-1">
          <UserNameItem color={record.color} /> <span>{text}</span>
        </div>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "DATE",
      dataIndex: "date",
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record) => (
        <TriangleButton />
      ),
    },
  ];

  const enquiry_data = [
    {
      username: "Courtney Henry",
      type: "AIR",
      date: "22/05/2023",
      color: '#FF3F3B',
    },
    {
      username: "Courtney Henry",
      type: "Ocean",
      date: "22/05/2023",
      color: '#33FF00',
    },
    {
      username: "Courtney Henry",
      type: "Ocean",
      date: "22/05/2023",
      color: '#33FF00',
    },
    {
      username: "Courtney Henry",
      type: "Ocean",
      date: "22/05/2023",
      color: '#FF3F3B',
    },
  ];

  const kam_columns = [
    {
      title: "KAM NAME",
      dataIndex: "kam_name",
      render: (text,record) => (
        <span style={{color: '#3F4254'}}>{text}</span>
      )
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => (
        <ViewButton handleClick={handleView} data={record} />
      ),
    },
  ];

  const kam_data = [
    {
      kam_name: "Kylie Jenner",
      label: "Kylie Jenner",
      value: "Kylie Jenner",
    },
    {
      kam_name: "Kim Kardashian",
      label: "Kim Kardashian",
      value: "Kim Kardashian",
    },
    {
      kam_name: "Felix Kjellberg",
      label: "Felix Kjellberg",
      value: "Felix Kjellberg",
    },
    {
      kam_name: "Gary Vaynerchuk",
      label: "Gary Vaynerchuk",
      value: "Gary Vaynerchuk",
    },
    {
      kam_name: "Courtney Henry",
      label: "Courtney Henry",
      value: "Courtney Henry",
    },
    
  ];

  const customer_columns = [
    {
      title: "USER ID",
      dataIndex: "user_id",
    },
    {
      title: "USER FULL NAME",
      dataIndex: "user_full_name",
    },
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      render: (text,record) => (
        <div className="flex flex-col justify-end mt-3">
          <p>{text}</p>
          <p className="text-[11px] text-lightGrey">{record.company_type}</p>
        </div>
      )
    },
    {
      title: "KEY ACCOUNT MANAGER",
      dataIndex: "key_account_manager",
    },
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => (
        <ViewButton handleClick={customerView} data={record} />
      ),
    },
  ]

  const customer_data = [
    {
      user_id: "SG234567H",
      user_full_name: "Brad Simmons",
      company_name: "Intertico",
      company_type: "PVT Ltd",
      key_account_manager: "Brad Simmons",
      city: "Naperville"
    },
    {
      user_id: "SG234567H",
      user_full_name: "Jessie Clarcson",
      company_name: "Agoda",
      company_type: "PVT Ltd",
      key_account_manager: "Jessie Clarcson",
      city: "Austin"
    },
    {
      user_id: "SG234567H",
      user_full_name: "Lebron Wayde",
      company_name: "RoadGee",
      company_type: "PVT Ltd",
      key_account_manager: "Lebron Wayde",
      city: "Toledo"
    }
  ]

  return (
    <div>
      <CustomeHeader title="Reports" description="reports" />
      <div className="bg-white shadow-md rounded-lg mt-9">
        <ReportHeader
          title="Enquiry Overview"
          description="All Enquiry Stat"
          show_tab={true}
          mode={mode}
          setMode={setMode}
          setState={setState}
          state={state}
        />
        <div className="p-10">
          <HeaderFilter />
          <LineMultiChart mode={mode} state={state} />
          <div className="flex gap-5 mt-7">
            <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
              <span className="font-medium text-2xl text-black">1,362</span>
              <span className="ml-3 font-medium text-base text-black">TEU</span>
              <p className="text-[14px] text-base mt-2">Total Converted</p>
            </div>
            <div className="bg-lightBlue p-4 pb-8 rounded-md w-48">
              <span className="font-medium text-2xl text-black">1,362</span>
              <span className="ml-3 font-medium text-base text-black">TEU</span>
              <p className="text-[14px] text-base mt-2">Total Rejected</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg mt-9">
        <ReportHeader
          title="Rate Search"
          description="Rate search updates"
          show_tab={true}
          mode={mode}
          setMode={setMode}
          setState={setState}
          state={state}
        />
        <div className="p-10">
          <HeaderFilter />
          <div className="mt-10">
            <TableTop>
              <TableTitle>Request List</TableTitle>
            </TableTop>
            <TableFilter>
              <TableTab className="first sub-tab" active="queries">
                Air
              </TableTab>
            </TableFilter>
            <PaginationTable
              currentPage={rateCurrentPage}
              setCurrentPage={setRateCurrentPage}
              pageSize={10}
              columns={columns}
              data={data}
            />
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg mt-9">
        <ReportHeader
          title="KAM Performance"
          description="Overall & Individual Overview"
          show_tab={false}
          mode={mode}
          setMode={setMode}
          setState={setState}
          state={state}
          data={kam_data}
          search={true}
          onChange={onKamChange}
          onSearch={onKamSearch}
        />
        <div className="p-10 flex pt-7 pb-0">
          <div className="w-3/5 pr-5">
            <span className="text-[22px] text-mainYellow font-bold">
              Overall Performance
            </span>
            <GroupBarChart />
          </div>
          <div className="w-2/5 bg-bgRose rounded-t-lg p-4">
            <span className="text-[14px] font-semibold color-formlabel">Enquiries</span>
            <PaginationTable
              currentPage={enqCurrentPage}
              setCurrentPage={setEnqCurrentPage}
              pageSize={5}
              columns={enquiry_columns}
              data={enquiry_data}
            />
          </div>
        </div>
        <div className="p-10 flex pt-0">
            <div className="w-2/5">
              <KAMTable
                currentPage={kamCurrentPage}
                setCurrentPage={setKamCurrentPage}
                pageSize={10}
                columns={kam_columns}
                data={kam_data}
                className="report_kam_table"
              />
            </div>
            <div className="w-3/5 bg-bgRose ml-4 rounded-tl-lg rounded-tr-none rounded-bl-lg rounded-br-lg p-3">
              <div className="flex items-center justify-between">
                  <span className="text-[14px] font-semibold color-formlabel">Response Time On Queries</span>
                  <SelectSearch placeholder="week" />
              </div>
              <div className="flex items-center justify-between mt-5">
                <div>
                  <p className="mb-4 text-darkGreen text-[24px] font-semibold">1,362</p>
                  <p className="text-[14px] font-normal">Total Queries Received</p>
                </div>
                <div>
                  <p className="mb-4 text-fontBlue text-[24px] font-semibold">1,362</p>
                  <p className="text-[14px] font-normal">Total Queries Quoted</p>
                </div>
                <div>
                  <p className="mb-4 text-lightGreen text-[24px] font-semibold">1,362</p>
                  <p className="text-[14px] font-normal">Total Queries Won</p>
                </div>
                <div>
                  <p className="mb-4 text-lightRed text-[24px] font-semibold">1,362</p>
                  <p className="text-[14px] font-normal">Total Queries Lost</p>
                </div>
              </div>
              <div>
                <QueryBarChart />
              </div>
            </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg mt-9 p-5">
          <div className="flex justify-between">
              <span className="text-[20px] font-semibold text-tableHeadColor">Customer Wise Data</span>
              <div className="flex items-center gap-4">
                  <span>Filter by</span>
                  <SimpleSelect options={kam_list} width="140px" value={state.kam_filter} handleChange={handleFilterChange} placeholder="Select KAM" />
              </div>
          </div>
          <div>
            <PaginationTable
              currentPage={customerCurrentPage}
              setCurrentPage={setCustomerCurrentPage}
              pageSize={10}
              columns={customer_columns}
              data={customer_data}
            />
          </div>
      </div>
    </div>
  );
}

export default Report;

const TriangleButton = styled.div`
  width: 0px;
  height: 0px;
  border-top: 9px solid transparent;
  border-left: 15px solid #FFAF00;
  border-bottom: 9px solid transparent;
  border-radius: 3px;
  cursor: pointer;
`

const UserNameItem = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({color})=> color ? color : 'red'};
  border-radius: 50%;
`