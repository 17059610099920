import React from 'react'
import SelectSearch from './SelectSearch'

function HeaderFilter() {
  return (
    <div class="flex flex-wrap gap-8">
        <div class="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Carrier</span>
            <SelectSearch />
        </div>
        <div class="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Port</span>
            <SelectSearch />
        </div>
        <div class="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Country</span>
            <SelectSearch />
        </div>
        <div class="flex items-center gap-8 sm:w-1/2 md:w-1/3">
            <span>Select Sector</span>
            <SelectSearch />
        </div>
        </div>
  )
}

export default HeaderFilter
