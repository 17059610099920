import React from 'react'

const SummaryShip = ({nodeData}) => {
    const originServices = nodeData?.containerDetails?.vaslist?.originServices
    const destinationServices = nodeData?.containerDetails?.vaslist?.destinationServices
    const destinationcargohandling = nodeData?.containerDetails?.destinationcargohandling
    return (
        <div className="p-7">
            <div className="grid grid-cols-12 gap-3 ">
                <div className="col-span-6 text-sm font-semibold leading-6">Cargo Type</div>
                <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.cargoType}</div>
                <div className="col-span-6 text-sm font-semibold leading-6">Container Load</div>
                <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerLoadType}</div>
                <div className="col-span-6 text-sm font-semibold leading-6">Origin Shipment</div>
                <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.originPort?.origin}</div>
                {
                    nodeData?.originPort?.originPickup === "1" && nodeData?.originPort?.originLocation &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin Location</div>
                        <div className="col-span-6 text-sm font-normal leading-6 break-all">{nodeData?.originPort?.originLocation}</div>
                    </>
                }
                {
                    nodeData?.originPort?.originPickup === "1"  &&
                    <div className="col-span-12 text-sm font-light leading-62 text-primary">Require pickup from origin location</div>
                }
                <div className="col-span-6 text-sm font-semibold leading-6">Destination Shipment</div>
                <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.destinationPort?.destination}</div>
                {
                    nodeData?.destinationPort?.destinationPickup === "1" && nodeData?.destinationPort?.destinationLocation &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Location</div>
                        <div className="col-span-6 text-sm font-normal leading-6 break-all">{nodeData?.destinationPort?.destinationLocation}</div>
                    </>
                }
                {
                    nodeData?.destinationPort?.destinationPickup === "1" &&
                    <div className="col-span-12 text-sm font-light leading-5 text-primary">Require dropoff at destination</div>
                }
                {
                    nodeData?.containerDetails?.containerDetails?.map((item, i)=>{
                        return(
                            <div className='col-span-12' key={i}>
                                <div className="grid grid-cols-12 gap-2 ">
                                    <div className="col-span-12 w-full font-medium text-sm leading-6 my-3 text-primary">Container Details {i + 1}</div>
                                    <div className="col-span-6 text-sm font-semibold leading-6">Container Size</div>
                                    <div className="col-span-6 text-sm font-normal leading-6">{item?.containerSize === '20' ? '20FT' : item?.containerSize === '40' ? '40FT' :item?.containerSize === '45' ? '45FT' : item?.containerSize === '40HC' ? '40FT HC' : item?.containerSize === '45HC' ? '45FT HC' : ''}</div>
                                    <div className="col-span-6 text-sm font-semibold leading-6">Container Type</div>
                                    <div className="col-span-6 text-sm font-normal leading-6 flex items-start gap-3 flex-col">
                                        <p>{item?.containerType}</p>
                                        <p>{item?.containerSubType}</p>
                                    </div>
                                    <div className="col-span-6 text-sm font-semibold leading-6">Container Weight</div>
                                    <div className="col-span-6 text-sm font-normal leading-6">{item?.cargoWeight} KG</div>
                                    <div className="col-span-6 text-sm font-semibold leading-6">Container Count</div>
                                    <div className="col-span-6 text-sm font-normal leading-6">{item?.containerCount}</div>
                                </div>
                            </div>
                        )
                    })
                }

                {nodeData?.containerDetails?.packagingDetails?.commodity &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Commodity</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.packagingDetails?.commodity}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.packagingDetails?.totalPackages && 
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Total Packages</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{Number(nodeData?.containerDetails?.packagingDetails?.totalPackages).toFixed(2)} Nos</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.packagingDetails?.totalVolume && 
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Total Volume</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{Number(nodeData?.containerDetails?.packagingDetails?.totalVolume).toFixed(2)} CBM</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.packagingDetails?.totalWeight && 
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Total Weight</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{Number(nodeData?.containerDetails?.packagingDetails?.totalWeight).toFixed(2)} KG</div>
                    </>
                }
                
                <div className="col-span-6 text-sm font-semibold leading-6">Incoterms</div>
                <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.incoTerms}</div>
                {
                    (originServices || destinationServices) && (Object.keys(originServices).length > 0 || Object.keys(destinationServices).length > 0) &&
                    <>
                    <div className="col-span-6 text-sm font-semibold leading-6">Value Added Services</div>
                    <div className="col-span-6 text-sm font-normal leading-6">
                        {
                            originServices && Object.keys(originServices).length > 0 &&
                            <div className="flex flex-col gap-4 w-full mb-5">
                                <div className="w-full font-medium text-sm leading-6 text-primary">Origin Services</div>
                                <ul className="w-full space-y-2 list-disc list-inside ">
                                    {
                                        originServices?.map((item,i)=>{
                                            return (
                                                <li key={i}>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            destinationServices && Object.keys(destinationServices).length > 0 &&
                            <div className="flex flex-col gap-4 w-full">
                                <div className="w-full font-medium text-sm leading-6 text-primary">Destination Services</div>
                                <ul className="w-full space-y-2 list-disc list-inside ">
                                    {
                                        destinationServices?.map((item,i)=>{
                                            return (
                                                <li key={i}>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>                            
                    </>
                }
                {
                    nodeData?.containerDetails?.origincargostuffing &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin Cargo Stuffing</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.origincargostuffing}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.originzipcode &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin PIN /ZIP Code</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.originzipcode}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.originaddress &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin Address</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.originaddress}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.origincfscargostuffing &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin CFS Cargo Stuffing</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.origincfscargostuffing}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.adcode &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">AD Code</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.adcode}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.origincargocurrency &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin Cargo Currency</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.origincargocurrency}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.origincargovalue &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Origin Cargo Value</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.origincargovalue}</div>
                    </>
                }
                {/* {
                    destinationcargohandling && Object.keys(destinationcargohandling).length > 0 &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Cargo Handling</div>
                        <div className="col-span-6 text-sm font-normal leading-6">
                            <ul className="w-full space-y-2 list-disc list-inside ">
                                {
                                    destinationcargohandling?.map((item,i)=>{
                                        return (
                                            <li key={i}>{item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </>
                } */}
                {
                    nodeData?.containerDetails?.destinationzipcode &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination PIN /ZIP Code</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.destinationzipcode}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.destinationaddress &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Address</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.destinationaddress}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.destinationcargocurrency &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Cargo Currency</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.destinationcargocurrency}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.destinationcargovalue &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Cargo Value</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.destinationcargovalue}</div>
                    </>
                }
                {
                    nodeData?.containerDetails?.destinationtypeofstuffing &&
                    <>
                        <div className="col-span-6 text-sm font-semibold leading-6">Destination Type of Stuffing</div>
                        <div className="col-span-6 text-sm font-normal leading-6">{nodeData?.containerDetails?.destinationtypeofstuffing}</div>
                    </>
                }
            </div>
        </div>
    )
}

export default SummaryShip