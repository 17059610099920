import { Modal,Alert, Space, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { password_reset_link, resend_invitation, updatenotificationdata } from '../../store/Common/CommonSlice'
import { ButtonSection, ContentRow, ContentTitle, ContentValue, DetailContentContainer } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SubmitButton from '../Common/SubmitButton'

function ActionSection(props) {
    const dispatch = useDispatch()
    const [reset_loading, setResetLoading] = useState(false)
    const [resend_loading, setResendLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const handleArchive = () => {
        console.log("archive");
    }

    const showConfirm = () => {
        Modal.warning({
            title: 'Coming Soon',
            // content: 'this ',
            okButtonProps: {
                style: {
                  backgroundColor: '#1890ff',
                  borderColor: '#1890ff',
                  borderRadius: '4px',
                  color: '#fff',
                },
              },
          });
      };

    const handleResetPassword = () => {
        if(props.instance?.default_kam === 1){
            // setShowAlert(true); 
            messageApi.open({
                type: 'warning',
                content: "Default KAM can't Reset Password",
              });
        }else {
            setResetLoading(true)
            const id = props.instance.id
            const value = {id: id}
            dispatch(password_reset_link(value))
            .then((res)=>{
                // console.log('login res => ',res);
                dispatch(updatenotificationdata(res?.payload?.data))
                setResetLoading(false)
            })
            .catch(err=>{
                console.error(err);
            })
        }
        
    }

    const handleResendInvitation = () => {
        if(props.instance?.default_kam === 1){
            messageApi.open({
                type: 'warning',
                content: "Default KAM can't Resend Invitation",
              });
        }else {
            setResendLoading(true)
            const id = props.instance.id
            const value = {kam_id: id}
            dispatch(resend_invitation(value))
            .then((res)=>{
                // console.log('login res => ',res);
                dispatch(updatenotificationdata(res?.payload?.data))
                setResendLoading(false)
            })
            .catch(err=>{
                console.error(err);
            })
        }
    }
  return (
    <>
       {contextHolder}
        <DetailContentContainer>
        <ContentRow mb="20px">
            <ContentTitle>
                Invitation Link
            </ContentTitle>
            <ContentValue>
                <AddButton title="Resend Invitation Link" handleClick={()=> handleResendInvitation()} is_icon={false} loading={resend_loading} />
            </ContentValue>
        </ContentRow>
        <ContentRow>
            <ContentTitle>
                    Reset Password
            </ContentTitle>
            <ContentValue>
                <AddButton title="Send Reset Password Link" handleClick={()=> handleResetPassword()} is_icon={false} loading={reset_loading} />
            </ContentValue>
        </ContentRow>
        </DetailContentContainer>
        <ButtonSection>
            {/* <AddButton title="Move To Archive" icon_type="archive" handleClick={handleArchive}  />
            <SubmitButton type='button' title="save" is_save={true} ml="28px" /> */}
        </ButtonSection>
    </>
    
  )
}

export default ActionSection
