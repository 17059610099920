import React from 'react'
import { IconSection, LeftHead, SubHeadContainer, SubHeadTitle } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import AddButton from './AddButton'

function SubHeading(props) {
  return (
    <SubHeadContainer border={props.border} pb={props.pb} pt={props.pt}>
      <LeftHead className={props.class} >
        {props.title1? <SubHeadTitle className='sub-head title1'>{props.title1}</SubHeadTitle>: null}
        <SubHeadTitle className='sub-head'>{props.title}</SubHeadTitle>
      </LeftHead>
    </SubHeadContainer>
  )
}

export default SubHeading
