import React from 'react'
import { Select, Space } from 'antd';
import styled from 'styled-components';
import { IconImg } from '../../StyledComponents/Header';
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select;

function CustomSelectContainer(props) {
  return (
    <SpaceItem wrap className={props.main_class} >
        {props.is_icon? <SelectIcon>₹</SelectIcon> : null}
        <ItemSelect
            is_icon={props.is_icon}
            size={props.size ? props.size : "large"}
            placeholder={props.placeholder ?props.placeholder : "Select"}
            className={props.class}
            style={{
                width: props.width? props.width : 230,
            }}
            onChange={(e) => props.handleChange(e,props.name,props.index)}
            // options={props.options}
            value={props.value}
            pdg={props.pdg}
            height={props.height}
            disabled={props.disabled? props.disabled : false}
        >
            {props.options?.map((i,index)=> 
                <Option key={index} value={i.currency}>{i.currency}</Option>
            )}
        </ItemSelect>
        
       
    </SpaceItem>
  )
}

export default CustomSelectContainer

const SpaceItem = styled(Space)`
    position: relative;
    &.main_class {
        width: 57%;
    }
`
const SelectIcon = styled.span`
    z-index: 9;
    position: absolute;
    top: 10px;
    left: 17px;
    color: #242424 !important;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto';
    font-style: normal;
`
const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 6px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
        padding-left: ${({ is_icon })=> is_icon ? "20px !important": 'unset'};
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        height: ${({ height }) => height? height : 'unset'};
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    
    & .ant-select-selection-placeholder {
        color: #242424 !important;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Roboto';
        font-style: normal;
        &.normal-placeholder {
            color: #B5B5C3 !important;
        }
    }
    
    & .ant-select-arrow {
        color: #242424 !important;
    }
    & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    }
    &.normal-placeholder {
        & .ant-select-selection-placeholder {
            color: #B5B5C3 !important;
            font-size: 14px;
            font-weight: 500;
            font-family: 'Roboto';
            font-style: normal;
        }
    }
    &.ant-input {
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
`