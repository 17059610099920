import { Route, Routes, useLocation } from "react-router-dom";
import React,{useEffect,Suspense, lazy} from "react";
import "./App.css";
// import Login from "./layouts/Login";
import Dashboard from "./pages/Dashboard";
import Navigation from "./routes/Navigation";
import ProtectedRoute from "./routes/ProtectedRoute";
import {
  getnotificationdata,
  notificationlist,
  updatefirebasetoken,
  updatenotificationCount,
  updatenotificationdata,
} from "./store/Common/CommonSlice";
import Notification from "./shared/notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import LoginAdmin from "./layouts/LoginAdmin";
// import ForgotPasswordForm from "./components/Authentication/ForgotPasswordForm";
// import LoginForm from "./components/Authentication/LoginForm";
// import LoginAdminForm from "./components/Authentication/LoginAdminForm";
// import ChangePasswordForm from "./components/Authentication/ChangePasswordForm";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "./firebase";
import { notification, Skeleton } from "antd";

const Login = lazy(()=> import('./layouts/Login'))
const LoginForm = lazy(()=> import('./components/Authentication/LoginForm'))
const LoginAdminForm = lazy(()=> import('./components/Authentication/LoginAdminForm'))
const ForgotPasswordForm = lazy(()=> import('./components/Authentication/ForgotPasswordForm'))
const ChangePasswordForm = lazy(()=> import('./components/Authentication/ChangePasswordForm'))

function App() {
  const notificationData = useSelector(getnotificationdata);
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        dispatch(notificationlist(10))
        .then((res)=>{
            if(res?.payload?.data?.statusCode === 200){
                dispatch(updatenotificationCount(res?.payload?.data?.newCount))
                notification.open({
                    message: payload?.notification?.title,
                    description: payload?.notification?.body,
                    onClick: () => {
                      console.log('Notification Clicked!');
                    },
                });
            }
        })
        .catch(err=>{
            console.error(err);
        })
    });

    useEffect(()=>{
      const fetchData = async () => {
        let swPath = "./firebase-messaging-sw.js"
        if(pathname === "/auth/admin-login" || pathname === "/auth/login"){
          swPath = "../firebase-messaging-sw.js"
        }
        const registration = await navigator.serviceWorker.register(swPath)
        getToken(messaging, { 
          serviceWorkerRegistration: registration,
          vapidKey: 'BAWZ3S4S_Wpke5TVYSAOU6faC5a63H8IVdIMRc_7HtXjF8mQoe249tr40GM_S7vIMrRdRagDR4Sr5nrKGE-Pr-w' })
          .then((currentToken) => {
              if (currentToken) {
                  localStorage.setItem("firebaseToken", currentToken);
                  dispatch(updatefirebasetoken(currentToken))
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
          }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
          });
      } 
      
      fetchData()
      // if ("serviceWorker" in navigator) {
      //   navigator.serviceWorker
      //     .register("../firebase-messaging-sw.js")
      //     .then((registration) => {
      //       console.log(registration);
      //     })
      //     .catch((error) => {
      //       console.error("Service Worker registration failed:", error);
      //     });
      // }

    },[messaging])
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/auth/*" element={<Suspense fallback={<Skeleton active />}><Login/></Suspense>}>
          <Route path="login" element={<Suspense fallback={<Skeleton active />}><LoginForm/></Suspense>} />
          <Route path="admin-login" element={<Suspense fallback={<Skeleton active />}><LoginAdminForm/></Suspense>} />
          <Route path="forgotpassword" element={<Suspense fallback={<Skeleton active />}><ForgotPasswordForm/></Suspense>} />
          <Route
            path="changepassword/:id/:token"
            element={<Suspense fallback={<Skeleton active />}><ChangePasswordForm/></Suspense>}
          />
        </Route>
        <Route path='*' element={<Navigation />}/>
      </Routes>
      {notificationData.success && notificationData.message && (
        <>
          <Notification notificationData={notificationData} />
        </>
      )}
    </>
  );
}

export default App;
