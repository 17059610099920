import { Layout, Menu, theme } from 'antd';
import React from 'react';
import styled from "styled-components/macro";


const { Header, Content, Footer, Sider } = Layout;



export const MainLayout = styled(Layout)`
    padding: 25px;
    background-color: #F3F6F9;
    min-height: 900px;
    .secondary {
        padding: 0px !important;
        margin-left: 50px !important;
    }
    header {
        /* margin-left: 25px !important; */
    }
    main {
        margin: 0px !important;
    }
    & .ant-layout-sider-children {
        height: 93vh;
    }
`
export const SiderItem = styled(Sider)`
    /* max-width: 300px !important; */
    max-width: ${({collapsed})=> collapsed ? '100px !important' : '300px !important'};
    min-width: ${({collapsed})=> collapsed ? '100px !important' : '300px !important'};
    width: ${({collapsed})=> collapsed ? '100px !important' : '300px !important'};
    /* min-width: 300px !important;
    width: 300px !important; */
    border-radius: 24px !important;
    background-color: #fff !important;
    
    @media (max-width: 1200px) {
        min-width: 260px !important;    
    }
`
export const MenuItem = styled(Menu)`
    border-radius: 24px;
    background-color: #fff;
    
    li {
        /* align-items: flex-start !important; */
        &.ant-menu-item {
            height: 55px !important;
            line-height: 55px !important;
            width: 94% !important;
            img {
                filter: grayscale(100%);
            }
        }
        &.ant-menu-item-active {
            background-color: #fff !important;
            span {
                color: #FFAF00 !important;
                font-weight: 600;
            }
            img {
                filter: unset
            }
        }
        
        &.ant-menu-item-selected {
            background-color: #fff !important;
            span {
                color: #FFAF00 !important;
                font-weight: 600;
            }
            img {
                filter: unset
            }
        }
        

        img {
            width: 16px !important;
        }

    }
    span {
        color: #242424;
        font-weight: 400;
        font-size: 16px;
        display: block;
        white-space: pre-wrap;
        line-height: 1.1;
        margin-left: 20px !important;
    }
    
`
export const LogoContainer = styled.div`
    padding: 10px;
    margin-bottom: 44px;
    display: flex;
    gap: 45px;
    align-items: center;
    &.collapsed-logo {
        flex-direction: column;
        gap: 15px;
    }
`