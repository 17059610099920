import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_kycVerification_list = createAsyncThunk('get_kycVerification_list', async ()=>{
    try{
        const url = 'admin/kyc_verification_list'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const kyc_verification = createAsyncThunk('kyc_verification', async (values)=>{
    try{
        const url = 'admin/kyc_accept_or_reject'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})



const initialState = {
    data:{},
    loading:false,
}

const kycVerificationSlice = createSlice({
    name : 'kycVerification',
    initialState,
})
export default kycVerificationSlice.reducer