import React,{ useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { TabItem,DetailContainer, TabImg, TabHead, TabTitle, TabPaneItem } from '../../StyledComponents/CreateKeyAccountManager'
import ProfileSection from './ProfileSection';
import QuoteSection from './QuoteSection';
import ActionSectionAdmin from './ActionSectionAdmin';
import ProfileSectionAdmin from './ProfileSectionAdmin';
import ContentHead from '../Common/ContentHead';
import { Menu } from 'antd';
import styled from 'styled-components/macro';
import { LeftHead, SubHeadContainer, SubHeadTitle, TitleHead } from '../../StyledComponents/Common';
import { IconImg } from '../../StyledComponents/Header';
import { UserIDContainer, UserText } from '../../StyledComponents/KYCVerifications';
import AddButton from '../Common/AddButton';
import QuoteSectionAdmin from './QuoteSectionAdmin';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_param_url } from '../../functions/Utils';
import CreditDetails from './CreditDetails';

const { SubMenu } = Menu;

const { TabPane } = Tabs;
function UserDetailsAdmin(props) {
    const navigate = useNavigate()
    const [openKeys, setOpenKeys] = useState([]);
    const [currentTab, setCurrentTab] = useState(props.tab? props.tab : "1");
    const [currentSubTab, setCurrentSubTab] = useState(props.tab? props.tab : "1");
    const [is_edit, setIsEdit] = useState(false);
    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
      if(location.pathname === '/user-management-admin'){
        if(isLoad === false){
          props.setPageType("table")
        }else {
          setIsLoad(false)
        }
      }
    },[location])
    
    const callback = (key)=> {
        setCurrentTab(key)
        if(key != 3){
            setOpenKeys([])
            setCurrentSubTab("1")
        }else {
          setOpenKeys(['services'])
        }
        
    }

    const handleCancel = () => {
        setIsEdit(false)
    }
    const handleEdit = () => {
        setIsEdit(true)
    }

    const handleBack = () => {
      const user_id = get_param_url("user_id")
      if(user_id){
        navigate(`/dashboard`)
      }else {
        props.setPageType("table")
      }
      
    }

    const handleMenuClick = ({ key }) => {
        setCurrentSubTab(key)
        setOpenKeys((prevOpenKeys) => {
          const index = prevOpenKeys.indexOf(key);
          if (index === -1) {
            return [...prevOpenKeys, key];
          }
          return prevOpenKeys.filter((item) => item !== key);
        });
      };
    
      const handleSubMenuOpen = (keys) => {
        setOpenKeys(keys);
      };

    const ItemsMenu = [
      {
        label: <TabTitle className='user-admin' active={currentTab === "3"}>
            Actions
        </TabTitle>,
        key: 'services',
        children: [{ label: <TabTitle className='user-admin sub-menu' active={currentSubTab === "1"}>Reassign KAM</TabTitle>, key: '1' },
                    { label: <TabTitle className='user-admin sub-menu' active={currentSubTab === "2"}>Change Status</TabTitle>, key: '2' },
                  ],
      },
    ]

    const items = [
        {
          key: '1',
          tab: (
            <TabHead>
                {currentTab === "1" ? <TabImg src={require('../../assets/images/user-squaretab_active.png')} />:<TabImg src={require('../../assets/images/user-squaretab.png')} /> }
                <TabTitle active={currentTab === "1"}>
                    Profile
                </TabTitle>
            </ TabHead>
          ),
          content: <ProfileSectionAdmin instance={props.instance} is_edit={is_edit} handleCancel={handleCancel} setPageType={props.setPageType} />,
        },
        {
          key: '2',
          tab: (
            <TabHead>
                {currentTab === "2" ? <TabImg src={require('../../assets/images/finger-scantab_active.png')} />:<TabImg src={require('../../assets/images/finger-scantab.png')} /> }
                <TabTitle active={currentTab === "2"}>
                    Quotes Accepted
                </TabTitle>
            </ TabHead>
          ),
          content: <QuoteSectionAdmin instance={props.instance} type="accepted" setQueryInstance={props.setQueryInstance} />,
        },
        {
            key: '3',
            tab: (
              <TabHead className='user-admin'>
                  {currentTab === "3" ? <TabImg src={require('../../assets/images/action_tab_active.png')} />:<TabImg src={require('../../assets/images/action_tab.png')} /> }
                  <MenuItem
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={handleSubMenuOpen}
                    onClick={handleMenuClick}
                    items={ItemsMenu}
                    />
                    {/* <SubMenu key="services" title={<TabTitle className='user-admin' active={currentTab === "3"}>
                      Actions
                  </TabTitle>}>
                        <Menu.Item key="1"><TabTitle className='user-admin sub-menu' active={currentSubTab === "1"}>Reassign KAM</TabTitle></Menu.Item>
                        <Menu.Item key="2"><TabTitle className='user-admin sub-menu' active={currentSubTab === "2"}>Change Status</TabTitle></Menu.Item>
                    </SubMenu>
                    </MenuItem> */}
              </ TabHead>
            ),
            content: <ActionSectionAdmin instance={props.instance} kam_list={props.kam_list} setPageType={props.setPageType} currentSubTab={currentSubTab} setInstance={props.setInstance} />,
        },
        {
            key: '4',
            tab: (
              <TabHead>
                  {currentTab === "4" ? <TabImg src={require('../../assets/images/credit_details_active.png')} />:<TabImg className='grey' src={require('../../assets/images/credit_details_active.png')} /> }
                  <TabTitle active={currentTab === "4"}>
                    Credit Details
                  </TabTitle>
              </ TabHead>
            ),
            content: <CreditDetails instance={props.instance} setPageType={props.setPageType} currentSubTab={currentSubTab} setInstance={props.setInstance} />,
        },
      ];
  return (
    <>
    {/* <ContentHead title="User Details" is_button={true} is_edit={currentTab === "1" ? is_edit : true} handleClick={()=> props.setPageType("table")} handleEdit={handleEdit} /> */}
    <SubHeadContainer className='flex-column'>
      <LeftHead className='space-btwn'>
          <TitleHead>
            <IconImg onClick={()=> handleBack()} src={require('../../assets/images/back_page_arrow.png')} />
            <SubHeadTitle>User Details</SubHeadTitle>
          </TitleHead>
          {/* <AddButton title="Edit" icon_type="edit" handleClick={handleEdit}  /> */}
      </LeftHead>
      <UserIDContainer>
        <UserText>USER ID</UserText>
        <UserText className='value'>{props.instance?.id}</UserText>
      </UserIDContainer>
  </SubHeadContainer>
    <DetailContainer className='user-management'>
       <TabItem
        tabPosition="left"
        defaultActiveKey={props.tab? props.tab : "1"}
        onChange={callback}
        items={items.map((i,idx) => {
            const id = String(idx + 1);
            return {
              label: i.tab,
              key: i.key,
              children: i.content,
            };
          })}
      />
    </DetailContainer>
    </>
    
  )
}

export default UserDetailsAdmin


const MenuItem = styled(Menu)`
    & .ant-menu-submenu-title {
        padding-left:  0px !important;
        align-items: flex-start !important;
        height: 20px !important;
        background: #fff !important;
        :hover {
            background-color: #fff !important;
        }
    }
    & .ant-menu.ant-menu-sub.ant-menu-inline {
        background-color: #fff !important;
    }
    & .ant-menu-submenu-arrow {
        display: none !important;
    }
    border: none !important;
    text-align: left;
    & .ant-menu-title-content {
        line-height: 16px !important;
    }
    & .ant-menu-item {
        background: #fff !important;
        height: 20px !important;
        padding-left: 0px !important;
    }
    
`