import React,{ useEffect, useState } from 'react'
import { Spin } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { updatenotificationdata } from '../../store/Common/CommonSlice';
import * as Yup from 'yup'
import { SpinContainer } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { CustomeButton } from '../../StyledComponents/Common';
import { change_unarchive_rate } from '../../store/Archives/ArchiveSlice';
import { useLocation } from 'react-router-dom';




function ArchiveRateView(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [archieve_loading, setArchiveLoading] = useState(false);

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/archives'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])
    
    const handleUnArchive = () => {
        setArchiveLoading(true)
        const values = {
            customType: "Air",
            data : props.instance,
            rate_id: props.instance._id
        }
        dispatch(change_unarchive_rate(values))
        .then(res=>{
            if(res?.payload?.data?.statusCode === 200 ){
                // console.log(res?.payload?.data?.data);
                dispatch(updatenotificationdata(res?.payload?.data))
                setArchiveLoading(false)
                props.setPageType("table")
                if(res?.payload?.data === 1){
                    let air_data = props.data
                    air_data = air_data.filter((i)=> i._id !== props.instance._id)
                    props.setRateData(air_data)
                }
                props.setGetList(true)
            }
        })
        .catch(err=>{
            console.error(err);
        })
      }

    const initialValues = {
        airline: props.instance?.airline ? props.instance?.airline : '',
        destination_port: props.instance?.destination_port ? props.instance?.destination_port : null,
        origin_port: props.instance?.origin_port ? props.instance?.origin_port : null,
        carr_code: props.instance?.carr_code ? props.instance?.carr_code : null,
        num_code: props.instance?.num_code ? props.instance?.num_code : null,
        product: props.instance?.product ? props.instance?.product : '',
        ship_type: props.instance?.ship_type ? props.instance?.ship_type : null,
        pub_minimum: props.instance?.pub_minimum ? props.instance?.pub_minimum : '',
        sell_min: props.instance?.sell_min ? props.instance?.sell_min : '',
        buy_min: props.instance?.buy_min ? props.instance?.buy_min : '',
        pub_less45: props.instance?.pub_less45 ? props.instance?.pub_less45 : '',
        pub_plus45: props.instance?.pub_plus45 ? props.instance?.pub_plus45 : '',
        sell_less45: props.instance?.sell_less45 ? props.instance?.sell_less45 : '',
        buy_less45: props.instance?.buy_less45 ? props.instance?.buy_less45 : '',
        buy_plus45: props.instance?.buy_plus45 ? props.instance?.buy_plus45 : '',
        sell_plus45: props.instance?.sell_plus45 ? props.instance?.sell_plus45 : '',
        pub_plus100: props.instance?.pub_plus100 ? props.instance?.pub_plus100 : '',
        sell_plus100: props.instance?.sell_plus100 ? props.instance?.sell_plus100 : '',
        buy_plus100: props.instance?.buy_plus100 ? props.instance?.buy_plus100 : '',
        pub_plus250: props.instance?.pub_plus250 ? props.instance?.pub_plus250 : '',
        sell_plus250: props.instance?.sell_plus250 ? props.instance?.sell_plus250 : '',
        buy_plus250: props.instance?.buy_plus250 ? props.instance?.buy_plus250 : '',
        pub_plus300: props.instance?.pub_plus300 ? props.instance?.pub_plus300 : '',
        sell_plus300: props.instance?.sell_plus300 ? props.instance?.sell_plus300 : '',
        buy_plus300: props.instance?.buy_plus300 ? props.instance?.buy_plus300 : '',
        pub_plus500: props.instance?.pub_plus500 ? props.instance?.pub_plus500 : '',
        sell_plus500: props.instance?.sell_plus500 ? props.instance?.sell_plus500 : '',
        buy_plus500: props.instance?.buy_plus500 ? props.instance?.buy_plus500 : '',
        pub_plus1000: props.instance?.pub_plus1000 ? props.instance?.pub_plus1000 : '',
        sell_plus1000: props.instance?.sell_plus1000 ? props.instance?.sell_plus1000 : '',
        buy_plus1000: props.instance?.buy_plus1000 ? props.instance?.buy_plus1000 : '',
        fsc_minimum: props.instance?.fsc_minimum ? props.instance?.fsc_minimum : '',
        fsc_minimum_terms: props.instance?.fsc_minimum_terms ? props.instance?.fsc_minimum_terms : '',
        fsc_normal: props.instance?.fsc_normal ? props.instance?.fsc_normal : '',
        fsc_normal_terms: props.instance?.fsc_normal_terms ? props.instance?.fsc_normal_terms : '',
        ssc: props.instance?.ssc ? props.instance?.ssc : '',
        xray: props.instance?.xray ? props.instance?.xray : '',
        misc: props.instance?.misc ? props.instance?.misc : '',
        ctg: props.instance?.ctg ? props.instance?.ctg : '',
        ams: props.instance?.ams ? props.instance?.ams : '',
        others: props.instance?.others ? props.instance?.others : '',
        customType: "Air",
        id: props.instance?._id ? props.instance?._id : '',
      }

    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
    return (
        <FormikForm
            initialValues={initialValues}
        >
        {({ values }) => (
            <FormItem>
        <DetailContainer>
        <HeadContainer>
                <Title>Air</Title>
                <TopButtonDiv className='mb-3'>
                    {/* <CustomeButton size='large' loading={archieve_loading} onClick={()=> handleUnArchive()}>Un-archive</CustomeButton> */}
                    <CustomeButton size='large' onClick={()=> props.DeleteArchive("single")} loading={props.deleteLoading}>Delete From Archives</CustomeButton>
                    {/* <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} /> */}
                </TopButtonDiv>
                
        </HeadContainer>
            <TopContent>
                <LeftContent>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>Carr Code</TitleRow>
                        </TitleDiv>
                         <RowValue>{values.carr_code}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                            <TitleRow>Origin Airport</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.origin_port}</RowValue>
                        
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>Product</TitleRow>
                        </TitleDiv>
                        <RowValue className='raw-width'>{values.product}</RowValue>
                    </DetailRow>
                </LeftContent>
                <LeftContent>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>Num Code</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.num_code}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                            <TitleRow>Destination Airport</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.destination_port}</RowValue>
                        
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>Ship Type</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.ship_type}</RowValue>
                    </DetailRow>
                </LeftContent>
                <RightContent>
                    <DetailRow>
                        <TitleDiv>
                            <TitleRow>Airline</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.airline}</RowValue>
                        
                    </DetailRow>
                </RightContent>
            </TopContent>
            <MidContent>
            <TableItem>
                <THeadItem>
                    <TRItem>
                        <THItem className='view'></THItem>
                        <THItem className='view'>Min</THItem>
                        <THItem className='view'>Less 45</THItem>
                        <THItem className='view'>Plus 45</THItem>
                        <THItem className='view'>Plus 100</THItem>
                        <THItem className='view'>Plus 250</THItem>
                        <THItem className='view'>Plus 300</THItem>
                        <THItem className='view'>Plus 500</THItem>
                        <THItem className='view'>Plus 1000</THItem>
                    </TRItem>
                </THeadItem>
                <tbody>
                    <TRItem>
                        <THItem className='view'>Buy</THItem>
                        <TDItem>
                            <RowValue>{values.buy_min}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_less45}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_plus45}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_plus100}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_plus250}</RowValue>
                        </TDItem>
                        
                        <TDItem>
                            <RowValue>{values.buy_plus300}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_plus500}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.buy_plus1000}</RowValue>
                        </TDItem>
                    </TRItem>
                    <TRItem>
                        <THItem className='view'>Sell</THItem>
                        <TDItem>
                            <RowValue>{values.sell_min}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_less45}</RowValue>
                        </TDItem>

                        <TDItem>
                            <RowValue>{values.sell_plus45}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_plus100}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_plus250}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_plus300}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_plus500}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.sell_plus1000}</RowValue>
                        </TDItem>
                    </TRItem>

                    <TRItem>
                        <THItem className='view'>Pub</THItem>
                        <TDItem>
                            <RowValue>{values.pub_minimum}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_less45}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus45}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus100}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus250}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus300}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus500}</RowValue>
                        </TDItem>
                        <TDItem>
                            <RowValue>{values.pub_plus1000}</RowValue>
                        </TDItem>
                    </TRItem>
                </tbody>
                </TableItem>
            </MidContent>
            <TitleRow className='sub-head mt-10'>Surcharges</TitleRow>
            <BottomContent>
                <LeftContent>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>FSC Minimum</TitleRow>
                        </TitleDiv>
                            <RowValue>{values.fsc_minimum}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>SSC</TitleRow>
                        </TitleDiv>
                            <RowValue>{values.ssc}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                            <TitleRow>AMS</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.ams}</RowValue>
                    </DetailRow>
                </LeftContent>
                <LeftContent>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>FSC Minimum Terms</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.fsc_minimum_terms}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>XRAY</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.xray}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>Others</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.others}</RowValue>
                </DetailRow>
                </LeftContent>
                <LeftContent>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>FSC Normal</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.fsc_normal}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                            <TitleRow>MISC</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.misc}</RowValue>
                    </DetailRow>
                    <DetailRow style={{marginBottom: '53px'}}></DetailRow>
                </LeftContent>
                <RightContent>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>FSC Normal Terms</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.fsc_normal_terms}</RowValue>
                    </DetailRow>
                    <DetailRow>
                        <TitleDiv>
                        <TitleRow>CTG</TitleRow>
                        </TitleDiv>
                        <RowValue>{values.ctg}</RowValue>
                    </DetailRow>
                    <DetailRow style={{marginBottom: '53px'}}>
                    </DetailRow>
                </RightContent>
            </BottomContent>
        </DetailContainer>
            </FormItem>
        )}
        </FormikForm>
        
    )
    }
}

export default ArchiveRateView


