import React,{useEffect, useState} from 'react'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage, } from 'formik'
import TextError from '../TextErrot'
import { useDispatch, } from 'react-redux'
import { Spin, } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { checkpasseordresetlink, forgotpassword, setpassword } from '../../store/Authentication/AuthSlice';
import Notification from '../../shared/notification/Notification';
import { EyeInvisibleOutlined, EyeTwoTone,ArrowRightOutlined } from '@ant-design/icons';
import { updatenotificationdata } from '../../store/Common/CommonSlice'

const initialValues = {
    password: '',
    confirmpassword : ''
}
const validationSchema = Yup.object({
    password: Yup.string().required('Password is required')
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    confirmpassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
})
const ChangePassword = () => {
    const [spinner, setSpinner] = useState(false);
    const [resData, setResData] = useState({})
    const [linkActive, setLinkActive] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id,token} = useParams()
    const onSubmit = (values, submitProps) => {
        setSpinner(true);
        setResData('')
        values.id = id
        values.token = token
        dispatch(setpassword(values))
        .then((res)=>{
            // console.log('login res => ',res);
            setResData(res?.payload?.data)
            setSpinner(false);
            const data = {
                message : res?.payload?.data?.message,
                statusCode: 200,
                success: res?.payload?.data?.success
            }
            dispatch(updatenotificationdata(data))
            navigate('/auth/login')
        })
        .catch(err=>{
            console.error(err);
        })
        
    }

    useEffect(()=>{
        if(id && token){
            dispatch(checkpasseordresetlink({'id':id,'random_token':token}))
            .then(res=>{
                if(res?.payload?.data?.statusCode === 200){
                    setLinkActive(true)
                }else{
                    setLinkActive(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
    },[dispatch,id,token])

    return (
        <>

{
                linkActive ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {
                            formik =>{
                                return(
                                    <Form autoComplete='off'>
                                        <div className="bg-whites my-5">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <label htmlFor='password' className="py-2 block text-xs font-semibold leading-5 text-formlabel">New Password*</label>
                                                    <div className="relative">
                                                        <Field type={passwordType} id='password' name='password' placeholder="New Password" className="mt-2 leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border rounded-md relative"/>
                                                        <button onClick={()=>{
                                                            passwordType==="password" ? setPasswordType("text") : setPasswordType("password")
                                                        }} type='button' className='absolute right-4 top-2'>
                                                        {
                                                            passwordType === 'password' ? <EyeInvisibleOutlined/> : <EyeTwoTone/>
                                                        }
                                                        </button>

                                                    </div>
                                                    <ErrorMessage name='password' component={TextError} />
                                                </div>

                                                <div className="col-span-6">
                                                    <label htmlFor='confirmpassword' className="py-2 block text-xs font-semibold leading-5 text-formlabel">Confirm Password*</label>
                                                    <div className="relative">
                                                        <Field type={passwordType2} id='confirmpassword' name='confirmpassword' placeholder="Confirm Password" className="mt-2 leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border rounded-md relative"/>
                                                        <button onClick={()=>{
                                                            passwordType2==="password" ? setPasswordType2("text") : setPasswordType2("password")
                                                        }} type='button' className='absolute right-4 top-2'>
                                                        {
                                                            passwordType2 === 'password' ? <EyeInvisibleOutlined/> : <EyeTwoTone/>
                                                        }
                                                        </button>

                                                    </div>
                                                    <ErrorMessage name='confirmpassword' component={TextError} />
                                                </div>                                        <div className="col-span-6">
                                                    <button type='submit' className='w-full text-white bg-primary py-3 rounded-md px-5 text-sm font-bold hover:bg-secondary shadow-loginbutton mt-3' disabled={spinner}>
                                                        {
                                                            spinner ?
                                                                <Spin/>
                                                            :
                                                                'Update Password'
                                                        }
                                                    </button>
                                                </div>
                                                
                                                {/* <div className="col-span-6">
                                                    <button  type='button' onClick={()=> navigate('signup')} className='w-full text-primary py-3 rounded-lg px-8 text-sm font-bold border-primary hover:bg-secondary hover:text-white shadow-newuser my-5 bg-white border flex items-center justify-between' >
                                                    <p>New User? Sign up here</p>
                                                    <div className="flex justify-center items-center w-[30px] h-[30px] font-black  rounded-full border-2 border-primary hover:border-white">
                                                        <ArrowRightOutlined className='font-black'/>
                                                    </div>
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                : 
                <>
                    <p className='text-center'>Rest Password link has expired</p>
                    <div className="col-span-6">
                        <button type='submit' onClick={()=> navigate('/auth/login')} className='w-full text-white bg-primary py-3 rounded-md px-5 text-sm font-bold hover:bg-secondary shadow-loginbutton mt-3' >Sign In</button>
                    </div>
                </>
            }
            
            {/* {
                (resData.success && resData.message) &&
                <>
                    <Notification type={resData?.success} msg={resData?.message}/>
                </>
            } */}
        </>
    )
}

export default ChangePassword