import React from 'react'
import styled from 'styled-components/macro'
import { IconImg } from '../../StyledComponents/Header'

function CardItem(props) {
  return (
    <Container>
        <IconImg src={require('../../assets/images/user_icon.png')} />
        <NumberCount className='mt-3 mb-1'>{props.value}</NumberCount>
        <Title>{props.title}</Title>
    </Container>
  )
}

export default CardItem

const Container = styled.div`
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: flex-start;
`
const NumberCount =styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #464E5F;
`
const Title = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #B5B5C3;
`