import React, { useState } from 'react'
import { Select, Space } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

function SimpleSelect(props) {
    
  return (
    <SpaceItem wrap gap={props.gap} mr={props.mr} mb={props.mb}>
        <ItemSelect
            size={props.size ? props.size : "large"}
            placeholder={props.placeholder ?props.placeholder : "Select"}
            style={{
                width: props.width? props.width : 230,
            }}
            onChange={props.handleChange}
            value={props.value}
            bradius={props.bradius}
        >
            {props.options?.map((i,index)=> 
                <Option key={index} value={i.id}>{i.full_name}</Option>
            )}   
        </ItemSelect>
        
       
    </SpaceItem>
  )
}

export default SimpleSelect

const SpaceItem = styled(Space)`
    position: relative;
`
const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        background-color: #F5F8FA !important;
        border: none !important;
        height: ${({height})=> height? height : 'unset'};
    }
    & .ant-select-selection-placeholder {
        color: #242424 !important;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Roboto';
        font-style: normal;
    }
    & .ant-select-arrow {
        color: #7E8299 !important;
    }
    & .ant-select-selection-item {
        color: #A1A5B7;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
    & .ant-select-item-option {
        font-size: 12px;
        font-family: 'Roboto';
        font-style: normal;
    }
`