import React,{ useState } from 'react'
import { Tabs } from 'antd';
import { TabItem,DetailContainer, TabImg, TabHead, TabTitle, TabPaneItem } from '../../StyledComponents/CreateKeyAccountManager'
import ProfileSection from '../UserManagement/ProfileSection';
import ProfileDetails from './ProfileDetails';
import DocumentDetails from './DocumentDetails';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const { TabPane } = Tabs;
function KYCView(props) {
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(true);
  useEffect(()=> {
    if(location.pathname === '/kyc-verification'){
      if(isLoad === false){
        props.setPageType("table")
      }else {
        setIsLoad(false)
      }
    }
  },[location])


    const [currentTab, setCurrentTab] = useState(props.tab? props.tab : "1");
    const callback = (key)=> {
        setCurrentTab(key)
    }
    const items = [
        {
          key: '1',
          tab: (
            <TabHead>
                {currentTab === "1" ? <TabImg src={require('../../assets/images/user-squaretab_active.png')} />:<TabImg src={require('../../assets/images/user-squaretab.png')} /> }
                <TabTitle active={currentTab === "1"}>
                    Details
                </TabTitle>
            </ TabHead>
          ),
          content: <ProfileDetails instance={props.instance} />,
        },
        {
            key: '2',
            tab: (
              <TabHead>
                  {currentTab === "2" ? <TabImg src={require('../../assets/images/finger-scantab_active.png')} />:<TabImg src={require('../../assets/images/finger-scantab.png')} /> }
                  <TabTitle active={currentTab === "2"}>
                      Documents
                  </TabTitle>
              </ TabHead>
            ),
            content: <DocumentDetails instance={props.instance} setPageType={props.setPageType} />,
          },
        
        
      ];
  return (
    <DetailContainer className='user-management'>
       <TabItem
        tabPosition="left"
        defaultActiveKey={props.tab? props.tab : "1"}
        onChange={callback}
        items={items.map((i,idx) => {
            const id = String(idx + 1);
            return {
              label: i.tab,
              key: i.key,
              children: i.content,
            };
          })}
      />
    </DetailContainer>
  )
}

export default KYCView
