import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import AddButton from '../components/Common/AddButton'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import CreateForm from '../components/CreateAccountantManager/CreateForm'
import DetailsPage from '../components/CreateAccountantManager/DetailsPage'
import OceanViewPage from '../components/Queries/OceanViewPage'
import ViewPage from '../components/Queries/ViewPage'
import { get_param_url } from '../functions/Utils'
import { get_kam_list } from '../store/KAM/KAMSlice'
import { TablePagination } from '../StyledComponents/Common'
import { ButtonHeadSection, ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { SpinContainer } from '../StyledComponents/Queries'

function CreateKeyAccountManager() {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    const dispatch = useDispatch()
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState("1");
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)


    useEffect(()=>{
        if(pageType === 'table'){
            dispatch(get_kam_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.data)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[pageType])

    useEffect(()=>{
        const kam_id = get_param_url("kam_id")
        if(kam_id && data.length){
            let record = data.filter((i)=> i.id == kam_id)[0]
            handleView(record)
        }
    },[data])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }


    const columns = [
        {
            title: 'NAME OF THE USER',
            dataIndex: 'full_name',
        },
        {
            title: 'MOBILE NUMBER',
            dataIndex: 'phone_number',
            render: (text, record) => (
                <span>{record.country_code? "+" : null}{record.country_code} {text}</span>
              ),
        },
        {
            title: 'EMAIL ID',
            dataIndex: 'email',
        },
        {
            title: 'WHATSAPP NUMBER',
            dataIndex: 'whatsapp',
            render: (text, record) => (
                <span>{record.wcountrycode? "+" : null}{record.wcountrycode} {text}</span>
              ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];
      
    //   const data = [
    //     {
    //       key: '1',
    //       name_of_the_user: 'Courtney Hentry',
    //       mobile_number: '(219)555-0114',
    //       email_id: 'name@email.com',
    //       username: 'Minh Anh Le',
    //       password: 'WxJPbEjF***'
    //     },
    //     {
    //         key: '2',
    //         name_of_the_user: 'Albert Flores',
    //         mobile_number: '(808)555-0111',
    //         email_id: 'name@email.com',
    //         username: 'Lan Ngy=uyen',
    //         password: 'WxJPbEjF***'
    //     },
    //     {
    //         key: '3',
    //         name_of_the_user: 'Albert Flores',
    //         mobile_number: '(808)555-0111',
    //         email_id: 'name@email.com',
    //         username: 'Lan Ngy=uyen',
    //         password: 'WxJPbEjF***'
    //     },
    //     {
    //         key: '4',
    //         name_of_the_user: 'Albert Flores',
    //         mobile_number: '(808)555-0111',
    //         email_id: 'name@email.com',
    //         username: 'Lan Ngy=uyen',
    //         password: 'WxJPbEjF***'
    //     },
       
    //     // ... additional data
    //   ];
  return (
    <MainContainer>
      <CustomeHeader title="Create Key Account Manager" description="Create/ remove KAM's" />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>KAM List</TableTitle>
                <ButtonHeadSection>
                    {/* <AddButton title="Download" handleClick={()=> setPageType("")} icon_type="download" /> */}
                    <AddButton title="Add Key Account Manager" handleClick={()=> setPageType("add_form")}  />
                </ButtonHeadSection>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} />
            }
            
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>:pageType === 'add_form'? 
        <ContentContainer>
            <ContentHead title="Create Key Account Manager" handleClick={()=> setPageType("table")} />
            <CreateForm setPageType={setPageType} />
        </ContentContainer>
        :pageType === 'view_page'? 
        <>
           {query_instance?.queryId? 
                userroles === 'ocean_admin' ? 
                <OceanViewPage setPageType={setPageType} instance={query_instance} parent_type="kam_management" setQueryInstance={setQueryInstance} setCurrentTab={setCurrentTab} />
                :
                <ViewPage setPageType={setPageType} instance={query_instance} parent_type="kam_management" setQueryInstance={setQueryInstance} setCurrentTab={setCurrentTab} />
            : 
            <ContentContainer>
                <DetailsPage instance={instance} setPageType={setPageType} setQueryInstance={setQueryInstance} tab={currentTab} />
            </ContentContainer>  } 
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default CreateKeyAccountManager
