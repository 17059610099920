import React from "react";
import ReportHeaderTab from "./ReportHeaderTab";
import shipIcon from "../../assets/images/ship-icon.svg";
import shipIconActive from "../../assets/images/active-ship-icon.svg";
import FlightIcon from "../../assets/images/flight-icon.svg";
import FlightActive from "../../assets/images/active-flight-icon.svg";
import { IconImg } from "../../StyledComponents/Header";
import { Select, Space } from "antd";
import styled from "styled-components/macro";

function ReportHeader(props) {
  return (
    <div className="border-b border-gray-300 flex items-center flex-wrap p-10 justify-between">
      {props.short_head ? (
        <div>
          <h2 class="font-nunito-sans font-semibold text-black text-lg">
            {props.title}
          </h2>
          <h5 class="font-nunito-sans font-normal text-base text-gray-700 text-[14px]">
            {props.description}
          </h5>
        </div>
      ) : (
        <div>
          <h2 class="font-nunito-sans font-semibold text-black text-2xl">
            {props.title}
          </h2>
          <h5 class="font-nunito-sans font-normal text-base text-gray-700 text-[17px]">
            {props.description}
          </h5>
        </div>
      )}

      {props.show_switch_icon ? (
        <div className="flex gap-3">
          {props.switchView === "Ocean" ? (
            <div className="border border-solid border-mainYellow rounded-lg p-2">
              <IconImg src={shipIconActive} />
            </div>
          ) : (
            <div
              onClick={() => props.setSwitchView("Ocean")}
              className="border border-solid border-darkGrey rounded-lg p-2 cursor-pointer"
            >
              <IconImg src={shipIcon} />
            </div>
          )}

          {props.switchView === "Air" ? (
            <div className="border border-solid border-mainYellow rounded-lg p-2">
              <IconImg src={FlightActive} />
            </div>
          ) : (
            <div
              onClick={() => props.setSwitchView("Air")}
              className="border border-solid border-darkGrey rounded-lg p-2 cursor-pointer"
            >
              <IconImg src={FlightIcon} />
            </div>
          )}
        </div>
      ) : null}

      {props.show_tab ? (
        <div>
          <ReportHeaderTab
            mode={props.mode}
            setMode={props.setMode}
            setState={props.setState}
            state={props.state}
          />
        </div>
      ) : null}

      {props.search ? (
        <SpaceItem>
          <ItemSelect
            showSearch
            className="bg-blue"
            placeholder="Select a KAM"
            optionFilterProp="children"
            onChange={props?.onChange}
            onSearch={props?.onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={props.data}
          />
        </SpaceItem>
      ) : null}
    </div>
  );
}

export default ReportHeader;

const SpaceItem = styled(Space)`
  position: relative;
  width: 20%;
  & .ant-space-item {
    width:  100% !important;
  }
`;
const ItemSelect = styled(Select)`
  &.ant-select-single.ant-select-show-arrow {
    width:  100% !important;
  }
  & .ant-select-selector {
    border-radius: 8px !important;
    background-color: #f5f8fa !important;
    border: none !important;
    height: ${({ height }) => (height ? height : "40px !important")};
    display: flex !important;
    align-items: center !important;
    padding: 12px !important;
  }
  & .ant-select-selection-placeholder {
    color: #a1a5b7 !important;
    font-size: 14px;
    font-weight: 700;
    font-family: "Roboto";
    font-style: normal;
  }
  & .ant-select-arrow {
    color: #7e8299 !important;
  }
  & .ant-select-selection-item {
    color: #a1a5b7;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }
  & .ant-select-item-option {
    font-size: 12px;
    font-family: "Roboto";
    font-style: normal;
  }
`;
