import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance,httpinstancefile, httpinstanceRate } from "../../axios/api"


export const get_user_amount = createAsyncThunk('get_user_amount', async (values)=>{
    try{
        const url = 'admin/user_total_amount_paid'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const get_rigistered_user_count = createAsyncThunk('get_rigistered_user_count', async (values)=>{
    try{
        const url = 'reg_users_count'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    data:{},
    loading:false,
}

const dashboardSlice = createSlice({
    name : 'dashboard',
    initialState,
})
export default dashboardSlice.reducer