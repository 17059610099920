import { Input } from "antd";
import styled from "styled-components/macro";

 export const DocText = styled.span`
    background: rgba(255, 175, 0, 0.38);
    border-radius: 4px;
    padding: 2px 12px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #3F4254;
    /* word-break: break-all; */
    @media (max-width: 1480px) {
      font-size: 12px;
      padding: 2px 9px;
      }
 `
 export const DocTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
 `
 export const UserIDContainer = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.wdt-27 {
      width: 27%;
    }
 `
 export const SubHeadSection = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
 `
 export const UserText = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #B5B5C3;
    &.value {
        color: #3F4254;
        font-size: 15px;
    }
 `

 export const DocInput = styled(Input)`
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    &.ant-input {
        :hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    ::placeholder {
            color: #B5B5C3 !important;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
        }
 `