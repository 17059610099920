import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import { ButtonSubmit, ButtonView, CustomeButton, SubHeadTitle } from '../StyledComponents/Common'
import { ButtonHeadSection, ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { Input, Popover, Select, Spin } from 'antd';
import { IconImg } from '../StyledComponents/Header'
import { handleKeyPress } from '../functions/Utils'
import { exchange_rate } from '../store/ExchangeRate/ExchangeRateSlice'
import { useDispatch } from 'react-redux'
import { updatenotificationdata } from '../store/Common/CommonSlice'
import axios from 'axios'
import { SpinContainer } from '../StyledComponents/Queries'
import AddButton from '../components/Common/AddButton'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
//import { REACT_APP_API_BASE_URL_NODE } from '../utils/secondsToTime'

const { Option } = Select;

function ExchangeRate() {
    const dispatch = useDispatch()
    const [state,setState] = useState({
        data: [{
            currency: null,
            inrRate: "",
        }],
    })
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [valid_index, setValidIndx] = useState([])
    const [curr_index, setCurrIndx] = useState(null)
    const token = localStorage.getItem('accessToken');
    const [pageType, setPageType] = useState("table");
    const [instance, setInstance] = useState({});
    const [list_data, setListData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL_NODE}admin/getexchangerates`,
            { headers: { Authorization: `Bearer ${token}`,"Content-Type": "application/json" } }
            );
            if(result.status === 200){
                setListData(result.data?.data)
                setRefresh(false)
            }
          } catch (error) {
              console.log(error);
          }
        };
        if(pageType === 'table' || refresh === true){
            fetchData();
        }
    }, [refresh || pageType]);

    const handleChange = (value,index,name) => {
        console.log(name,"name>>>>>>");
        let data = [...state.data]
        data[index][name] = value
        if(valid_index.includes(index)&& name === "inrRate"){
            let val_indx = valid_index.filter((v)=> v !== index)
            setValidIndx(val_indx)
        }else if(name === "currency"){
            setCurrIndx(null)
        }
        setState((prevState)=> {
            return {
                ...prevState,
                data
            }
        })
        // setListData([])
    }
    const handleAddMore = () => {
        let data = [...state.data]
        data.push({
            currency: null,
            inrRate: "",
        })
        setState((prevState)=> {
            return {
                ...prevState,
                data
            }
        })
    }
    const handleRemove = (index) => {
        let data = [...state.data]
        data.splice(index,1)
        setState((prevState)=> {
            return {
                ...prevState,
                data
            }
        })
    }

    const handleSubmit = () => {
        let data = [...state.data]
        let validate = true
        let val_indx = []
        data.map((i,index)=> {
            if(i.currency && !i.inrRate){
                val_indx.push(index)
                validate = false
            } else if (!i.currency && i.inrRate){
                setCurrIndx(index)
                validate = false
            }else if(!i.currency && !i.inrRate){
                if(index === 0){
                    validate = false
                    setCurrIndx(index)
                    val_indx.push(index)
                }else {
                    data.splice(index,1)
                }
            }
        })
        setValidIndx(val_indx)
        if(validate){
            setLoading(true)
            let final_data = {
                data: data
            }
            dispatch(exchange_rate(final_data))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                    dispatch(updatenotificationdata(res?.payload?.data))
                    setLoading(false)
                    setPageType("table")
                    setState((prevState)=> {
                        return {
                            ...prevState,
                            datacurrency: null,
                            inrRate: "",
                        }
                    })
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
    }

    const handleEditSubmit = () => {
        let data = [instance]
        let validate = true
        let val_indx = []
        if(instance.currency && !instance.inrRate){
            val_indx.push(0)
            validate = false
        } else if (!instance.currency && instance.inrRate){
            setCurrIndx(0)
            validate = false
        }else if(!instance.currency && !instance.inrRate){
            setCurrIndx(0)
            val_indx.push(0)
            validate = false
        }
        setValidIndx(val_indx)
        if(validate){
            setLoading(true)
            let final_data = {
                data: data
            }
            dispatch(exchange_rate(final_data))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                    dispatch(updatenotificationdata(res?.payload?.data))
                    setLoading(false)
                    setPageType("table")
                    setRefresh(true)
                    setState((prevState)=> {
                        return {
                            ...prevState,
                            datacurrency: null,
                            inrRate: "",
                        }
                    })
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
    }


    const handleEditChange = (value,name) => {
        // let insts = instance
        const insts = { ...instance };
        if(name === "currency"){
            insts.currency = value
        }else {
            insts.inrRate = value
        }
        setInstance(insts)
    }

    const handleVisibleChange = (visible) => {
        if (!visible) {
          setInstance({})
        }
      };

    const handleView = (record) => {
        setInstance({...record})
        setValidIndx([])
        setCurrIndx(null)
    }

    const AddForm = () => {
        setState((prevState)=> {
            return {
                ...prevState,
                data: [{
                    currency: null,
                    inrRate: "",
                }],
            }
        })
        setPageType("add_form")
    }
    const text = <span>Title</span>;
    const content = (
        <div style={{ padding: '10px'}}>
            <ItemContainer className='flex-row'>
                <LabelText className='wd-20'>Currency</LabelText>
                <div style={{width: '80%'}}>
                    <TextInput
                        id="currency"
                        name="currency"
                        // className='wd-80'
                        value={instance.currency}
                        size="large"
                        prefix="1"
                        onChange={(e)=> handleEditChange(e.target.value,"currency")}
                        placeholder="currency"
                        disabled
                    />
                    
                    {curr_index === 0? 
                        <ErrorText>required</ErrorText>
                    : null}
                </div>
            </ItemContainer>
            <ItemContainer className='flex-row mt-5'>
                    <LabelText className='wd-20'>In INR</LabelText>
                    <div style={{width: '80%'}}>
                        <TextInput
                            id="inr_amount"
                            name="inr_amount"
                            // className='wd-80'
                            value={instance.inrRate}
                            size="large"
                            onKeyPress={handleKeyPress}
                            prefix="INR"
                            onChange={(e)=> handleEditChange(e.target.value,"inrRate")}
                            placeholder="amount"
                        />
                        {valid_index.includes(0)? 
                            <ErrorText>required</ErrorText>
                        : null}
                    </div>
            </ItemContainer>
            <ItemContainer className='mt-5 flex-row flex-center'>
                <ButtonSubmit onClick={()=> handleEditSubmit()} size='large' loading={loading} icon={<IconImg src={require('../assets/images/save_button.png')} />}>Save</ButtonSubmit>
            </ItemContainer>
        </div>
    
    );

    const columns = [
        {
            title: 'SL NO',
            dataIndex: 'sl_no',
            render: (text, record,index) => (
                <span>{index + 1}</span>
              ),
        },
        {
            title: 'CURRENCY',
            dataIndex: 'currency',
            align: "center",
        },
        {
            title: 'INR RATE',
            dataIndex: 'inrRate',
            align: "center",
            render: (text, record,index) => (
                <span>{record.inrRate}</span>
              ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            align: "right",
            render: (text, record) => (
                <div>
                    <Popover placement="leftTop" onVisibleChange={handleVisibleChange} title={text} content={content} trigger="click">
                        <ButtonView onClick={()=> handleView(record)}>Edit</ButtonView>
                    </Popover>
                </div>
              ),
        }
      ];

    const currency_options = [
        {
          id: 1,
          value: "USD",
          label: "1 USD",
        },
        {
          id: 2,
          value: "EUR",
          label: "1 EUR",
        },
      ]
  return (
    <MainContainer>
      <CustomeHeader title="Exchange Rate" description="Currency exchange rates are added  here..." />
      {pageType === 'table'? <>
      <ContentContainer>
            <TableTop>
                <TableTitle>Exchange Rate List</TableTitle>
                <ButtonHeadSection>
                    <AddButton title="Add Exchange Rate" handleClick={()=> AddForm()}  />
                </ButtonHeadSection>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable rowKey="_id" name="exchangeRate" columns={columns} data={list_data} currentPage={1} pageSize={list_data.length} />
            }
            
        </ContentContainer>
        
      </>:
        <ContentContainer>
            <ContentHead title="Add Exchange Rate" is_back_icon={true} ml="2px" handleClick={()=> setPageType("table")} />
            <MainContent className='mt-8'>
                {state.data.map((i,index)=> 
                    <FormRow className='mb-3' key={index}>
                        <ItemContainer>
                            <LabelText>Currency</LabelText>
                            <TextInput
                                id="currency"
                                name="currency"
                                value={i.currency}
                                size="large"
                                prefix="1"
                                onChange={(e)=> handleChange(e.target.value,index,"currency")}
                                placeholder="currency"
                                maxLength={3}
                            />
                            {/* <ItemSelect
                                size="large"
                                placeholder="select currency"
                                style={{
                                    width: '100%',
                                }}
                                onChange={(val)=> handleChange(val,index,"currency")}
                                value={i.currency}
                            >
                                {currency_options?.map((i,index)=> 
                                    <Option key={index} value={i.value}>{i.label}</Option>
                                )}   
                            </ItemSelect> */}
                            {curr_index === index? 
                                <ErrorText>required</ErrorText>
                            : null}
                        </ItemContainer>
                        <ItemContainer>
                            <LabelText>In INR</LabelText>
                            <TextInput
                                id="inr_amount"
                                name="inr_amount"
                                value={i.inrRate}
                                size="large"
                                onKeyPress={handleKeyPress}
                                prefix="INR"
                                onChange={(e)=> handleChange(e.target.value,index,"inrRate")}
                                placeholder="amount"
                            />
                            {valid_index.includes(index)? 
                                <ErrorText>required</ErrorText>
                            : null}
                        </ItemContainer>
                        {index === state.data.length -1 ? 
                            <>
                                {i.currency && i.inrRate ? 
                                    <ItemContainer className='mt-5 button'>
                                        <ButtonSubmit onClick={()=> handleAddMore()} size='large' icon={<IconImg src={require('../assets/images/add-white.png')} />}>Add More</ButtonSubmit>
                                    </ItemContainer>
                                : null}
                            <ItemContainer className='mt-5 button'>
                                <ButtonSubmit onClick={()=> handleSubmit()} size='large' loading={loading} icon={<IconImg src={require('../assets/images/save_button.png')} />}>Submit</ButtonSubmit>
                            </ItemContainer>
                            </>
                        : <ItemContainer className='mt-5 image'>
                                <IconImg onClick={()=> handleRemove(index)} src={require('../assets/images/close-square.png')} />
                            </ItemContainer>}
                        
                    </FormRow>
                )}
                
            </MainContent>
        </ContentContainer>
        }
    </MainContainer>
  )
}

export default ExchangeRate

const MainContent = styled.div``
const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 25%;
    &.button {
        width: 13%;
        
        @media (max-width: 1280px) {
            width: 16%;
        }
        @media (max-width: 1100px) {
            width: 18%;
        }
        @media (max-width: 980px) {
            width: 22%;
        }
        @media (max-width: 860px) {
            width: 32%;
        }
    }
    &.image {
        align-items: flex-start;
    }
    &.flex-row {
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    &.flex-center{
        justify-content: flex-end;
    }
`
const LabelText = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #242424;
    &.wd-20 {
        width: 20%;
    }
`

const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 6px !important;
        border: 1px solid #D9D9D9 !important;
        height: ${({height})=> height? height : 'unset'};
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    & .ant-select-selection-placeholder {
        color: #D2D6DA !important;
        font-size: 12px;
        font-weight: 400;
        font-family: 'Roboto';
        font-style: normal;
    }
    & .ant-select-arrow {
        color: #7E8299 !important;
    }
    & .ant-select-selection-item {
        color: #A1A5B7;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
    & .ant-select-item-option {
        font-size: 12px;
        font-family: 'Roboto';
        font-style: normal;
    }
`
const FormRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 30px;
`
const TextInput = styled(Input)`
    border-radius: 6px !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    &.wd-80 {
        width: 80%;
    }
    &.ant-input {
        :hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    & .ant-input-prefix{
        color: #d1d3d6;
    }
`
const ErrorText = styled.span`
    color: red;
    font-size: 13px;
    font-weight: 400;
`
