import React,{ useEffect, useState } from 'react'
import { Divider, Input, Select, Spin, Tabs } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableInput, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem, TableFilter } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { ErrorMessage } from 'formik';
import { create_rate, create_vas_rate, move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { shipport_list, updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
import SelectSearchAirline from '../Common/SelectSearchAirline';
import SelectSearchPort from '../Common/SelectSearchPort';
import styled from 'styled-components';
import * as Yup from 'yup'
import { formatDate, handleKeyPress } from '../../functions/Utils';
import { SpinContainer, TextAreaItem } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { TableTab } from '../../StyledComponents/Common';
import SelectSearchOceanPort from '../Common/SelectSearchOceanPort';
import SelectSearchShipline from '../Common/SelectSearchShipline';
import DateInput from '../Common/DateInput';
import FormikDateInput from '../Common/FormikDateInput';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import RadioGroups, { ItemLabel, RadioContainer } from '../Common/RadioGroups';
import { IconImg } from '../../StyledComponents/Header';
import SelectSearchVASOceanPort from '../Common/SelectSearchVASOceanPort';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

const { Option } = Select;

const validationSchema = Yup.object().shape({
    seaport: Yup.string().nullable().required('Origin Port is required'),
    origin_cargo_stuffing: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Origin Cargo Stuffing is required'),
    // origin_transportation: Yup.string().nullable()
    // .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Origin Transportation is required'),
    // origin_transportation_min: Yup.string().nullable()
    // .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Origin Transportation MIN is required'),
    origin_customs_clearance: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Origin Customs_Clearance is required'),
    origin_cfs_clearance: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Origin CFS Clearance is required'),
    destination_cargo_handling: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Destination Cargo_Handling is required'),
    destination_customs_clearance: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Destination Customs_Clearance is required'),
    // destination_transportation: Yup.string().nullable()
    // .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Destination Transportation is required'),
    // destination_transportation_min: Yup.string().nullable()
    // .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Destination Transportation MIN is required'),
    destination_cfs_clearance: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Destination CFS Clearances is required'),
    inland_haulage: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Inland Haulage is required'),
})

function OceanVASDetailPage(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [archieve_loading, setArchiveLoading] = useState(false);
    const [type, setType] = useState(props.type);
    // const [tabType, setTabType] = useState("air");
    const [state, setState] = useState({
        shipport_list: [],
    });

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/rate-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=> {
        setState((prevState) => {
            return {
                ...prevState,
                shipport_list: props?.shipport_list
            }
        })
    },[props?.shipport_list])

    // useEffect(()=>{
    //     dispatch(shipport_list())
    //     .then(res=>{
    //         // console.log('login res => ',res);
    //         if(res?.payload?.status === 200 ){
    //             const shipports = res?.payload?.data?.data
    //             const indianShipports = shipports.filter(shipport => shipport.country_name === 'INDIA' || shipport.country_name === 'India');
    //             indianShipports.sort((a, b) => a.port_name.localeCompare(b.port_name));
                
    //             const foreignShipports = shipports.filter(shipport => shipport.country_name !== 'India' || shipport.country_name !== 'INDIA');
    //             foreignShipports.sort((a, b) => {
    //                 if (a.country === b.country) {
    //                     return a.port_name.localeCompare(b.port_name);
    //                 }
    //                 return a.country.localeCompare(b.country);
    //             });
    //             const sortedShipports = indianShipports.concat(foreignShipports);
    //             setState((prevState) => {
    //                 return {
    //                     ...prevState,
    //                     shipport_list: sortedShipports
    //                 }
    //             })
    //             setLoading(false)
    //         }
    //     })
    //     .catch(err=>{
    //         console.error(err);
    //     })
    // },[])

    const handleCancel = (resetForm) => {
        setType("view")
        props.setType("view")
        resetForm()
    }

    const handleEdit = () => {
        setType("edit")
        props.setType("edit")
    }
    const handleSelectChange = (e,name,setFieldValue)=> {
        setFieldValue([name],e)
    }

    const handleArchive = () => {
        setArchiveLoading(true)
        let rate_id = props.instance._id
          const values = {
            rate_id : rate_id,
            customType: "Ocean"
          }
          dispatch(move_rate_archive(values))
          .then(res=>{
              if(res?.payload?.data?.statusCode === 200 ){
                //   console.log(res?.payload?.data?.data);
                  dispatch(updatenotificationdata(res?.payload?.data))
                  setArchiveLoading(false)
                  props.setPageType("table")
                  props.setCallList(true)
              }
          })
          .catch(err=>{
              console.error(err);
          })
      }

    const DateChange = (date, dateString,setFieldValue) => {
        setFieldValue("validity", date)
    };

    const initialValues = {
        seaport: props.instance?.Seaport ? props.instance?.Seaport : null,
        origin_cargo_stuffing: props.instance?.Origin_Cargo_Stuffing ? props.instance?.Origin_Cargo_Stuffing : null,
        origin_transportation: props.instance?.Origin_Transportation ? props.instance?.Origin_Transportation : null,
        origin_transportation_min: props.instance?.Origin_Transportation_Min ? props.instance?.Origin_Transportation_Min : null,
        origin_customs_clearance: props.instance?.Origin_Customs_Clearance ? props.instance?.Origin_Customs_Clearance : null,
        origin_cfs_clearance: props.instance?.Origin_CFS_Clearance ? props.instance?.Origin_CFS_Clearance : null,
        destination_cargo_handling: props.instance?.Destination_Cargo_Handling ? props.instance?.Destination_Cargo_Handling : null,
        destination_customs_clearance: props.instance?.Destination_Customs_Clearance ? props.instance?.Destination_Customs_Clearance : '',
        destination_transportation: props.instance?.Destination_Transportation ? props.instance?.Destination_Transportation : null,
        destination_transportation_min: props.instance?.Destination_Transportation_Min ? props.instance?.Destination_Transportation_Min : null,
        destination_cfs_clearance: props.instance?.Destination_CFS_Clearance ? props.instance?.Destination_CFS_Clearance : null,
        inland_haulage: props.instance?.Inland_Haulage ? props.instance?.Inland_Haulage : null,
        customType: "Ocean",
        id: props.instance?._id ? props.instance?._id : '', 
      }
   


    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
        return (
            <FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async(values,{resetForm}) => {
                    setSpinner(true)
                    // const val_date = formatDate(values.validity)
                    const param_data = {
                        "Seaport" : values.seaport,
                        "Origin Cargo Stuffing" : values.origin_cargo_stuffing,
                        // "Origin Transportation" : values.origin_transportation,
                        // "Origin Transportation MIN" : values.origin_transportation_min,
                        "Origin Customs Clearance" : values.origin_customs_clearance,
                        "Origin CFS Clearance" : values.origin_cfs_clearance,
                        "Destination Cargo Handling" : values.destination_cargo_handling,
                        "Destination Customs Clearance" : values.destination_customs_clearance,
                        // "Destination Transportation" : values.destination_transportation,
                        // "Destination Transportation MIN" : values.destination_transportation_min,
                        "Destination CFS Clearance" : values.destination_cfs_clearance,
                        "Inland Haulage" : values.inland_haulage,
                        "customType" : 'Ocean',
                        "id" : values.id
                    }

                    try {
                        const result = await axios.post(
                        `${process.env.REACT_APP_API_BASE_URL_NODE}query/individual`,
                        param_data,
                        { headers: { Authorization: `Bearer ${token}`,"Content-Type": "application/json" } }
                        );
                        // console.log(result);
                    if(result.status === 200){
                            setSpinner(false);
                            resetForm()
                            props.setCallList(true)
                            props.setPageType("table")
                            let data = {
                                message : result.data.message,
                                statusCode: 200,
                                success: result.data.status
                            }
                            dispatch(updatenotificationdata(data))
                    }
                    } catch (error) {
                        console.log(error);
                        let data = {
                            message : error.response.data.message,
                            statusCode: 200,
                            success: error.response.data.status
                        }
                        dispatch(updatenotificationdata(data))
                    }

                    // dispatch(create_vas_rate(param_data))
                    // .then(res=>{
                    //     console.log('login res => ',res);
                    //     if(res?.payload?.status === 200 ){
                    //         dispatch(updatenotificationdata(res?.payload?.data))
                    //         setSpinner(false);
                    //         resetForm()
                    //         props.setCallList(true)
                    //         props.setPageType("table")
                    //     }
                    // })
                    // .catch(err=>{
                    //     console.error(err);
                    //     setSpinner(false);
                    // })
                    
            }}
            >
            {({ values,setFieldValue, handleChange, handleBlur, resetForm }) => (
                <FormItem>
            <DetailContainer>
            <HeadContainer>
                    <TableFilter>
                        <>
                        {/* {type === 'create'? 
                            <>
                                <TableTab className='first' active={props.tabType === 'air'} onClick={()=> props.setTabType('air')}>Air</TableTab>
                                <Divider type="vertical" />
                            </>
                        : null} */}
                        <TableTab className={type === 'create'? "first" : 'single-tab'} active={props.tabType === 'ocean'} onClick={()=> props.setTabType('ocean')}>Ocean</TableTab>
                        </>
                        <TableFilter className='mb-2'>
                            {type === 'create'? <RadioGroups datas={props.sub_filter} value={props.subTabType} handleClick={Object.keys(props.instance).length === 0 ? props.handleRadioClick : null} name="ocean" /> :
                                <ItemLabel className='ml-0'>Value added service rate</ItemLabel>
                            }
                        </TableFilter>
                    </TableFilter>
                    {/* <Title>Air</Title> */}
                    <TopButtonDiv className='mb-3'>
                            {/* {type === 'view'?
                                <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} />
                            : null} */}
                        {type === 'edit' || type === 'create' ? 
                        <>  {type === 'edit' ? <SubmitButton type='button' title="Cancel" is_icon={false} handleClick={()=> handleCancel(resetForm)} /> : null}
                            <SubmitButton type='submit' title="save" is_save={true} loading={spinner} />
                        </>
                        : <SubmitButton type="button" title="Edit" icon_type="edit" handleClick={()=> handleEdit()} /> }
                    </TopButtonDiv>
                    
            </HeadContainer>
                <TopContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Seaport*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="seaport" setFieldValue={setFieldValue} value={values.seaport} options={state.shipport_list} width="100%" bradius="8px" placeholder="Seaport" />
                                <ErrorMessage name="seaport" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.seaport}</RowValue> }
                            
                        </DetailRow>
                        
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin Cargo Stuffing*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="origin_cargo_stuffing"
                                    name="origin_cargo_stuffing"
                                    value={values.origin_cargo_stuffing}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Origin Cargo Stuffing'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="origin_cargo_stuffing" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_cargo_stuffing}</RowValue> }
                        </DetailRow>
                        {/* <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin Transportation</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="origin_transportation"
                                    name="origin_transportation"
                                    value={values.origin_transportation}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Origin Transportation'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="origin_transportation" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_transportation}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin Transportation MIN</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="origin_transportation_min"
                                    name="origin_transportation_min"
                                    value={values.origin_transportation_min}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Origin Transportation MIN'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="origin_transportation_min" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_transportation_min}</RowValue> }
                        </DetailRow> */}
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin Customs Clearance*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="origin_customs_clearance"
                                    name="origin_customs_clearance"
                                    value={values.origin_customs_clearance}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Origin Customs Clearance'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="origin_customs_clearance" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_customs_clearance}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin CFS Clearance*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="origin_cfs_clearance"
                                    name="origin_cfs_clearance"
                                    value={values.origin_cfs_clearance}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Origin CFS Clearance'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="origin_cfs_clearance" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_cfs_clearance}</RowValue> }
                        </DetailRow>
                        
                    </LeftContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Inland Haulage*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="inland_haulage"
                                    name="inland_haulage"
                                    value={values.inland_haulage}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Inland Haulage'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="inland_haulage" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.inland_haulage}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination Cargo Handling*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="destination_cargo_handling"
                                    name="destination_cargo_handling"
                                    value={values.destination_cargo_handling}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Destination Cargo Handling'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="destination_cargo_handling" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_cargo_handling}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination Customs Clearance*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="destination_customs_clearance"
                                    name="destination_customs_clearance"
                                    value={values.destination_customs_clearance}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Destination Customs Clearance'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="destination_customs_clearance" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_customs_clearance}</RowValue> }
                        </DetailRow>
                        {/* <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination Transportation</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="destination_transportation"
                                    name="destination_transportation"
                                    value={values.destination_transportation}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Destination Transportation'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="destination_transportation" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_transportation}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination Transportation MIN</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="destination_transportation_min"
                                    name="destination_transportation_min"
                                    value={values.destination_transportation_min}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Destination Transportation MIN'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="destination_transportation_min" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_transportation_min}</RowValue> }
                        </DetailRow> */}
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination CFS CLearance*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="destination_cfs_clearance"
                                    name="destination_cfs_clearance"
                                    value={values.destination_cfs_clearance}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Destination CFS CLearance'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="destination_cfs_clearance" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_cfs_clearance}</RowValue> }
                        </DetailRow>
                    </LeftContent>
                </TopContent>
                
            </DetailContainer>
                </FormItem>
            )}
            </FormikForm>
            
        )
    }
}

export default OceanVASDetailPage


const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important
    }
    /* & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    } */
`