import React, {useState, useEffect} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input, Button, Row, Col, Form as AntForm, Checkbox } from 'antd';
import { FormColumn, FormikForm, FormItem, FormRow } from '../../StyledComponents/CreateKeyAccountManager';
import SubmitButton from '../Common/SubmitButton';
import { create_kam } from '../../store/KAM/KAMSlice';
import { useDispatch, useSelector } from 'react-redux';
import { allcountrycode, countrycode, updatenotificationdata } from '../../store/Common/CommonSlice'
import * as Yup from 'yup'
import { PhoneInput } from '../Common/PhoneInput';
import { TextInputItem } from '../../StyledComponents/Common';
import styled from 'styled-components';


function CreateForm(props) {
    const dispatch = useDispatch()
    const [spinner, setSpinner] = useState(false);

    useEffect(()=>{
        dispatch(countrycode())
    },[])
    const getAllCountryCode = useSelector(allcountrycode)

    const handleSelectChange = (e,setFieldValue,name)=> {
        setFieldValue([name], e)
    }

    const initialValues = {
        full_name: '',
        phone_number: '',
        email: '',
        username: '',
        password: '',
        whatsapp_number: '',
        country_code: '91',
        wcountrycode: '91'
      }
    

    const validationSchema = Yup.object().shape({
        full_name:Yup.string().required("Full Name is required"),
        phone_number: Yup.number()
            .typeError('Phone number must be a number')
            .test('len', 'Phone number must be exactly 10 digits', (val) => {
            if (val) {
                return val.toString().length === 10;
            }
            return false;
            })
            .required('Phone number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        whatsapp_number: Yup.number()
        .typeError('Whatsapp number must be a number')
        .test('len', 'Whatsapp number must be exactly 10 digits', (val) => {
        if (val) {
            return val.toString().length === 10;
        }
        return false;
        })
        .required('Whatsapp number is required'),
    })


    // ----------------------><-----------------------
  return (
    <FormikForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={(values,{resetForm}) => {
            setSpinner(true)
            dispatch(create_kam(values))
            .then((res)=>{
                // console.log('login res => ',res);
                dispatch(updatenotificationdata(res?.payload?.data))
                setSpinner(false);
                if(res?.payload?.status === 200){
                    resetForm();
                    props.setPageType("table")
                }
            })
            .catch(err=>{
                console.error(err);
            })
      }}
    >
      {({ values,setFieldValue, handleChange, handleBlur,errors,touched }) => (
        <FormItem>
            <FormRow>
                <FormColumn span={5} style={{ width: '50%' }}>
                    <label htmlFor="full_name">FULL NAME*</label>
                </FormColumn>
                <FormColumn span={7} style={{ width: '50%' }}>
                    <div style={{ display: 'flex',flexDirection: 'column', width: '100%'}}>
                        <TextInputItem
                            id="full_name"
                            name="full_name"
                            value={values.full_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="large"
                            placeholder='Full Name'
                        />
                        <ErrorMessage name="full_name" component="div" style={{ color: 'red',fontSize: '12px' }} />
                    </div>
                    
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn span={5} style={{ width: '50%' }}>
                    <label htmlFor="phone_number">MOBILE NUMBER*</label>
                </FormColumn>
                <FormColumn span={7} style={{ width: '60%' }}>
                    
                    <div style={{ display: 'flex',flexDirection: 'column', width: '100%'}}>
                        <PhoneInput getAllCountryCode={getAllCountryCode} name="phone_number" id="phone_number" value={values.phone_number}
                            handleSelectChange={handleSelectChange} setFieldValue={setFieldValue} handleChange={handleChange} handleBlur={handleBlur} placeholder="Mobile Number" code_name="country_code"
                            code_value={values.country_code}
                        />
                        <ErrorMessage name="phone_number" component="div" style={{ color: 'red',fontSize: '12px' }} />
                        
                    </div>
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn span={5} style={{ width: '50%' }}>
                    <label htmlFor="whatsapp_number">WHATSAPP NUMBER*</label>
                </FormColumn>
                <FormColumn span={11} >
                    <div style={{ display: 'flex',flexDirection: 'column', width: '100%'}}>
                        <PhoneInput getAllCountryCode={getAllCountryCode} name="whatsapp_number" id="whatsapp_number" value={values.whatsapp_number}
                        handleSelectChange={handleSelectChange} setFieldValue={setFieldValue} handleChange={handleChange} handleBlur={handleBlur} placeholder="Whatsapp Number" code_name="wcountrycode"
                        code_value={values.wcountrycode}
                        />
                        <ErrorMessage name="whatsapp_number" component="div" style={{ color: 'red',fontSize: '12px' }} />
                    </div>
                      <CheckboxItem
                            className='ml-5'
                            onChange={(e) => e.target.checked ? setFieldValue('whatsapp_number', values.phone_number) : setFieldValue('whatsapp_number', "") }
                        >
                            same phone number
                        </CheckboxItem>
                </FormColumn>
                
            </FormRow>
            <FormRow className='last'>
                <FormColumn span={5} style={{ width: '50%' }}>
                    <label htmlFor="email">EMAIL ID*</label>
                </FormColumn>
                <FormColumn span={7} style={{ width: '60%' }}>
                    <div style={{ display: 'flex',flexDirection: 'column', width: '100%'}}>
                        <TextInputItem
                            id="email"
                            name="email"
                            type='email'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="large"
                            placeholder='Email'
                        />
                        <ErrorMessage name="email" component="div" style={{ color: 'red',fontSize: '12px' }} />
                    </div>
                </FormColumn>
                <FormColumn className='submit' span={12} style={{ width: '50%' }}>
                    <SubmitButton type='submit' title="Add and Invite" loading={spinner} />
                </FormColumn>
            </FormRow>
           
            
        </FormItem>
      )}
    </FormikForm>
  )
}

export default CreateForm


const CheckboxItem = styled(Checkbox)`
    display: flex;
    align-items: center;
    width: 240px;
    span {
        
    }
`