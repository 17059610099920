import { Dropdown,Avatar,Badge } from 'antd';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AppDrawer from '../components/Common/AppDrawer';
import FilterSelect from '../components/Common/FilterSelect';
import SimpleTable from '../components/Common/SimpleTable';
import CardItem from '../components/Dashboard/CardItem';
import PaymentCard from '../components/Dashboard/PaymentCard';
import { ItemMenu, TableItem } from '../StyledComponents/Common';
import { AmountSection, AmountText, BottomHeader, BottomSection, CardSection, CollapseItem, ContentSection, FilterDiv, HeadContainer, MainContainer, NameSection, ProfileSection, TableFooter, TitleBottom, TopSection, UserContainer, UserListDiv, UserNameTitle, ViewAll } from '../StyledComponents/Dashboard'
import { HeadName, HeadShort, IconImg, NameDiv, NameSpan, ProfileDiv, ProfileSpan, RightHeader } from '../StyledComponents/Header'
import { Collapse, Divider } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_rigistered_user_count, get_user_amount } from '../store/dashboard/DashboardSlice';
import axios from 'axios';
import { ConvertAmountCurrency, GetExchangeRate } from '../functions/Utils';
import shipline from '../assets/images/active-ship-icon.svg'
import airline from '../assets/images/flight_icon.svg'
import { BellOutlined } from '@ant-design/icons';
import { getfirebasetoken, getNotificationCount, notificationlist, updatenotificationCount, updatenotificationneworold } from '../store/Common/CommonSlice';
import { userlogout } from '../store/Authentication/AuthSlice';
//import { REACT_APP_API_BASE_URL_NODE } from '../utils/secondsToTime';

const { Panel } = Collapse;
const inner_text = `some text`

function DashboardPage() {
  const user = JSON.parse(localStorage.getItem('user'));
  const firebaseToken = useSelector(getfirebasetoken)
  const notificationCount = useSelector(getNotificationCount)
  const userroles = user.userroles;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [total_earnings, setTotalEarnings] = useState(0);
  const [userPaymentData, setUserPaymentData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [reg_user_count, setRegUserCount] = useState(0);
  const [accept_quote_count, setAcceptQuoteCount] = useState(0);
  const [filter_Currency, setFilterCurrency] = useState("₹");
  const [filter_date, setFilterDate] = useState("this_week");
  const [view_all_user, setViewAllUser] = useState(false);
  const [exchangeRateList, setExchangeRateList] = useState([])
  const [userName, setUserName] = useState("");
  const [defaultLogo, setDefaultLogo] = useState(airline);
  const [notificationData, setNotificationData] = useState();
  const count = 10

  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL_NODE}admin/getexchangerates`,
        { headers: { Authorization: `Bearer ${token}`,"Content-Type": "application/json" } }
        );
        if(result.status === 200){
          setExchangeRateList(result.data?.data)
        }
      } catch (error) {
          console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    dispatch(notificationlist(count))
    .then((res)=>{
        if(res?.payload?.data?.statusCode === 200){
            setNotificationData(res?.payload?.data)
            dispatch(updatenotificationCount(res?.payload?.data?.newCount))
            if(open){
              dispatch(updatenotificationneworold())
            }
        }
    })
    .catch(err=>{
        console.error(err);
    })
},[open])

  useEffect(()=> {
    let admin_role = ""
    let username = user?.full_name
      if(userroles === 'air_admin'){
        admin_role = "Air"
        username = "Air Administrator"
        setDefaultLogo(airline)
      }else if(userroles === 'ocean_admin'){
        admin_role = "Ocean"
        username = "Ocean Administrator"
        setDefaultLogo(shipline)
      }
    setUserName(username)
    let currency_type = filter_Currency
    if(filter_Currency === '₹'){
      currency_type = "INR"
    }
    let data = {
      date_filter: filter_date,
      currency_type: currency_type,
      admin_role: admin_role
    }
    dispatch(get_user_amount(data))
      .then(res=>{
          // console.log('login res => ',res);
          if(res?.payload?.status === 200 ){
            setUserPaymentData(res?.payload?.data?.data)
            setTotalEarnings(res?.payload?.data?.total_earnings)
            // const jsonArray = JSON.stringify(res?.payload?.data?.graphData);
            setChartData(res?.payload?.data?.graphData)
          }
      })
      .catch(err=>{
          console.error(err);
      })
      
      let role_data = {
        admin_role:admin_role
      }
      dispatch(get_rigistered_user_count(role_data))
      .then(res=>{
          // console.log('login res => ',res);
          if(res?.payload?.status === 200 ){
            setRegUserCount(res?.payload?.data?.count)
            setAcceptQuoteCount(res?.payload?.data?.quotes_accept_count)
          }
      })
      .catch(err=>{
          console.error(err);
      })
  },[filter_Currency, filter_date])

  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleAdd = (key) => {
    if(key === 'logout'){
      let values = {
        fcmId: firebaseToken
      }
      dispatch(userlogout(values))
      if(user?.userroles === 'admin' || user?.userroles === 'air_admin' || user?.userroles === 'ocean_admin'){
        navigate('/auth/admin-login')
      }else {
        navigate('/auth/login')
      }
    }
  }

  const onClick = ({ key }) => {
    handleAdd(key)
  };

  const handleCurrencyChange = (val) => {
    setFilterCurrency(val)
  }

  const handleDateChange = (val) => {
    setFilterDate(val)
  }

  const ShowAll = () => {
    setViewAllUser(true)
  }
  const handleSinglePage = (id) => {
    navigate(`/user-management-admin?user_id=${id}`)
  }

  const items = [
    // {
    //   label: <ItemMenu>Settings</ItemMenu>,
    //   key: 'settings',
    // },
    {
      label: <ItemMenu>Logout</ItemMenu>,
      key: 'logout',
    },
  ];

  const cuurency_options = [
    {
      id: 1,
      value: "₹",
      label: "₹ INR",
    },
    {
      id: 2,
      value: "$",
      label: "$ USD",
    },
    {
      id: 3,
      value: "€",
      label: "€ EUR",
    },
  ]
  const date_options = [
    {
      id: 1,
      value: "this_week",
      label: "This Week",
    },
    {
      id: 2,
      value: "this_month",
      label: "This Month",
    },
    {
      id: 3,
      value: "this_year",
      label: "This Year",
    },
  ]

  const columns = [
    {
        title: 'USERS',
        dataIndex: 'users',
        width: '50%',
        render: (text, record, index) => {
          return (
            <UserContainer>
              <UserNameTitle>{record.full_name}</UserNameTitle>
              <UserNameTitle className='sub-title'>{record.company_name}</UserNameTitle>
            </UserContainer>
          )
        },
    },
    {
        title: 'TOTAL AMOUNT PAID',
        dataIndex: 'total_amount',
        width: '50%',
        render: (text, record, index) => {
          return (
            <AmountSection onClick={()=> handleSinglePage(record.id)}>
              <AmountText>{filter_Currency} {filter_Currency === 'INR'? Number(record.total_amount).toFixed(2) : ConvertAmountCurrency(exchangeRateList,filter_Currency,Number(record.total_amount))}</AmountText>
              <IconImg src={require('../assets/images/arrow-right-black.png')} />
            </AmountSection>
          //   <CollapseItem bordered={false} expandIconPosition="end">
          //   <Panel header={text} key="1">
          //     <p>{inner_text}</p>
          //   </Panel>
          // </CollapseItem>
          )
        },
    }
  ];

  const data = [
    {
    id: 1,
    users: "Brad Simmons",
    type: "HTML, JS, ReactJS",
    total_amount_paid: "$ 15263.75"
  },{
    id: 2,
    users: "Jessie Clarcson",
    type: "HTML, JS, ReactJS",
    total_amount_paid: "$ 15263.75"
  },{
    id: 3,
    users: "Lebron Wayde",
    type: "HTML, JS, ReactJS",
    total_amount_paid: "$ 15263.75"
  },{
    id: 4,
    users: "Natali Trump",
    type: "HTML, JS, ReactJS",
    total_amount_paid: "$ 15263.75"
  }]
  return (
    <MainContainer>
        <HeadContainer>
            <NameSection>
                <HeadName>Hello, {userName}!</HeadName>
                <HeadShort>Good day, let's catch up with new updates</HeadShort>
            </NameSection>
            <RightHeader>
                {/* <IconImg src={require('../assets/images/header_square.png')} onClick={()=> showDrawer()} /> */}
                <Badge count={notificationCount} className="text-primary">
                  <Avatar shape="circle" onClick={()=> showDrawer()} size='large' style={{display:'flex',alignItems:'center',justifyContent:'center', width: '32px', height: '32px', padding: '4px' }} icon={<BellOutlined />} className="bg-primary hover:bg-secondary cursor-pointer"/>
                </Badge>
                <NameDiv>
                    <NameSpan>{userName}</NameSpan>
                    {/* {user?.userroles === "admin" ? <NameSpan>{user?.userroles}</NameSpan> : null} */}
                    
                </NameDiv>
                <ProfileDiv>
                  {/* <ProfileSpan>
                  {user?.full_name? user?.full_name.charAt(0) : ""}
                  </ProfileSpan> */}
                  <IconImg src={defaultLogo} />

                  <AppDrawer onClose={onClose} open={open}setOpen={setOpen} userName={userName} notificationData={notificationData} count={count} />
                </ProfileDiv>
                  <Dropdown
                    menu={{
                      items,
                      onClick,
                    }}
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <IconImg src={require('../assets/images/down_arrow.png')} />
                </Dropdown>
              </RightHeader>
        </HeadContainer>
        <ContentSection className='mt-10'>
            <TopSection>
              <CardItem title="Total Registered User" value={reg_user_count} />
              <CardItem title="Total Quotes Accepted" value={accept_quote_count} />
            </TopSection>
            <BottomSection className='mt-8'>
                <BottomHeader>
                    <TitleBottom>Payments</TitleBottom>
                    <FilterDiv>
                      {/* as per the discussion we are not showing this filter */}
                      {/* <FilterSelect options={cuurency_options} handleChange={handleCurrencyChange} value={filter_Currency} width="100%" height="34px !important" /> */}
                      <FilterSelect options={date_options} handleChange={handleDateChange} value={filter_date} width="100%" height="34px !important" />
                    </FilterDiv>
                </BottomHeader>
                <CardSection className='mt-4'>
                  <PaymentCard currency={filter_Currency} total_amount={total_earnings} exchangeRateList={exchangeRateList} filter_date={filter_date} chartData={chartData} />
                </CardSection>
                <UserListDiv>
                  {/* <SimpleTable name="user_list" columns={columns} data={data} /> */}
                  <TableItem rowKey="id" name="user_list" columns={columns} dataSource={view_all_user? userPaymentData : userPaymentData.slice(0,5)}  scroll={view_all_user? {y: 500} : false} pagination={false} showScroll={false} />
                  {userPaymentData.length ?
                    view_all_user? <TableFooter className='mt-5'>
                      <IconImg className='rotation' src={require('../assets/images/right_arrow.png')} onClick={()=> setViewAllUser(false)} />
                      <ViewAll onClick={()=> setViewAllUser(false)}>Show Less</ViewAll>
                    </TableFooter> :
                    <TableFooter className='mt-5'>
                      <ViewAll onClick={()=> ShowAll()}>View All Users</ViewAll>
                      <IconImg src={require('../assets/images/right_arrow.png')} onClick={()=> ShowAll()} />
                    </TableFooter>
                  : null}
                  
                  
                </UserListDiv>
            </BottomSection>
        </ContentSection>
    </MainContainer>
  )
}

export default DashboardPage
