import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDXFZS-fr_SKTOR-hYXR3CKYx4j0vl8uB4",
  authDomain: "fir-gate.firebaseapp.com",
  projectId: "fir-gate",
  storageBucket: "fir-gate.appspot.com",
  messagingSenderId: "865356886800",
  appId: "1:865356886800:web:1f292755cfdbe60f862b95",
  measurementId: "G-FB5CGWCL29"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export default messaging