import React, {useState} from 'react'
import { AutoComplete, Input, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

export const PhoneInput = (props) => {
  const {Group} = ItemInput
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const filterOption = (input, option) => {
    const { value, children } = option.props;
    return (
      value.toString().toLowerCase().includes(input.toLowerCase()) ||
      children.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  function handleKeyPress(event) {
    const charCode = event.which || event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  
  const handleChange = (e) => {
    if(e.target.value.length < 11){
      props.handleChange(e)
    }
  }

 
  
  return (
    <Group compact>
      <ItemSelect showSearch  name={props.code_name} size='large' style={{ width: '45%' }} 
        onChange={(e) => props.handleSelectChange(e,props.setFieldValue,props.code_name)}
        onSearch={handleSearch}
        value={props.code_value}
        filterOption={filterOption}
        >
        {props.getAllCountryCode?.data?.map((i,index)=> 
            <Option key={index} value={i.country_code}>+{i.country_code}: {i.name}</Option>
        )}
      </ItemSelect>
      <ItemInput type='text' size="large" style={{ width: '55%',appearance: 'none'  }} id={props.id} name={props.name} placeholder={props.placeholder} 
      value={props.value}  onChange={handleChange} onKeyPress={handleKeyPress} onBlur={props.handleBlur} onKeyDown={handleKeyDown} />
    </Group>
  );
};


const ItemInput = styled(Input)`
    height: 42.2px;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    width: ${({name}) => name === 'whatsapp_number' ? '55% !important': 'unset'} ;
    &.ant-input {
        :hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

`
const ItemSelect = styled(Select)`
    width: ${({name}) => name === 'wcountrycode' ? '44% !important': 'unset'} ;
    & .ant-select-selector {
      font-size: 14px !important;
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
      :hover {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;  
      }
        height: 42.2px !important;
    }
`