import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { ButtonView } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'

function ViewButton(props) {
  return (
    <div>
      <ButtonView onClick={()=> props.handleClick(props.data)}>View</ButtonView>
    </div>
  )
}

export default ViewButton
