import React,{ useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { TabItem,DetailContainer, TabImg, TabHead, TabTitle, TabPaneItem } from '../../StyledComponents/CreateKeyAccountManager'
import ProfileSection from './ProfileSection';
import QuoteSection from './QuoteSection';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
function UserDetails(props) {
  const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/user-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])
    const [currentTab, setCurrentTab] = useState(props.tab? props.tab : "1");
    const callback = (key)=> {
        setCurrentTab(key)
    }
    const items = [
        {
          key: '1',
          tab: (
            <TabHead>
                {currentTab === "1" ? <TabImg src={require('../../assets/images/user-squaretab_active.png')} />:<TabImg src={require('../../assets/images/user-squaretab.png')} /> }
                <TabTitle active={currentTab === "1"}>
                    Profile
                </TabTitle>
            </ TabHead>
          ),
          content: <ProfileSection instance={props.instance} />,
        },
        {
          key: '2',
          tab: (
            <TabHead>
                {currentTab === "2" ? <TabImg src={require('../../assets/images/finger-scantab_active.png')} />:<TabImg src={require('../../assets/images/finger-scantab.png')} /> }
                <TabTitle active={currentTab === "2"}>
                    Quotes Accepted
                </TabTitle>
            </ TabHead>
          ),
          content: <QuoteSection instance={props.instance} type="accepted" setQueryInstance={props.setQueryInstance} />,
        },  
        {
          key: '3',
          tab: (
            <TabHead>
                {currentTab === "3" ? <TabImg src={require('../../assets/images/finger-scantab_active.png')} />:<TabImg src={require('../../assets/images/finger-scantab.png')} /> }
                <TabTitle active={currentTab === "3"}>
                    Quotes Created
                </TabTitle>
            </ TabHead>
          ),
          content: <QuoteSection instance={props.instance} type="quoted" setQueryInstance={props.setQueryInstance} />,
        },
      ];
  return (
    <DetailContainer className='user-management'>
       <TabItem
        tabPosition="left"
        defaultActiveKey={props.tab? props.tab : "1"}
        onChange={callback}
        items={items.map((i,idx) => {
            const id = String(idx + 1);
            return {
              label: i.tab,
              key: i.key,
              children: i.content,
            };
          })}
      />
    </DetailContainer>
  )
}

export default UserDetails
