import { Upload, Button } from 'antd'
import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { CustomeButton } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import AddButton from './AddButton'

function UploadDocs(props) {
  const hiddenFileInputAdrs = useRef(null);
  let icon_img = require('../../assets/images/upload_doc.png')
  return (
    <div>
      <input type="file" ref={hiddenFileInputAdrs} onChange={props.handleChange} className='hidden' />
        <ButtonSubmit htmlType="button" onClick={()=>hiddenFileInputAdrs.current.click()} size='large' icon={<IconImg src={icon_img} className={props.class} />}>
              Upload Documents
        </ButtonSubmit>
      
    </div>
    
  )
}

export default UploadDocs


const ButtonSubmit = styled(Button)`
    background-color: #FFAF00;
    color: #FFFFFF;
    /* box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.12); */
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 8px !important;
    :hover {
        border-color: #FFAF00 !important;
        color: #FFFFFF !important;
    }
    img {
        margin-right: 10px;
    }
    &.custom-file-upload:not(.selected) {
      background-color: #007bff;
      color: #fff;
    }
    &.active-button {
        color: #FFAF00 !important;
        background-color: #fff !important;
    }
`