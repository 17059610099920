import React, {useState} from 'react'
import { IconImg } from '../../StyledComponents/Header'
import { AmountDiv, BottomContent, ButtonSection, ContentRow, ContentSection, LableText, MainContainer, QuoteContainer, SubheadTitle, SubTitleDiv, SummaryContainer, SummaryContent, TopContent } from '../../StyledComponents/Queries'

function AddQuote(props) {
  const [state, setState] = useState({
    airline: 'air_india',
    sell_rate: '0.000',
    destination_cargo_handling: '0.000',
    amounts: [0,0]
  })

  const handleAmount = ()=> {
    let amounts = state.amounts
    amounts.push(0)
    setState((prevState)=> {
      return {
        ...prevState,
        amounts
      }
    })
  }
  const airline_options = [
    {
    value: 'air_india',
    label: 'Air India',
    },
    {
    value: 'lucy',
    label: 'Lucy',
    },
    {
    value: 'Yiminghe',
    label: 'yiminghe',
    },
    {
    value: 'disabled',
    label: 'Disabled',
    disabled: true,
    },
]
  return (
    <MainContainer>
        
    </MainContainer>
  )
}

export default AddQuote
