import React,{ useEffect, useState } from 'react'
import { Button, message, Popconfirm, Tabs } from 'antd';
import { TabItem,DetailContainer, TabImg, TabHead, TabTitle, TabPaneItem } from '../../StyledComponents/CreateKeyAccountManager'
import ProfileSection from './ProfileSection';
import QuoteSection from './QuoteSection';
import ActionSection from './ActionSection';
import ContentHead from '../Common/ContentHead';
import { ConfirmButton, CustomeButton, CustomePopConfirm, LeftHead, SubHeadContainer, SubHeadTitle, TitleHead } from '../../StyledComponents/Common';
import { IconImg } from '../../StyledComponents/Header';
import AddButton from '../Common/AddButton';
import styled from 'styled-components';
import { move_kam_archive } from '../../store/KAM/KAMSlice';
import { updatenotificationdata } from '../../store/Common/CommonSlice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;
function DetailsPage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [currentTab, setCurrentTab] = useState(props.tab? props.tab : "1");
    const [is_edit, setIsEdit] = useState(false);
    const [archieve_loading, setArchiveLoading] = useState(false);

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
      if(location.pathname === '/create-key-account-manager'){
        if(isLoad === false){
          props.setPageType("table")
        }else {
          setIsLoad(false)
        }
      }
    },[location])
    const callback = (key)=> {
        setCurrentTab(key)
    }

    const handleCancel = () => {
        setIsEdit(false)
    }
    const handleEdit = () => {
        setIsEdit(true)
    }
    const handleBack = () => {
      navigate(`/create-key-account-manager`)
      props.setPageType("table")
    }

    const confirm = () => {
      // message.info('Clicked on Yes.');
      // handleClick={()=> handleArchive()}
      navigate(`/user-management-admin?kam_id=${props.instance.id}`)
    };

    const handleArchive = () => {
      setArchiveLoading(true)
      let user_id = props.instance.id
        const values = {
            status: "archive",
            user_id:user_id
        }
        dispatch(move_kam_archive(values))
        .then(res=>{
            if(res?.payload?.data?.statusCode === 200 ){
                // console.log(res?.payload?.data?.data);
                dispatch(updatenotificationdata(res?.payload?.data))
                setArchiveLoading(false)
                props.setPageType("table")
                
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    
    
    const items = [
        {
          key: '1',
          tab: (
            <TabHead>
                {currentTab === "1" ? <TabImg src={require('../../assets/images/user-squaretab_active.png')} />:<TabImg src={require('../../assets/images/user-squaretab.png')} /> }
                <TabTitle active={currentTab === "1"}>
                    Profile
                </TabTitle>
            </ TabHead>
          ),
          content: <ProfileSection instance={props.instance} is_edit={is_edit} handleCancel={handleCancel} setPageType={props.setPageType} />,
        },
        {
          key: '2',
          tab: (
            <TabHead>
                {currentTab === "2" ? <TabImg src={require('../../assets/images/finger-scantab_active.png')} />:<TabImg src={require('../../assets/images/finger-scantab.png')} /> }
                <TabTitle active={currentTab === "2"}>
                    Quotes Created
                </TabTitle>
            </ TabHead>
          ),
          content: <QuoteSection instance={props.instance} setQueryInstance={props.setQueryInstance} />,
        },
        {
          key: '3',
          tab: (
            <TabHead>
                {currentTab === "3" ? <TabImg src={require('../../assets/images/action_tab_active.png')} />:<TabImg src={require('../../assets/images/action_tab.png')} /> }
                <TabTitle active={currentTab === "3"}>
                    Actions
                </TabTitle>
            </ TabHead>
          ),
          content: <ActionSection instance={props.instance} />,
        },
      ];
    
  return (
    <>
    {/* <ContentHead title="Details" is_button={true} is_edit={currentTab === "1" ? is_edit : true} handleClick={()=> props.setPageType("table")} handleEdit={handleEdit} /> */}
    <SubHeadContainer className='flex-column'>
      <LeftHead className='space-btwn'>
          <TitleHead>
            <IconImg onClick={()=> handleBack()} src={require('../../assets/images/back_page_arrow.png')} />
            <SubHeadTitle>Details</SubHeadTitle>
          </TitleHead>
          {currentTab === "1" ?
              props.instance.default_kam === 0 ?
              <TopButtonSection>
                {props.instance?.userCount > 0 ? 
                  <CustomePopConfirm
                    placement="bottom"
                    title="Oops! There are users under this KAM."
                    description="Please re-assign the users under this KAM to another KAM for archiving."
                    onConfirm={confirm}
                    okText="Confirm"
                    cancelText="Cancel"
                    okButtonProps={{style: {color: 'red',background: '#fff',border: '1px solid red',boxShadow: 'none'}}}
                >
                  <CustomeButton size='large' icon={<IconImg src={require('../../assets/images/archive-add.png')} />} >Move To Archive</CustomeButton>
                </CustomePopConfirm>
                :
                  <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} />
                }
                
                <AddButton title="Edit" icon_type="edit" handleClick={()=> handleEdit()}  />
              </TopButtonSection> : null
          : null}
      </LeftHead>
    </SubHeadContainer>
    <DetailContainer>
       {/* <TabItem defaultActiveKey="1"  items={items} onChange={callback} tabPosition="left" /> */}
       <TabItem
        tabPosition="left"
        defaultActiveKey={props.tab? props.tab : "1"}
        onChange={callback}
        items={items.map((i,idx) => {
            const id = String(idx + 1);
            return {
              label: i.tab,
              key: i.key,
              children: i.content,
            };
          })}
      />
    </DetailContainer>
    </>
  )
}

export default DetailsPage

const TopButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
