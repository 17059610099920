import React,{ useEffect, useState } from 'react'
import { Divider, Input, Select, Spin, Tabs } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableInput, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem, TableFilter } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { ErrorMessage } from 'formik';
import { create_rate, move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { shipment_list, shipport_list, updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
import SelectSearchAirline from '../Common/SelectSearchAirline';
import SelectSearchPort from '../Common/SelectSearchPort';
import styled from 'styled-components';
import * as Yup from 'yup'
import { formatDate, GetContainerSizeLabel, handleKeyPress } from '../../functions/Utils';
import { SpinContainer, TextAreaItem } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { TableTab } from '../../StyledComponents/Common';
import SelectSearchShipline from '../Common/SelectSearchShipline';
import DateInput from '../Common/DateInput';
import FormikDateInput from '../Common/FormikDateInput';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import RadioGroups from '../Common/RadioGroups';
import SelectSearchVASOceanPort from '../Common/SelectSearchVASOceanPort';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

const { Option } = Select;

const validationSchema = Yup.object().shape({
    origin_port: Yup.string().nullable().required('Origin Port is required'),
    destination_port: Yup.string().nullable().required('Destination Port required'),
    cont_size: Yup.string().nullable().required('Content Size is required'),
    cont_type: Yup.string().nullable().required('Content Type is required'),
    freight: Yup.string().nullable()
    .matches(/^[0-9]*(\.[0-9]+)?$/, 'Please enter a valid number').required('Freight is required'),
    line_code: Yup.string().nullable().required('Line Code is required'),
    remarks: Yup.string().nullable().required('Remarks is required'),
    shipper_name: Yup.string().nullable().required('Shipper Name is required'),
    validity: Yup.string().nullable().required('Validity is required'),
    validity_terms: Yup.string().nullable().required('Validity Terms is required'),
})

function OceanDetailsPage(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [archieve_loading, setArchiveLoading] = useState(false);
    const [type, setType] = useState(props.type);
    // const [tabType, setTabType] = useState("air");
    const [state, setState] = useState({
        shipline_list: [],
        shipport_list: [],
    });

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/rate-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=> {
        setState((prevState) => {
            return {
                ...prevState,
                shipline_list: props?.shipline_list,
                shipport_list: props?.shipport_list
            }
        })
    },[props?.shipline_list,props?.shipport_list])

    // useEffect(()=>{
    //     dispatch(shipment_list())
    //     .then(res=>{
    //         // console.log('login res => ',res?.payload?.data?.data);
    //         if(res?.payload?.status === 200 ){
    //             setState((prevState) => {
    //                 return {
    //                     ...prevState,
    //                     shipline_list: res?.payload?.data?.data?.data
    //                 }
    //             })
    //         }
    //     })
    //     .catch(err=>{
    //         console.error(err);
    //     })

    //     dispatch(shipport_list())
    //     .then(res=>{
    //         // console.log('login res => ',res);
    //         if(res?.payload?.status === 200 ){
    //             const shipports = res?.payload?.data?.data
    //             const indianShipports = shipports.filter(shipport => shipport.country_name === 'INDIA' || shipport.country_name === 'India');
    //             indianShipports.sort((a, b) => a.port_name.localeCompare(b.port_name));
    //             const Mundra = indianShipports.filter((i)=> i.port_name === 'Mundra' || i.port_code === 'Mundra')
    //             const foreignShipports = shipports.filter(shipport => shipport.country_name !== 'India' || shipport.country_name !== 'INDIA');
    //             foreignShipports.sort((a, b) => {
    //                 if (a.country === b.country) {
    //                     return a.port_name.localeCompare(b.port_name);
    //                 }
    //                 return a.country.localeCompare(b.country);
    //             });
    //             const sortedShipports = indianShipports.concat(foreignShipports);
    //             setState((prevState) => {
    //                 return {
    //                     ...prevState,
    //                     shipport_list: sortedShipports
    //                 }
    //             })
    //             setLoading(false)
    //         }
    //     })
    //     .catch(err=>{
    //         console.error(err);
    //     })
    // },[])

    const handleCancel = (resetForm) => {
        setType("view")
        props.setType("view")
        resetForm()
    }

    const handleEdit = () => {
        setType("edit")
        props.setType("edit")
    }
    const handleSelectChange = (e,name,setFieldValue)=> {
        setFieldValue([name],e)
    }

    const handleArchive = () => {
        setArchiveLoading(true)
        let rate_id = props.instance._id
          const values = {
            rate_id : rate_id,
            customType: "Ocean"
          }
          dispatch(move_rate_archive(values))
          .then(res=>{
              if(res?.payload?.data?.statusCode === 200 ){
                //   console.log(res?.payload?.data?.data);
                  dispatch(updatenotificationdata(res?.payload?.data))
                  setArchiveLoading(false)
                  props.setPageType("table")
                  props.setCallList(true)
              }
          })
          .catch(err=>{
              console.error(err);
          })
      }

    const DateChange = (date, dateString,setFieldValue) => {
        setFieldValue("validity", date)
    };

    const initialValues = {
        origin_port: props.instance?.POR ? props.instance?.POR : null,
        destination_port: props.instance?.POD ? props.instance?.POD : null,
        cont_size: props.instance?.cont_size ? props.instance?.cont_size : null,
        cont_type: props.instance?.cont_type ? props.instance?.cont_type : null,
        freight: props.instance?.freight ? props.instance?.freight : null,
        line_code: props.instance?.line ? props.instance?.line : null,
        remarks: props.instance?.remarks ? props.instance?.remarks : '',
        shipper_name: props.instance?.shipper_name ? props.instance?.shipper_name : null,
        validity: props.instance?.validity ? props.instance?.validity : null,
        validity_terms: props.instance?.validity_terms ? props.instance?.validity_terms : null,
        customType: "Ocean",
        id: props.instance?._id ? props.instance?._id : '', 
      }
   
    const line_code_list = state?.shipline_list?.filter((obj,index,self) => {
        return index === self.findIndex((t) => (
            t.shipping_line_code === obj.shipping_line_code 
        ));
    })

    const cont_sizes = [
        {
            value: "20",
            name: "20FT"
        },
        {
            value: "40",
            name: "40FT"
        },{
            value: "40HC",
            name: "40FT HC"
        },{
            value: "45HC",
            name: "45FT HC"
        }
    ]

    const cont_types = [{
            value: "Standard (Dry)",
            name: "Standard (Dry)"
        },
        {
            value: "Refrigerated (Reefer)",
            name: "Refrigerated (Reefer)"
        },{
            value: "Open Top",
            name: "Open Top"
        },{
            value: "Flat Rack",
            name: "Flat Rack"
        },{
            value: "ISO Tank",
            name: "ISO Tank"
        },{
            value: "Open Side (One door open)",
            name: "Open Side (One door open)"
        }
    ]


    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
        return (
            <FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async(values,{resetForm}) => {
                    setSpinner(true)
                    const val_date = formatDate(values.validity)
                    const param_data = {
                        "POR/POL" : values.origin_port,
                        "POD" : values.destination_port,
                        "Cont Size" : values.cont_size,
                        "Cont Type" : values.cont_type,
                        "Freight" : values.freight,
                        "Line" : values.line_code,
                        "Remarks" : values.remarks,
                        "Validity" : val_date,
                        "Validity Terms" : values.validity_terms,
                        "shipper_name": values.shipper_name,
                        "customType" : values.customType,
                        "oceanType": "FCL",
                        "id" : values.id
                    }
                    if(type === 'edit'){
                        try {
                            const result = await axios.put(
                                // `https://dev.sgate-search.2.mtlstaging.com/query/update/${values.id}`,
                                `${process.env.REACT_APP_API_BASE_URL_NODE}query/update/${values.id}`,
                                param_data,
                                { headers: { Authorization: `Bearer ${token}` } }
                                );
                                if(result.status === 200){
                                    let data = {
                                        message : result.data.message,
                                        statusCode: 200,
                                        success: result.data.success
                                    }
                                    dispatch(updatenotificationdata(data))
                                    setSpinner(false);
                                    // resetForm()
                                    props.setCallList(true)
                                    props.setPageType("table")
                                }
                        } catch (error) {
                            console.log(error);
                            setSpinner(false);
                        }
                    }else {
                        try {
                            const result = await axios.post(
                                // "https://dev.sgate-search.2.mtlstaging.com/query/manual",
                                `${process.env.REACT_APP_API_BASE_URL_NODE}query/manual`,
                                param_data,
                                { headers: { Authorization: `Bearer ${token}` } }
                                );
                                if(result.status === 200){
                                    let data = {
                                        message : result.data.message,
                                        statusCode: 200,
                                        success: result.data.status
                                    }
                                    dispatch(updatenotificationdata(data))
                                    setSpinner(false);
                                    resetForm()
                                    props.setCallList(true)
                                    props.setPageType("table")
                                }
                        } catch (error) {
                            console.log(error);
                            setSpinner(false);
                        }
                    }
                    
            }}
            >
            {({ values,setFieldValue, handleChange, handleBlur, resetForm }) => (
                <FormItem>
            <DetailContainer>
            <HeadContainer>
                    <TableFilter>
                        <>
                        {/* {type === 'create'? 
                            <>
                                <TableTab className='first' active={props.tabType === 'air'} onClick={()=> props.setTabType('air')}>Air</TableTab>
                                <Divider type="vertical" />
                            </>
                        : null} */}
                        <TableTab className={type === 'create'? "first" : 'single-tab'} active={props.tabType === 'ocean'} onClick={()=> props.setTabType('ocean')}>Ocean</TableTab>
                        </>
                        <TableFilter className='mb-2'>
                            <RadioGroups datas={props.sub_filter} value={props.subTabType} handleClick={Object.keys(props.instance).length === 0 ? props.handleRadioClick : null} name="ocean" />
                        </TableFilter>
                    </TableFilter>
                    {/* <Title>Air</Title> */}
                    <TopButtonDiv className='mb-3'>
                            {type === 'view'?
                                <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} />
                            : null}
                        {type === 'edit' || type === 'create' ? 
                        <>  {type === 'edit' ? <SubmitButton type='button' title="Cancel" is_icon={false} handleClick={()=> handleCancel(resetForm)} /> : null}
                            <SubmitButton type='submit' title="save" is_save={true} loading={spinner} />
                        </>
                        : <SubmitButton type="button" title="Edit" icon_type="edit" handleClick={()=> handleEdit()} /> }
                    </TopButtonDiv>
                    
            </HeadContainer>
                <TopContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Line Code*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchShipline name="line_code" value={values.line_code} setFieldValue={setFieldValue} options={line_code_list} 
                                width="100%" bradius="8px" placeholder="Line Code" />
                                <ErrorMessage name="line_code" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.line_code}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Origin Ship Port*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="origin_port" setFieldValue={setFieldValue} value={values.origin_port} options={state.shipport_list} width="100%" bradius="8px" placeholder="Origin Ship Port" />
                                <ErrorMessage name="origin_port" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.origin_port}</RowValue> }
                            
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Container Size*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <ItemSelect
                                    size="large"
                                    id="cont_size"
                                    name="cont_size"
                                    placeholder="Content Size"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => handleSelectChange(e,"cont_size",setFieldValue)}
                                    // options={cont_sizes}
                                    value={values.cont_size}
                                >
                                    {cont_sizes?.map((i,index)=> 
                                        <Option key={index} value={i.value}>{i.name}</Option>
                                    )}   
                                </ItemSelect>
                                <ErrorMessage name="cont_size" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{GetContainerSizeLabel(values.cont_size)}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Freight*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="freight"
                                    name="freight"
                                    value={values.freight}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Freight'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="freight" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.freight}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Validity*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                                <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                    <FormikDateInput setFieldValue={setFieldValue} name="validity" value={values.validity}  placeholder="Validity" disabledate={true} />
                                    <ErrorMessage name="validity" component="div" style={{ color: 'red',fontSize: '12px' }} />
                                </div>
                            :
                            <RowValue>{values.validity}</RowValue> }
                        </DetailRow>
                    </LeftContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Shipper Name*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="shipper_name"
                                    name="shipper_name"
                                    value={values.shipper_name}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Shipper Name'
                                    disabled
                                />
                                <ErrorMessage name="shipper_name" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.shipper_name}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Destination Ship Port*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="destination_port" setFieldValue={setFieldValue} value={values.destination_port} options={state.shipport_list} width="100%" bradius="8px" placeholder="Destination Ship Port" />
                                <ErrorMessage name="destination_port" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.destination_port}</RowValue> }
                            
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Container Type*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <ItemSelect
                                    size="large"
                                    id="cont_type"
                                    name="cont_type"
                                    placeholder="Container Type"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => handleSelectChange(e,"cont_type",setFieldValue)}
                                    options={cont_types}
                                    value={values.cont_type}
                                />
                                <ErrorMessage name="cont_type" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.cont_type}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Validity Terms*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="validity_terms"
                                    name="validity_terms"
                                    value={values.validity_terms}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    size="large"
                                    placeholder='Validity Terms'
                                />
                                <ErrorMessage name="validity_terms" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.validity_terms}</RowValue> }
                        </DetailRow>
                        <DetailRow className='align-start'>
                            <TitleDiv>
                            <TitleRow>Remarks*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <TextAreaItem rows={3} placeholder="Remarks" name="remarks"
                                    value={values.remarks} onChange={handleChange} />
                                <ErrorMessage name="remarks" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue className='raw-width'>{values.remarks}</RowValue> }
                        </DetailRow>
                    </LeftContent>
                </TopContent>
                
            </DetailContainer>
                </FormItem>
            )}
            </FormikForm>
            
        )
    }
}

export default OceanDetailsPage


const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important
    }
    /* & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    } */
`