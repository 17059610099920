import { Button, Pagination, Spin,Select,Modal,Input,Radio } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import CustomeHeader from '../components/Common/CustomeHeader'
import RadioGroups from '../components/Common/RadioGroups'
import SimpleTable from '../components/Common/SimpleTable'
import UploadFileItem from '../components/Common/UploadFileItem'
import ViewButton from '../components/Common/ViewButton'
import RejectedQuoteDetails from '../components/RejectedQuoteDetails/RejectedQuoteDetails'
import RejectedQuoteOceanDetails from '../components/RejectedQuoteDetails/RejectedQuoteOceanDetails'
import { getFileSize } from '../functions/Utils'
import { updatenotificationdata } from '../store/Common/CommonSlice'
import { get_accepted_list, get_rejected_list, refund_file_upload, refund_status_submission } from '../store/Queries/QuerySlice'
import { get_user_list } from '../store/UserManagement/UserManagementSlice'
import { LeftHead, SubHeadContainer, SubHeadTitle, TablePagination } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { SpinContainer } from '../StyledComponents/Queries'

const { Option } = Select;
const { TextArea } = Input;
 

function RejectedQuoteManagement() {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "ocean_admin"
    const dispatch = useDispatch()
    const [pageType, setPageType] = useState("table");
    const [getList, setGetList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true)
    const [selectedItem, setSelectedItem] = useState("Booking Cancelled");
    const [modalVisible, setModalVisible] = useState(false);

    const [spinner, setSpinner] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFilePath, setUploadedFilePath] = useState("");
    const [status_spinner, setStatusSpinner] = useState(false);

    const handleDropdownChange = (value, record) => {
        setSelectedItem(record.booking_status);
        let newData = [...data]
        let dataObject = newData.filter((i)=> i.queryId === record.queryId)[0]
        dataObject.booking_status = value
        setData(newData)
        // setRecordItem(record)
        setInstance(record)
        if(value === "Refund Completed"){
            setModalVisible(true);
        }
      };
    
      const handleModalClose = () => {
        setSelectedFile(null)
        setRefundRemark("")
        setModalVisible(false);
        setGetList(true)
      };

    const [refundRemark, setRefundRemark] = useState("");
    const [isRemarkRequired, setIsRemarkRequired] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [file_type, setFileType] = useState(".jpg, .png, image/jpeg, image/png")
    

    const handleRemark = (e)=> {
        const {value} = e.target
        setRefundRemark(value)
        if(value){
            setIsRemarkRequired(false)
        }else {
            setIsRemarkRequired(true)
        }
    }
    const handleRefundComplete = () => {
        if(refundRemark){
            let formValid = false
            if(!selectedFile?.name){
                formValid = true
            }else if(selectedFile?.name && isFileUploaded){
                formValid = true
            }
            if(formValid){
                setStatusSpinner(true)
                let data = {
                    payment_id: instance.id,
                    refundReason: refundRemark,
                    attachedFilePath: uploadedFilePath,
                    file_type: file_type,
                }
                dispatch(refund_status_submission(data))
                .then(res=>{
                    // console.log('login res => ',res);
                    if(res?.payload?.data?.statusCode === 200 ){
                        setGetList(true)
                        setModalVisible(false)
                        dispatch(updatenotificationdata(res?.payload?.data))
                        setRefundRemark("")
                        setSelectedFile(null)
                    }
                    setStatusSpinner(false)
                })
                .catch(err=>{
                    console.error(err);
                })
            }
        }else {
            setIsRemarkRequired(true)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=>{
        if(pageType === 'table' || getList === true){
            setQueryInstance({})
            dispatch(get_rejected_list())
            .then(res=>{
                console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.data)
                    setGetList(false)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[pageType, getList])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const handleFileChange = (event) => {
        const filesize = getFileSize(event.target.files[0])
        console.log(filesize,"filesize");
        if (filesize >= 1) {
            dispatch(updatenotificationdata({"message":"Please select a file less than 1MB.","success":"error"}))
        }else {
            setIsFileUploaded(false)
            setSelectedFile(event.target.files[0]);
        }
        
    };


    const handleFileType = (value,name) => {
        setFileType(value)
    }

    const handleUpload = () => {
        setSpinner(true)
        const formData = new FormData();
        formData.append('refund_file', selectedFile);
        formData.append('user_id', instance.userId);
        // formData.append('file_type', file_type);
        dispatch(refund_file_upload(formData))
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.data?.statusCode === 200){
                setUploadedFilePath(res?.payload?.data?.full_path_name)
                setIsFileUploaded(true)
                dispatch(updatenotificationdata(res?.payload?.data))
                setSpinner(false)
            }else if(res?.payload?.data?.statusCode === 400){
                dispatch(updatenotificationdata({"message":"file upload failed","success":"error"}))
            }
        })
        .catch(err=>{
            console.error(err);
        })
        
    }

    const columns = [
        {
            title: 'ORDER ID',
            dataIndex: 'queryId',
            render: (text, record, index) => {
                return <span>{(index + 1) + ((currentPage - 1) * 10)}</span>;
              },
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            render: (text, record,index) => (
                <span>{record?.customer_details?.full_name}</span>
              ),
        },
        {
            title: 'COMPANY NAME',
            dataIndex: 'company_name',
            render: (text, record,index) => (
                <span>{record?.customer_details?.company_name}</span>
              ),
        },
        {
            title: 'FRIEGHT TYPE',
            dataIndex: 'carrier_type',
        },
        {
            title: userroles === 'ocean_admin'? 'SHIPLINE' : 'AIRLINE',
            dataIndex: 'airline_shipline',
            render: (text, record) => (
                userroles === 'ocean_admin'?
                <span>{record?.bookeditem ? JSON.parse(record?.bookeditem)?.line : ""}</span> : 
                <span>{record?.bookeditem ? JSON.parse(record?.bookeditem)?.airlines : ""}</span>
              ),
        },
        {
            title: 'CHARGEBLE WEIGHT',
            dataIndex: 'chargeble_weight',
            render: (text, record) => (
                userroles === 'ocean_admin'? 
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.totalWeight).toFixed(2) : "0.00"} KG</span>
                :
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.totalWeight).toFixed(2) : "0.00"} {record?.querySearch?.totalWeightUnit}</span>
              ),
        },
        {
            title: userroles === 'ocean_admin'? 'SHIPMENT RATE' : 'FRIEGHT RATE',
            dataIndex: 'freight_rate',
            render: (text, record) => (
                userroles === 'ocean_admin'? 
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.USD_totalShipmentRate).toFixed(2) : "0.00"}</span>
                :
                <span>{record?.bookeditem? Number(JSON.parse(record?.bookeditem)?.totalFreightRate).toFixed(2): "0.00"}</span>
              ),
        },
        {
            title: 'PAYMENT STATUS',
            dataIndex: 'payment_status',
            render: (text, record) => (
                <StatusText status={record?.payment_status}>{record?.payment_status}</StatusText>
              ),
        },
        {
            title: 'STATUS',
            key: 'actions', 
            render: (_, record) => (
              <Select
                value={record.booking_status}
                style={{ width: 160 }}
                onChange={(value) => handleDropdownChange(value, record)}
                disabled={record.payment_status === 'Refund Completed'}
              >
                <Option value="Booking Cancelled" disabled>Booking Cancelled</Option>
                <Option value="Refund Completed">Refund Completed</Option>
              </Select>
            ),
          },
          {
            title: 'REJECTION REASON',
            dataIndex: 'reason_for_cancellation',
            width: "250px",
            render: (text, record) => (
                <span className="break-all">{text}</span>
              ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];

    const file_types = [
        {
            id: ".jpg, .png, image/jpeg, image/png",
            name: "Image"
        },
        {
            id: "pdf",
            name: "Pdf"
        }
    ]
      
   
  return (
    <MainContainer>
      <CustomeHeader title="Rejected Quotation Management" description="View all rejected quotation by every user one by one." />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>Quotation List</TableTitle>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <>
                    <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} scroll={true} rowKey="queryId" showScroll={true} xWidth={1800} />
                    <Modal
                        // title=""
                        visible={modalVisible}
                        onCancel={handleModalClose}
                        // onOk={handleModalClose}
                        footer={false}
                        >
                       <ModalContents>
                            <ReasonTitle>
                                Remarks*
                            </ReasonTitle>
                            <TextArea rows={4} placeholder="type remarks" value={refundRemark} onChange={(e)=> handleRemark(e)} />
                            {isRemarkRequired? <ErrorMsg>required</ErrorMsg> : null }
                            {/* <ReasonTitle className='mt-3'>
                                File Upload
                            </ReasonTitle> */}
                            <RadioGroups datas={file_types} value={file_type} handleClick={handleFileType} name="refund" className="mt-2" />
                            <UploadFileItem handleChange={handleFileChange} loading={spinner} file_name={selectedFile?.name} handleClick={handleUpload} button_class="mr-5" accept={file_type} />
                            <span style={{ fontSize: '13px'}}>{selectedFile?.name}</span>
                            {isFileUploaded === false && selectedFile?.name ?
                                <span style={{ fontSize: '13px', color: 'red'}}>please upload file before submit form</span>
                            :
                                null
                            }

                            <SubmitButton type="primary" className='mt-3' onClick={()=> handleRefundComplete()} loading={status_spinner} disabled={spinner} >
                                Submit
                            </SubmitButton>
                        </ModalContents>
                    </Modal>
                </>
            }
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
        <>
           <ContentContainer>
                <SubHeadContainer className='flex-column'>
                    <LeftHead>
                        <IconImg onClick={()=> setPageType("table")} src={require('../assets/images/back_page_arrow.png')} />
                        <SubHeadTitle>Details</SubHeadTitle>
                    </LeftHead>
                   {/* <UserIDContainer>
                    <UserText>ORDER ID</UserText>
                    <UserText className='value'>SG234567H</UserText>
                   </UserIDContainer> */}
                </SubHeadContainer>
                {instance.carrier_type === 'Ocean Cargo'?
                    <RejectedQuoteOceanDetails instance={instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                :
                    <RejectedQuoteDetails instance={instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                }
            </ContentContainer>
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default RejectedQuoteManagement

// const StatusText = styled.span`
//     font-family: 'Rubik';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     color: ${({ text }) => text === 'Pending' ? '#FF3F3B' : '#3F4254'};
// `

const StatusText = styled.span`
    color: ${({status})=> status === 'Payment Confirmed' || status === 'SUCCESS' || status === "Refund Completed" ? '#08C004': status === 'Payment Rejected' || status === 'REJECTED'? '#FF3F3B' : status === 'Waiting for Confirmation' || status === 'PENDING'? '#FFAF00':'#3F4254' };
    font-weight: 700;
`

const SubmitButton = styled(Button)`
    background-color: #FFAF00;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 700;
    border-color: #FFAF00;
    :hover {
        border-color: #FFAF00 !important;
        background-color: #FFAF00 !important;
    }
`

const CancelModal = styled(Modal)`
   
`
const ReasonTitle = styled.span`
    font-size: 15px;
    font-weight: 8px;
`
const ModalContents = styled.div`
    /* text-align: center; */
    display: flex;
    flex-direction: column;
`
const ErrorMsg = styled.span`
    color: red;
`