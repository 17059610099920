import { Col, Input, Row, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { Formik, Form } from 'formik';
import styled from "styled-components/macro";


export const TableFilter = styled.div`
    margin-top: 15px;
    & .ant-divider {
        border-inline-start: 2px solid #B5B5C3 !important;
        height: 1.4em !important;
    }
    &.sub-tab {
        display: flex;
        align-items: flex-start;
    }
`

export const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
export const Title = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFAF00;
`

export const ContentContainer = styled.div`
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px 20px 0px 20px;
    margin-top: 35px;
`
export const DetailContent = styled.div`
    border-top: 3px dotted #E4E6EF;
    margin-top: 15px;
    display: flex;
    &.column-dir {
        flex-direction: column;
    }
`
export const LeftContent = styled.div`
    /* border-right: 2px dotted #E4E6EF; */
    width: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    padding: 20px 0px;
    &.create {
        border-right: none;
        width: 100%;
    }
`
export const RightContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    margin-left: 50px;
`
export const DetailRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    &.create {
        margin-bottom: 25px;
    }
    &.individual-rate {
        width: 28%;
        margin-bottom: 0px;
    }
    &.align-start {
        align-items: flex-start;
    }
`
export const TitleRow = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #B5B5C3;
    &.sub-head {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #FFAF00;
    }
`
export const RowValue = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #3F4254;
    &.raw-width {
        width: 60%;
        display: inline-block;
        word-break: break-all;
    }
`
export const TitleDiv = styled.div`
    width: 40%;
    &.create-rate {
        width: 16%;
    }
    &.individual-rate {
        /* margin-right: 10px; */
    }
`

export const ChargesSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 83%;
`
export const UnitDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.unit {
        width: 28%;
    }
    &.amount {
        width: 24%;
    }

`

export const TopButtonDiv = styled.div`
    /* width: 16%; */
    display: flex;
    gap: 20px;
    /* justify-content: ${({ is_edit }) => is_edit ? 'space-between' : 'flex-end'}; */
`
export const TopContent = styled.div`
    border-top: 3px dotted #E4E6EF;
    margin-top: 10px;
    display: flex;
    margin: 0 auto;
   width: 100%;
`
export const MidContent = styled.div`
    margin: 20px 0px;
`
export const BottomContent = styled.div`
    display: flex;
    margin-bottom: 15px;
`
export const ContentRow = styled.div`
    display: flex;
    /* gap: 5px; */
    align-items: center;
    margin-top: 10px;
    width: 100%;
    /* justify-content: center; */
`
export const TableItem = styled.table`
    border-collapse: collapse;
`
export const THeadItem = styled.thead`
    
`
export const TRItem = styled.tr`
    
`
export const THItem = styled.th`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #B5B5C3;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    /* max-width: 15%; */
    width: 6%;
    overflow: hidden;
    text-overflow: ellipsis;
    &.view {
        width: 10%;
    }
`
export const TDItem = styled.td`
    padding: 8px;
    width: 12%;
`
export const TableInput = styled(Input)`
    ::placeholder {
        font-family: 'Rubik';
        font-style: normal;
        color: #d9d9d9 !important;
        font-size: 12px;
        font-weight: 400;
    }
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`