import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { password_reset_link, updatenotificationdata } from '../../store/Common/CommonSlice'
import { change_status, update_user_kam } from '../../store/UserManagement/UserManagementSlice'
import { ButtonSubmit, CustomeButton, TableItem } from '../../StyledComponents/Common'
import { ActionText, ButtonSection, ContentItem, ContentRow, ContentTitle, ContentValue, DetailContentContainer, HeadSection, TableSection, TopHead } from '../../StyledComponents/CreateKeyAccountManager'
import AddButton from '../Common/AddButton'
import SimpleTable from '../Common/SimpleTable'
import SubmitButton from '../Common/SubmitButton'

function ActionSectionAdmin(props) {
    const dispatch = useDispatch()
    const [assign_index, setAssignIndex] = useState("")
    const [kam_data, setKamData] = useState([])
    const [changeList, setChangeList] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loading_deactivate, setLoadingDeactivate] = useState(false)
    const [loading_archive, setLoadingArchive] = useState(false)
    
    useEffect(()=>{
        if(changeList === true){
            let data = props.kam_list.filter((i)=> i.id !== props.instance.key_account_manager)
            setKamData(data)
            setChangeList(false)
        }
        
    },[changeList])

    const handleChangeStatus = (status)=> {
        if(status === 'deactivate' || status === 'activate'){
            setLoadingDeactivate(true)
        }else if (status === 'archive'){
            setLoadingArchive(true)
        }
        let user_id = props.instance.id
        const values = {
            status: status,
            user_id:user_id
        }
        dispatch(change_status(values))
        .then(res=>{
            if(res?.payload?.data?.statusCode === 200 ){
                // console.log(res?.payload?.data?.data);
                dispatch(updatenotificationdata(res?.payload?.data))
                setLoadingDeactivate(false)
                setLoadingArchive(false)
                if (status === 'archive'){
                    props.setPageType("table")
                }else {
                    let deactivate_user = 0
                    if(status === 'deactivate'){
                        deactivate_user = 1
                    }
                    props.instance.deactivate_user = deactivate_user
                    props.setInstance(props.instance)
                }
                
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }

    const handleUpdateKam = () => {
        setLoading(true)
        let user_id = props.instance.id
        let kam_id = kam_data[assign_index].id
        let full_name = kam_data[assign_index].full_name
        if(user_id && kam_id ){
            const values = {
                user_id:user_id,
                kam_id:kam_id,
            }
            dispatch(update_user_kam(values))
            .then(res=>{
                if(res?.payload?.data?.statusCode === 200 ){
                    // console.log(res?.payload?.data?.data);
                    props.instance.kam_user.full_name = full_name
                    props.instance.kam_user.id = kam_id
                    props.instance.key_account_manager = kam_id
                    props.setInstance(props.instance)
                    setChangeList(true)
                    setAssignIndex("")
                    dispatch(updatenotificationdata(res?.payload?.data))
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        
    }

    const columns = [
        {
            title: 'KEY ACCOUNT MANAGERS',
            dataIndex: 'full_name',
            render: (text, record) => (
                <ActionText className='name'>{text}</ActionText>
              ),
        },
        
        {
            title: 'ACTION',
            dataIndex: 'action',
            align: 'right',
            render: (text, record,index) => (
                assign_index === index ? <ActionText onClick={()=> setAssignIndex("")} className='cursor'>Re-Assign</ActionText>
                 :<ActionText onClick={()=> setAssignIndex(index)} className='cursor'>Assign</ActionText> 
                
              ),
        }
      ];
    
  return (
    <>
        <DetailContentContainer>
            {props.currentSubTab == 1 ? 
                <>
                    <HeadSection>
                        <TopHead>
                            <ContentItem>CURRENT KAM</ContentItem>
                            <ContentItem className='values'>{props.instance.kam_user?.full_name}</ContentItem>
                        </TopHead>
                        {assign_index !== '' ? 
                            <SubmitButton type='button' title="Change Key Account Manager & Update" loading={loading} handleClick={handleUpdateKam} is_icon={false} ml="28px" />
                        : null}
                    </HeadSection>
                    <TableSection>
                    <TableItem rowKey="id" name="kam" columns={columns} dataSource={kam_data} pagination={false} />
                    </TableSection>
                </>
            : 
            <>
                <HeadSection>
                    <ContentItem>Status</ContentItem>
                    <ContentItem className='values'>{props.instance.deactivate_user == 0 ? "Active" : "Deactive"}</ContentItem>
                    {props.instance.deactivate_user == 0 ?
                     <ButtonSubmit size='large' onClick={()=> handleChangeStatus("deactivate")} loading={loading_deactivate}>Deactivate User</ButtonSubmit> : 
                     <ButtonSubmit size='large' onClick={()=> handleChangeStatus("activate")} loading={loading_deactivate}>Activate User</ButtonSubmit>
                    }
                    
                    <CustomeButton size='large' className='ml-5' onClick={()=> handleChangeStatus("archive")} loading={loading_archive}>Move to Archives</CustomeButton>
                    {/* <SubmitButton type='button' title="Deactivate User" is_icon={false} ml="28px" /> */}
                </HeadSection>
            </>
            }
            
        </DetailContentContainer>
    </>
    
  )
}

export default ActionSectionAdmin
