import { Modal } from "antd";
import { menus } from "../routes/routes"
import dayjs from 'dayjs';

export function getActive(pathname,user_role){
    let role_menus = menus.filter((i)=> i.role.includes(user_role))
    const index = role_menus.findIndex(item => item.path === pathname);
    let initial_active = String(index)
    if(Number(initial_active) < 0){
      initial_active = "0"
    }
    // if(pathname === '' || pathname === '/dashboard'){
    //   initial_active = "0"
    // }else if(pathname === '/rate-management'){
    //   initial_active = "4"
    // }else if(pathname === '/create-key-account-manager'){
    //     initial_active = "6"
    // }
    return initial_active
}


    
export const ShowComingSoon = () => {
    Modal.warning({
        title: 'Coming Soon',
        // content: 'this ',
        okButtonProps: {
            style: {
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              borderRadius: '4px',
              color: '#fff',
            },
          },
      });
  };

export function handleKeyPress(event) {
    const charCode = event.which || event.keyCode;
    const value = event.target.value;
    const regex = /^-?\d*(\.\d{0,2})?$/; // allow up to 2 decimal places
    const isValidValue =
      charCode === 8 || // backspace
      charCode === 13 || // enter
      regex.test(value + String.fromCharCode(charCode)); // validate input value
  
    if (!isValidValue) {
      event.preventDefault();
    }
  }

  export function shortenString(str) {
    if (str?.length > 25) {
      return str.slice(0, 25) + '...';
    } else {
      return str;
    }
  }

export const convertDateStringToNormalSTringDate = (dateString) => {
  var parts = dateString.split(".");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1; // Months are zero-based (0-11)
  var year = parseInt(parts[2], 10);
  var dateObject = new Date(year, month, day);
  return dateObject
}


function checkDateFormat(dateString) {
  const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
  let type = "normal"
  if (dateRegex.test(dateString)) {
    type = "last_year"
  }
  return type
}

export const getFormattedDateTime = (dateString) => {
  if(dateString){
    try {
      let type = checkDateFormat(dateString)
      let date = new Date(dateString);
      if(type === "last_year"){
        date = convertDateStringToNormalSTringDate(dateString)
      }
      // const formattedDate = date.toISOString().replace('T', ' ').slice(0, -5);
      const formattedDate = date.toISOString().slice(0, 10);
      return formattedDate
    }catch(err) {
      return dateString
    }
  }else {
    return ""
  }
}

export function formatDate(date) {
    date = new Date(date)
    // extract the day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    // join the day, month, and year with dots to create the formatted string
    return `${day}.${month}.${year}`;
}

export function convertDateStringToDateObject(dateString) {
  // parse the date string into a `dayjs` object using the 'DD.MM.YYYY' format
  const dateObject = dayjs(dateString, 'DD.MM.YYYY');
  
  // return the `dayjs` object
  return dateObject;
}

export function get_param_url(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  return value;
}

export const GetContainerSizeLabel = (value) => {
  const cont_sizes = [
    {
        value: "20",
        name: "20FT"
    },
    {
        value: "40",
        name: "40FT"
    },{
        value: "40HC",
        name: "40FT HC"
    },{
        value: "45HC",
        name: "45FT HC"
    }
  ]
  let Label = cont_sizes.filter((i)=> i.value === value)[0]?.name
  if(!Label){
    Label = value
  }
  return Label
}

export const GetExchangeRate = (exchangeRateList, currency) => {
  let exchange_rate = exchangeRateList?.filter((i)=> i.currency === currency)[0]?.inrRate
  return exchange_rate
}

export const ConvertAmountCurrency = (exchangeRateList, currency,amount) => {
  let result_amount = amount
  let currency_type = currency
    if(currency === '₹'){
      currency_type = "INR"
    }else if(currency === '$'){
      currency_type = "USD"
    }else if(currency === '€'){
      currency_type = "EUR"
    }
  let exchange_rate = exchangeRateList?.filter((i)=> i.currency === currency_type)[0]?.inrRate
    if(currency_type !== 'INR'){
      if(!amount){
        amount = 0
      }
      if(!exchange_rate){
        exchange_rate = 0
      }
      if(Number(amount) != 0 && Number(exchange_rate) != 0)
      result_amount = Number(amount) / Number(exchange_rate)
    }
  return Number(result_amount).toFixed(2)
}

export const DateTimeFormattedConvertion = (inputDate) => {
  // Convert input string to Date object
  const date = new Date(inputDate);
  // Define weekday names and month names
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract the required components from the date object
  const weekday = weekdays[date.getDay()];
  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  let hour = hours
  if(hours >= 12){
    hour = hours % 12
  }
  hour = hour.toString().padStart(2, '0')
  // Format the date string
  const formattedDate = `${weekday}, ${day} ${month} ${year},${hour}:${minutes < 10 ? '0' + minutes : minutes} ${amPm}`;
  return formattedDate
}

export const getReportLabels = (mode)=> {
  let labels = []
  if(mode === "day"){
    for (var i = 1; i <= 24; i++) {
      labels.push(i);
    }
  }else if(mode === "week"){
      labels = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  }else if(mode === "month"){
      // Get the current date
      var currentDate = new Date();
      // Get the current month
      var currentMonth = currentDate.getMonth();
      // Get the number of days in the current month
      var numberOfDays = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();

      // Create the array of numbers
      labels = Array.from({length: numberOfDays}, (_, index) => index + 1);
  }else if(mode === "year"){
    labels =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','July','Aug','Sep','Oct','Nov','Dec']
  }
  return labels
}

export function getDaysBetweenDates(startDate, endDate) {
  const result = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);
  
  while (currentDate <= lastDate) {
    const day = currentDate.getDate().toString().padStart(2, '0');
    result.push(day);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return result;
}

export const removeDuplicatePort = (list)=> {
  return list.filter((object, index) => {
    const firstIndex = list.findIndex(obj => obj.port_code === object.port_code);
    return index === firstIndex;
  });
}


export function toCamelCase(inputString) {
  // Convert the input string to lowercase
  const lowerCaseString = inputString.toLowerCase();

  // Capitalize the first letter
  const camelCaseString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);

  return camelCaseString;
}

export function getFileSize(file){
  const fileSize = file.size;
  const fileMb = fileSize / 1024 ** 2;
  return fileMb
}
