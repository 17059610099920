import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { ButtonSubmit } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'

function SubmitButton(props) {
  let icon_img = require('../../assets/images/add_invite.png')
    if(props.is_save) {
        icon_img = require('../../assets/images/save_button.png')
    }else if(props.icon_type === 'edit') {
      icon_img = require('../../assets/images/editwhite.png')
    }else if(props.icon_type === 'search') {
      icon_img = require('../../assets/images/search_Icons.png')
    }

  return (
    <div>
      <ButtonSubmit ml={props.ml} htmlType={props.type} onClick={props.handleClick ? props.handleClick : null}
       size='large' icon={props.is_icon === false ? null : <IconImg src={icon_img} />} loading={props.loading? props.loading : false}>{props.title}
       </ButtonSubmit>
    </div>
  )
}

export default SubmitButton
