import { Button, Empty, Menu, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import AddButton from '../components/Common/AddButton'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import { ItemMenu, TablePagination, TableTab } from '../StyledComponents/Common'
import {  TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { ContentContainer, TableFilter } from '../StyledComponents/RateManagement'
import { Divider, Modal } from 'antd';
import SimpleInput from '../components/Common/SimpleInput'
import DropDownButton from '../components/Common/DropDownButton'
import RateUploadModal from '../components/RateManagement/RateUploadModal'
import DetailsPage from '../components/RateManagement/DetailsPage'
import CreateRate from '../components/RateManagement/CreateRate'
import DropDownButton1 from '../components/Common/DropDownButton1'
import { get_rate_list, get_surcharge_rate_list, get_vas_rate_list } from '../store/RateManagement/RateManagementSlice'
import { useDispatch } from 'react-redux'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { GetContainerSizeLabel, shortenString } from '../functions/Utils'
import { SpinContainer } from '../StyledComponents/Queries'
import OceanDetailsPage from '../components/RateManagement/OceanDetailPage'
import RadioGroups from '../components/Common/RadioGroups'
import RateTable from '../components/Common/RateTable'
import AirVASDetailPage from '../components/RateManagement/AirVASDetailPage'
import OceanVASDetailPage from '../components/RateManagement/OceanVasDetailPage'
import SurchargeDetailPage from '../components/RateManagement/SurchargeDetailPage'
import OceanSurchargeDetailsPage from '../components/RateManagement/OceanSurchargeDetailpage'
import OceanLCLDetailsPage from '../components/RateManagement/OceanLCLDetailsPage'
import { airlines_list,airport_list, shipment_list, shipport_list } from '../store/Common/CommonSlice'

function RateManagement() {
    const dispatch = useDispatch()
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "air_admin"
    const [tabType, setTabType] = useState(userroles === 'ocean_admin'?"ocean" : "air");
    const [subTabType, setSubTabType] = useState("freight_rate");
    const [subOceanTabType, setOceanSubTabType] = useState("FCL");
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageVas, setCurrentPageVas] = useState(1);
    const [currentPageSurcharge, setCurrentPageSurcharge] = useState(1);
    const [currentOceanPage, setCurrentOceanPage] = useState(1);
    const [currentOceanPageVas, setCurrentOceanPageVas] = useState(1);
    const [currentOceanPageSurcharge, setCurrentOceanPageSurcharge] = useState(1);
    const [currentPageLcl, setCurrentPageLcl] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [rateType, setRateType] = useState("");
    const [type, setType] = useState("view");
    const [data, setData] = useState([]);
    const [AirVasData, setAirVasData] = useState([]);
    const [AirSurchargeData, setAirSurchargeData] = useState([]);
    const [OceanVasData, setOceanVasData] = useState([]);
    const [ocean_data, setOceanData] = useState([]);
    const [OceanSurchargeData, setOceanSurchargeData] = useState([]);
    const [OceanLclData, setOceanLclData] = useState([]);
    const [call_list, setCallList] = useState(true);
    const [loading, setLoading] = useState(true)
    const [airlineList, setAirlineList] = useState([])
    const [airportList, setAirportList] = useState([])
    const [shiplineList, setShiplineList] = useState([])
    const [shipportList, setShipportList] = useState([])

    const { confirm } = Modal;

    useEffect(()=>{
      if(tabType === 'air'){
        dispatch(airlines_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
              setAirlineList(res?.payload?.data?.data)
            }
        })
        .catch(err=>{
            console.error(err);
        })
  
        dispatch(airport_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                const airports = res?.payload?.data?.data
                const indianAirports = airports.filter(airport => airport.countryName === 'INDIA' || airport.countryName === 'India');
                indianAirports.sort((a, b) => a.name.localeCompare(b.name));
                
                const foreignAirports = airports.filter(airport => airport.countryName !== 'India' || airport.countryName !== 'INDIA');
                foreignAirports.sort((a, b) => {
                if (a.country === b.country) {
                    return a.name.localeCompare(b.name);
                }
                return a.country.localeCompare(b.country);
                });
                const sortedAirports = indianAirports.concat(foreignAirports);
                let resultPorts = sortedAirports.filter((obj, index, self) =>
                    index === self.findIndex((o) => o.code === obj.code)
                );
                setAirportList(resultPorts)
            }
        })
        .catch(err=>{
            console.error(err);
        })
      }else if(tabType === 'ocean') {
        dispatch(shipment_list())
        .then(res=>{
            // console.log('login res => ',res?.payload?.data?.data);
            if(res?.payload?.status === 200 ){
              setShiplineList(res?.payload?.data?.data?.data)
            }
        })
        .catch(err=>{
            console.error(err);
        })

        dispatch(shipport_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                const shipports = res?.payload?.data?.data
                const indianShipports = shipports.filter(shipport => shipport.country_name === 'INDIA' || shipport.country_name === 'India');
                indianShipports.sort((a, b) => a.port_name.localeCompare(b.port_name));
                const Mundra = indianShipports.filter((i)=> i.port_name === 'Mundra' || i.port_code === 'Mundra')
                const foreignShipports = shipports.filter(shipport => shipport.country_name !== 'India' || shipport.country_name !== 'INDIA');
                foreignShipports.sort((a, b) => {
                    if (a.country === b.country) {
                        return a.port_name.localeCompare(b.port_name);
                    }
                    return a.country.localeCompare(b.country);
                });
                const sortedShipports = indianShipports.concat(foreignShipports);
                setShipportList(sortedShipports)
            }
        })
        .catch(err=>{
            console.error(err);
        })
      }
      
  },[])

    useEffect(()=>{
        if(call_list){
            dispatch(get_rate_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                    setData(res?.payload?.data?.data)
                    setOceanData(res?.payload?.data?.oceanData)
                    setOceanLclData(res?.payload?.data?.lclData)
                    // setCallList(false)
                    // setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            dispatch(get_vas_rate_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                  setAirVasData(res?.payload?.data?.nodeData?.airdata)
                  setOceanVasData(res?.payload?.data?.nodeData?.oceanData)
                  setCallList(false)
                  setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            

            dispatch(get_surcharge_rate_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                  setAirSurchargeData(res?.payload?.data?.nodeData?.airdata)
                  setOceanSurchargeData(res?.payload?.data?.nodeData?.oceanData)
                  setCallList(false)
                  setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[call_list])

    const handlePageChange = (page) => {
      if(tabType == 'ocean'){
        if(subOceanTabType === 'FCL'){
          setCurrentOceanPage(page);
        }else  if(subOceanTabType === 'LCL'){
          setCurrentPageLcl(page);
        }else  if(subOceanTabType === 'sur_charge'){
          setCurrentOceanPageSurcharge(page);
        }else {
          setCurrentOceanPageVas(page);
        }
      }else {
        if(subTabType === 'freight_rate'){
          setCurrentPage(page);
        }else if(subTabType === 'sur_charge') {
          setCurrentPageSurcharge(page);
        }else {
          setCurrentPageVas(page);
        }
      }
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        if(tabType == 'ocean'){
          if(subTabType === 'FCL'){
            setCurrentOceanPage(1);
          }else if(subTabType === 'LCL'){
            setCurrentPageLcl(1);
          }else if(subTabType === 'sur_charge'){
            setCurrentOceanPageSurcharge(1);
          }else {
            setCurrentOceanPageVas(1);
          }
        }else {
          if(subTabType === 'freight_rate'){
            setCurrentPage(1);
          }else if(subTabType === 'sur_charge'){
            setCurrentPageSurcharge(1);
          }else {
            setCurrentPageVas(1);
          }
        }
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
        setType("view")
    }


    const handleInputChange = (e) => {
        console.log(e);
    }

    const handleAdd = (key) => {
        setRateType(key)
        if(key === 'individual_rate'){
            setInstance({})
            setPageType("view_page")
            setType("create")
        }else {
            setModalOpen(true)
        }
    }

   
    const handleBack = () => {
      if(type === 'edit'){
        confirm({
          title: 'Do you want to exit?',
          icon: <ExclamationCircleFilled />,
          content: 'Your changes will not be saved.',
          okButtonProps: {
            style: {
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              borderRadius: '4px',
              color: '#fff',
            },
          },
          onOk() {
            setCallList(true)
            setPageType("table")
            setInstance({})
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }else {
        // setCallList(true)
        setPageType("table")
        setInstance({})
      }
      
    }

    const handleRadioClick = (value,name) => {
      if(name === 'ocean'){
        setOceanSubTabType(value)
      }else {
        setSubTabType(value)
      }
    }


    const columns = [
        {
            title: 'AIRLINE',
            dataIndex: 'airline',
            width: '23%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="airline" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'ORIGIN',
            dataIndex: 'origin_port',
            width: '23%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="origin" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'DESTINATION',
            dataIndex: 'destination_port',
            // className: 'text-center',
            width: '23%',

            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="destination" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            // className: 'text-right',
            width: '23%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="product" handleChange={handleInputChange} size="large" />;
                // }
                return shortenString(text);
              },
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            // className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const air_vas_columns = [
        {
            title: 'AIRPORT',
            dataIndex: 'Airport',
            width: '15%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="airline" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'ORIGIN CARGO STUFFING',
            dataIndex: 'Origin_Cargo_Stuffing',
            render: (text, record, index) => {
                return text;
              },
        },
        {
            title: 'ORIGIN CUSTOMS CLEARANCE',
            dataIndex: 'Origin_Customs_Clearance',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
          title: 'ORIGIN CFS CLEARANCE',
          dataIndex: 'Origin_CFS_Clearance',
          render: (text, record, index) => {
              return text;
            },
      },
      {
          title: 'DESTINATION CARGO HANDLING',
          dataIndex: 'Destination_Cargo_Handling',
          render: (text, record, index) => {
              return text;
            },
      },
      {
        title: 'DESTINATION CFS CLEARANCE',
        dataIndex: 'Destination_CFS_Clearance',
        render: (text, record, index) => {
            return text;
          },
      },
      {
        title: 'DESTINATION CUSTOMS CLEARANCE',
        dataIndex: 'Destination_Customs_Clearance',
        render: (text, record, index) => {
            return text;
          },
      },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const air_surcharge_columns = [
        {
            title: 'ORIGIN',
            dataIndex: 'origin',
            // width: '23%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="origin" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'DESTINATION',
            dataIndex: 'destination',
            // className: 'text-center',
            // width: '23%',

            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="destination" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'CHARGE NAME',
            dataIndex: 'chargeName',
            // width: '23%',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
          title: 'CHARGE AMOUNT',
          dataIndex: 'chargeAmount',
          // width: '23%',
          render: (text, record, index) => {
              return text;
            },
      },
      {
        title: 'MIN CHARGE AMOUNT',
        dataIndex: 'minChargeAmount',
        // width: '23%',
        render: (text, record, index) => {
            return text;
          },
      },
      {
        title: 'CHARGE BASIS',
        dataIndex: 'chargeBasis',
        // width: '23%',
        render: (text, record, index) => {
            return text;
          },
      },
      {
        title: 'MIN CHARGE BASIS',
        dataIndex: 'minChargeBasis',
        // width: '23%',
        render: (text, record, index) => {
            return text;
          },
      },
        {
            title: 'ACTION',
            dataIndex: 'action',
            // className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const ocean_columns = [
        {
            title: 'SHIPPER',
            dataIndex: 'shipper_name',
            width: '20%',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
            title: 'ORIGIN PORT',
            dataIndex: 'POR',
            width: '20%',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
          title: 'DESTINATION PORT',
          dataIndex: 'POD',
          width: '20%',
          render: (text, record, index) => {
              return shortenString(text);
            },
        },
        {
            title: 'CONTAINER SIZE',
            dataIndex: 'cont_size',
            // className: 'text-right',
            width: '15%',
            render: (text, record, index) => {
                return GetContainerSizeLabel(text);
              },
        },
        {
          title: 'CONTAINER TYPE',
          dataIndex: 'cont_type',
          // className: 'text-right',
          width: '15%',
          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'FREIGHT',
          dataIndex: 'freight',
          // className: 'text-center',
          width: '12%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'VALIDITY',
          dataIndex: 'validity',
          // className: 'text-center',
          width: '15%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            // className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const ocean_columns_Lcl = [
        {
            title: 'POL',
            dataIndex: 'POL',
            width: '12%',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
            title: 'COUNTRY',
            dataIndex: 'country',
            width: '12%',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
          title: 'POD',
          dataIndex: 'POD',
          width: '12%',
          render: (text, record, index) => {
              return shortenString(text);
            },
        },
        {
          title: 'SHIPPER LINE',
          dataIndex: 'shipperLine',
          // className: 'text-right',
          // width: '15%',
          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'RATE/CBM',
          dataIndex: 'rate',
          // className: 'text-center',
          // width: '12%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'MINIMUM RATE',
          dataIndex: 'minRate',
          // className: 'text-center',
          // width: '15%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'ROUTING',
          dataIndex: 'routing',
          // className: 'text-center',
          // width: '17%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'T/T',
          dataIndex: 'tT',
          // className: 'text-center',
          // width: '15%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
          title: 'FREQUENCY',
          dataIndex: 'frequency',
          // className: 'text-center',
          // width: '17%',

          render: (text, record, index) => {
              return text;
            },
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            // className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const ocean_vas_columns = [
        {
            title: 'SEAPORT',
            dataIndex: 'Seaport',
            width: '15%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return <SimpleInput name="airline" handleChange={handleInputChange} size="large" />;
                // }
                return text;
              },
        },
        {
            title: 'ORIGIN CARGO STUFFING',
            dataIndex: 'Origin_Cargo_Stuffing',
            render: (text, record, index) => {
                return text;
              },
        },
        {
            title: 'ORIGIN CUSTOMS CLEARANCE',
            dataIndex: 'Origin_Customs_Clearance',
            render: (text, record, index) => {
                return shortenString(text);
              },
        },
        {
          title: 'ORIGIN CFS CLEARANCE',
          dataIndex: 'Origin_CFS_Clearance',
          render: (text, record, index) => {
              return text;
            },
      },
      {
          title: 'DESTINATION CARGO HANDLING',
          dataIndex: 'Destination_Cargo_Handling',
          render: (text, record, index) => {
              return text;
            },
      },
      {
        title: 'DESTINATION CUSTOMS CLEARANCE',
        dataIndex: 'Destination_Customs_Clearance',
        render: (text, record, index) => {
            return text;
          },
      },
      {
        title: 'DESTINATION CFS CLEARANCE',
        dataIndex: 'Destination_CFS_Clearance',
        render: (text, record, index) => {
            return text;
          },
      },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];

      const ocean_surcharge_columns = [
        {
            title: 'SHIPPER LINE',
            dataIndex: 'shipper_line',
            width: '15%',
            render: (text, record, index) => {
                return text;
              },
        },
        {
          title: 'POL',
          dataIndex: 'pol',
          width: '12%',
          render: (text, record, index) => {
              return shortenString(text);
            },
      },
      {
        title: 'POD',
        dataIndex: 'pod',
        width: '12%',
        render: (text, record, index) => {
            return shortenString(text);
          },
      },
        {
            title: 'COUNTRY',
            dataIndex: 'country',
            render: (text, record, index) => {
                return text;
              },
        },
        {
            title: 'CHARGE NAME',
            dataIndex: 'chargeName',
            render: (text, record, index) => {
                return text
              },
        },
        {
          title: 'CHARGE',
          dataIndex: 'charge',
          render: (text, record, index) => {
              return text;
            },
      },
      {
          title: 'CHARGE BASIS',
          dataIndex: 'chargeBasis',
          render: (text, record, index) => {
              return text;
            },
      },
      {
        title: 'GST',
        dataIndex: 'gst',
        render: (text, record, index) => {
            return text;
          },
    },
    {
      title: 'GST BASIS',
      dataIndex: 'gstBasis',
      render: (text, record, index) => {
          return text;
        },
  },
        {
            title: 'ACTION',
            dataIndex: 'action',
            className: 'text-right',
            width: '8%',
            render: (text, record, index) => {
                // if (index === 0) {
                //   return ''
                // }
                return <ViewButton handleClick={handleView} data={record} />;
              },
        }
      ];


      // const items = [
      //   {
      //     label: <ItemMenu>Air</ItemMenu>,
      //     key: 'air',
      //   },
      //   {
      //     label: <ItemMenu>Ocean</ItemMenu>,
      //     key: 'ocean',
      //   },
      //   {
      //     label: <ItemMenu>Individual Rate</ItemMenu>,
      //     key: 'individual_rate',
      //   },
      // ];

      const items = [
        {
          label: <ItemMenu>Upload File</ItemMenu>,
          key: tabType,
        },
        {
          label: <ItemMenu>Individual Rate</ItemMenu>,
          key: 'individual_rate',
        },
      ];

      const sub_filter = [
        {
          id: "freight_rate",
          name: "Freight rate"
        },
        {
            id: "value_added_service_rate",
            name: "Value added service rate"
        },
        {
          id: "sur_charge",
          name: "Surcharge"
        }
      ]

      const sub_filter_ocean = [
        {
          id: "FCL",
          name: "FCL"
        },
        {
          id: "LCL",
          name: "LCL"
        },
        {
            id: "value_added_service_rate",
            name: "Value added service rate"
        },
        {
          id: "sur_charge",
          name: "Surcharge"
        }
      ]

    
  return (
    <MainContainer>
      <CustomeHeader title="Rate Management" description="Manage rates" />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>View Rates</TableTitle>
                <DropDownButton1 items={items} handleDropDownChange={handleAdd} />
                {/* <DropDownButton menu={menu} /> */}
                <RateUploadModal modalOpen={modalOpen} setModalOpen={setModalOpen} rateType={rateType} setCallList={setCallList} tabType={tabType} subTabType={subTabType} subOceanTabType={subOceanTabType} />
                {/* <AddButton title="Add Rates" handleClick={()=> setPageType("add_form")}  /> */}
            </TableTop>

            {userroles === 'air_admin'?
              <TableFilter>
                  <TableTab className='first' active={tabType === 'air'} onClick={()=> setTabType('air')}>Air</TableTab>
              </TableFilter>
            : userroles === 'ocean_admin'?
              <TableFilter>
                  <TableTab className='first' active={tabType === 'ocean'} onClick={()=> setTabType('ocean')}>Ocean</TableTab>
              </TableFilter>
            :
              <TableFilter>
                  <TableTab className='first' active={tabType === 'air'} onClick={()=> setTabType('air')}>Air</TableTab>
                    <Divider type="vertical" />
                  <TableTab active={tabType === 'ocean'} onClick={()=> setTabType('ocean')}>Ocean</TableTab>
              </TableFilter>
            }
            

            
            {tabType === 'air' ?
              <>
              <TableFilter>
                <RadioGroups datas={sub_filter} value={subTabType} handleClick={handleRadioClick} name="air" />
              </TableFilter>
                {loading? 
                  <SpinContainer className='user-quote'>
                      <Spin />
                  </SpinContainer>:
                  subTabType === 'freight_rate'? <RateTable name="rate_management" currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} rowKey="_id" tabType={tabType} subTabType={subTabType} setCallList={setCallList} />:
                  subTabType === 'sur_charge' ? <RateTable name="rate_management" currentPage={currentPageSurcharge} pageSize={pageSize} columns={air_surcharge_columns} data={AirSurchargeData} rowKey="_id" tabType={tabType} subTabType={subTabType} setCallList={setCallList} /> :
                  <RateTable name="rate_management" currentPage={currentPageVas} pageSize={pageSize} columns={air_vas_columns} data={AirVasData} rowKey="_id" tabType={tabType} subTabType={subTabType} setCallList={setCallList} />
                }
              </>
             : 
             <>
                <TableFilter>
                  <RadioGroups datas={sub_filter_ocean} value={subOceanTabType} handleClick={handleRadioClick} name="ocean" />
                </TableFilter>
                {loading? 
                  <SpinContainer className='user-quote'>
                      <Spin />
                  </SpinContainer>: 
                  subOceanTabType === 'FCL' ? <RateTable name="rate_management" currentPage={currentOceanPage} pageSize={pageSize} columns={ocean_columns} data={ocean_data} rowKey="_id" tabType={tabType} subTabType={subOceanTabType} setCallList={setCallList} />:
                  subOceanTabType === 'LCL' ? <RateTable name="rate_management" currentPage={currentPageLcl} pageSize={pageSize} columns={ocean_columns_Lcl} data={OceanLclData} rowKey="_id" tabType={tabType} subTabType={subOceanTabType} setCallList={setCallList} />:
                  subOceanTabType === 'sur_charge' ? <RateTable name="rate_management" currentPage={currentOceanPageSurcharge} pageSize={pageSize} columns={ocean_surcharge_columns} data={OceanSurchargeData} rowKey="_id" tabType={tabType} subTabType={subOceanTabType} setCallList={setCallList} />:
                  <RateTable name="rate_management" currentPage={currentOceanPageVas} pageSize={pageSize} columns={ocean_vas_columns} data={OceanVasData} rowKey="_id" tabType={tabType} subTabType={subOceanTabType} setCallList={setCallList} />
                }
              </>
             
             }
        </ContentContainer>
        {data?.length && tabType === 'air' && subTabType === 'freight_rate' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            defaultCurrent={currentPage}
            defaultPageSize={10}
            total={data?.length? data?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}

        {AirVasData?.length && tabType === 'air' && subTabType === 'value_added_service_rate' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange} 
            defaultCurrent={currentPageVas}
            defaultPageSize={10}
            total={AirVasData?.length? AirVasData?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}

        {AirSurchargeData?.length && tabType === 'air' && subTabType === 'sur_charge' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange} 
            defaultCurrent={currentPageSurcharge}
            defaultPageSize={10}
            total={AirSurchargeData?.length? AirSurchargeData?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}

        { ocean_data?.length && tabType === 'ocean' && subOceanTabType === 'FCL' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            defaultCurrent={currentOceanPage}
            defaultPageSize={10}
            total={ocean_data?.length? ocean_data?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}

        { ocean_data?.length && tabType === 'ocean' && subOceanTabType === 'LCL' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            defaultCurrent={currentPageLcl}
            defaultPageSize={10}
            total={OceanLclData?.length? OceanLclData?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}

        {OceanVasData?.length && tabType === 'ocean' && subOceanTabType === 'value_added_service_rate' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange} 
            defaultCurrent={currentOceanPageVas}
            defaultPageSize={10}
            total={OceanVasData?.length? OceanVasData?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}
         {OceanVasData?.length && tabType === 'ocean' && subOceanTabType === 'sur_charge' ?
          <TablePagination
            style={{ marginTop: 16, textAlign: 'center' }}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange} 
            defaultCurrent={currentOceanPageSurcharge}
            defaultPageSize={10}
            total={OceanSurchargeData?.length? OceanSurchargeData?.length : 10}
            showSizeChanger={false}
            showQuickJumper={false}
        /> : null}
      </>
        :pageType === 'view_page' && tabType === 'air' && subTabType === 'freight_rate' ? 
          <ContentContainer>
              <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
              <DetailsPage airline_list={airlineList} airport_list={airportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
               sub_filter={sub_filter} subTabType={subTabType} setSubTabType={setSubTabType} handleRadioClick={handleRadioClick} />
          </ContentContainer>
        :pageType === 'view_page' && tabType === 'air' && subTabType === 'value_added_service_rate' ? 
          <ContentContainer>
              <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
              <AirVASDetailPage airport_list={airportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
                sub_filter={sub_filter} subTabType={subTabType} setSubTabType={setSubTabType} handleRadioClick={handleRadioClick} />
          </ContentContainer>
        :pageType === 'view_page' && tabType === 'air' && subTabType === 'sur_charge' ? 
        <ContentContainer>
            <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
            <SurchargeDetailPage airport_list={airportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
             sub_filter={sub_filter} subTabType={subTabType} setSubTabType={setSubTabType} handleRadioClick={handleRadioClick} />
        </ContentContainer>
        :pageType === 'view_page' && tabType === 'ocean' && subOceanTabType === 'FCL' ? 
            <ContentContainer>
                <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
                <OceanDetailsPage shipline_list={shiplineList} shipport_list={shipportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
                 sub_filter={sub_filter_ocean} subTabType={subOceanTabType} setSubTabType={setOceanSubTabType} handleRadioClick={handleRadioClick} />
            </ContentContainer>
        :pageType === 'view_page' && tabType === 'ocean' && subOceanTabType === 'LCL' ? 
        <ContentContainer>
            <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
            <OceanLCLDetailsPage shipline_list={shiplineList} shipport_list={shipportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
             sub_filter={sub_filter_ocean} subTabType={subOceanTabType} setSubTabType={setOceanSubTabType} handleRadioClick={handleRadioClick} />
        </ContentContainer>
        :pageType === 'view_page' && tabType === 'ocean' && subOceanTabType === 'value_added_service_rate' ? 
          <ContentContainer>
              <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
              <OceanVASDetailPage shipport_list={shipportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
                sub_filter={sub_filter_ocean} subTabType={subOceanTabType} setSubTabType={setOceanSubTabType} handleRadioClick={handleRadioClick} />
          </ContentContainer>
        :pageType === 'view_page' && tabType === 'ocean' && subOceanTabType === 'sur_charge' ? 
        <ContentContainer>
            <ContentHead title="Details" handleClick={()=> handleBack()} border={false} />
            <OceanSurchargeDetailsPage shipline_list={shiplineList} shipport_list={shipportList} type={type} instance={instance} setPageType={setPageType} setType={setType} setCallList={setCallList} tabType={tabType} setTabType={setTabType}
              sub_filter={sub_filter_ocean} subTabType={subOceanTabType} setSubTabType={setOceanSubTabType} handleRadioClick={handleRadioClick} />
        </ContentContainer>
            :
        pageType === 'custom_page'?
            <ContentContainer>
                <ContentHead title="Create Rate" handleClick={()=> setPageType("table")} />
                <CreateRate />
            </ContentContainer>
            : null}
    </MainContainer>
  )
}

export default RateManagement
