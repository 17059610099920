import { SearchOutlined } from '@ant-design/icons'
import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import AddButton from '../components/Common/AddButton'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import FilterDatePicker from '../components/Common/FilterDatePicker'
import FilterSelect from '../components/Common/FilterSelect'
import SelectItem from '../components/Common/SelectItem'
import SimpleTable from '../components/Common/SimpleTable'
import SubmitButton from '../components/Common/SubmitButton'
import ViewButton from '../components/Common/ViewButton'
import AddQuote from '../components/Queries/AddQuote'
import OceanViewPage from '../components/Queries/OceanViewPage'
import ViewPage from '../components/Queries/ViewPage'
import UserDetails from '../components/UserManagement/UserDetails'
import { formatDate, get_param_url } from '../functions/Utils'
import { get_query_list, get_single_query } from '../store/Queries/QuerySlice'
import { ButtonSubmit, StatusText, TablePagination } from '../StyledComponents/Common'
import { ButtonHeadSection, ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { DateSection, FilterSection, FlexContainer, LabelItem, RightSection, SpinContainer } from '../StyledComponents/Queries'

function Queries() {
    const dispatch = useDispatch()
    const params_queryID = get_param_url("queryId")
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [data, setData] = useState([]);
    const [searched_data, setSearchedData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        filter_value: 'date_range',
        from_date: '',
        to_date: '',
    })

    useEffect(()=> {
        if(params_queryID){
            // console.log(active);
            let data = {
                queryId: params_queryID
            }
            dispatch(get_single_query(data))
            .then(res=>{
                if(res.payload.status === 200){
                    // console.log(res);
                    let singleQry = res.payload?.data?.nodeData?.data
                    if(singleQry){
                        handleView(singleQry)
                    }
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
    },[params_queryID])

    useEffect(()=>{
        if(pageType === "table"){
            dispatch(get_query_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                    setData(res?.payload?.data?.nodeData?.output)
                    setSearchedData(res?.payload?.data?.nodeData?.output)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        
    },[pageType])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const handleSort = (name) => {
        console.log(name);
    }

    const handleFilterChange = (value) => {
        setState((prevState)=> {
            return {
                ...prevState,
                filter_value: value
            }
        })
    }

    function filterByDateRange(arr, fromDate, toDate) {
        return arr.filter(obj => {
            const date = new Date(obj.queryDate);
            const from_date = new Date(fromDate);
            const to_date = new Date(toDate);
            const objDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()); 
            const from = new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate());
            const to = new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate());
          return objDate >= from && objDate <= to;
        });
      }
    
    useEffect(()=> {
        if(state.filter_value){
            if(state.filter_value === 'date_range'){
                if(state.from_date && state.to_date){
                    handleSearch()
                }
            }else {
                handleSearch()
            }
        }
    },[state.filter_value,state.from_date,state.to_date])

    const handleSearch = () => {
        let status = ["New","Quoted","Booked","Expired","Cancelled"]
        let mode = ["Air Cargo","Ocean Cargo"]
        let search_data = data
        if(status.includes(state.filter_value)){
            search_data = data.filter((i)=> i.status === state.filter_value)
        }else if(mode.includes(state.filter_value)){
            search_data = data.filter((i)=> i.cargoType === state.filter_value)
        }else if(state.filter_value === 'date_range'){
            let from_date = state.from_date
            let to_date = state.to_date
            search_data = filterByDateRange(search_data,from_date,to_date)
        }
        setCurrentPage(1)
        setSearchedData(search_data)
    }


    const columns = [
        {
            title: () => (
                  <span className='custome-class'>SLNO</span>
              ),
            dataIndex: 'slNo',
            render: (text, record, index) => {
                return <span>{(index + 1) + ((currentPage - 1) * 10)}</span>;
              },
        },
        {
            title: 'ORIGIN',
            dataIndex: 'origin',
            render: (text, record, index) => {
                if (record.originPort?.origin) {
                  return <span>{record.originPort.origin}</span>;
                }else {
                    return <span>-</span>;
                }
              },
        },
        {
            title: 'DESTINATION',
            dataIndex: 'destination',
            render: (text, record, index) => {
                if (record.destinationPort?.destination) {
                  return <span>{record.destinationPort.destination}</span>;
                }else {
                    return <span>-</span>;
                }
              },
        },
        {
            title: () => (
                <FlexContainer>
                  <span>TYPE OF QUERY</span>
                  {/* <FlexContainer className='column' onClick={()=> handleSort('type_of_query')}>
                    <IconImg src={require('../assets/images/arrow-up.png')} />
                    <IconImg src={require('../assets/images/arrow-down.png')} />
                  </FlexContainer> */}
                </FlexContainer>
              ),
            dataIndex: 'queryType',
        },
        {
            title: () => (
                <FlexContainer>
                  <span>SHIPPING MODE</span>
                  {/* <FlexContainer className='column' onClick={()=> handleSort('shipping_mode')}>
                    <IconImg src={require('../assets/images/arrow-up.png')} />
                    <IconImg src={require('../assets/images/arrow-down.png')} />
                  </FlexContainer> */}
                </FlexContainer>
              ),
            dataIndex: 'shipping_mode',
            render: (text, record, index) => {
                return <span>{record.cargoType}</span>;
              },
        },
        {
            title: () => (
                <FlexContainer>
                  <span>QUERY DATE</span>
                </FlexContainer>
              ),
            dataIndex: 'query_date',
            render: (text, record, index) => {
                return <span>{formatDate(record.queryDate)}</span>;
              },
        },
        {
            title: () => (
                <FlexContainer>
                  <span>STATUS</span>
                  {/* <FlexContainer className='column' onClick={()=> handleSort('status')}>
                    <IconImg src={require('../assets/images/arrow-up.png')} />
                    <IconImg src={require('../assets/images/arrow-down.png')} />
                  </FlexContainer> */}
                </FlexContainer>
              ),
            dataIndex: 'status',
            render: (text, record) => (
                text === 'Booked' ? <StatusText text="Won">Won</StatusText> 
                : text === 'Expired' ? <StatusText text="Lost">Lost</StatusText> 
                : text === 'Pending' ? <StatusText text="QueryPending">Pending</StatusText>
                : <StatusText text={text}>{text}</StatusText> 
              ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];
      

    const filter_options = [
        {
            value: 'New',
            label: 'New Queries',
        },
        {
            value: 'Quoted',
            label: 'Quoted',
        },
        {
            value: 'Booked',
            label: 'Won',
        },
        {
            value: 'Cancelled',
            label: 'Cancelled',
        },
        {
            value: 'Expired',
            label: 'Lost',
        },
        {
            value: 'Air Cargo',
            label: 'Air Cargo',
        },
        {
            value: 'Ocean Cargo',
            label: 'Ocean Cargo',
        },
        {
            value: 'date_range',
            label: 'Date Range',
        },
    ]

    console.log(currentPage,"currentPage");
  return (
    <MainContainer>
        {pageType === 'add_quote' ? <CustomeHeader title="Create Rate" description="Create new rates & view created rate list" />: 
        <CustomeHeader title="Queries" description="View all queries / requests from users assigned" />}
      
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>Query List</TableTitle>
                <RightSection>
                    <FilterSection className='filter'>
                        <LabelItem>Filter by</LabelItem>
                        <FilterSelect options={filter_options} width="118px" value={state.filter_value} handleChange={handleFilterChange} />
                    </FilterSection>
                    {state.filter_value === 'date_range'? 
                    <DateSection>
                        <FilterSection>
                            <LabelItem>From</LabelItem>
                            <FilterDatePicker name="from_date" setState={setState} />
                        </FilterSection>
                    <FilterSection>
                        <LabelItem>To</LabelItem>
                        <FilterDatePicker name="to_date" setState={setState} />
                    </FilterSection>
                    </DateSection>
                    : null}
                   
                    {/* <FilterSection className='button-section'>
                        <ButtonSubmit onClick={handleSearch} size='large' icon={<SearchOutlined />} className='icon-class' >Search</ButtonSubmit>
                    </FilterSection> */}
                </RightSection>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable name="queries" rowKey="queryId" currentPage={currentPage} pageSize={pageSize} columns={columns} data={searched_data} />
            }
            
        </ContentContainer>
        {searched_data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                current={currentPage}
                defaultPageSize={10}
                total={searched_data?.length? searched_data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
            instance.cargoType === 'Ocean Cargo'? 
            <OceanViewPage setPageType={setPageType} instance={instance} />
            :
            <ViewPage setPageType={setPageType} instance={instance} />
        :pageType === 'add_quote'? 
        <ContentContainer>
            <ContentHead title="Add Quote"  handleClick={()=> setPageType("view_page")} />
            <AddQuote setPageType={setPageType} />
        </ContentContainer>
            
        : null}
      
      
    </MainContainer>
  )
}

export default Queries
