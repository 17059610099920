import React, { useState } from "react";
import { Table, Pagination } from "antd";
import styled from "styled-components";

const KAMTable = (props) => {
//   const startIndex = (props.currentPage - 1) * props.pageSize;
//   const endIndex = startIndex + props.pageSize;
//   const currentData = props?.data?.slice(startIndex, endIndex);

const handlePageChange = (page) => {
    props.setCurrentPage(page);
};
const paginationConfig = {
    current: props.currentPage,
    pageSize: props.pageSize,
    total: props.data.length,
    onChange: handlePageChange,
};
const slicedData = props?.data.slice((props.currentPage - 1) * props.pageSize, props.currentPage * props.pageSize);

  return (
    <TableContainer>
      <TableItem
        rowKey={props.rowKey ? props.rowKey : "id"}
        name={props.name}
        columns={props.columns}
        dataSource={slicedData}
        scroll={props.scroll ? { x: 1300 } : null}
        pagination={paginationConfig}
        fpadding={props.fpadding}
        showScroll={props.showScroll}
        className={props.className ? props.className : ""}
      />
    </TableContainer>
  );
};

export default KAMTable;

const TableContainer = styled.div`
  /* overflow-x: hidden;
  width: 100%; */
`;

const TableItem = styled(Table)`
  margin-top: 8px;
  th {
    font-size: 12px !important;
    color: #b5b5c3 !important;
    background-color: #fff !important;
    border-start-start-radius: 0px !important;
    border-bottom: 1px dashed #e4e6ef !important;
    ::before {
      content: none !important;
    }
    padding-left: ${({ name }) =>
      name === "rate_management" ? "0px !important" : "unset"};
    width: ${({ width }) => (width ? width : "unset")};
    &.custome-class {
      border-bottom: none !important;
      width: unset !important;
    }
    &.text-center {
      text-align: center;
    }
    &.text-right {
      text-align: right;
    }
    &.wdth-50 {
      width: 100% !important;
    }
  }
  th:first-child {
    width: "25%";
  }

  td {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #3f4254 !important;
    background-color: #fff !important;
    border-start-start-radius: 0px !important;
    border-bottom: 1px dashed #e4e6ef !important;
    font-family: "Rubik";
    font-style: normal;
    ::before {
      content: none !important;
    }
    padding: ${({ fpadding }) =>
      fpadding ? fpadding : "25px 16px !important"};
    padding-left: ${({ name }) =>
      name === "rate_management" ? "0px !important" : "unset"};
    /* padding: 25px 16px !important; */
  }
  & .ant-table-content {
    &::-webkit-scrollbar {
      /* display: none; */
      display: ${({ showScroll }) => (showScroll ? "block" : "none")};
      height: 6px !important;
    }
    & .ant-table-measure-row {
      display: none !important;
    }
  }
  & .ant-table-body {
    &::-webkit-scrollbar {
      /* display: none; */
      display: ${({ showScroll }) => (showScroll ? "block" : "none")};
      width: 6px !important;
    }
    & .ant-table-measure-row {
      display: none !important;
    }
  }
  & .ant-table-header {
    table {
      table-layout: unset !important;
      & .ant-table-thead {
        th {
          width: 50%;
        }
        & .ant-table-cell-scrollbar {
          display: none;
        }
      }
    }
  }
  &.report_kam_table {
    tbody tr:hover {
      td {
        background-color: rgba(255, 175, 0, 0.08) !important;
      }
    }

    th:last-child {
      border-right: 1px solid #ffaf00;
      border-radius: 0px !important;
    }
    td:last-child {
      border-right: 1px solid #ffaf00;
      border-radius: 0px !important;
      :hover {
        border-radius: 0px !important;
      }
    }
  }
`;
