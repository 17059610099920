import React,{ useEffect, useState } from 'react'
import { Spin } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { updatenotificationdata } from '../../store/Common/CommonSlice';
import * as Yup from 'yup'
import { SpinContainer } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { CustomeButton } from '../../StyledComponents/Common';
import { change_unarchive_rate } from '../../store/Archives/ArchiveSlice';
import { useLocation } from 'react-router-dom';




function ArchiveOceanRateView(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [archieve_loading, setArchiveLoading] = useState(false);

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/archives'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    const handleUnArchive = () => {
        setArchiveLoading(true)
        const values = {
            customType: "Ocean",
            data : props.instance,
            rate_id: props.instance._id
        }
        dispatch(change_unarchive_rate(values))
        .then(res=>{
            if(res?.payload?.data?.statusCode === 200 ){
                // console.log(res?.payload?.data?.data);
                dispatch(updatenotificationdata(res?.payload?.data))
                setArchiveLoading(false)
                props.setPageType("table")
                if(res?.payload?.data === 1){
                    let ocean_data = props.data
                    ocean_data = ocean_data.filter((i)=> i._id !== props.instance._id)
                    props.setRateOceanData(ocean_data)
                }
                props.setGetList(true)
            }
        })
        .catch(err=>{
            console.error(err);
        })
      }

    const initialValues = {
        origin_port: props.instance?.POR ? props.instance?.POR : null,
        destination_port: props.instance?.POD ? props.instance?.POD : null,
        cont_size: props.instance?.cont_size ? props.instance?.cont_size : null,
        cont_type: props.instance?.cont_type ? props.instance?.cont_type : null,
        freight: props.instance?.freight ? props.instance?.freight : null,
        line_code: props.instance?.line ? props.instance?.line : null,
        remarks: props.instance?.remarks ? props.instance?.remarks : '',
        shipper_name: props.instance?.shipper_name ? props.instance?.shipper_name : null,
        validity: props.instance?.validity ? props.instance?.validity : null,
        validity_terms: props.instance?.validity_terms ? props.instance?.validity_terms : null,
        customType: "Ocean",
        id: props.instance?._id ? props.instance?._id : '',
      }

    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
    return (
        <FormikForm
            initialValues={initialValues}
        >
        {({ values }) => (
            <FormItem>
        <DetailContainer>
        <HeadContainer>
                <Title>Ocean</Title>
                <TopButtonDiv className='mb-3'>
                    {/* <CustomeButton size='large' loading={archieve_loading} onClick={()=> handleUnArchive()}>Un-archive</CustomeButton> */}
                    <CustomeButton size='large' onClick={()=> props.DeleteArchive("single")} loading={props.deleteLoading}>Delete From Archives</CustomeButton>
                    {/* <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} /> */}
                </TopButtonDiv>
                
        </HeadContainer>
        <TopContent>
            <LeftContent>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Line Code</TitleRow>
                    </TitleDiv>
                        <RowValue>{values.line_code}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>Origin Ship Port</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.origin_port}</RowValue>
                    
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Container Size</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.cont_size}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>Freight</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.freight}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Validity</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.validity}</RowValue>
                </DetailRow>
            </LeftContent>
            <LeftContent>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Shipper Name</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.shipper_name}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                        <TitleRow>Destination Ship Port</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.destination_port}</RowValue>
                    
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Container Type</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.cont_type}</RowValue>
                </DetailRow>
                <DetailRow>
                    <TitleDiv>
                    <TitleRow>Validity Terms</TitleRow>
                    </TitleDiv>
                    <RowValue>{values.validity_terms}</RowValue>
                </DetailRow>
                <DetailRow className='align-start'>
                    <TitleDiv>
                    <TitleRow>Remarks</TitleRow>
                    </TitleDiv>
                    <RowValue className='raw-width'>{values.remarks}</RowValue>
                </DetailRow>
            </LeftContent>
        </TopContent>
        </DetailContainer>
            </FormItem>
        )}
        </FormikForm>
        
    )
    }
}

export default ArchiveOceanRateView


