import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { CustomeButton, LeftHead, SubHeadContainer, SubHeadTitle, TextInputItem } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import { AirlineContainer, AmountDiv, BottomContent, ButtonSection, ContentRow, ContentSection, ExpirySection, LableText, MainContainer, QuoteContainer, Seperator, ServiceHead, ServiceHeadContainer, ServiceSection, SpinContainer, SubheadTitle, SubTitleDiv, SummaryContainer, SummaryContent, TextAreaItem, TopContent } from '../../StyledComponents/Queries'
import AddButton from '../Common/AddButton'
import ContentHead from '../Common/ContentHead'
import CustomSelectItem from '../Common/CustomSelectItem'
import InputWithSelect from '../Common/InputWithSelect'
import SelectItem from '../Common/SelectItem'
import SubHeading from '../Common/SubHeading'
import SubmitButton from '../Common/SubmitButton'
import { ErrorMessage, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { send_query_rate, view_query } from '../../store/Queries/QuerySlice'
import QuerySelectItem from './QuerySelectItem'
import { airlines_list, updatenotificationdata } from '../../store/Common/CommonSlice'
import { Select, Spin } from 'antd'
import { QueryVASTypePage } from './QueryVASTypePage'
import DateInput from '../Common/DateInput'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs';
import { GetExchangeRate, handleKeyPress } from '../../functions/Utils'
import axios from 'axios'
import CustomSelectOption from '../Common/CustomSelectOption'
import { useLocation } from 'react-router-dom'
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime'


const { Option } = Select;

const initialValues = {
  destination_charge: null,
  per_unit: null,
  currency: '',
  exchange_rate: '',
  amount_inr: '',
  total_amount_inr: '',
  destination_services: [],
  origin_charge: null,
  origin_per_unit: null,
  origin_currency: '',
  origin_exchange_rate: '',
  origin_amount_inr: '',
  origin_total_amount_inr: '',
  origin_services: [],
  airline: null,
  airline_code: '',
  sell_rate: '',
  remarks: '',
  sell_rate_cuurency: 'INR',
  exchange_sell_rate: 1,
  sell_rate_inr: '',
  airline_list: [],

};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  message: Yup.string().required('Required'),
});

const onSubmit = (values, { setSubmitting }) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  }, 400);
};

function ViewPage(props) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    destination_charges: [],
    origin_charges: [],
    airline_list: [],
    searchQuery: {},
    output: [],
    calculated_rate: 0,
    quoteExpiryDate: null,
    rateNulledOutput: [],
    nulledAirline: null,
    nulledSellRate: 0,
    originChargeList: [],
    destinationChargeList: [],
    status: "New",
    destination_sum: 0,
    origin_sum: 0,
    origin_quoted: false,
    destination_quoted: false,
    expiryDate_required: false
  })
  const [editIndex, setEditIndex] = useState(null)
  const [editOriginIndex, setEditOriginIndex] = useState(null)
  const [editAirlineIndex, setEditAirlineIndex] = useState(null)
  const [ShowPage, setShowPages] = useState(props.instance.status == 'New' ? "first_page" : "second_page")
  const [is_addRate, setIsAddRate] = useState("add_rate")
  const [loading, setLoading] = useState(true)
  const [is_vas_save, setVasSave] = useState(false)
  const [get_vas_sum, setVasSum] = useState(false)
  const token = localStorage.getItem('accessToken');
  const [exchangeRateList, setExchangeRateList] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)

  const [hideAirline, setHideAirline] = useState(false)
  // const [initialCurrency, setInitialCurrency] = useState(exchangeRateList[0].value);

  const formik = useFormik({
     initialValues,
       // validationSchema,
      //  initialValues={
      //   currency: exchangeRateList[0].value,
      // },
    onSubmit,
  });

  const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/queries'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL_NODE}admin/getexchangerates`,
        { headers: { Authorization: `Bearer ${token}`,"Content-Type": "application/json" } }
        );
        if(result.status === 200){
          console.log(result);
          console.log("viewpage",result.data?.data)
          // setExchangeRateList(result.data?.data)
        //   const inrObj = result.data?.data.find(obj => obj.currency === "INR");
        // if (inrObj) {
        //   setExchangeRateList([inrObj]);
        // } else {
          setExchangeRateList(result.data?.data);
       // }
        }
      } catch (error) {
          console.log(error);
      }
    };

    fetchData();
  }, []);
  

  useEffect(()=>{
    let data = {
      queryId: props.instance.queryId,
      type: props.instance.queryType,
      status: props.instance.status,
    }
    dispatch(view_query(data))
    .then(res=>{
        // console.log('login res => ',res);
        let output = res?.payload?.data?.nodeData?.result?.output
        let quoteExpiryDate = res?.payload?.data?.nodeData?.result?.queryExpiryDate
        if(quoteExpiryDate){
          quoteExpiryDate = dayjs(new Date(quoteExpiryDate))
        }
        let rateNulledOutput = []
        let totalVAS = 0
        let totalFreightRate = 0
        
        if(output?.length){
          output.map((i)=> {
            if(props.instance.status != 'New'){
              totalVAS = i.totalVAS
              totalFreightRate = i.totalFreightRate
            }else {
              i.remarks = ""
            }
            i.totalVAS = totalVAS
            i.totalFreightRate = totalFreightRate
            if(i.rates === null || i.rates === "To be quoted."){
              rateNulledOutput.push(i)
            }
          })
        }
        if(res?.payload?.status === 200 ){
            let valued_origin_services = []
            let valued_destination_services = []
            let origin_services = []
            let destination_services = []
            let origin_quoted = false
            let destination_quoted = false
            // const origin_services = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges
            // const destination_services = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges

            if(res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges && res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges[0]?.originValue === 'To be quoted'){
              origin_services = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges
            }else {
              let transportaion = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges?.filter((i)=> i.originName === 'Origin Transportation' && (i.originValue == null || i.originValue == 'To be quoted'))
              if(transportaion && transportaion.length){
                origin_services = transportaion
              }
              res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges?.map((i)=> {
                if(i.originValue !== 'To be quoted' && i.originValue != null){
                  valued_origin_services.push({
                    origin_charge: i.originName,
                    origin_total_amount_inr: Number(i.originValue),
                    origin_quoted: true
                  })
                }
              })
              // else {
              //   res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.originCharges?.map((i)=> {
              //     valued_origin_services.push({
              //       origin_charge: i.originName,
              //       origin_amount_inr: i.originValue,
              //       origin_quoted: true
              //     })
              //   })
              //   origin_quoted = true
              // }
            }
            if(res?.payload?.data?.nodeData?.result?.IncoTerms && res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges && res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges[0]?.destinationValue === 'To be quoted'){
              destination_services = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges
            }else {
              let transportaion = res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges?.filter((i)=> i.destinationName === 'Destination Transportation' && (i.destinationValue == null || i.destinationValue == 'To be quoted'))
              if(transportaion && transportaion.length){
                destination_services = transportaion
              }
              res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges?.map((i)=> {
                if(i.destinationValue !== 'To be quoted' && i.destinationValue != null){
                  valued_destination_services.push({
                    destination_charge: i.destinationName,
                    total_amount_inr: Number(i.destinationValue),
                    destination_quoted: true
                  })
                }
              })

              // else {
              //   res?.payload?.data?.nodeData?.result?.IncoTerms[0]?.destinationCharges?.map((i)=> {
              //     valued_destination_services.push({
              //       destination_charge: i.destinationName,
              //       amount_inr: i.destinationValue,
              //       destination_quoted: true
              //     })
              //   })
              //   destination_quoted = true
              // }
            }
            formik.setValues({ ...formik.values,origin_services:valued_origin_services,destination_services: valued_destination_services })

            let destination_sum = 0
            let origin_sum = 0
            if(destination_services?.length){
              destination_sum = destination_services?.reduce((sum, charge) => sum + Number(charge.destinationValue), 0);
            }
            if(origin_services?.length){
              origin_sum = origin_services?.reduce((sum, charge) => sum + Number(charge.originValue), 0);
            }
            
            setState((prevState) => {
              return {
                ...prevState,
                destination_charges: destination_services,
                origin_charges: origin_services,
                searchQuery: res?.payload?.data?.nodeData?.result?.searchQuery,
                output: output,
                airline_list: res?.payload?.data?.nodeData?.result?.airlines,
                rateNulledOutput,
                originChargeList: origin_services,
                destinationChargeList: destination_services,
                status: props.instance.status,
                quoteExpiryDate,
                destination_sum,
                origin_sum,
                origin_quoted,
                destination_quoted,
              }
            })
            if(props.instance.queryType === 'Freight Charges' && props.instance.status != 'New'){
              let airline_list = []
              let origin_services = []
              let destination_services = []
              let originCharges = res?.payload?.data?.nodeData?.result?.resultData[0]?.originalAirFreightLocal?.originCharges
              originCharges?.map((i)=>{
                origin_services.push({
                  origin_charge: i.originName,
                  origin_total_amount_inr: i.originValue,
                })
              })

              let destinationCharges = res?.payload?.data?.nodeData?.result?.resultData[0]?.originalAirFreightLocal?.destinationCharges
              destinationCharges?.map((i)=>{
                destination_services.push({
                  destination_charge: i.destinationName,
                  total_amount_inr: i.destinationValue,
                })
              })

              console.log(destination_services,"destination_services>>>>>>>>>>>");
              console.log("state",state);
              res?.payload?.data?.nodeData?.result?.resultData?.map((i)=>{
                airline_list.push({
                  airline: i.airlines,
                  sell_rate: i.rates,
                  remarks: i.remarks,
                })
              })
              formik.setValues({ ...formik.values, airline_list: airline_list,origin_services:origin_services,destination_services:destination_services })

              let destination_sum = 0
              let origin_sum = 0
              if(destination_services?.length){
                destination_sum = destination_services?.reduce((sum, charge) => sum + Number(charge.total_amount_inr), 0);
              }
              if(origin_services?.length){
                origin_sum = origin_services?.reduce((sum, charge) => sum + Number(charge.origin_total_amount_inr), 0);
              }
              
              setState((prevState)=> {
                return {
                  ...prevState,
                  origin_sum,
                  destination_sum
                }
              })
            }
            
            setLoading(false)
        }
    })
    .catch(err=>{
        console.error(err);
    })
    
  },[props.instance])

  useEffect(()=>{
      if(formik.values.amount_inr && formik.values.exchange_rate){
        let total_amount_inr = Number(formik.values.exchange_rate) * Number(formik.values.amount_inr)
        formik.setValues({ ...formik.values, total_amount_inr : total_amount_inr })
      }
  },[formik.values.amount_inr, formik.values.exchange_rate])

  useEffect(()=>{
    if(formik.values.origin_amount_inr && formik.values.origin_exchange_rate){
      let origin_total_amount_inr = Number(formik.values.origin_exchange_rate) * Number(formik.values.origin_amount_inr)
      formik.setValues({ ...formik.values, origin_total_amount_inr : origin_total_amount_inr })
    }
  },[formik.values.origin_amount_inr, formik.values.origin_exchange_rate])

  useEffect(()=>{
    if(formik.values.sell_rate_inr && formik.values.exchange_sell_rate){
      let sell_rate = Number(formik.values.exchange_sell_rate) * Number(formik.values.sell_rate_inr)
      formik.setValues({ ...formik.values, sell_rate : sell_rate })
    }
  },[formik.values.sell_rate_inr])

  useEffect(()=>{
   if(get_vas_sum){
    let destination_services = [...formik.values.destination_services]
    let origin_services = [...formik.values.origin_services]
    const destination_sum = destination_services.reduce((sum, charge) => sum + Number(charge.total_amount_inr), 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + Number(charge.origin_total_amount_inr), 0);
    const calculated_rate = Number(destination_sum) + Number(origin_sum)
    setVasSum(false)
    setState((prevState)=> {
      return {
        ...prevState,
        calculated_rate
      }
    })
   }
},[get_vas_sum])
// useEffect(() => {
//   console.log("formik",formik.values)
//   // Set default value of currency to "INR" when the component mounts
//   formik.setInitialValues({
//     currency: exchangeRateList[0].value,
//   });
// }, []);
  const handleSelectChange = (e,name)=> {
    // console.log("Currency",formik.values.origin_currency,formik.values.origin_exchange_rate)
    // formik.setValues([name], e);
    let exchange_rate = formik.values.exchange_rate
    let origin_exchange_rate = formik.values.origin_exchange_rate
    let exchange_sell_rate = formik.values.exchange_sell_rate
    console.log(exchange_rate,origin_exchange_rate)
    if(name === 'origin_currency'){
      origin_exchange_rate = GetExchangeRate(exchangeRateList,e)
    }else if(name === 'currency'){
      
      exchange_rate = GetExchangeRate(exchangeRateList,e)
    
      
    }else if(name === 'sell_rate_cuurency'){
      exchange_sell_rate = GetExchangeRate(exchangeRateList,e)
    }
    formik.setValues({ ...formik.values, [name]: e,origin_exchange_rate,exchange_rate,exchange_sell_rate })
    console.log("Currency",formik.values)

    
  }

  const handleSelectAirlineChange = (value,option) => {
    let airline_list = state.airline_list
    let logo = airline_list.filter((i)=> i.carr_name === value)[0]?.logo
    if(logo){
      formik.setValues({ ...formik.values, airline : option.children, airline_code: value,logo })
    }else {
      formik.setValues({ ...formik.values, airline : option.children, airline_code: value })
    }
  }

  const handleInputChange = (e)=> {
    const { name, value } = e.target
    // formik.setValues([name], e);
    console.log("7 amt" ,name,value);
    formik.setValues({ ...formik.values, [name]: value })
    console.log(formik.values);
  }
  const handleAddDestinationService = ()=> {
    let destination_services = [...formik.values.destination_services]
    if(editIndex != null){
      destination_services[editIndex]["destination_charge"] = formik.values.destination_charge
      destination_services[editIndex]["per_unit"] = formik.values.per_unit
      destination_services[editIndex]["currency"] = formik.values.currency
      destination_services[editIndex]["exchange_rate"] = formik.values.exchange_rate
      destination_services[editIndex]["amount_inr"] = formik.values.amount_inr
      destination_services[editIndex]["total_amount_inr"] = formik.values.total_amount_inr
    }else {
      let dict = {
        destination_charge: formik.values.destination_charge,
        per_unit: formik.values.per_unit,
        currency: formik.values.currency,
        exchange_rate: formik.values.exchange_rate,
        amount_inr: formik.values.amount_inr,
        total_amount_inr: formik.values.total_amount_inr,
      }
      destination_services.push(dict)
    }
    setEditIndex(null)
    setVasSum(true)
    formik.setValues({ ...formik.values,
      destination_services: destination_services,
      destination_charge: null,
      per_unit: null,
      currency: "₹",
      exchange_rate: "",
      amount_inr: "",
      total_amount_inr: "",
    })
    
    const destinationChargeList = state.destinationChargeList?.filter((elemA) => {
      let foundMatch = destination_services?.some((elemB) => elemB.destination_charge === elemA.destinationName);
      let result = []
      if(foundMatch){
        result = !foundMatch
      }
      return result;
    });
    setState((prevState) => {
      return {
        ...prevState,
        destinationChargeList
      }
    })
  }


  const handleAddOriginService = ()=> {
    let origin_services = [...formik.values.origin_services]
    if(editOriginIndex != null){
      origin_services[editOriginIndex]["origin_charge"] = formik.values.origin_charge
      origin_services[editOriginIndex]["origin_per_unit"] = formik.values.origin_per_unit
      origin_services[editOriginIndex]["origin_currency"] = formik.values.origin_currency
      origin_services[editOriginIndex]["origin_exchange_rate"] = formik.values.origin_exchange_rate
      origin_services[editOriginIndex]["origin_amount_inr"] = formik.values.origin_amount_inr
      origin_services[editOriginIndex]["origin_total_amount_inr"] = formik.values.origin_total_amount_inr
    }else {
      let dict = {
        origin_charge: formik.values.origin_charge,
        origin_per_unit: formik.values.origin_per_unit,
        origin_currency: formik.values.origin_currency,
        origin_exchange_rate: formik.values.origin_exchange_rate,
        origin_amount_inr: formik.values.origin_amount_inr,
        origin_total_amount_inr: formik.values.origin_total_amount_inr,
      }
      origin_services.push(dict)
    }
    setEditOriginIndex(null)
    setVasSum(true)
    formik.setValues({ ...formik.values,
      origin_services: origin_services,
      origin_charge: null,
      origin_per_unit: null,
      origin_currency: "₹",
      origin_exchange_rate: "",
      origin_amount_inr: "",
      origin_total_amount_inr: "",
    })
    const originChargeList = state.originChargeList?.filter((elemA) => {
      let foundMatch = origin_services?.some((elemB) => elemB.origin_charge === elemA.originName);
      let result = []
      if(foundMatch){
        result = !foundMatch
      }
      return result;
    });
    setState((prevState) => {
      return {
        ...prevState,
        originChargeList
      }
    })
  }

  const handleDestinationChargeActions = (index,action) => {
        let destination_services = [...formik.values.destination_services]
        let destinationChargeList = [...state.destinationChargeList]
        destinationChargeList.push({
          destinationName: destination_services[index]["destination_charge"],
          destinationValue: "To be quoted"
        })
        if(action === 'edit'){
          setEditIndex(index)
          formik.setValues({ ...formik.values,
            destination_charge: destination_services[index]["destination_charge"],
            per_unit: destination_services[index]["per_unit"],
            currency: destination_services[index]["currency"],
            exchange_rate: destination_services[index]["exchange_rate"],
            amount_inr: destination_services[index]["amount_inr"],
            total_amount_inr: destination_services[index]["total_amount_inr"],
           })
        }else if (action === 'remove') {
          destination_services.splice(index,1)
          formik.setValues({ ...formik.values, destination_services: destination_services })
        }
        setState((prevState)=> {
          return {
            ...prevState,
            destinationChargeList
          }
        })
  }

  const handleOriginChargeActions = (index,action) => {
    let origin_services = [...formik.values.origin_services]
    let originChargeList = [...state.originChargeList]
    originChargeList.push({
      originName: origin_services[index]["origin_charge"],
      originValue: "To be quoted"
    })
    if(action === 'edit'){
      setEditOriginIndex(index)
      formik.setValues({ ...formik.values,
        origin_charge: origin_services[index]["origin_charge"],
        origin_per_unit: origin_services[index]["origin_per_unit"],
        origin_currency: origin_services[index]["origin_currency"],
        origin_exchange_rate: origin_services[index]["origin_exchange_rate"],
        origin_amount_inr: origin_services[index]["origin_amount_inr"],
        origin_total_amount_inr: origin_services[index]["origin_total_amount_inr"],
       })
    }else if (action === 'remove') {
      origin_services.splice(index,1)
      formik.setValues({ ...formik.values, origin_services: origin_services })
    }
    setState((prevState)=> {
      return {
        ...prevState,
        originChargeList
      }
    })
}

  const handleDestinationServiceSave = () => {
    const origin_services = formik.values.origin_services
    const destination_services = formik.values.destination_services
    const destination_sum = destination_services.reduce((sum, charge) => sum + Number(charge.total_amount_inr), 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + Number(charge.origin_total_amount_inr), 0);
    setState((prevState)=> {
      return {
        ...prevState,
        destination_sum,
        origin_sum
      }
    })
    setShowPages("second_page")
  }

  const handleAddAirline = () => {
    if(ShowPage === "second_page"){
      setHideAirline(true)
    }
    let airline_list = [...formik.values.airline_list]
    if(editAirlineIndex != null){
      airline_list[editAirlineIndex]["airline"] = formik.values.airline
      airline_list[editAirlineIndex]["logo"] = formik.values.logo
      // airline_list[editAirlineIndex]["airline_code"] = formik.values.airline_code
      airline_list[editAirlineIndex]["remarks"] = formik.values.remarks
      airline_list[editAirlineIndex]["sell_rate"] = formik.values.sell_rate
      airline_list[editAirlineIndex]["sell_rate_cuurency"] = formik.values.sell_rate_cuurency
    }else {
      let dict = {
        airline: formik.values.airline,
        logo: formik.values.logo,
        // airline_code: formik.values.airline_code,
        remarks: formik.values.remarks,
        sell_rate: formik.values.sell_rate,
        sell_rate_cuurency: formik.values.sell_rate_cuurency,
        exchange_sell_rate: formik.values.exchange_sell_rate,
        sell_rate_inr: formik.values.sell_rate_inr,
      }
      airline_list.push(dict)
    }
    setEditAirlineIndex(null)
    setIsAddRate("send")
    let state_airlines = state.airline_list.filter((i)=> i.carr_name != formik.values.airline)
    setState((prevState)=> {
      return {
        ...prevState,
        airline_list: state_airlines
      }
    })
    formik.setValues({ ...formik.values,
      airline_list: airline_list,
      airline: null,
      logo: null,
      // airline_code: "",
      remarks: "",
      sell_rate: "",
      sell_rate_cuurency: "",
      exchange_sell_rate: "",
      sell_rate_inr: "",
    })
  }

  const handleAirlineActions = (index,action) => {
      let airline_list = [...formik.values.airline_list]
        let state_airlines = state.airline_list
        state_airlines.push(airline_list[index]["airline"])
        setState((prevState)=> {
          return {
            ...prevState,
            airline_list: state_airlines
          }
        })
        if(action === 'edit'){
          if(ShowPage === "second_page"){
            setHideAirline(false)
          }
          setEditAirlineIndex(index)
          formik.setValues({ ...formik.values,
            airline: airline_list[index]["airline"],
            logo: airline_list[index]["logo"],
            remarks: airline_list[index]["remarks"],
            sell_rate: airline_list[index]["sell_rate"],
            sell_rate_cuurency: airline_list[index]["sell_rate_cuurency"],
            exchange_sell_rate: airline_list[index]["exchange_sell_rate"],
            sell_rate_inr: airline_list[index]["sell_rate_inr"],
           })
           console.log(formik.values);
        }else if (action === 'remove') {
          airline_list.splice(index,1)
          formik.setValues({ ...formik.values, airline_list: airline_list })
        }
  }

  const handleServicesVAS = () => {
    let destination_services = formik.values.destination_services
    let origin_services = formik.values.origin_services
    const destination_sum = destination_services.reduce((sum, charge) => sum + charge.total_amount_inr, 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + charge.origin_total_amount_inr, 0);
    const service_sum = Number(destination_sum) + Number(origin_sum)
    let output = [...state.output]
    // const totalweight = state.searchQuery?.totalWeight
    let totalweight = 0
    if(state?.searchQuery?.packagingDetails?.packagingDetailsBy === 'Shipment Total'){
      totalweight = state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalweight
    }else{
      totalweight = state?.searchQuery?.packagingDetails?.packagingType?.totalweight
    }
    if(!totalweight){
      totalweight = 0
    }

    output.map((i)=> {
      i.totalVAS = Number(service_sum)
      // i.totalFreightRate = Number(i.rates) + Number(service_sum)
      const totalFlightCharges = Number(i.rates) * Number(totalweight)
      let totalFreightRate = Number(service_sum) + Number(totalFlightCharges)
      if(!totalFreightRate){
        totalFreightRate = 0
      }
      i.totalFreightRate = totalFreightRate
    })
    setIsAddRate("send_rate")
    setState((prevState)=> {
      return {
        ...prevState,
        output: output
      }
    })
    setVasSave(true)
  }

  const handleAddRate = ()=> {
    if(props.instance.queryType === 'VAS'){
      if(is_addRate === 'add_rate'){
        console.log(formik.values,"703")
        setIsAddRate("cancel")
        console.log("cancel")
      }else if(is_addRate === 'cancel'){
        setIsAddRate("add_rate")
        console.log(formik.values,"707")
        console.log("addraTE")
      }else {
        handleSubmit()
      }
      
    }else if(props.instance.queryType === 'Freight Charges'){
      handleSubmit()
    }
  }

  const handleRemoveItem = (index) => {
    let output = [...state.output]
    output.splice(index,1)
    setState((prevState)=> {
      return {
        ...prevState,
        output
      }
    })
  }

  const handleAddRemarkVAS = (index,value) => {
    let output = [...state.output]
    output[index]["remarks"] = value
    setState((prevState)=> {
      return {
        ...prevState,
        output
      }
    })
  }

  const handleSelectVasAirline = (value,option) => {
    console.log(value)

    setState((prevState)=> {
      return {
        ...prevState,
        nulledAirline: value
      }
    })
  }

  const handleVasSellRate = (e) => {
    const value = e.target.value
    console.log(value)
        setState((prevState)=> {
      return {
        ...prevState,
        nulledSellRate: value
      }
    })
  }

  const handleAddVasAirlineRate = () => {
    console.log("airline");
    let output = [...state.output]
    output.filter((i)=> i.airlines === state.nulledAirline).map((j)=> {
      j.rates = formik.values.sell_rate
    })

    let rateNulledOutput = state.rateNulledOutput.filter((i)=> i.airlines !== state.nulledAirline)
    setState((prevState)=> {
      return {
        ...prevState,
        output,
        nulledAirline: null,
        nulledSellRate: 0,
        rateNulledOutput
      }
    })
    console.log(formik.values);
  }

  const handleSubmit = () => {
    if(state.quoteExpiryDate){
      setSubmitLoading(true)
      let final_data = {}
      let destinationCharges = []
      let originCharges = []
      let airline_list = formik.values.airline_list
      let destination_services = formik.values.destination_services
      let origin_services = formik.values.origin_services
      destination_services.map((i)=> {
        destinationCharges.push({
          destinationName: i.destination_charge,
          destinationValue: i.total_amount_inr,
        })
      })
      origin_services.map((i)=> {
        originCharges.push({
          originName: i.origin_charge,
          originValue: i.origin_total_amount_inr,
        })
      })
      let originalAirFreightLocal = {
        originCharges: originCharges,
        destinationCharges: destinationCharges
      }

      if(props.instance.queryType === 'Freight Charges'){
        // const totalweight = state.searchQuery?.totalWeight
        let totalweight = 0
        if(state?.searchQuery?.packagingDetails?.packagingDetailsBy === 'Shipment Total'){
          totalweight = state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalweight
        }else{
          totalweight = state?.searchQuery?.packagingDetails?.packagingType?.totalweight
        }
        if(!totalweight){
          totalweight = 0
        }
        const destination_sum = destinationCharges.reduce((sum, charge) => sum + Number(charge.destinationValue), 0);
        const origin_sum = originCharges.reduce((sum, charge) => sum + Number(charge.originValue), 0);
        const service_sum = Number(destination_sum) + Number(origin_sum)
        
        let data = []
        airline_list.map((i)=> {
          const totalFlightCharges = Number(i.sell_rate) * Number(totalweight)
          const totalFreightRate = Number(service_sum) + Number(totalFlightCharges)
          let result = {
            airlines: i.airline,
            originalAirFreightLocal: originalAirFreightLocal,
            originAirport: state.searchQuery?.originAirportShort,
            originAirportFull: state.searchQuery?.originAirportFull,
            destinationAirport: state.searchQuery?.destinationAirportShort,
            destinationAirportFull: state.searchQuery?.destinationAirportFull,
            rates: Number(i.sell_rate),
            remarks: i.remarks,
            totalVAS: service_sum,
            totalFlightCharges: totalFlightCharges,
            totalFlightRate: totalFlightCharges,
            totalFreightRate: Number(totalFreightRate).toFixed(2),
            queryQuotingStatus: "Manual",
            totalWeight: totalweight,
            logo: i.logo ? i.logo : ""
          }
          data.push(result)
        })
        final_data = {
          queryId: props.instance.queryId,
          data: data,
          quoteExpiryDate: state.quoteExpiryDate
        }
      }else if(props.instance.queryType === 'VAS'){
        let output = [...state.output]
        // const totalweight = state.searchQuery?.totalWeight
        let totalweight = 0
        if(state?.searchQuery?.packagingDetails?.packagingDetailsBy === 'Shipment Total'){
          totalweight = state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalweight
        }else{
          totalweight = state?.searchQuery?.packagingDetails?.packagingType?.totalweight
        }
        if(!totalweight){
          totalweight = 0
        }
        output.map((i)=> {
          const totalFlightCharges = Number(i.rates) * Number(totalweight)
          const totalFreightRate = Number(i.totalVAS) + Number(totalFlightCharges)
          i.originalAirFreightLocal = originalAirFreightLocal
          i.totalFlightCharges = totalFlightCharges
          i.totalFreightRate = totalFreightRate
          i.queryQuotingStatus = "Manual"
          i.totalWeight = totalweight
        })
        final_data = {
          queryId: props.instance.queryId,
          data: output,
          quoteExpiryDate: state.quoteExpiryDate
        }
      }
      dispatch(send_query_rate(final_data))
      .then(res=>{
          if(res.payload.status === 200){
            let data = {
                message : res?.payload?.data?.message,
                statusCode: 200,
                success: res?.payload?.data?.success
            }
            dispatch(updatenotificationdata(data))
            props.setPageType("table")
          }
          setSubmitLoading(false)
      })
      .catch(err=>{
          console.error(err);
      })
    }else {
      setState((prevState)=> {
        return {
          ...prevState,
          expiryDate_required: true
        }
      })
    }
    console.log(formik.values);
  }

  // const handleAmount = ()=> {
  //   let amounts = state.amounts
  //   amounts.push(0)
  //   setState((prevState)=> {
  //     return {
  //       ...prevState,
  //       amounts
  //     }
  //   })
  // }

  // const handlePage = ()=> {
  //   console.log("enterrrrrrrrrr");
  //   props.setPageType("add_quote")
  // }
  const destination_charges = [
    {
    value: 'Destination Cargo Handling',
    label: 'Destination Cargo Handling',
    },
    {
    value: 'Destination Customs Clearance',
    label: 'Destination Customs Clearance',
    },
]
const per_unit_list = [
  {
    value: 'KG',
    label: 'KG',
  },
  {
    value: 'HAWB',
    label: 'HAWB',
  },
  {
    value: 'MAWB',
    label: 'MAWB',
  },
]

const airline_list = [
  {
  value: 'Air India',
  label: 'Air India',
  },
  {
  value: 'Airline 1',
  label: 'Airline 1',
  },
]

const money_symbols = [
  {
    id: 1,
    value: "₹"
  },
  {
    id: 2,
    value: "$"
  },
  {
    id: 3,
    value: "€"
  },
]

    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (value) => {
        setSearchValue(value);
    };
    const filterOption = (input, option) => {
      const { value, children } = option.props;
      if(children){
        return (
          children.toString().toLowerCase().includes(input.toLowerCase())
        );
      }else {
        return ""
      }
      
    };
    
    const DateChange = (date, dateString) => {
      setState((prevState)=> {
        return {
          ...prevState,
          quoteExpiryDate: date,
          expiryDate_required: false
        }
      })
    };


    const handleBackArrow = () => {
      if(props.parent_type === 'user_management'){
        if(props.instance.status === 'Booked'){
          props.setCurrentTab("2")
        }else if(props.instance.status === 'Quoted'){
          props.setCurrentTab("3")
        }
        props.setQueryInstance({})
      }else if(props.parent_type === 'kam_management'){
        props.setCurrentTab("2")
        props.setQueryInstance({})
      }else {
        props.setPageType("table")
      }
    }

  if(loading){
    return (
      <SpinContainer >
        <Spin />
      </SpinContainer>
    )
  }else {
    return (
      <MainContainer >
        <LeftSection>
          <QuoteContainer onSubmit={handleSubmit} className={props.instance.queryType}>
              <ContentHead title={state.status === 'New'? "Add Quote" : "Quote Details"} setPageType={props.setPageType} handleClick={()=> handleBackArrow()} pb="20px" pt="10px" is_icon={false} is_back_icon={true}
                is_button={(props.instance.queryType === 'VAS' && !state.output?.length) || state.status != 'New' ? false : true} is_add_rate={(props.instance.queryType === 'VAS') || (props.instance.queryType === 'Freight Charges' && ShowPage === 'second_page')} add_title={is_addRate === 'add_rate'? "Add Rates" : is_addRate === 'cancel'? "Cancel" : "Send Rate"} loading={submitLoading}  handleAddRate={() => handleAddRate()}
              />
              <ExpirySection className='mt-3'>
                <span style={{ color: '#B5B5C3' }}>Expiry Date*</span>
                <DateInput DateChange={DateChange} value={state.quoteExpiryDate} disabled={state.status != 'New'} placeholder="Expiry Date" disabledate={true} />
                {state.expiryDate_required?
                  <ErrorMsg>Required</ErrorMsg>
              : null}
              </ExpirySection>
              
              {props.instance.queryType === 'Freight Charges'? 
                <>
                  <SubHeading title="Details" pb="20px" pt="20px" />
                  <ContentSection className='mb-10'>
                  {ShowPage === 'first_page' ?
                    <>
                    <LeftHead className="multi-head mt-5" >
                        {state.originChargeList?.length ?
                          <SubHeadTitle className='sub-head'>Origin Services</SubHeadTitle>
                        : null}
                        {formik.values?.origin_services?.length ?
                        formik.values.origin_services.map((i,index)=>
                          i.origin_quoted ?
                            <ContentRow className='mt-0 sub-head' key={index}>
                              <LableText>{i.origin_charge}</LableText>
                              <InputWithSelect width="32px" value={Number(i.origin_total_amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true}
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions} disabled_class={true} />
                            </ContentRow>
                          :
                            <ContentRow className='mt-0 sub-head' key={index}>
                              <LableText>{i.origin_charge}</LableText>
                              <InputWithSelect width="32px" value={Number(i.origin_total_amount_inr).toFixed(2)} close_icon={true} edit_icon={editOriginIndex == index ? false : true} is_input={true}
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions} disabled_class={true} />
                            </ContentRow>
                          )
                        : 
                        null}
                        
                      </LeftHead>
                    {state.originChargeList?.length ? 
                      <ContentRow>
                        <LableText>Origin Charges</LableText>
                        <QuerySelectItem value={formik.values.origin_charge} handleChange={handleSelectChange} name="origin_charge" options={state.originChargeList} 
                        placeholder="Select Origin Charge" size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                      </ContentRow>
                    : null}
                    
  
                  {formik.values.origin_charge ?
                      <>
                      {/* console.log("Currency",formik.values.origin_currency,formik.values.origin_exchange_rate) */}
                          <ContentRow>
                          <LableText>per/Unit</LableText>
                          <CustomSelectItem value={formik.values.origin_per_unit} handleChange={handleSelectChange} name="origin_per_unit" class="normal-placeholder" placeholder="Select Unit" options={per_unit_list} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                        </ContentRow>
                        <ContentRow>
                          <LableText>Currency & Exchange Rate 1</LableText>
                          <CurrencyDiv>
                            <CustomSelectOption handleChange={handleSelectChange} name="origin_currency" options={exchangeRateList} size="middle" value={formik.values.origin_currency} width="80px" height="35px !important" pdg="3px 11px !important" 
                            />
                            <TextInputItem
                                      id="origin_exchange_rate"
                                      name="origin_exchange_rate"
                                      className='normal-placeholder'
                                      placeholder='Exchange Rate'
                                      value={formik.values.origin_exchange_rate}
                                      onKeyPress={handleKeyPress}
                                      onChange={formik.handleChange}
                                        disabled={true}
                                  />
                          </CurrencyDiv>
                        </ContentRow>
                        <ContentRow>
                          <LableText>Amount</LableText>
                          <InputWithSelect name="origin_amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.origin_amount_inr} is_input={true} input_val={formik.values.origin_currency}
                          first_class="first-class" second_class="second-class" placeholder="Amount" />
                        </ContentRow>
                        <ContentRow>
                          <LableText>Total Amount In INR</LableText>
                          <InputWithSelect handlePlusClick={handleAddOriginService} width="32px"  value={Number(formik.values.origin_total_amount_inr).toFixed(2)} is_input={true}
                          first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={Number(formik.values.origin_total_amount_inr) > 0} is_save={false}
                          icon_class="flex-end" handleSave={handleDestinationServiceSave}  />
                        </ContentRow>
                        </>
                      : null}

                    <LeftHead className="multi-head mt-5" >
                      {state.destinationChargeList?.length?
                        <SubHeadTitle className='sub-head'>Destination Services</SubHeadTitle>
                       : null}
                        {formik.values?.destination_services?.length ?
                        formik.values.destination_services.map((i,index)=>
                          i.destination_quoted ?
                            <ContentRow className='mt-0 sub-head' key={index}>
                              <LableText>{i.destination_charge}</LableText>
                              <InputWithSelect width="32px" value={Number(i.total_amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true}
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions} disabled_class={true} />
                            </ContentRow> :
                            <ContentRow className='mt-0 sub-head' key={index}>
                            <LableText>{i.destination_charge}</LableText>
                            <InputWithSelect width="32px" value={Number(i.total_amount_inr).toFixed(2)} close_icon={true} edit_icon={editIndex == index ? false : true} is_input={true}
                            first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions} disabled_class={true} />
                          </ContentRow>
                          )
                          
                        : 
                        null}
                      </LeftHead>
                    {state.destinationChargeList?.length? 
                      <ContentRow>
                        <LableText>Destination Charges</LableText>
                        <QuerySelectItem value={formik.values.destination_charge} handleChange={handleSelectChange} name="destination_charge" options={state.destinationChargeList}
                         placeholder="Select Destination Charge" size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                      </ContentRow>
                    : null}
                    
                  
                  {formik.values.destination_charge ?
                  <>
                      <ContentRow>
                      <LableText>per/Unit</LableText>
                      <CustomSelectItem value={formik.values.per_unit} handleChange={handleSelectChange} name="per_unit" placeholder="Select Unit" class="normal-placeholder" options={per_unit_list} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                    </ContentRow>
                    <ContentRow>
                      <LableText>Currency & Exchange Rate2</LableText>
                      <CurrencyDiv>
                        <CustomSelectOption handleChange={handleSelectChange} name="currency" options={exchangeRateList} size="middle" value={formik.values.currency} width="80px" height="35px !important" pdg="3px 11px !important" />
                        <TextInputItem
                                  id="exchange_rate"
                                  name="exchange_rate"
                                  className='normal-placeholder'
                                  placeholder='Exchange Rate'
                                  value={formik.values.exchange_rate}
                                  onKeyPress={handleKeyPress}
                                  onChange={formik.handleChange}
                                  // defaultValue={exchangeRateList[0].value}
                                  disabled={true}
                              />
                      </CurrencyDiv>
                    </ContentRow>
                    <ContentRow>
                      <LableText>Amount</LableText>
                      <InputWithSelect name="amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.amount_inr} is_input={true} input_val={formik.values.currency}
                      first_class="first-class" second_class="second-class" placeholder="Amount" />
                    </ContentRow>
                    <ContentRow>
                      <LableText>Total Amount In INR</LableText>
                      <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={Number(formik.values.total_amount_inr).toFixed(2)} is_input={true}
                      first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={Number(formik.values.total_amount_inr) > 0} is_save={false}
                      icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                    </ContentRow>
                      
                    </>
                  : null}
                  {/* <div style={{ marginTop: '15px', display: 'flex',justifyContent: 'flex-end' }}>
                    <CustomeButton onClick={()=> handleDestinationServiceSave()} size='large'>Save</CustomeButton>
                  </div> */}
                  <Seperator></Seperator>
                    </>
                  :
                  <>
                    
                      {formik.values.airline_list?.length ?
                        formik.values.airline_list.map((i,index)=> 
                        <>
                          <ContentRow className='space-between' key={index}>
                            <AirLineDiv>
                              <LableText>Airline</LableText>
                              <LableText className='content services'>{i.airline}</LableText>
                              <LableText className='ml-4'>Sell Rate/Kg</LableText>
                              <LableText className='content'>₹ {Number(i.sell_rate).toFixed(2)}</LableText>
                            </AirLineDiv>
                            {state.status == 'New'?
                            <ButtonSection>
                              <IconImg  src={require('../../assets/images/editicon.png')} onClick={()=> handleAirlineActions(index,"edit")} />
                              <IconImg className='ml-3' src={require('../../assets/images/trashicon.png')} onClick={()=> handleAirlineActions(index,"remove")} />
                            </ButtonSection> : null}
                            
                          </ContentRow>
                          {i.remarks ?
                            <ContentRow className='mt-1'>
                                <LableText className='remarks'>Remarks</LableText>
                                <LableText className='content break-word'>{i.remarks}</LableText>
                            </ContentRow>
                          : null}
                          
                          { formik.values.origin_services?.length ? 
                            <ContentRow className='mt-0'>
                              <DestServiceSection className='mt-1'>
                                <LableText className='wd-20'>Origin Services</LableText>
                                    {formik.values.origin_services.map((i,index)=> 
                                      <DestDetails className='mb-2 ml-1' key={index}>{i.origin_charge} &nbsp;&nbsp;&nbsp; {i.origin_currency} {i.origin_exchange_rate} &nbsp;&nbsp; {i.origin_amount_inr} &nbsp;&nbsp; ₹ {Number(i.origin_total_amount_inr).toFixed(2)}</DestDetails>
                                    )}
                                    <DestDetails className='mb-2 ml-4'>Total Amount(INR) ₹ {Number(state.origin_sum).toFixed(2)}</DestDetails>
                              </DestServiceSection>
                            </ContentRow>
                            : null
                          }
  
                          { formik.values.destination_services?.length ? 
                            <ContentRow className='mt-0'>
                              <DestServiceSection className='mt-1'>
                                <LableText className='wd-20'>Destination Services</LableText>
                                
                                    {formik.values.destination_services.map((i,index)=> 
                                      <DestDetails className='mb-2 ml-1' key={index}>{i.destination_charge} &nbsp;&nbsp;&nbsp; {i.currency} {i.exchange_rate} &nbsp;&nbsp; {i.amount_inr} &nbsp;&nbsp; ₹ {Number(i.total_amount_inr).toFixed(2)}</DestDetails>
                                    )}
                                    <DestDetails className='mb-2 ml-4'>Total Amount(INR) ₹ {Number(state.destination_sum).toFixed(2)}</DestDetails>
                              </DestServiceSection>
                            </ContentRow>
                          : null
                          }
                        </>
                        )
                      : null}
                      
                    <Seperator></Seperator>
                    </>
                    }

                  {state.status === 'New'?
                    <>
                    {formik.values.airline_list?.length ? 
                      formik.values.airline_list.map((i,index)=>
                      <>
                      <ContentRow className='mt-3 sub-head' key={index}>
                        <LableText>{i.airline}</LableText>
                        <InputWithSelect width="32px" value={Number(i.sell_rate).toFixed(2)} close_icon={true} edit_icon={editAirlineIndex == index ? false : true} is_input={true}
                        first_class="first-class" second_class="second-class" index={index} handleAction={handleAirlineActions}
                         disabled_class={true} remarks={i.remarks} />
                      </ContentRow>
                      </>
                      ) : null
                    }

                    {hideAirline? 
                      <div className='mt-2'>
                          <span className='underline cursor-pointer text-[#B5B5CF] hover:text-[#353536]' onClick={()=> setHideAirline(false)}>add more airline</span>
                      </div> : null}

                    {hideAirline ? null :
                    <>
                    <ContentRow className='flex-start'>
                      <LableText className='Airline'>Airline*</LableText>
                      <RightDiv>
                        <ItemSelect
                            showSearch
                            size="large"
                            placeholder="Select Airline"
                            style={{
                                width: 170,
                            }}
                            onChange={(value,option)=> handleSelectAirlineChange(value,option)}
                            onSearch={handleSearch}
                            filterOption={filterOption}
                            value={formik.values.airline}
                            bradius="8px"
                          >
                              {state.airline_list?.map((i,index)=> 
                                  <Option key={index} value={i.carr_name}>{i.carr_name}</Option>
                              )}   
                          </ItemSelect>
                          <div style={{ display: 'flex',alignItems: 'center', gap: 12}}>
                              {formik.values.airline && formik.values.sell_rate ?
                                <IconImg src={require('../../assets/images/add-circle.png')} onClick={()=> handleAddAirline()} />
                              : null}
                              {ShowPage === 'first_page' && formik.values.airline_list?.length ? <CustomeButton onClick={()=> {handleDestinationServiceSave();setHideAirline(true)}} size='large'>Save</CustomeButton>: null}
                          </div>
                          
                      </RightDiv>
                    </ContentRow>
                    {/* updation */}
                    <ContentRow>
                      <LableText>Currency & Exchange Rate 3*</LableText>
                      <CurrencyDiv>
                        <CustomSelectOption handleChange={handleSelectChange} name="sell_rate_cuurency" 
                        options={exchangeRateList} size="middle" 
                        // defaultValue={exchangeRateList[0].currency}
                         value={formik.values.sell_rate_cuurency} width="80px" height="35px !important" 
                         pdg="3px 11px !important" />
                        <TextInputItem
                            id="exchange_sell_rate"
                            name="exchange_sell_rate"
                            className='normal-placeholder'
                            placeholder='Exchange Rate'
                            value={formik.values.exchange_sell_rate}
                            onKeyPress={handleKeyPress}
                            onChange={formik.handleChange}
                            // defaultValue={exchangeRateList[0].value}
                            disabled={true}
                        />
                      </CurrencyDiv>
                    </ContentRow>
                    <ContentRow>
                      <LableText>Amount*</LableText>
                      <InputWithSelect name="sell_rate_inr" handleChange={handleInputChange} width="48px" value={formik.values.sell_rate_inr} is_input={true} input_val={formik.values.sell_rate_cuurency}
                      first_class="first-class" second_class="second-class" placeholder="Amount" />
                    </ContentRow>
                    <ContentRow>
                        <LableText>Sell Rate/Kg</LableText>
                        <InputWithSelect handlePlusClick={handleAddOriginService} width="32px"  value={Number(formik.values.sell_rate).toFixed(2)} is_input={true}
                        first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={false} is_save={false}
                        icon_class="flex-end" handleSave={handleDestinationServiceSave}  />
                      </ContentRow>
                    {/* end */}
                    {/* <ContentRow>
                      <LableText>Sell Rate/Kg</LableText>
                      <RightDiv className='sell-rate'>
                      <InputWithSelect width="75px" options={exchangeRateList} name="sell_rate" placeholder="Sell Rate" handleChange={handleInputChange} value={formik.values.sell_rate} second_class="normal-paceholder" />
                      </RightDiv>
                    </ContentRow> */}
                    <ContentRow className='align-start'>
                      <LableText>Remarks</LableText>
                      <RightDiv className='remarks'>
                        <TextAreaItem rows={3} placeholder="Remarks" name="remarks"
                         value={formik.values.remarks} onChange={formik.handleChange} />
                      </RightDiv>
                    </ContentRow>
                    </>
                    }
                    
                    </>
                  : null}
                  
                  
                  
                </ContentSection>
              </>
              : 
              <>
              <AirlineContainer>
                  {state.output?.map((item,index)=> 
                    <>
                    <QueryVASTypePage key={index} item={item} searchquery={state.searchQuery} handleRemoveItem={handleRemoveItem} index={index}
                     status={state.status} handleAddRemarkVAS={handleAddRemarkVAS} handleChange={formik.handleChange} remark_value={formik.values.remarks}
                     formik={formik} />
                    {(state.output.length -1) != index ?
                      <Seperator></Seperator>
                      : null}
                    </>
                  )}
                  
              </AirlineContainer>
              
              </>
              }
              
          </QuoteContainer>

          {props.instance.queryType === 'VAS' && (is_addRate === 'cancel' || is_addRate === 'send_rate') && state.output.length ?
            <ServiceSection>
              {/* <ServiceHeadContainer>
                <ServiceHead>Origin Services</ServiceHead>
                {formik.values?.origin_services?.length ?
                    formik.values.origin_services.map((i,index)=>
                      <ContentRow className='mt-2 sub-head'>
                        <LableText className='services'>{i.origin_charge}</LableText>
                        <InputWithSelect width="32px" value={i.origin_total_amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true}
                        first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                        disabled_class={is_vas_save} />
                      </ContentRow>
                      )
                      
                    : 
                    null}
                <ServiceHead>Destination Services</ServiceHead>
                {formik.values?.destination_services?.length ?
                      formik.values.destination_services.map((i,index)=>
                        <ContentRow className='mt-2 sub-head'>
                          <LableText className='services'>{i.destination_charge}</LableText>
                          <InputWithSelect width="32px" value={i.total_amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true}
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                          disabled_class={is_vas_save} />
                        </ContentRow>
                        )
                        
                      : 
                      null}
              </ServiceHeadContainer> */}
              {ShowPage === 'first_page' ?
                    <>
                    <ServiceHeadContainer>
                    {state.originChargeList?.length || formik.values?.origin_services?.length ?
                      <ServiceHead>Origin Services</ServiceHead>
                    : null }
                      {formik.values?.origin_services?.length ?
                          formik.values.origin_services.map((i,index)=>
                          i.origin_quoted ?
                            <ContentRow className='mt-2 sub-head' key={index}>
                              <LableText>{i.origin_charge}</LableText>
                              <InputWithSelect width="32px" value={Number(i.origin_total_amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true}
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                              disabled_class={true} />
                            </ContentRow>
                            :
                            <ContentRow className='mt-2 sub-head' key={index}>
                              <LableText>{i.origin_charge}</LableText>
                              <InputWithSelect width="32px" value={Number(i.origin_total_amount_inr).toFixed(2)} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true}
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                              disabled_class={is_vas_save} />
                            </ContentRow>
                            )
                          : 
                          null}
                      {/* <ServiceHead>Destination Services</ServiceHead>
                      {formik.values?.destination_services?.length ?
                            formik.values.destination_services.map((i,index)=>
                              <ContentRow className='mt-2 sub-head'>
                                <LableText className='services'>{i.destination_charge}</LableText>
                                <InputWithSelect width="32px" value={i.total_amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true}
                                first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                                disabled_class={is_vas_save} />
                              </ContentRow>
                              )
                              
                            : 
                            null} */}
                    </ServiceHeadContainer>
                  {state.originChargeList?.length ? 
                    <ContentRow>
                      <LableText>Origin Charges</LableText>
                      <QuerySelectItem value={formik.values.origin_charge} handleChange={handleSelectChange} name="origin_charge" options={state.originChargeList}
                       disabled={is_vas_save} size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                    </ContentRow>
                  : null}
                  
  
                {formik.values.origin_charge ?
                    <>
                        <ContentRow>
                        <LableText>per/Unit</LableText>
                        <CustomSelectItem value={formik.values.origin_per_unit} handleChange={handleSelectChange} name="origin_per_unit" options={per_unit_list} 
                         disabled={is_vas_save} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                      </ContentRow>
                      <ContentRow>
                        <LableText>Currency & Exchange Rate 4</LableText>
                        <CurrencyDiv>
                          <CustomSelectOption handleChange={handleSelectChange} name="origin_currency" options={exchangeRateList} disabled={is_vas_save} size="middle" value={formik.values.origin_currency} width="80px" height="35px !important" pdg="3px 11px !important" />
                          <TextInputItem
                                    id="origin_exchange_rate"
                                    name="origin_exchange_rate"
                                    placeholder=''
                                    value={formik.values.origin_exchange_rate}
                                    onKeyPress={handleKeyPress}
                                    onChange={formik.handleChange}
                                    // defaultValue={exchangeRateList[0].value}
                                    disabled={true}
                                />
                        </CurrencyDiv>
                      </ContentRow>
                      <ContentRow>
                        <LableText>Amount</LableText>
                        <InputWithSelect name="origin_amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.origin_amount_inr} is_input={true} input_val={formik.values.origin_currency}
                        first_class="first-class" second_class="second-class" disabled_class={is_vas_save} />
                      </ContentRow>
                      <ContentRow>
                        <LableText>Total Amount In INR</LableText>
                        <InputWithSelect handlePlusClick={handleAddOriginService} width="32px"  value={Number(formik.values.origin_total_amount_inr).toFixed(2)} is_input={true}
                        first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={!is_vas_save && Number(formik.values.origin_total_amount_inr) > 0} is_save={false}
                        icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                      </ContentRow>
                      </>
                    : null}

                  <ServiceHeadContainer className='mt-5'>
                  {state.destinationChargeList?.length || formik.values?.destination_services?.length ?
                    <ServiceHead>Destination Services</ServiceHead>
                  : null}
                    {
                      formik.values?.destination_services?.length ?
                      formik.values.destination_services.map((i,index)=>
                        i.destination_quoted ?
                          <ContentRow className='mt-2 sub-head' key={index}>
                            <LableText>{i.destination_charge}</LableText>
                            <InputWithSelect width="32px" value={Number(i.total_amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true}
                            first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                            disabled_class={true} />
                          </ContentRow>
                        :
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.destination_charge}</LableText>
                          <InputWithSelect width="32px" value={Number(i.total_amount_inr).toFixed(2)} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true}
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                          disabled_class={is_vas_save} />
                        </ContentRow>
                        )
                      : 
                      null  
                    }
                  </ServiceHeadContainer>
                  {state.destinationChargeList?.length? 
                    <ContentRow>
                      <LableText>Destination Charges</LableText>
                      <QuerySelectItem value={formik.values.destination_charge} handleChange={handleSelectChange} name="destination_charge" options={state.destinationChargeList}
                       disabled={is_vas_save} size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                    </ContentRow>
                  : null}
                  
                
                {formik.values.destination_charge ?
                <>
                    <ContentRow>
                    <LableText>per/Unit</LableText>
                    <CustomSelectItem value={formik.values.per_unit} handleChange={handleSelectChange} name="per_unit" options={per_unit_list}
                     disabled={is_vas_save} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                  </ContentRow>
                  <ContentRow>
                    <LableText>Currency & Exchange Rate 6</LableText>
                    <CurrencyDiv>
                      <CustomSelectOption handleChange={handleSelectChange} name="currency" options={exchangeRateList} disabled={is_vas_save}
                       size="middle" value={formik.values.currency} width="80px" height="35px !important" pdg="3px 11px !important" />
                      <TextInputItem
                                id="exchange_rate"
                                name="exchange_rate"
                                placeholder=''
                                value={formik.values.exchange_rate}
                                onKeyPress={handleKeyPress}
                                onChange={formik.handleChange}
                                // defaultValue={exchangeRateList[0].value}
                                disabled={true}
                            />
                    </CurrencyDiv>
                  </ContentRow>
                  <ContentRow>
                    <LableText>Amount</LableText>
                    <InputWithSelect name="amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.amount_inr} is_input={true} input_val={formik.values.currency}
                    first_class="first-class" second_class="second-class" disabled_class={is_vas_save}  />
                  </ContentRow>
                  <ContentRow>
                    <LableText>Total Amount In INR</LableText>
                    <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={Number(formik.values.total_amount_inr).toFixed(2)} is_input={true}
                    first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={!is_vas_save && Number(formik.values.total_amount_inr) > 0} is_save={false}
                    icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                  </ContentRow>
                    
                  </>
                : null}
                <div style={{ marginTop: '15px', display: 'flex',justifyContent: 'space-between',alignItems: 'center' }}>
                  <ContentRow className='sub-head'>
                    <LableText className='sum-rate'>Calculated Rate</LableText>
                    <InputWithSelect name="amount_inr" handleChange={handleInputChange} width="32px" value={state.calculated_rate} is_input={true}
                    first_class="first-class" second_class="second-class" disabled_class={true}  />
                  </ContentRow>
                  {is_vas_save && Number(state.calculated_rate) > 0 ? 
                    <CustomeButton onClick={()=> setVasSave(false)} size='large'>Edit</CustomeButton>
                  : Number(state.calculated_rate) > 0 ? 
                    <CustomeButton onClick={()=> handleServicesVAS()} size='large'>Save</CustomeButton>
                    : null
                  }
                </div>
                <Seperator></Seperator>
                {state.rateNulledOutput.length && state.status === 'New' ? 
                  <>
                    <ContentRow className='flex-start'>
                    <LableText className='Airline'>Airline7</LableText>
                    <RightDiv>
                      <ItemSelect
                          size="large"
                          placeholder="Select Airline"
                          style={{
                              width: 170,
                          }}
                          onChange={(value,option)=> handleSelectVasAirline(value,option)}
                          value={state.nulledAirline}
                          bradius="8px"
                        >
                            {state.rateNulledOutput?.map((i,index)=> 
                                <Option key={index} value={i.airlines}>{i.airlines}</Option>
                            )}   
                        </ItemSelect>
                        <div style={{ display: 'flex',justifyContent: 'flex-end', alignItems: 'center', gap: '18px' }}>
                          <IconImg src={require('../../assets/images/add-circle.png')} onClick={()=> handleAddVasAirlineRate()} />
                        </div>
                        
                    </RightDiv>
                    
                  </ContentRow>
                  {/* updation rate set ariline avail;ble  */}
                  <ContentRow>
                      <LableText>Currency & Exchange Rate 7</LableText>
                      <CurrencyDiv>
                        <CustomSelectOption handleChange={handleSelectChange} name="sell_rate_cuurency" options={exchangeRateList} size="middle" value={formik.values.sell_rate_cuurency} width="80px" height="35px !important" pdg="3px 11px !important" />
                        <TextInputItem
                            id="exchange_sell_rate"
                            name="exchange_sell_rate"
                            className='normal-placeholder'
                            placeholder='Exchange Rate'
                            value={formik.values.exchange_sell_rate}
                            onKeyPress={handleKeyPress}
                            onChange={formik.handleChange}
                            // defaultValue={exchangeRateList[0].value}
                            disabled={true}
                        />
                      </CurrencyDiv>
                    </ContentRow>
                    <ContentRow>
                      <LableText>Amount</LableText>
                      <InputWithSelect name="sell_rate_inr" handleChange={handleInputChange} width="48px" value={formik.values.sell_rate_inr} is_input={true} input_val={formik.values.sell_rate_cuurency}
                      first_class="first-class" second_class="second-class" placeholder="Amount" />
                    </ContentRow>
                    <ContentRow>
                        <LableText>Sell Rate/Kg</LableText>
                        <InputWithSelect handleChange={handleVasSellRate} width="32px"  value={formik.values.sell_rate} is_input={true}
                        first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={false} is_save={false}
                        icon_class="flex-end"  />
                    </ContentRow>
                    {/* end */}
                  {/* <ContentRow>
                    <LableText>Sell Rate/Kg</LableText>
                    <RightDiv className='sell-rate'>
                    <InputWithSelect options={exchangeRateList} width="75px" name="sell_rate" placeholder="Sell Rate" handleChange={handleVasSellRate} value={state.nulledSellRate} second_class="normal-paceholder" />
                  </ContentRow> */}
                  </>
                : null}
                  </>
                :
                <>
                  
                    {formik.values.airline_list?.length ?
                      formik.values.airline_list.map((i,index)=> 
                      <>
                        <ContentRow className='space-between' key={index}>
                          <AirLineDiv>
                            <LableText>Airline</LableText>
                            <LableText className='content'>{i.airline}</LableText>
                            <LableText className='ml-4'>Sell Rate/Kg</LableText>
                            <LableText className='content'>{i.sell_rate_cuurency} {Number(i.sell_rate).toFixed(2)}</LableText>
                          </AirLineDiv>
                          <ButtonSection>
                            <IconImg  src={require('../../assets/images/editicon.png')} onClick={()=> handleAirlineActions(index,"edit")} />
                            <IconImg className='ml-3' src={require('../../assets/images/trashicon.png')} onClick={()=> handleAirlineActions(index,"remove")} />
                          </ButtonSection>
                        </ContentRow>
                        { formik.values.origin_services?.length ? 
                          <ContentRow className='mt-0'>
                            <DestServiceSection className='mt-1'>
                              <LableText>Origin Services</LableText>
                              
                                  {formik.values.origin_services.map((i,index)=> 
                                    <DestDetails className='mb-2' key={index}>{i.origin_charge} &nbsp;&nbsp;&nbsp; {i.origin_currency} {Number(i.origin_exchange_rate).toFixed(2)} &nbsp;&nbsp; {Number(i.origin_amount_inr).toFixed(2)} &nbsp;&nbsp; ₹ {Number(i.origin_total_amount_inr).toFixed(2)}</DestDetails>
                                  )}
                                  
                            </DestServiceSection>
                          </ContentRow>
                          : null
                        }
  
                        { formik.values.destination_services?.length ? 
                          <ContentRow className='mt-0'>
                            <DestServiceSection className='mt-1'>
                              <LableText>Destination Services</LableText>
                                  {formik.values.destination_services.map((i,index)=> 
                                    <DestDetails className='mb-2' key={index}>{i.destination_charge} &nbsp;&nbsp;&nbsp; {i.currency} {Number(i.exchange_rate).toFixed(2)} &nbsp;&nbsp; {Number(i.amount_inr).toFixed(2)} &nbsp;&nbsp; ₹ {Number(i.total_amount_inr).toFixed(2)}</DestDetails>
                                  )}
                            </DestServiceSection>
                          </ContentRow>
                        : null
                        }
                      </>
                      )
                    : null}
                    
                  <Seperator></Seperator>
                  </>
                  }
            </ServiceSection>
          : null}
        </LeftSection>
  
  
          <SummaryContainer>
            <ContentHead title="Query Summary" pb="20px" pt="10px" fsz="16px" ml="0px" is_back_icon={false} border={false} class="summary-head" name="view_page" />
            <SummaryContent>
                <TopContent>
                  {state?.searchQuery?.cargoType && 
                    <ContentRow className='summary'>
                      <LableText className='summary'>Cargo Type</LableText>
                      <LableText className='summary-value'>{state?.searchQuery?.cargoType}</LableText>
                    </ContentRow>
                  }
                  
                  <ContentRow className='summary'>
                    <LableText className='summary'>Origin Airport</LableText>
                    <LableText className='summary-value'>{state?.searchQuery?.originAirport?.origin}</LableText>
                  </ContentRow>
                  {state?.searchQuery?.originAirport?.originPickup === "1" && state?.searchQuery?.originAirport?.originLocation && 
                    <ContentRow className='summary'>
                      <LableText className='summary'>Origin Location</LableText>
                      <LableText className='summary-value'>{state?.searchQuery?.originAirport?.originLocation}</LableText>
                    </ContentRow>
                  }
                  
                  {
                      state?.searchQuery?.originAirport?.originPickup === "1" &&
                      <SubTitleDiv>
                        <SubheadTitle>Require pickup from origin location</SubheadTitle>
                      </SubTitleDiv>
                  }
                  
                  <ContentRow className='summary'>
                    <LableText className='summary'>Destination Airport</LableText>
                    <LableText className='summary-value'>{state?.searchQuery?.destinationAirport?.destination}</LableText>
                  </ContentRow>
                  {state?.searchQuery?.destinationAirport?.destinationPickup === "1" && state?.searchQuery?.destinationAirport?.destinationLocation && 
                    <ContentRow className='summary'>
                      <LableText className='summary'>Destination Location</LableText>
                      <LableText className='summary-value'>{state?.searchQuery?.destinationAirport?.destinationLocation}</LableText>
                    </ContentRow>
                  }
                  
                  {
                      state?.searchQuery?.destinationAirport?.destinationPickup === "1" &&
                      <SubTitleDiv>
                        <SubheadTitle>Require dropoff at destination</SubheadTitle>
                      </SubTitleDiv>
                  }
                  
                  <SubTitleDiv className='head'>
                    <SubheadTitle className='head'>Goods Details</SubheadTitle>
                  </SubTitleDiv>
                  <ContentRow className='summary'>
                    <LableText className='summary'>Cargo Ready Date</LableText>
                    <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.cargoreadydate}</LableText>
                  </ContentRow>
                  <ContentRow className='summary align-start'>
                    <LableText className='summary'>Commodity Type</LableText>
                    <div>
                      <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.commonditytype}</LableText>
                      <br />
                      {state?.searchQuery?.goodsDetails?.commonditytype?.commonditytype === 'General Cargo' ? 
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.generalcargo?.generalgoods}</LableText>
                      :
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.generalcargo?.commonditytypesub}</LableText>
                      }
                    </div>
                  </ContentRow>
                  {state?.searchQuery?.goodsDetails?.commonditytype?.generalcargo?.commonditytypesub === 'Dangerous Goods' && 
                    <>
                      <ContentRow className='summary'>
                        <LableText className='summary'>HS Code</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.dangerousgoods?.DGhscode}</LableText>
                      </ContentRow>
                      <ContentRow className='summary'>
                        <LableText className='summary'>Class Description</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.dangerousgoods?.DGclassdescription}</LableText>
                      </ContentRow>
                      <ContentRow className='summary'>
                        <LableText className='summary'>Packing Groups</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.dangerousgoods?.DGpackinggroup}</LableText>
                      </ContentRow>
                    </>
                  }
  
                  {
                      state?.searchQuery?.goodsDetails?.commonditytype?.commonditytypesub === 'Temperature Controlled / Pharma' &&
                      <>
                          <ContentRow className='summary'>
                            <LableText className='summary'>Temperature Control Type</LableText>
                            <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.temperaturecontrolled?.TCtempereaturecontroledtype}</LableText>
                          </ContentRow>
                          <ContentRow className='summary'>
                            <LableText className='summary'>Temperature Control Range</LableText>
                            <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.temperaturecontrolled?.TCtemperaturecontrolrange}</LableText>
                          </ContentRow>
                      </>
                  }
                  {
                      state?.searchQuery?.goodsDetails?.commonditytype?.commonditytypesub === 'Other Special Commodity' &&
                      <ContentRow className='summary'>
                        <LableText className='summary'>Other Special Commodity Type</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.goodsDetails?.commonditytype?.specialcargo?.specialcommodity?.OSotherspecialcommoditytype}</LableText>
                      </ContentRow>
                  }
                  
                  
                </TopContent>
                <BottomContent>
                    {state?.searchQuery?.cargoType === "Ocean Cargo" ?
                    <>
                    <SubTitleDiv className='head'>
                        <SubheadTitle className='head'>Container Details</SubheadTitle>
                      </SubTitleDiv>
                      {state?.searchQuery?.containerDetails?.containerDetails?.map((i)=> 
                      <>
                            <TopContent className='bottom'>
                              <ContentRow className='summary'>
                                <LableText className='summary'>Type</LableText>
                                <LableText className='summary-value'>{i.containerType}</LableText>
                              </ContentRow>
                              <ContentRow className='summary'>
                                <LableText className='summary'>Sub Type</LableText>
                                <LableText className='summary-value'>{i.containerSubType}</LableText>
                              </ContentRow>
                              <ContentRow className='summary'>
                                <LableText className='summary'>Weight</LableText>
                                <LableText className='summary-value'>{i.cargoWeight}</LableText>
                              </ContentRow>
                              <ContentRow className='summary'>
                                <LableText className='summary'>Count</LableText>
                                <LableText className='summary-value'>{i.containerCount}</LableText>
                              </ContentRow>
                              <ContentRow className='summary'>
                                <LableText className='summary'>Size</LableText>
                                <LableText className='summary-value'>{i.containerSize}</LableText>
                              </ContentRow>
                              
                            </TopContent>
                            </>
                      )}
                      </>
                    : 
                      <>
                        <SubTitleDiv className='head'> 
                          <SubheadTitle className='head'>Packaging Details</SubheadTitle>
                        </SubTitleDiv>
                        {state?.searchQuery?.packagingDetails?.packagingDetailsBy === 'Shipment Total'?
                          <TopContent className='bottom'>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Units</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalunits? state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalunits : '0.00'} units</LableText>
                            </ContentRow>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Weight</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalweight? state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalweight : '0.00'} KG</LableText>
                            </ContentRow>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Volume</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalvolume? state?.searchQuery?.packagingDetails?.shipmentTotal[0]?.totalvolume : '0.00'} CBM</LableText>
                            </ContentRow>
                          </TopContent>
                        : 
                          <TopContent className='bottom'>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Units</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.packagingType?.totalunits? state?.searchQuery?.packagingDetails?.packagingType?.totalunits : '0.00'} units</LableText>
                            </ContentRow>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Weight</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.packagingType?.totalweight? state?.searchQuery?.packagingDetails?.packagingType?.totalweight : '0.00'} KG</LableText>
                            </ContentRow>
                            <ContentRow className='summary'>
                              <LableText className='summary'>Volume</LableText>
                              <LableText className='summary-value'>{state?.searchQuery?.packagingDetails?.packagingType?.totalvolume? state?.searchQuery?.packagingDetails?.packagingType?.totalvolume : '0.00'} CBM</LableText>
                            </ContentRow>
                          </TopContent>
                        }
                      </>
                    }
                    
                    <SubTitleDiv className='head'>
                      <SubheadTitle className='head'>Payment Terms</SubheadTitle>
                    </SubTitleDiv>
                    <TopContent className='bottom'>
                      <ContentRow className='summary'>
                        <LableText className='summary'>Payment Type</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.paymentTerms?.paymentType}</LableText>
                      </ContentRow>
                      <ContentRow className='summary'>
                        <LableText className='summary'>Incoterms</LableText>
                        <LableText className='summary-value'>{state?.searchQuery?.paymentTerms?.incoTerms}</LableText>
                      </ContentRow>
                    </TopContent>
                </BottomContent>
                
            </SummaryContent>
          </SummaryContainer>
      </MainContainer>
    )
  }
  
}

export default ViewPage

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
`

const CurrencyDiv =styled.div`
  display: flex;
  gap: 10px;
`

const AirLineDiv = styled.div`
  display: flex;
  gap: 40px;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
`

const DestServiceSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const DestDetails = styled.span`
  background: rgba(255, 175, 0, 0.2);
  border-radius: 4px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #242424;
  padding: 2px 5px;
  
`
const ItemSelect = styled(Select)`

    & .ant-select-selector {
        border-radius: ${({bradius}) => bradius? bradius : "4px !important;"};
        padding-left: ${({ is_icon })=> is_icon ? "20px !important": 'unset'};
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    & .ant-select-selection-placeholder {
        color: #B5B5C3 !important;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Roboto';
        font-style: normal;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important;
    }
`

const RightDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 67%;
  &.sell-rate {
    width: 65%;
  }
  &.remarks {
    width: 34%;
  }
`
const ErrorMsg = styled.span`
  color: red;
  font-size: 13px;
`