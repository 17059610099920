import React, {useEffect, useState} from 'react'
import styled, { ThemeConsumer } from 'styled-components'
import { CustomeButton, LeftHead, SubHeadContainer, SubHeadTitle, TextInputItem } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'
import { AirlineContainer, AmountDiv, BottomContent, ButtonSection, ContentRow, ContentSection, ExpirySection, LableText, MainContainer, QuoteContainer, Seperator, ServiceHead, ServiceHeadContainer, ServiceSection, SpinContainer, SubheadTitle, SubTitleDiv, SummaryContainer, SummaryContent, TextAreaItem, TopContent } from '../../StyledComponents/Queries'
import AddButton from '../Common/AddButton'
import ContentHead from '../Common/ContentHead'
import CustomSelectItem from '../Common/CustomSelectItem'
import InputWithSelect from '../Common/InputWithSelect'
import SelectItem from '../Common/SelectItem'
import SubHeading from '../Common/SubHeading'
import SubmitButton from '../Common/SubmitButton'
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { send_query_rate, view_query } from '../../store/Queries/QuerySlice'
import QuerySelectItem from './QuerySelectItem'
import { airlines_list, shipment_list, updatenotificationdata } from '../../store/Common/CommonSlice'
import { Select, Spin } from 'antd'
import { QueryVASTypePage } from './QueryVASTypePage'
import DateInput from '../Common/DateInput'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs';
import { convertDateStringToDateObject, formatDate, GetExchangeRate, handleKeyPress } from '../../functions/Utils'
import axios from 'axios'
import CustomSelectOption from '../Common/CustomSelectOption'
import { useLocation } from 'react-router-dom'
import { QueryOceanTypePage } from './QueryOceanTypePage'
import CustomSelectContainer from '../Common/CustomeSelectContainer'
import InputWithSelectContainer from '../Common/InputWithSelectContainer'
import ContainerDate from '../Common/ContainerDate'
import SummaryShip from './SummaryShip'
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime'


const { Option } = Select;

const initialValues = {
  destination_charge: null,
  per_unit: null,
  currency: '',
  exchange_rate: '',
  amount_inr: '',
  total_amount_inr: '',
  destination_services: [],
  origin_charge: null,
  origin_per_unit: null,
  origin_currency: '',
  origin_exchange_rate: '',
  origin_amount_inr: '',
  origin_total_amount_inr: '',
  origin_services: [],
  airline: null,
  airline_code: '',
  sell_rate: '',
  remarks: '',
  sell_rate_cuurency: '',
  exchange_sell_rate: '',
  sell_rate_inr: '',
  shipline_list: [],
  container_currency: '',
  container_exchange_rate: '',
  container_amount: '',
  container_amount_inr: '',
  container_calculated: '',
  validity: null,
  validity_terms: "",
  remarks: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  message: Yup.string().required('Required'),
});

const onSubmit = (values, { setSubmitting }) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  }, 400);
};

function OceanViewPage(props) {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    destination_charges: [],
    origin_charges: [],
    shipline_list: [],
    searchQuery: {},
    output: [],
    calculated_rate: 0,
    quoteExpiryDate: null,
    rateNulledOutput: [],
    nulledAirline: null,
    nulledSellRate: 0,
    originChargeList: [],
    destinationChargeList: [],
    status: "New",
    destination_sum: 0,
    origin_sum: 0,
    get_calculated_rate: false,
    current_index: "",
    container_details: [],
    origin_quoted: false,
    destination_quoted: false,
    container_calculated: 0,
    toBeQuotedContainer_details: [],
    reset_container_details: false,
    container_action: "",
    is_addOCL: false,
    isIncoTermEmpty: false,
    show_calculatedRate: false,
    editContainerIdx: null
  })
  const [editIndex, setEditIndex] = useState(null)
  const [editOriginIndex, setEditOriginIndex] = useState(null)
  const [editAirlineIndex, setEditAirlineIndex] = useState(null)
  const [ShowPage, setShowPages] = useState(props.instance.status == 'New' ? "first_page" : "second_page")
  const [is_addRate, setIsAddRate] = useState("add_rate")
  const [loading, setLoading] = useState(true)
  const [is_vas_save, setVasSave] = useState(false)
  const [get_vas_sum, setVasSum] = useState(false)
  const token = localStorage.getItem('accessToken');
  const [exchangeRateList, setExchangeRateList] = useState([])
  const [isOutput, setIsOutput] = useState(true)
  const [shipper_list, setShipperList] = useState([])
  const [shipperLine, setShipperLine] = useState("")
  const [is_lcl_save, setlclSave] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit,
  });

  const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/queries'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL_NODE}admin/getexchangerates`,
        { headers: { Authorization: `Bearer ${token}`,"Content-Type": "application/json" } }
        );
        if(result.status === 200){
          setExchangeRateList(result.data?.data)
        }
      } catch (error) {
          console.log(error);
      }
    };

    fetchData();
  }, []);
  

  useEffect(()=>{
    let data = {
      queryId: props.instance.queryId,
      type: props.instance.queryType,
      status: props.instance.status,
    }
    dispatch(view_query(data))
    .then(res=>{
        // console.log('login res => ',res);
        let output = res?.payload?.data?.nodeData?.result?.output
        let quoteExpiryDate = res?.payload?.data?.nodeData?.result?.queryExpiryDate
        if(quoteExpiryDate){
          quoteExpiryDate = dayjs(new Date(quoteExpiryDate))
        }
        let rateNulledOutput = []
        let totalVAS = 0
        let totalFreightRate = 0
        let shipline_list = []
        let is_addOCL = false
        let isIncoTermEmpty = false
        let show_calculatedRate =false
        if(output?.length){
          output.map((i)=> {
            if(i.line){
              shipline_list.push(i.line)
            }
            if(props.instance.status != 'New'){
              totalVAS = i.totalVAS
              totalFreightRate = i.totalFreightRate
              handleSaveLCLContainerRate()
            }else {
              i.remarks = ""
            }
            i.rate = i.totalShipmentRate
            if(i.USD_totalShipmentRate === "To be quoted"){
              is_addOCL = true
            }
          })
        }else {
          dispatch(shipment_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.status === 200 ){
                  let data = res?.payload?.data?.data?.data
                  const shippers = data.map(item => item.shipper_name).filter(shipper_name => shipper_name !== '');
                  setShipperList(data)
                  // setState((prevState)=> {
                  //   return {
                  //     ...prevState,
                  //     shipline_list: shippers
                  //   }
                  // })
                }
            })
            .catch(err=>{
                console.error(err);
            })
          setIsOutput(false)
          let IncoTerms = res?.payload?.data?.nodeData?.result?.IncoTerms
          let containerDetails = res?.payload?.data?.nodeData?.result?.searchQuery?.containerDetails?.containerDetails
          let USD_totalVAS = 0
          let USD_totalShipmentRate = 0
          containerDetails.map((i)=> {
            if(!i?.freight){
              i.freight = "To be quoted"
            }else if(Number(i.freight)) {
              USD_totalShipmentRate += Number(i.freight)
            }
            if(!i?.totalRate){
              i.totalRate = "To be quoted"
            } 
            if(!i?.validity){
              i.validity = "To be quoted"
            } 
            if(!i?.validity_terms){
              i.validity_terms = "To be quoted"
            } 
            if(!i?.remarks){
              i.remarks = "To be quoted"
            } 
          })
          let originCharges = IncoTerms.originCharges
          let destinationCharges = IncoTerms.destinationCharges
          if(originCharges && originCharges.length && originCharges[0].originValue != 'To be quoted'){
            let USD_totalVAS_origin = originCharges.reduce((accumulator, obj) => accumulator + obj.originValue, 0);
            USD_totalVAS += Number(USD_totalVAS_origin)
          }
          if(destinationCharges && destinationCharges.length && destinationCharges[0].destinationValue != 'To be quoted'){
            let USD_totalVAS_destination = destinationCharges.reduce((accumulator, obj) => accumulator + obj.destinationValue, 0);
            USD_totalVAS += Number(USD_totalVAS_destination)
          }
          if(USD_totalVAS === 0){
            USD_totalVAS = "To be quoted"
          }
          if(USD_totalShipmentRate === 0){
            USD_totalShipmentRate = "To be quoted"
          }
          let USD_totalFreightRate = "To be quoted"
          if(USD_totalVAS !== "To be quoted" && USD_totalShipmentRate !== "To be quoted"){
            USD_totalFreightRate = Number(USD_totalVAS) + Number(USD_totalShipmentRate)
          }

          let searchQuery = res?.payload?.data?.nodeData?.result?.searchQuery
          let originPortFull = searchQuery?.originPortFull
          let originPortShort = searchQuery?.originPortShort
          let destinationPortFull = searchQuery?.destinationPortFull
          let destinationPortShort = searchQuery?.destinationPortShort
          let totalWeight = searchQuery?.totalWeight
          if(!USD_totalVAS){
            USD_totalVAS = "To be quoted"
          }
          if(USD_totalShipmentRate === "To be quoted"){
            is_addOCL = true
          }
          if (Object.keys(IncoTerms).length === 0) {
            USD_totalVAS = 0
            isIncoTermEmpty = true
          }
         
          output.push({
            containerDetails: containerDetails,
            individualVASRate: IncoTerms,
            USD_totalVAS: USD_totalVAS,
            USD_totalShipmentRate: USD_totalShipmentRate,
            USD_totalFreightRate: USD_totalFreightRate,
            originPortFull:originPortFull,
            originPortShort:originPortShort,
            destinationPortFull:destinationPortFull,
            destinationPortShort: destinationPortShort,
            totalWeight:totalWeight
          })
        }
        if(res?.payload?.status === 200 ){
            // const origin_services = res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges
            // const destination_services = res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges
            let valued_origin_services = []
            let valued_destination_services = []
            let origin_services = []
            let destination_services = []
            let origin_quoted = false
            let destination_quoted = false
            if(res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges && res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges[0]?.originValue === 'To be quoted'){
              origin_services = res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges
              show_calculatedRate = true
            }else {
              let transportaion = res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges?.filter((i)=> i.originName === 'Origin Transportation' && (i.originValue == null || i.originValue == 'To be quoted'))
              if(transportaion && transportaion.length){
                origin_services = transportaion
                show_calculatedRate = true
              }
              res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges?.map((i)=> {
                if(i.originValue !== 'To be quoted' && i.originValue != null){
                  valued_origin_services.push({
                    origin_charge: i.originName,
                    origin_amount_inr: i.originValue,
                    origin_total_amount_inr: i.INR_originValue,
                    origin_quoted: true
                  })
                }
              })
              // else {
              //   res?.payload?.data?.nodeData?.result?.IncoTerms?.originCharges?.map((i)=> {
              //     valued_origin_services.push({
              //       origin_charge: i.originName,
              //       origin_amount_inr: i.originValue,
              //       origin_total_amount_inr: i.INR_originValue,
              //       origin_quoted: true
              //     })
              //   })
              //   // origin_quoted = true
              // }
            }
            if(res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges && res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges[0]?.destinationValue === 'To be quoted'){
              destination_services = res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges
              show_calculatedRate = true
            }else {
              let transportaion = res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges?.filter((i)=> i.destinationName === 'Destination Transportation' && (i.destinationValue == null || i.destinationValue == 'To be quoted'))
              if(transportaion && transportaion.length){
                destination_services = transportaion
                show_calculatedRate = true
              }
              res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges?.map((i)=> {
                if(i.destinationValue !== 'To be quoted' && i.destinationValue != null){
                  valued_destination_services.push({
                    destination_charge: i.destinationName,
                    amount_inr: i.destinationValue,
                    total_amount_inr: i.INR_destinationValue,
                    destination_quoted: true
                  })
                }
              })
              // else {
              //   res?.payload?.data?.nodeData?.result?.IncoTerms?.destinationCharges?.map((i)=> {
              //     valued_destination_services.push({
              //       destination_charge: i.destinationName,
              //       amount_inr: i.destinationValue,
              //       total_amount_inr: i.INR_destinationValue,
              //       destination_quoted: true
              //     })
              //   })
              //   // destination_quoted = true
              // }
            }
            formik.setValues({ ...formik.values,origin_services:valued_origin_services,destination_services: valued_destination_services })

            let destination_sum = 0
            let origin_sum = 0
            if(destination_services?.length){
              destination_sum = destination_services?.reduce((sum, charge) => sum + Number(charge.destinationValue), 0);
            }
            if(origin_services?.length){
              origin_sum = origin_services?.reduce((sum, charge) => sum + Number(charge.originValue), 0);
            }
            let container_details = output[0]?.containerDetails?.filter((i)=> i.freight === 'To be quoted').map(item => ({...item}));
            
            if(isIncoTermEmpty){
              show_calculatedRate = false
            }
            setState((prevState) => {
              return {
                ...prevState,
                destination_charges: destination_services,
                origin_charges: origin_services,
                searchQuery: res?.payload?.data?.nodeData?.result?.searchQuery,
                output: output,
                shipline_list,
                rateNulledOutput,
                originChargeList: origin_services,
                destinationChargeList: destination_services,
                status: props.instance.status,
                quoteExpiryDate,
                destination_sum,
                origin_sum,
                container_details,
                origin_quoted,
                destination_quoted,
                toBeQuotedContainer_details: container_details,
                is_addOCL,
                isIncoTermEmpty,
                show_calculatedRate
              }
            })
            
            
            setLoading(false)
        }
    })
    .catch(err=>{
        console.error(err);
    })
    
  },[props.instance])


  useEffect(()=> {
    if(state.reset_container_details === true){
      let container_details = [...state.container_details]
      let updatedList = container_details.map(obj => ({ ...obj,container_amount: "", container_amount_inr: "",freight: "To be quoted", totalRate: "To be quoted", validity: null,validity_terms: "",remarks: "" }));
      // container_details.map((c,index)=> {
      //   c.container_amount = ""
      //   c.container_amount_inr = ""
      //   c.container_currency = ""
      //   c.container_exchange_rate = ""
      //   c.freight = "To be quoted"
      //   c.totalRate = "To be quoted"
      //   c.validity = null
      //   c.validity_terms = ''
      //   c.remarks = ''
      // })
      setState((prevState)=> {
        return {
          ...prevState,
          container_details: updatedList,
          reset_container_details: false
        }
      })
    }
},[state.reset_container_details === true && state.output])

  useEffect(()=>{
      if(formik.values.amount_inr && formik.values.exchange_rate){
        let total_amount_inr = Number(formik.values.exchange_rate) * Number(formik.values.amount_inr)
        formik.setValues({ ...formik.values, total_amount_inr : total_amount_inr })
      }
  },[formik.values.amount_inr, formik.values.exchange_rate])

  useEffect(()=>{
    if(formik.values.origin_amount_inr && formik.values.origin_exchange_rate){
      let origin_total_amount_inr = Number(formik.values.origin_exchange_rate) * Number(formik.values.origin_amount_inr)
      formik.setValues({ ...formik.values, origin_total_amount_inr : origin_total_amount_inr })
    }
  },[formik.values.origin_amount_inr, formik.values.origin_exchange_rate])

  useEffect(()=>{
    if(formik.values.sell_rate_inr && formik.values.exchange_sell_rate){
      let sell_rate = Number(formik.values.exchange_sell_rate) * Number(formik.values.sell_rate_inr)
      formik.setValues({ ...formik.values, sell_rate : sell_rate })
    }
  },[formik.values.sell_rate_inr])

  useEffect(()=>{
    if(state.get_calculated_rate === true){
      let container_detail = state.container_details[state.current_index]
      if(container_detail?.container_exchange_rate && container_detail?.containerCount){
        let container_amount = 0
        if(container_detail.container_amount){
          container_amount = container_detail.container_amount
        }
        let total_amount_inr = Number(container_detail.container_exchange_rate) * Number(container_amount)
        let container_amount_inr = Number(total_amount_inr) * Number(container_detail.containerCount)
        container_detail.container_amount_inr = container_amount_inr
        setState((prevState)=> {
          return {
            ...prevState,
            container_amount_inr,
            get_calculated_rate: false
          }
        })
      }
    }
},[state.get_calculated_rate])
  

  useEffect(()=>{
   if(get_vas_sum){
    let destination_services = [...formik.values.destination_services]
    let origin_services = [...formik.values.origin_services]
    const destination_sum = destination_services.reduce((sum, charge) => sum + Number(charge.amount_inr), 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + Number(charge.origin_amount_inr), 0);
    const calculated_rate = Number(destination_sum) + Number(origin_sum)
    setVasSum(false)
    setState((prevState)=> {
      return {
        ...prevState,
        calculated_rate
      }
    })
   }
},[get_vas_sum])

  const handleSelectChange = (e,name)=> {
    // formik.setValues([name], e);
    let exchange_rate = formik.values.exchange_rate
    let origin_exchange_rate = formik.values.origin_exchange_rate
    let exchange_sell_rate = formik.values.exchange_sell_rate
    let container_exchange_rate = formik.values.container_exchange_rate
    if(name === 'origin_currency'){
      origin_exchange_rate = GetExchangeRate(exchangeRateList,e)
    }else if(name === 'currency'){
      exchange_rate = GetExchangeRate(exchangeRateList,e)
    }else if(name === 'sell_rate_cuurency'){
      exchange_sell_rate = GetExchangeRate(exchangeRateList,e)
    }else if(name === 'container_currency'){
      container_exchange_rate = GetExchangeRate(exchangeRateList,e)
    }
    formik.setValues({ ...formik.values, [name]: e,origin_exchange_rate,exchange_rate,exchange_sell_rate,container_exchange_rate })
    
  }

  const handleSelectChangeContainer = (e,name,index)=> {
    let container_detail = state.container_details[index]
    // formik.setValues([name], e);
    let container_exchange_rate = container_detail.container_exchange_rate
    if(name === 'container_currency'){
      container_exchange_rate = GetExchangeRate(exchangeRateList,e)
    }
    container_detail.container_currency = e
    container_detail.container_exchange_rate = container_exchange_rate
    setState((prevState)=> {
      return {
        ...prevState,
        container_detail
      }
    })
  }

  const handleSelectChangeLCL = (e,name,index)=> {
    // formik.setValues([name], e);
    if(name === 'container_currency'){
      let container_exchange_rate = GetExchangeRate(exchangeRateList,e)
      formik.setValues({ ...formik.values, [name]: e,container_exchange_rate })
    }else {
      formik.setValues({ ...formik.values, [name]: e })
    }
  }

  const handleInputChangeLCL = (e,index)=> {
    const { name, value } = e.target
    if(name === "container_amount"){
      let val = 0
      let container_exchange_rate = 0
      if(value){
        val = value
      }
      if(formik.values.container_exchange_rate){
        container_exchange_rate = formik.values.container_exchange_rate
      }
      let container_amount_inr = Number(val) * Number(container_exchange_rate)
      formik.setValues({ ...formik.values, [name]: value,container_amount_inr })
    }else {
      formik.setValues({ ...formik.values, [name]: value })
    }
    // formik.setValues([name], e);
  }


  const handleInputChangeContainer = (e,index)=> {
    const { name, value } = e.target
    let container_detail = [...state.container_details]
    container_detail[index][name] = value
    let get_calculated_rate = true
    if(name === 'validity_terms' || name === 'remarks'){
      get_calculated_rate = false
    }
    setState((prevState)=> {
      return {
        ...prevState,
        container_details: container_detail,
        current_index: index,
        get_calculated_rate
      }
    })
    // formik.setValues([name], e);
  }


  const handleInputChange = (e)=> {
    const { name, value } = e.target
    // formik.setValues([name], e);
    formik.setValues({ ...formik.values, [name]: value })
  }
  const handleAddDestinationService = ()=> {
    let destination_services = [...formik.values.destination_services]
    if(editIndex != null){
      destination_services[editIndex]["destination_charge"] = formik.values.destination_charge
      destination_services[editIndex]["per_unit"] = formik.values.per_unit
      destination_services[editIndex]["currency"] = formik.values.currency
      destination_services[editIndex]["exchange_rate"] = formik.values.exchange_rate
      destination_services[editIndex]["amount_inr"] = formik.values.amount_inr
      destination_services[editIndex]["total_amount_inr"] = formik.values.total_amount_inr
    }else {
      let dict = {
        destination_charge: formik.values.destination_charge,
        per_unit: formik.values.per_unit,
        currency: formik.values.currency,
        exchange_rate: formik.values.exchange_rate,
        amount_inr: formik.values.amount_inr,
        total_amount_inr: formik.values.total_amount_inr,
      }
      destination_services.push(dict)
    }
    setEditIndex(null)
    setVasSum(true)
    formik.setValues({ ...formik.values,
      destination_services: destination_services,
      destination_charge: null,
      per_unit: null,
      currency: "₹",
      exchange_rate: "",
      amount_inr: "",
      total_amount_inr: "",
    })
    
    const destinationChargeList = state.destinationChargeList?.filter((elemA) => {
      let foundMatch = destination_services?.some((elemB) => elemB.destination_charge === elemA.destinationName);
      let result = []
      if(foundMatch){
        result = !foundMatch
      }
      return result;
    });
    setState((prevState) => {
      return {
        ...prevState,
        destinationChargeList
      }
    })
  }


  const handleAddOriginService = ()=> {
    let origin_services = [...formik.values.origin_services]
    if(editOriginIndex != null){
      origin_services[editOriginIndex]["origin_charge"] = formik.values.origin_charge
      origin_services[editOriginIndex]["origin_per_unit"] = formik.values.origin_per_unit
      origin_services[editOriginIndex]["origin_currency"] = formik.values.origin_currency
      origin_services[editOriginIndex]["origin_exchange_rate"] = formik.values.origin_exchange_rate
      origin_services[editOriginIndex]["origin_amount_inr"] = formik.values.origin_amount_inr
      origin_services[editOriginIndex]["origin_total_amount_inr"] = formik.values.origin_total_amount_inr
    }else {
      let dict = {
        origin_charge: formik.values.origin_charge,
        origin_per_unit: formik.values.origin_per_unit,
        origin_currency: formik.values.origin_currency,
        origin_exchange_rate: formik.values.origin_exchange_rate,
        origin_amount_inr: formik.values.origin_amount_inr,
        origin_total_amount_inr: formik.values.origin_total_amount_inr,
      }
      origin_services.push(dict)
    }
    setEditOriginIndex(null)
    setVasSum(true)
    formik.setValues({ ...formik.values,
      origin_services: origin_services,
      origin_charge: null,
      origin_per_unit: null,
      origin_currency: "₹",
      origin_exchange_rate: "",
      origin_amount_inr: "",
      origin_total_amount_inr: "",
    })

    const originChargeList = state.originChargeList?.filter((elemA) => {
      let foundMatch = origin_services?.some((elemB) => elemB.origin_charge === elemA.originName);
      let result = []
      if(foundMatch){
        result = !foundMatch
      }
      return result;
    });
    setState((prevState) => {
      return {
        ...prevState,
        originChargeList
      }
    })
  }

  const handleDestinationChargeActions = (index,action) => {
        let destination_services = [...formik.values.destination_services]
        let destinationChargeList = [...state.destinationChargeList]
        destinationChargeList.push({
          destinationName: destination_services[index]["destination_charge"],
          destinationValue: "To be quoted"
        })
        if(action === 'edit'){
          setEditIndex(index)
          formik.setValues({ ...formik.values,
            destination_charge: destination_services[index]["destination_charge"],
            per_unit: destination_services[index]["per_unit"],
            currency: destination_services[index]["currency"],
            exchange_rate: destination_services[index]["exchange_rate"],
            amount_inr: destination_services[index]["amount_inr"],
            total_amount_inr: destination_services[index]["total_amount_inr"],
           })
        }else if (action === 'remove') {
          destination_services.splice(index,1)
          formik.setValues({ ...formik.values, destination_services: destination_services })
        }
        setState((prevState)=> {
          return {
            ...prevState,
            destinationChargeList
          }
        })
  }

  const handleOriginChargeActions = (index,action) => {
    let origin_services = [...formik.values.origin_services]
    let originChargeList = [...state.originChargeList]
    originChargeList.push({
      originName: origin_services[index]["origin_charge"],
      originValue: "To be quoted"
    })
    if(action === 'edit'){
      setEditOriginIndex(index)
      formik.setValues({ ...formik.values,
        origin_charge: origin_services[index]["origin_charge"],
        origin_per_unit: origin_services[index]["origin_per_unit"],
        origin_currency: origin_services[index]["origin_currency"],
        origin_exchange_rate: origin_services[index]["origin_exchange_rate"],
        origin_amount_inr: origin_services[index]["origin_amount_inr"],
        origin_total_amount_inr: origin_services[index]["origin_total_amount_inr"],
       })
    }else if (action === 'remove') {
      origin_services.splice(index,1)
      formik.setValues({ ...formik.values, origin_services: origin_services })
    }
    setState((prevState)=> {
      return {
        ...prevState,
        originChargeList
      }
    })
}

const handleViewActions = (index,action) => {
  let output = [...state.output]
  let shipperline = output[index]["line"]
  let shipline_list = [...state.shipline_list,shipperline]
  let container_details = state.container_details
  if(action === 'edit' && state.searchQuery?.containerLoadType === "LCL"){
    let USD_totalShipmentRate = output[index]["USD_totalShipmentRate"]
    let totalShipmentRate = output[index]["totalShipmentRate"]
    formik.setValues({ ...formik.values, container_amount: USD_totalShipmentRate,container_amount_inr: totalShipmentRate })
    setlclSave(false)
    setState((prevState)=> {
      return {
        ...prevState,
        shipperline,
        shipline_list
      }
    })
  }else if(action === 'edit' && state.searchQuery?.containerLoadType === "FCL"){
    setState((prevState)=> {
      return {
        ...prevState,
        shipperline,
        shipline_list,
        container_details: output[index]["containerDetails"]
      }
    })
  }else {
    setState((prevState)=> {
      return {
        ...prevState,
        reset_container_details: true,
      }
    })
  }
  
  setShowPages("first_page")
}

const handleContainerChangeActions = (index,action,output_index) => {
  let output = [...state.output]
  let shipperline = output[output_index].line
  let containerDetails = output[output_index].containerDetails.filter((i)=> i.added === true)[index]
  let shipline_list = [shipperline]
  if(action === 'edit'){
    setState((prevState)=> {
      return {
        ...prevState,
        shipperline,
        container_details: [containerDetails],
        shipline_list,
        output,
        container_action: action,
        editContainerIdx: index
      }
    })
    
  }else if (action === 'remove') {
    let shipline_list = [...state.shipline_list]
    shipline_list.push(containerDetails.line)
    containerDetails.container_amount = ""
    containerDetails.container_amount_inr = ""
    containerDetails.container_currency = ""
    containerDetails.container_exchange_rate = ""
    containerDetails.freight = ""
    containerDetails.line = ""
    containerDetails.remarks = ""
    containerDetails.totalRate = ""
    containerDetails.totalRate_inr = ""
    containerDetails.validity = null
    containerDetails.validity_terms = null
    containerDetails.added = false
    setState((prevState)=> {
      return {
        ...prevState,
        shipline_list,
        output,
      }
    })
  }

}

  const handleDestinationServiceSave = () => {
    const origin_services = formik.values.origin_services
    const destination_services = formik.values.destination_services
    const destination_sum = destination_services.reduce((sum, charge) => sum + Number(charge.total_amount_inr), 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + Number(charge.origin_total_amount_inr), 0);
    setState((prevState)=> {
      return {
        ...prevState,
        destination_sum,
        origin_sum
      }
    })
    setShowPages("second_page")
  }

  

  const handleAddRate = ()=> {
    if(is_addRate === 'add_rate'){
      setIsAddRate("cancel")
    }else if(is_addRate === 'cancel'){
      setIsAddRate("add_rate")
    }else {
      handleSubmit()
    }
  }

  const handleServicesVAS = () => {
    let destination_services = formik.values.destination_services
    let origin_services = formik.values.origin_services
    const destination_sum_inr = destination_services.reduce((sum, charge) => sum + Number(charge.total_amount_inr), 0);
    const origin_sum_inr = origin_services.reduce((sum, charge) => sum + Number(charge.origin_total_amount_inr), 0);
    const service_sum_inr = Number(destination_sum_inr) + Number(origin_sum_inr)
    
    const destination_sum = destination_services.reduce((sum, charge) => sum + Number(charge.amount_inr), 0);
    const origin_sum = origin_services.reduce((sum, charge) => sum + Number(charge.origin_amount_inr), 0);
    const service_sum = Number(destination_sum) + Number(origin_sum)

    let output = [...state.output]
    // const totalweight = state.searchQuery?.totalWeight
    
    output.map((i)=> {
      i.USD_totalVAS = Number(service_sum)
      i.totalVAS = Number(service_sum_inr)
      // i.totalFreightRate = Number(i.rates) + Number(service_sum)

      const totalFlightCharges =  i.containerDetails?.reduce((acc, obj) => {
                                  return acc + parseInt(obj.totalRate);
                                }, 0);
      const totalFlightChargesInr =  i.containerDetails?.reduce((acc, obj) => {
                                  return acc + parseInt(obj.totalRate_inr);
                                }, 0);                      
      let totalFreightRate = Number(service_sum)
      let totalFreightRateInr = Number(service_sum_inr)
      if(totalFlightCharges){
        i.USD_totalShipmentRate = totalFlightCharges
        totalFreightRate = Number(service_sum) + Number(totalFlightCharges)
      }else {
        if(state.searchQuery?.containerLoadType === "LCL" && i.USD_totalShipmentRate){
          totalFreightRate = Number(service_sum) + Number(i.USD_totalShipmentRate)
        }
      }   
      if(totalFlightChargesInr){
        i.totalShipmentRate = totalFlightChargesInr
        totalFreightRateInr = Number(service_sum) + Number(totalFlightChargesInr)
      } else {
        if(state.searchQuery?.containerLoadType === "LCL" && i.totalShipmentRate){
          totalFreightRateInr = Number(service_sum) + Number(i.totalShipmentRate)
        }
      }   

      if(!totalFreightRate){
        totalFreightRate = 0
      }
      if(!totalFreightRateInr){
        totalFreightRateInr = 0
      }
      
      i.USD_totalFreightRate = totalFreightRate
      i.totalFreightRate = totalFreightRateInr
    })
    // setIsAddRate("send_rate")
    // setShowPages("second_page")
    setState((prevState)=> {
      return {
        ...prevState,
        output: output
      }
    })
    setVasSave(true)
  }

  const handleRemoveItem = (index) => {
    let output = [...state.output]
    let line = output[index].line
    if(output.length == 1){
      delete output[index].line
      delete output[index].shipper_name
      setIsAddRate("add_rate")
    }else {
      output.splice(index,1)
    }
    // output.splice(index,1)
    let shipline_list = state.shipline_list.filter((i)=> i !== line)
    setState((prevState)=> {
      return {
        ...prevState,
        output,
        shipline_list,
      }
    })
  }

  const handleAddRemarkVAS = (index,value) => {
    let output = [...state.output]
    output[index]["remarks"] = value
    setState((prevState)=> {
      return {
        ...prevState,
        output
      }
    })
  }

  const handleSelectShipperline = (value,option) => {
    setState((prevState)=> {
      return {
        ...prevState,
        shipperline: value,
        // reset_container_details: true
      }
    })
  }

  const ContainerDateChange = (date, dateString,index) => {
    let container_details = state.container_details
    container_details[index]["validity"] = date
    setState((prevState)=> {
      return {
        ...prevState,
        container_details
      }
    })
  };

  const handleSaveLCLContainerRate = async()=> {
    setShowPages("second_page")
    setIsAddRate("send_rate")
  }

  const handleAddLCLContainerRate = async()=> {
    const output = [...state.output]
    const shipline_output = output.filter((i)=> i.line === state.shipperline)[0]
    let shipline_list = state.shipline_list.filter((i)=> i != state.shipperline)
    let USD_totalShipmentRate = formik.values.container_amount
    let totalShipmentRate = formik.values.container_amount_inr
    let USD_totalVAS = shipline_output.USD_totalVAS
    let totalVAS = shipline_output.totalVAS
    if(!USD_totalShipmentRate){
      USD_totalShipmentRate = 0
    }
    if(!totalShipmentRate){
      totalShipmentRate = 0
    }
    if(!USD_totalVAS){
      USD_totalVAS = 0
    }
    if(!totalVAS){
      totalVAS = 0
    }
    // let USD_totalFreightRate = shipline_output.USD_totalFreightRate
    shipline_output.USD_totalShipmentRate = USD_totalShipmentRate
    shipline_output.USD_totalFreightRate = Number(USD_totalShipmentRate) + Number(USD_totalVAS)
    shipline_output.totalShipmentRate = totalShipmentRate
    shipline_output.totalFreightRate = Number(totalShipmentRate) + Number(totalVAS)
    setState((prevState)=> {
      return {
        ...prevState,
        output,
        shipline_list,
        shipperline: ""
      }
    })
    formik.setValues({ ...formik.values, container_amount: "",container_amount_inr: "" })
    if(!shipline_list.length){
      setlclSave(true)
    }
  }

  const handleAddContainerRate = async()=> {
    const output = [...state.output]
    const shipline_output = output.filter((i)=> i.line === state.shipperline)[0]
    const containerDetails = [...shipline_output.containerDetails]
    const container_details = [...state.container_details]
    let container_calculated = state.container_calculated
    let shipline_list = state.shipline_list.filter((i)=> i != state.shipperline)
    let is_required = false
    containerDetails.map((i)=> {
      let detail = [...container_details.filter((u)=> u.containerSize === i.containerSize && u.containerType === i.containerType)][0]
      if(detail){
        if(!detail.validity || detail.validity_terms === "" || detail.remarks === ""){
          is_required = true
          if(!detail.validity){
            detail.validity_required= true
          }else {
            detail.validity_required= false
          }
          if(!detail.validity_terms){
            detail.validity_terms_required= true
          }else {
            detail.validity_terms_required= false
          }
          if(!detail.remarks){
            detail.remarks_required= true
          }else {
            detail.remarks_required= false
          }
        }else {
          if(state.container_action === 'edit'){
            container_calculated = Number(container_calculated) - (Number(i.freight)* Number(i.containerCount))
          }
          let amount = detail.container_amount
          let amount_inr = detail.container_amount_inr
          let count = detail.containerCount
          i.freight = Number(amount)
          i.totalRate = Number(amount) * Number(count)
          i.totalRate_inr = Number(amount_inr) * Number(count)
          i.added = true
          i.line = state.shipperline
          container_calculated += (Number(amount) * Number(count))
          i.container_amount = amount
          i.container_amount_inr = amount_inr
          i.container_currency = detail.container_currency
          i.container_exchange_rate = detail.container_exchange_rate
          i.validity = detail.validity
          i.validity_terms = detail.validity_terms
          i.remarks = detail.remarks

          detail.validity_required= false
          detail.validity_terms_required= false
          detail.remarks_required= false
        }
      }
    })

    // container_details.map((c)=> {
    //   c.container_amount = ""
    //   c.container_amount_inr = ""
    //   c.container_currency = ""
    //   c.container_exchange_rate = ""
    //   c.freight = "To be quoted"
    //   c.totalRate = "To be quoted"
    //   c.validity = null
    //   c.validity_terms = ''
    //   c.remarks = ''
    // })
    if(is_required){
      setState((prevState)=> {
        return {
          ...prevState,
          container_details
        }
      })
    }else{
      setState((prevState)=> {
        return {
          ...prevState,
          output,
          shipperline: "",
          container_calculated,
          shipline_list,
          container_action: "",
          reset_container_details: true,
          editContainerIdx: null
        }
      })
    }
    
  }

  const handleSaveContainerRate = () => {
    setShowPages("second_page")
    let output = [...state.output]
    output.map((i)=> {
      const totalFlightCharges =  i.containerDetails?.reduce((acc, obj) => {
                                  return acc + parseInt(obj.totalRate);
                                }, 0);
      let totalFreightRate = Number(i.USD_totalVAS) + Number(totalFlightCharges)
      if(!totalFreightRate){
        totalFreightRate = 0
      }
      i.USD_totalShipmentRate = totalFlightCharges
      i.USD_totalFreightRate = totalFreightRate
    })
    setIsAddRate("send_rate")
    setState((prevState)=> {
      return {
        ...prevState,
        output: output
      }
    })
  }

  const handleSelectShipperList = (value,option) => {
    let selectObj = shipper_list.filter((i)=> i.shipper_name === value)[0]
    let shipline_list = state.shipline_list
    if(shipline_list.includes(value) === false){
      let output = [...state.output]
    let newOutput = output.filter(obj => !('line' in obj));
    if(newOutput?.length){
      newOutput[0].line = value
      newOutput[0].shipper_name = value
      newOutput[0].logo = selectObj.logoLocation
    }else {
      let firstObjectCopy = {...output[0]};
      let toBeQuotedContainer_details = state.toBeQuotedContainer_details
      firstObjectCopy.containerDetails = toBeQuotedContainer_details
      firstObjectCopy.line = value
      firstObjectCopy.shipper_name = value
      firstObjectCopy.logo = selectObj.logoLocation
      output.push(firstObjectCopy);
      newOutput = output
    }
    
    if(shipline_list.includes(value) === false){
      shipline_list.push(value)
    }
    setState((prevState)=> {
      return {
        ...prevState,
        output: newOutput,
        shipline_list
      }
    })
    }
    
  }



  const handleSubmit = () => {
    setSubmitLoading(true)
    let final_data = {}
    let output = [...state.output]
    let destination_services = formik.values?.destination_services
    let origin_services = formik.values?.origin_services

    let destinationCharges = []
    destination_services.map((i)=> {
      let destCharge = {
        destinationName: i.destination_charge,
        destinationValue: i.amount_inr
      }
      destinationCharges.push(destCharge)
    })

    let originCharges = []
    origin_services.map((i)=> {
      let orgCharge = {
        originName: i.origin_charge,
        originValue: i.origin_amount_inr
      }
      originCharges.push(orgCharge)
    })

    let individualVASRate = {
      originCharges: originCharges,
      destinationCharges: destinationCharges,
    }

    let totalweight = state?.searchQuery?.totalWeight
    let USD_val = GetExchangeRate(exchangeRateList,"USD")
    
    output.map((i)=> {
      let totalVAS = Number(i.USD_totalVAS) * Number(USD_val)
      let totalFreightRate = Number(i.USD_totalFreightRate) * Number(USD_val)
      let totalShipmentRate = Number(i.USD_totalShipmentRate) * Number(USD_val)
      i.totalVAS = totalVAS
      // i.INR_totalVAS = i.totalVAS
      i.USD_totalFreightRate = Number(i.USD_totalFreightRate).toFixed(2)
      i.totalFreightRate = Number(totalFreightRate).toFixed(2)
      // i.INR_totalFreightRate = i.totalFreightRate
      i.USD_totalShipmentRate = i.USD_totalShipmentRate
      i.totalShipmentRate = totalShipmentRate
      i.individualVASRate = individualVASRate
      i.queryQuotingStatus = "Manual"
      i.totalweight = totalweight
      let containerDetails = i.containerDetails
      if(containerDetails && containerDetails.length){
        containerDetails?.map((c)=> {
          if(c.validity){
            c.validity = formatDate(c.validity)
          }
        })
      }
    })
    final_data = {
        queryId: props.instance.queryId,
        data: output,
        quoteExpiryDate: state.quoteExpiryDate
      }
    
    dispatch(send_query_rate(final_data))
    .then(res=>{
        // console.log('login res => ',res.payload.status);
        if(res.payload.status === 200){
          let data = {
              message : res?.payload?.data?.message,
              statusCode: 200,
              success: res?.payload?.data?.success
          }
          dispatch(updatenotificationdata(data))
          props.setPageType("table")
        }
        setSubmitLoading(false)
    })
    .catch(err=>{
        console.error(err);
    })
  }


const per_unit_list = [
  {
    value: 'KG',
    label: 'KG',
  },
  {
    value: 'HAWB',
    label: 'HAWB',
  },
  {
    value: 'MAWB',
    label: 'MAWB',
  },
]




    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (value) => {
        setSearchValue(value);
    };
    
    
    const DateChange = (date, dateString) => {
      setState((prevState)=> {
        return {
          ...prevState,
          quoteExpiryDate: date
        }
      })
    };


    const handleBackArrow = () => {
      if(props.parent_type === 'user_management'){
        if(props.instance.status === 'Booked'){
          props.setCurrentTab("2")
        }else if(props.instance.status === 'Quoted'){
          props.setCurrentTab("3")
        }
        props.setQueryInstance({})
      }else if(props.parent_type === 'kam_management'){
        props.setCurrentTab("2")
        props.setQueryInstance({})
      }else {
        props.setPageType("table")
      }
    }

    // console.log(formik.values?.origin_services,"formik.values?.origin_services");
    const show_add = state.container_details?.map(item => item.container_amount_inr).every(container_amount_inr => container_amount_inr);
  if(loading){
    return (
      <SpinContainer >
        <Spin />
      </SpinContainer>
    )
  }else {
    return (
      <MainContainer >
        <LeftSection>
          <QuoteContainer onSubmit={handleSubmit} className={props.instance.queryType}>
            <ContentHead title={state.status === 'New'? "Add Quote" : "Quote Details"} setPageType={props.setPageType} handleClick={()=> handleBackArrow()} pb="20px" pt="10px" is_icon={false} is_back_icon={true}
                  is_button={state.status != 'New' ? false : true} is_add_rate={state.status === 'New'} add_title={is_addRate === 'add_rate'? "Add Rates" : is_addRate === 'cancel'? "Cancel" : "Send Rate"} loading={submitLoading} handleAddRate={() => handleAddRate()}
                />
                {/* <ExpirySection className='mt-3'>
                  <span style={{ color: '#B5B5C3' }}>Expiry Date</span>
                  <DateInput DateChange={DateChange} value={state.quoteExpiryDate} disabled={state.status != 'New'} placeholder="Expiry Date" disabledate={true} />
                </ExpirySection> */}
              {state.output[0]?.line || state.output[0]?.shipper_name?
                <AirlineContainer>
                  {state.output?.map((item,index)=> 
                    <>
                    <QueryOceanTypePage key={index} item={item} searchquery={state.searchQuery} handleRemoveItem={handleRemoveItem} index={index}
                    status={state.status} handleAddRemarkVAS={handleAddRemarkVAS} handleChange={formik.handleChange} remark_value={formik.values.remarks}
                    formik={formik} />
                    {(state.output.length -1) != index ?
                      <Seperator></Seperator>
                      : null}
                    </>
                  )}
              </AirlineContainer>
              :
              null
              }

            </QuoteContainer>
              {(is_addRate === 'cancel' || is_addRate === 'send_rate') && state.output.length ?
              <ServiceSection>
              {ShowPage === 'first_page' ?
                    <>
                    <ServiceHeadContainer>
                      {isOutput? null :
                        <ContentRow className='flex-start'>
                          <LableText className='Airline'>Shipline</LableText>
                          <ItemSelect
                                size="large"
                                placeholder="Select Shipline"
                                style={{
                                    width: 180,
                                }}
                                onChange={(value,option)=> handleSelectShipperList(value,option)}
                                value={shipperLine}
                                bradius="8px"
                              >
                                  {shipper_list?.map((i,index)=> 
                                    state?.output && state?.output.some(item => item.line === i.shipper_name)? 
                                    null
                                    :
                                      <Option key={index} value={i.shipper_name}>{i.shipper_name}</Option>
                                  )}   
                              </ItemSelect>
                        </ContentRow>
                      }
                      {state.originChargeList?.length || formik.values?.origin_services?.length ?
                        <ServiceHead>Origin Services</ServiceHead>
                      : null}
                      {formik.values?.origin_services?.length ?
                      formik.values.origin_services.map((i,index)=>
                      i.origin_quoted ?
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.origin_charge}</LableText>
                          <InputWithSelect width="32px" value={Number(i.origin_amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                          disabled_class={true} />
                        </ContentRow> :
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.origin_charge}</LableText>
                          <InputWithSelect width="32px" value={i.origin_amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                          disabled_class={true} />
                        </ContentRow>
                        )
                        
                      : 
                      null}
                      {/* {state.origin_quoted?
                        formik.values?.origin_services?.length ?
                        formik.values.origin_services.map((i,index)=>
                          <ContentRow className='mt-2 sub-head' key={index}>
                            <LableText>{i.origin_charge}</LableText>
                            <InputWithSelect width="32px" value={i.origin_amount_inr} close_icon={false} edit_icon={false} is_input={true} input_val="$"
                            first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                            disabled_class={true} />
                          </ContentRow>
                          )
                          
                        : 
                        null
                      :
                      formik.values?.origin_services?.length ?
                      formik.values.origin_services.map((i,index)=>
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.origin_charge}</LableText>
                          <InputWithSelect width="32px" value={i.origin_amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleOriginChargeActions}
                          disabled_class={is_vas_save} />
                        </ContentRow>
                        )
                        
                      : 
                      null
                      } */}
                    
                    </ServiceHeadContainer>
                  {state.originChargeList?.length ? 
                    <ContentRow>
                      <LableText>Origin Charges</LableText>
                      <QuerySelectItem value={formik.values.origin_charge} handleChange={handleSelectChange} name="origin_charge" options={state.originChargeList}
                       disabled={is_vas_save} size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                    </ContentRow>
                  : null}
                  
  
                {formik.values.origin_charge ?
                    <>
                        <ContentRow>
                        <LableText>per/Unit</LableText>
                        <CustomSelectItem value={formik.values.origin_per_unit} handleChange={handleSelectChange} name="origin_per_unit" options={per_unit_list} 
                         disabled={is_vas_save} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                      </ContentRow>
                      <ContentRow>
                        <LableText>Currency & Exchange Rate</LableText>
                        <CurrencyDiv>
                          <CustomSelectOption handleChange={handleSelectChange} name="origin_currency" options={exchangeRateList} disabled={is_vas_save} size="middle" value={formik.values.origin_currency} width="80px" height="35px !important" pdg="3px 11px !important" />
                          <TextInputItem
                                    id="origin_exchange_rate"
                                    name="origin_exchange_rate"
                                    placeholder=''
                                    value={formik.values.origin_exchange_rate}
                                    onKeyPress={handleKeyPress}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                />
                        </CurrencyDiv>
                      </ContentRow>
                      <ContentRow>
                        <LableText>Amount</LableText>
                        <InputWithSelect name="origin_amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.origin_amount_inr} is_input={true} input_val={formik.values.origin_currency}
                        first_class="first-class" second_class="second-class" disabled_class={is_vas_save} />
                      </ContentRow>
                      <ContentRow>
                        <LableText>Total Amount In INR</LableText>
                        <InputWithSelect handlePlusClick={handleAddOriginService} width="32px"  value={Number(formik.values.origin_total_amount_inr).toFixed(2)} is_input={true}
                        first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={!is_vas_save && Number(formik.values.origin_total_amount_inr) > 0} is_save={false}
                        icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                      </ContentRow>
                      </>
                    : null}

                  <ServiceHeadContainer className='mt-5'>
                    {state.destinationChargeList?.length || formik.values?.destination_services?.length? 
                      <ServiceHead>Destination Services</ServiceHead>
                    : null}
                    {
                      formik.values?.destination_services?.length ?
                      formik.values.destination_services.map((i,index)=>
                        i.destination_quoted ?
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.destination_charge}</LableText>
                          <InputWithSelect width="32px" value={Number(i.amount_inr).toFixed(2)} close_icon={false} edit_icon={false} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                          disabled_class={true} />
                        </ContentRow>
                        :
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.destination_charge}</LableText>
                          <InputWithSelect width="32px" value={i.amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                          disabled_class={true} />
                        </ContentRow>
                        )
                        
                      : 
                      null
                    }
                    {/* {state.destination_quoted? 
                    formik.values?.destination_services?.length ?
                    formik.values.destination_services.map((i,index)=>
                      <ContentRow className='mt-2 sub-head' key={index}>
                        <LableText>{i.destination_charge}</LableText>
                        <InputWithSelect width="32px" value={i.amount_inr} close_icon={false} edit_icon={false} is_input={true} input_val="$"
                        first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                        disabled_class={true} />
                      </ContentRow>
                      )
                      
                    : 
                    null
                    :
                    formik.values?.destination_services?.length ?
                          formik.values.destination_services.map((i,index)=>
                            <ContentRow className='mt-2 sub-head' key={index}>
                              <LableText>{i.destination_charge}</LableText>
                              <InputWithSelect width="32px" value={i.amount_inr} close_icon={!is_vas_save} edit_icon={!is_vas_save} is_input={true} input_val="$"
                              first_class="first-class" second_class="second-class" index={index} handleAction={handleDestinationChargeActions}
                              disabled_class={is_vas_save} />
                            </ContentRow>
                            )
                            
                          : 
                          null
                           } */}
                    
                  </ServiceHeadContainer>
                  {state.destinationChargeList?.length? 
                    <ContentRow>
                      <LableText>Destination Charges</LableText>
                      <QuerySelectItem value={formik.values.destination_charge} handleChange={handleSelectChange} name="destination_charge" options={state.destinationChargeList}
                       disabled={is_vas_save} size="middle" width="228px" height="35px !important" pdg="3px 11px !important" />
                    </ContentRow>
                  : null}
                  
                
                {formik.values.destination_charge ?
                <>
                    <ContentRow>
                    <LableText>per/Unit</LableText>
                    <CustomSelectItem value={formik.values.per_unit} handleChange={handleSelectChange} name="per_unit" options={per_unit_list}
                     disabled={is_vas_save} size="middle" width="171px" height="35px !important" pdg="3px 11px !important" />
                  </ContentRow>
                  <ContentRow>
                    <LableText>Currency & Exchange Rate</LableText>
                    <CurrencyDiv>
                      <CustomSelectOption handleChange={handleSelectChange} name="currency" options={exchangeRateList} disabled={is_vas_save}
                       size="middle" value={formik.values.currency} width="80px" height="35px !important" pdg="3px 11px !important" />
                      <TextInputItem
                                id="exchange_rate"
                                name="exchange_rate"
                                placeholder=''
                                value={formik.values.exchange_rate}
                                onKeyPress={handleKeyPress}
                                onChange={formik.handleChange}
                                disabled={true}
                            />
                    </CurrencyDiv>
                  </ContentRow>
                  <ContentRow>
                    <LableText>Amount</LableText>
                    <InputWithSelect name="amount_inr" handleChange={handleInputChange} width="48px" value={formik.values.amount_inr} is_input={true} input_val={formik.values.currency}
                    first_class="first-class" second_class="second-class" disabled_class={is_vas_save}  />
                  </ContentRow>
                  <ContentRow>
                    <LableText>Total Amount In INR</LableText>
                    <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={Number(formik.values.total_amount_inr).toFixed(2)} is_input={true}
                    first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={!is_vas_save && Number(formik.values.total_amount_inr) > 0} is_save={false}
                    icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                  </ContentRow>
                    
                  </>
                : null}
                {state.show_calculatedRate ?
                  <div style={{ marginTop: '15px', display: 'flex',justifyContent: 'space-between',alignItems: 'center' }}>
                    <ContentRow className='sub-head'>
                      <LableText className='sum-rate'>Calculated Rate</LableText>
                      <InputWithSelect name="amount_inr" handleChange={handleInputChange} width="32px" value={state.calculated_rate} is_input={true} input_val="$"
                      first_class="first-class" second_class="second-class" disabled_class={true}  />
                    </ContentRow>

                    {/* {Number(state.calculated_rate) > 0 ?
                      <CustomeButton onClick={()=> handleServicesVAS()} size='large'>Save</CustomeButton>
                    : null} */}
                    {is_vas_save && Number(state.calculated_rate) > 0 ? 
                      <CustomeButton onClick={()=> setVasSave(false)} size='large'>Edit</CustomeButton>
                    : Number(state.calculated_rate) > 0 ? 
                      <CustomeButton onClick={()=> handleServicesVAS()} size='large'>Save</CustomeButton>
                      : null
                    }
                  </div>
                : null}
                
                <Seperator></Seperator>
                {state.searchQuery?.containerLoadType === "LCL" && state.is_addOCL ?
                <>
                  {state.shipline_list?.length ?
                  <>
                  <ServiceHeadContainer>
                    <ServiceHead>Add Shipment Amount</ServiceHead>
                  </ServiceHeadContainer>

                  <ContentRow className='flex-start'>
                      <LableText className='Airline'>Shipline</LableText>
                      <ItemSelect
                            size="large"
                            placeholder="Select Shipline"
                            style={{
                                width: 180,
                            }}
                            onChange={(value,option)=> handleSelectShipperline(value,option)}
                            value={state.shipperline}
                            bradius="8px"
                          >
                              {state.shipline_list?.map((i,index)=> 
                                  <Option key={index} value={i}>{i}</Option>
                              )}   
                          </ItemSelect>
                    </ContentRow>
                    <ContentRow>
                        <LableText>Currency & Exchange Rate</LableText>
                        <CurrencyDiv>
                          <CustomSelectContainer handleChange={handleSelectChangeLCL} name="container_currency" options={exchangeRateList} disabled={false}
                          size="middle" value={formik.values.container_currency?formik.values.container_currency : "" } width="80px" height="35px !important" pdg="3px 11px !important" />
                          <TextInputItem
                                id="container_exchange_rate"
                                name="container_exchange_rate"
                                placeholder=''
                                value={formik.values.container_exchange_rate}
                                onKeyPress={handleKeyPress}
                                onChange={(e)=> handleInputChangeLCL(e)}
                                disabled={true}
                            />
                        </CurrencyDiv>
                      </ContentRow>
                      <ContentRow>
                          <LableText>Total Shipment Amount</LableText>
                          <InputWithSelectContainer name="container_amount" handleChange={handleInputChangeLCL} width="48px" value={formik.values.container_amount? formik.values.container_amount: "" } is_input={true} input_val={formik.values.container_currency}
                          first_class="first-class" second_class="second-class" disabled_class={false}  />
                        </ContentRow>
                        
                        <ContentRow>
                          <LableText>Total Shipment Amount Inr</LableText>
                          <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={formik.values.container_amount_inr? Number(formik.values.container_amount_inr).toFixed(2): ''} is_input={true}
                          first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={false} is_save={false}
                          icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                        </ContentRow>
                  </>
                  : null}
                  
                        {state.shipline_list.length && formik.values.container_amount_inr ? 
                          <CustomeButton onClick={()=> handleAddLCLContainerRate()} size='large' className='mt-1'>Add</CustomeButton>
                          : !state.shipline_list.length && is_lcl_save ?
                          (is_vas_save && Number(state.calculated_rate) > 0) || (is_vas_save == false && Number(state.calculated_rate) === 0) ?
                          !formik.values.origin_charge && !formik.values.destination_charge && (state.destinationChargeList?.length === 0 || (state.destinationChargeList?.length > 0 && formik.values.destination_services?.length > 0)) && (state.originChargeList?.length === 0 || (state.originChargeList?.length > 0 && formik.values.origin_services?.length > 0)) ?
                          <CustomeButton onClick={()=> handleSaveLCLContainerRate()} size='large' className='mt-1'>Save</CustomeButton>
                          :
                          state.destinationChargeList?.length > 0 && formik.values.destination_services?.length === 0 ? 
                          <p className='text-[red]'>please add Destination Service rate</p>
                          : state.originChargeList?.length > 0 && formik.values.origin_services?.length === 0 ?
                          <p className='text-[red]'>please add Origin Service rate</p>
                          :
                          <p className='text-[red]'>please add VAS rate</p>
                          :
                          null
                          : null
                        }
                  </>
                :
                <>
                  <ServiceHeadContainer>
                    {state.searchQuery?.containerLoadType === "FCL"?
                      <ServiceHead>Container Details</ServiceHead>
                    : null}
                  </ServiceHeadContainer>

                    {state.container_details && state.container_details.length ?
                    state.output.map((o,output_index)=>
                      o?.containerDetails?.filter((i)=> i?.added === true)?.map((i,index)=>
                        <ContentRow className='mt-2 sub-head' key={index}>
                          <LableText>{i.line},{i.containerSize},{i.containerType},{i.containerCount}</LableText>
                          <InputWithSelectContainer width="32px" value={i.freight} close_icon={true} edit_icon={state.editContainerIdx == index ? false: true} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" index={index} output_index={output_index} handleAction={handleContainerChangeActions}
                          disabled_class={true} />
                        </ContentRow>
                        )
                      ): state.output.map((o,output_index)=>
                          o?.containerDetails?.map((i,index)=>
                            <ContentRow className='mt-2 sub-head' key={index}>
                              <LableText>{i.line},{i.containerSize},{i.containerType},{i.containerCount}</LableText>
                              <InputWithSelectContainer width="32px" value={i.freight} close_icon={false} edit_icon={false} is_input={true} input_val="$"
                              first_class="first-class" second_class="second-class" index={index} output_index={output_index} handleAction={handleContainerChangeActions}
                              disabled_class={true} />
                            </ContentRow>
                            )
                        )}

                  {state.container_details && state.container_details.length?
                    <ContentRow className='flex-start'>
                      <LableText className='Airline'>Shipline*</LableText>
                      <ItemSelect
                            size="large"
                            placeholder="Select Shipline"
                            style={{
                                width: 180,
                            }}
                            onChange={(value,option)=> handleSelectShipperline(value,option)}
                            value={state.shipperline}
                            bradius="8px"
                          >
                              {state.shipline_list?.map((i,index)=> 
                                  <Option key={index} value={i}>{i}</Option>
                              )}   
                          </ItemSelect>
                    </ContentRow>
                  : null}
                  
                    {state.shipline_list.length > 0 && state.container_details && state.container_details?.map((i,index)=> 
                        <div className='mb-10'>
                            <ContentRow className='sub-head'>
                            <LableText className='Airline'>Container  Details</LableText>
                            <TextInputItem
                                id="container"
                                name="container"
                                placeholder=''
                                value={i.containerSize || i.containerType || i.containerCount ? `${i.containerSize},${i.containerType},${i.containerCount}` : ""}
                                disabled={true}
                                className='container'
                            />
                            </ContentRow>
                            <ContentRow>
                              <LableText>Currency & Exchange Rate*</LableText>
                              <CurrencyDiv>
                                <CustomSelectContainer handleChange={handleSelectChangeContainer} name="container_currency" options={exchangeRateList} disabled={false}
                                size="middle" value={i.container_currency?i.container_currency : "" } index={index} width="80px" height="35px !important" pdg="3px 11px !important" />
                                <TextInputItem
                                          id="container_exchange_rate"
                                          name="container_exchange_rate"
                                          placeholder=''
                                          value={i.container_exchange_rate}
                                          onKeyPress={handleKeyPress}
                                          onChange={(e)=> handleInputChangeContainer(e,index)}
                                          disabled={true}
                                      />
                              </CurrencyDiv>
                            </ContentRow>
                            <ContentRow>
                              <LableText>Amount*</LableText>
                              <InputWithSelectContainer name="container_amount" handleChange={handleInputChangeContainer} width="48px" index={index} value={i.container_amount? i.container_amount: "" } is_input={true} input_val={i.container_currency}
                              first_class="first-class" second_class="second-class" disabled_class={false}  />
                            </ContentRow>
                            <ContentRow>
                              <LableText>Total Amount Inr</LableText>
                              <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={i.container_amount_inr? Number(i.container_amount_inr).toFixed(2): ''} is_input={true}
                              first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={false} is_save={false}
                              icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                            </ContentRow>
                            <ContentRow>
                              <LableText>Validity*</LableText>
                              <ContainerDate DateChange={ContainerDateChange} index={index} value={i.validity !== 'To be quoted' ?i.validity: null} disabled={state.status != 'New'} placeholder="Validity" disabledate={true} />
                              {i.validity_required? <span className='ml-2' style={{ color: 'red'}}>required</span> : null}
                            </ContentRow>
                            <ContentRow>
                              <LableText>Validity Terms*</LableText>
                              <TextInputItem
                                    id="validity_terms"
                                    name="validity_terms"
                                    placeholder=''
                                    value={i.validity_terms === 'To be quoted'? '':i.validity_terms}
                                    onChange={(e)=> handleInputChangeContainer(e,index)}
                                    disabled={false}
                                    className="container"
                                />
                                {i.validity_terms_required? <span className='ml-2' style={{ color: 'red'}}>required</span> : null}
                            </ContentRow>
                            <ContentRow>
                              <LableText>Remarks*</LableText>
                              <TextAreaItem
                                    id="remarks"
                                    name="remarks"
                                    placeholder=''
                                    value={i.remarks === 'To be quoted'? '':i.remarks}
                                    onChange={(e)=> handleInputChangeContainer(e,index)}
                                    disabled={false}
                                    className="container"
                                    rows={3} 
                                />
                                {i.remarks_required? <span className='ml-2' style={{ color: 'red'}}>required</span> : null}
                            </ContentRow>
                      </div>
                    )}

                    {state.shipperline && show_add && state.shipline_list.length && state.container_details && state.container_details.length  ?
                        <CustomeButton onClick={()=> handleAddContainerRate()} size='large' className='mt-1'>Add</CustomeButton>
                      : (!state.shipline_list.length && state.container_calculated) || ((state.container_details && !state.container_details.length) && state.calculated_rate) ?
                        (is_vas_save && Number(state.calculated_rate) > 0) || (is_vas_save == false && Number(state.calculated_rate) === 0) ?
                        (!formik.values.origin_charge && !formik.values.destination_charge) && (state.destinationChargeList?.length === 0 || (state.destinationChargeList?.length > 0 && formik.values.destination_services?.length > 0)) && (state.originChargeList?.length === 0 || (state.originChargeList?.length > 0 && formik.values.origin_services?.length > 0)) ?
                        <CustomeButton onClick={()=> handleSaveContainerRate()} size='large' className='mt-1'>Save</CustomeButton> 
                        : 
                        state.destinationChargeList?.length > 0 && formik.values.destination_services?.length === 0 ? 
                        <p className='text-[red]'>please add Destination Service rate</p>
                        : state.originChargeList?.length > 0 && formik.values.origin_services?.length === 0 ?
                        <p className='text-[red]'>please add Origin Service rate</p>
                        :
                        <p className='text-[red]'>please add VAS rate</p>
                        :
                        null
                      : null}
                      {state.container_details && state.container_details.length?
                        <ContentRow>
                          <LableText>Calculated Rate</LableText>
                          <InputWithSelect handlePlusClick={handleAddDestinationService} width="32px"  value={state.container_calculated? Number(state.container_calculated).toFixed(2): ''} is_input={true} input_val="$"
                          first_class="first-class" second_class="second-class" disabled_class={true} is_plus_icon={false} is_save={false}
                          icon_class="flex-end" handleSave={handleDestinationServiceSave} />
                        </ContentRow>
                      : null}
                </>
                }
                
                    
                    {/* {state.shipperline && state.container_calculated ?
                      <CustomeButton onClick={()=> handleServicesVAS()} size='large' className='mt-10'>Save</CustomeButton>
                    : null} */}
                  </>
                :
                <>
                    {state.output?.length ?
                      state.output?.map((i,index)=> 
                      <>
                        <ContentRow className='space-between' key={index}>
                          <AirLineDiv>
                            <LableText>Shipper line</LableText>
                            <LableText className='content'>{i.line}</LableText>
                            <LableText className='ml-4'>Rate</LableText>
                            <LableText className='content'>$ {Number(i.USD_totalFreightRate).toFixed(2)}</LableText>
                          </AirLineDiv>
                          <ButtonSection>
                            {props.instance.status == 'New'?
                              <IconImg  src={require('../../assets/images/editicon.png')} onClick={()=> handleViewActions(index,"edit")} />
                            : null}
                            {/* <IconImg className='ml-3' src={require('../../assets/images/trashicon.png')} onClick={()=> handleViewActions(index,"remove")} /> */}
                          </ButtonSection>
                        </ContentRow>
                        { formik.values.origin_services?.length ? 
                          <ContentRow className='mt-0'>
                            <DestServiceSection className='mt-1'>
                              <LableText>Origin Services</LableText>
                              
                                  {formik.values.origin_services.map((i,index)=> 
                                    <DestDetails className='mb-2' key={index}>{i.origin_charge} &nbsp;&nbsp;&nbsp;USD {Number(i.origin_amount_inr).toFixed(2)}</DestDetails>
                                  )}
                                  
                            </DestServiceSection>
                          </ContentRow>
                          : null
                        }
  
                        { formik.values.destination_services?.length ? 
                          <ContentRow className='mt-0'>
                            <DestServiceSection className='mt-1'>
                              <LableText>Destination Services</LableText>
                                  {formik.values.destination_services.map((i,index)=> 
                                    <DestDetails className='mb-2' key={index}>{i.destination_charge} &nbsp;&nbsp;&nbsp;USD {Number(i.amount_inr).toFixed(2)}</DestDetails>
                                  )}
                            </DestServiceSection>
                          </ContentRow>
                        : null
                        }

                        { i.containerDetails?.length ? 
                          <ContentRow className='mt-0'>
                            {state.searchQuery?.containerLoadType === "LCL" ? 
                                <DestServiceSection className='mt-1'>
                                  <LableText>Container Details</LableText>
                                      {i.containerDetails?.map((c,index)=> 
                                        <DestDetails className='mb-2' key={index}>{c.containerSize}, {c.containerType}, USD {Number(i.USD_totalShipmentRate).toFixed(2)} </DestDetails>
                                      )}
                                </DestServiceSection>
                              :
                                <DestServiceSection className='mt-1'>
                                  <LableText>Container Details</LableText>
                                      {i.containerDetails?.map((c,index)=> 
                                        <DestDetails className='mb-2' key={index}>{c.containerSize}, {c.containerType}, USD {Number(c.totalRate).toFixed(2)} </DestDetails>
                                      )}
                                </DestServiceSection>
                              }
                            
                          </ContentRow>
                        : null
                        }
                      </>
                      )
                    : null}
                    
                  <Seperator></Seperator>
                  </>
                  }
            </ServiceSection>
          : null}
        </LeftSection>
  
  
          <SummaryContainer>
            <ContentHead title="Query Summary" pb="20px" pt="10px" fsz="16px" ml="0px" is_back_icon={false} border={false} class="summary-head" name="view_page" />
            <SummaryShip nodeData={state.searchQuery} />
          </SummaryContainer>
      </MainContainer>
    )
  }
  
}

export default OceanViewPage

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
`

const CurrencyDiv =styled.div`
  display: flex;
  gap: 10px;
`

const AirLineDiv = styled.div`
  display: flex;
  gap: 40px;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
`

const DestServiceSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const DestDetails = styled.span`
  background: rgba(255, 175, 0, 0.2);
  border-radius: 4px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #242424;
  padding: 2px 5px;
  
`
const ItemSelect = styled(Select)`

    & .ant-select-selector {
        border-radius: ${({bradius}) => bradius? bradius : "4px !important;"};
        padding-left: ${({ is_icon })=> is_icon ? "20px !important": 'unset'};
        :hover,:focus,:not(:focus) {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    & .ant-select-selection-placeholder {
        color: #B5B5C3 !important;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Roboto';
        font-style: normal;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important;
    }
`

const RightDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 67%;
  &.sell-rate {
    width: 65%;
  }
  &.remarks {
    width: 34%;
  }
`