import { memo, useState } from "react"
import { ContentRow, FrightRateSection, LableText, RateSection, RateValue, RemarksLabel, VASRateSection } from "../../StyledComponents/Queries"
import AddButton from "../Common/AddButton"
import RemoveButton from "../Common/RemoveButton"
import { Modal,Button } from 'antd';
import { EditFilled, ExclamationCircleFilled } from '@ant-design/icons';
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";
import airline from '../../assets/images/flight_icon.svg'

const { confirm } = Modal;

export const QueryVASTypePage = ({item,i,searchquery,handleRemoveItem,index,status,handleAddRemarkVAS,handleChange,remark_value,formik})=>{
    // let airline = require("../../assets/images/flight_icon.svg")
    // if(item?.airlineLogo){
    //     airline = item?.airlineLogo
    // }
    
    const arrow_swap = require("../../assets/images/arrow-swap.png")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modal_type, setModalType] = useState("");
    const showModal = (type) => {
        setModalType(type)
        setIsModalOpen(true);
      };
      const handleOk = () => {
        handleAddRemarkVAS(index,remark_value)
        setIsModalOpen(false);
      };
      const handleCancel = (type) => {
        if(type === 'cancel'){
            formik.setValues({ ...formik.values, remarks: "" })
        }else if(type === 'edit'){
            formik.setValues({ ...formik.values, remarks: item.remarks })
        }
        setIsModalOpen(false);
      };

    const handleRemove = () => {
        confirm({
            title: 'Are you sure you want to remove this airline ?',
            icon: <ExclamationCircleFilled />,
            // content: 'Some descriptions',
            okText: 'Confirm',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
              handleRemoveItem(index)
            },
            onCancel() {
              console.log('Cancel');
            },
            className: 'custom-confirm',
          });
        
    }

    const icon = <EditFilled onClick={()=> setModalType("edit")} />;
    const Remarktitle = (
        <TitleHead>
            <span>
            Remark
            </span>
            {modal_type === 'show' && status === 'New' ? icon: null}
        </TitleHead>
    );

    const footer = modal_type === 'add' || modal_type === 'edit' ? (
        <div>
          <Button onClick={()=> handleCancel(modal_type === "add" ? "cancel" : "edit")}>Cancel</Button>
          <Button onClick={handleOk}>Confirm</Button>
        </div>
      ) : null;

    const [openCollapse, setOpenCollapse] = useState(false)
    let title = item?.airlines?.split("-(")[0];
    return(
        <div className="w-full rounded-2xl shadow-cardsection p-5 sm:p-5" key={index}>
            <div className="flex items-center gap-4 mb-10">
                <div className="rounded-full border border-primary w-10 h-10 flex items-center justify-center overflow-hidden">
                    <img src={item?.logo ? item?.logo : airline} alt="" style={{width: '100%',height: 'auto', display: 'block'}} />
                </div>
                <p className="flex text-secondary font-medium text-base leading-5">{title}</p>
            </div>
            <div className="sm:px-0 md:px-4 lg:px-10 px-12 py-10">
                <div className="grid grid-cols-12 gap-5 items-center">
                    <div className="sm:col-span-3  md:col-span-3 lg:col-span-4 col-span-4 border-[#E0E0E0] h-0 border-dashed border relative">
                        <div className="sm:hidden md:hidden rounded-full border border-[#979797] w-5 h-5 flex items-center justify-center absolute -left-[20px] -top-[10px]"></div>
                    </div>
                    <div className="sm:col-span-5 md:col-span-5 lg:col-span-4 col-span-4 border-[#E0E0E0] border-dashed border relative">
                        <div className="rounded-full border border-[#979797] w-4 h-4 flex items-center justify-center absolute -left-[20px] -top-[8px]"></div>
                        <div className="flex flex-col items-start justify-start absolute -left-[20px] -top-[60px] sm:-top-[40px]">
                            <p className='text-primary font-semibold text-sm leading-5 sm:hidden'>{item?.originAirportFull}</p>
                            <p className='text-secondary font-bold text-sm leading-5'>{item?.originAirport}</p>
                        </div>
                        <div className="sm:w-5 sm:h-5 flex flex-col items-start justify-start absolute -top-[60px] sm:-top-[40px] left-1/2 ">
                            <img src={arrow_swap} alt="Arrow Swap Icon" />
                        </div>
                        <div className="sm:w-5 sm:h-5 flex flex-col items-start justify-start absolute top-[10px] sm:-top-[40px] left-1/2 ">
                            <p className='text-graytext font-normal text-xs leading-5'>{searchquery?.goodsDetails?.cargoreadydate}</p>
                        </div>
                    </div>
                    <div className="sm:col-span-4 md:col-span-4 lg:col-span-4 col-span-4 border-[#E0E0E0] border-dashed border relative">
                        <div className="rounded-full border border-[#979797] w-4 h-4 flex items-center justify-center absolute -left-[20px] -top-[8px]"></div>
                        <div className="flex flex-col items-start justify-start absolute -left-[20px] -top-[60px] sm:-top-[40px]">
                            <p className='text-primary font-semibold text-sm leading-5 sm:hidden'>{item?.destinationAirportFull}</p>
                            <p className='text-secondary font-bold text-sm leading-5'>{item?.destinationAirport}</p>
                        </div>
                        <div className="sm:hidden md:hidden rounded-full border border-[#979797] w-5 h-5 flex items-center justify-center absolute -right-[20px] -top-[10px]"></div>
                    </div>
                </div>
            </div>
            <div className='dashed-line w-ull mt-5'></div>
            <RateSection>
                <FrightRateSection>
                    <RateValue>Freight  Rate</RateValue>
                    <RateValue className="ml-3">{Number(item.rates)? `₹` + Number(item.rates).toFixed(2) : item.rates }</RateValue>
                </FrightRateSection>
                <FrightRateSection>
                    <RateValue>VAS Rate</RateValue>
                    <RateValue className="ml-3">₹ {Number(item.totalVAS).toFixed(2)}</RateValue>
                </FrightRateSection>
                <FrightRateSection>
                    <RateValue>Total Freight Rate</RateValue>
                    <RateValue className="ml-3">₹ {item.totalFreightRate > 0? Number(item.totalFreightRate).toFixed(2): '0.00'}</RateValue>
                </FrightRateSection>
                {/* <VASRateSection>
                    <FrightRateSection>
                        <RateValue>VAS Rate</RateValue>
                        <RateValue className="ml-3">₹ {Number(item.totalVAS).toFixed(2)}</RateValue>
                    </FrightRateSection>
                    <RemoveButton title="Remove" handleClick={()=>handleRemoveItem(index)} />
                </VASRateSection> */}
            </RateSection>
            {status === "New"?
                <RateSection className="button-section mt-5">
                    {item.remarks ? 
                    <RemarksLabel onClick={()=> showModal("show")}>Show Remarks here</RemarksLabel>:
                    <RemarksLabel onClick={()=> showModal("add")}>Add Remarks here</RemarksLabel> }
                    <RemoveButton title="Remove" handleClick={()=> handleRemove()} />
                </RateSection>
            : <RateSection className="button-section mt-5">
                <RemarksLabel onClick={()=> showModal("show")}>Show Remarks here</RemarksLabel>:
            </RateSection>}
            <ItemModal title={Remarktitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false}
             okText="Confirm" cancelText="Cancel" footer={footer} >
                <ContentRow className='align-start'>
                    {modal_type === "show" ? <LableText className="full-width">{item.remarks}</LableText>: 
                    <>
                        <LableText className="wd-33">Remark</LableText>
                        <RightDiv>
                            <TextArea rows={3} placeholder="Remarks" name="remarks"
                                value={remark_value} onChange={handleChange} />
                        </RightDiv>
                    </>
                    }
                    
                </ContentRow>
            </ItemModal>
            

            
        </div>
    )
}

const ItemModal = styled(Modal)`
    & .ant-modal-content {
        width: 75% !important;
    }
    .ant-modal-footer {
        button.ant-btn-primary {
        background-color: #007bff;
        border-color: #007bff;
        }
    }
`

const RightDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 67%;

`
const TitleHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`