import React, { useState } from 'react';
import { Table, Pagination } from 'antd';
import { TableItem } from '../../StyledComponents/Common';
import styled from 'styled-components';



const SimpleTable = (props) => {

  const startIndex = (props.currentPage - 1) * props.pageSize;
  const endIndex = startIndex + props.pageSize;
  const currentData = props?.data?.slice(startIndex, endIndex);

  return (
    <TableContainer>
      <TableItem rowKey={props.rowKey ? props.rowKey : "id"} name={props.name} columns={props.columns} dataSource={currentData} 
      scroll={props.scroll ? {x: props?.xWidth ? props?.xWidth: 1300,} : null} pagination={false} fpadding={props.fpadding} showScroll={props.showScroll} className={props.className? props.className : ""} />
    </TableContainer>
  );
};

export default SimpleTable;

const TableContainer = styled.div`
  /* overflow-x: hidden;
  width: 100%; */
  
`