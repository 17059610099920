import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"


export const countrycode = createAsyncThunk('countrycode', async ()=>{
    try{
        const url = 'phone_countrycode'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const airlines_list = createAsyncThunk('airlines_list', async ()=>{
    try{
        const url = 'airlines'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})


export const airport_list = createAsyncThunk('airport_list', async ()=>{
    try{
        const url = 'airports'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const shipment_list = createAsyncThunk('shipment_list', async ()=>{
    try{
        const url = 'list_shipment'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const countries_list = createAsyncThunk('countries_list', async ()=>{
    try{
        const url = 'admin/ocean_country_list'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const state_list = createAsyncThunk('state_list', async ()=>{
    try{
        const url = 'list_states'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const shipport_list = createAsyncThunk('shipport_list', async ()=>{
    try{
        const url = 'list_ocean_port'
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

export const password_reset_link = createAsyncThunk('password_reset_link', async (values)=>{
    try{
        const url = 'admin/kam_send_reset_password_link'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const resend_invitation = createAsyncThunk('resend_invitation', async (values)=>{
    try{
        const url = 'admin/kam_resend_invitation_link'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const password_reset_link_user = createAsyncThunk('password_reset_link_user', async (values)=>{
    try{
        const url = 'kam/send_reset_password_link'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const notificationlist = createAsyncThunk('notificationlist', async (count)=>{
    try{
        const url = `notification/${count}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationread = createAsyncThunk('updatenotificationread', async (id)=>{
    try{
        const url = `notification_status_update/${id}`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationreadall = createAsyncThunk('updatenotificationreadall', async ()=>{
    try{
        const url = `mark_as_all_read`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})
export const updatenotificationneworold = createAsyncThunk('updatenotificationneworold', async ()=>{
    try{
        const url = `notification_read_status_update`
        const response = await httpinstance.get(url)
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    countryCode : {},
    notificationdata:{},
    notificationCount: 0,
    firebaseToken: localStorage.getItem('firebaseToken')? localStorage.getItem('firebaseToken') : ""
}

const commonSlice = createSlice({
    name : 'commonslice',
    initialState,
    reducers: {
        updatenotificationdata: (state, {payload})=>{
            state.notificationdata = payload
        },
        updatenotificationCount: (state, {payload})=>{
            state.notificationCount = payload
        },
        updatefirebasetoken: (state, {payload})=>{
            state.firebaseToken = payload
        },
    },
    extraReducers:{
        [countrycode.fulfilled]:(state,{payload})=>{
            return {...state, countryCode:payload?.data}
        },
    }
})

export const { updatenotificationdata,updatenotificationCount,updatefirebasetoken } = commonSlice.actions
export const allcountrycode = (state) => state.commonapi.countryCode;
export const getnotificationdata = (state) => state.commonapi.notificationdata;
export const getfirebasetoken = (state) => state.commonapi.firebaseToken;
export const getNotificationCount = (state) => state.commonapi.notificationCount;
export default commonSlice.reducer