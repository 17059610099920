import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CustomeHeader from '../components/Common/CustomeHeader'
import FilterSelect from '../components/Common/FilterSelect'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import KYCView from '../components/KYCVerifications/KYCView'
import { get_param_url } from '../functions/Utils'
import { get_kycVerification_list } from '../store/KYC/KYCVerificationSlice'
import { get_user_list } from '../store/UserManagement/UserManagementSlice'
import { LeftHead, SubHeadContainer, SubHeadTitle, TablePagination } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { DocText, DocTextContainer, UserIDContainer, UserText } from '../StyledComponents/KYCVerifications'
import { FilterSection, LabelItem, SpinContainer } from '../StyledComponents/Queries'

function KYCVerifications() {
    const dispatch = useDispatch()
    const params_kyc_id = get_param_url("kyc_id")
    const navigate = useNavigate();
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState("1");
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [filter_value, setFilterValue] = useState("All")
    const [getList, setGetList] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=> {
        if(params_kyc_id){
            setGetList(true)
        }
    },[params_kyc_id])

    useEffect(()=>{
        if(pageType === 'table'){
            setQueryInstance({})
            dispatch(get_kycVerification_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    const result = res?.payload?.data?.data
                    setData(res?.payload?.data?.data)
                    if(params_kyc_id){
                        let data = result.filter((i)=> i.id == params_kyc_id)
                        if(data.length){
                            handleView(data[0])
                        }
                    }
                    setFilterData(res?.payload?.data?.data)
                    setGetList(false)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[pageType,getList])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const handleBack = ()=> {
        setPageType("table")
        navigate(`/kyc-verification`)
    }

    const handleFilterChange = (value) => {
        setFilterValue(value)
        let data = filterData
        if(value !== 'All'){
            data = filterData.filter((i)=> i?.kyc_user?.accept_or_reject === value)
        }
        setData(data)
        setCurrentPage(1)
    }


    const columns = [
        {
            title: 'USER ID',
            dataIndex: 'id',
        },
        {
            title: 'FULL NAME',
            dataIndex: 'full_name',
            render: (text,record) => (
                <span>{record.kyc_user?.full_name}</span>
            ),
        },
        {
            title: 'COMPANY NAME',
            dataIndex: 'company_name',
        },
        {
            title: 'COMPANY TYPE',
            dataIndex: 'company_type',
            width: '20%',
            // render: (text,record) => (
            //     <span>{record.kyc_user?.company_type}</span>
            // ),
        },
        {
            title: 'DOCUMENTS',
            dataIndex: 'document1',
            width: "32%",
            render: (text, record) => (
                <DocTextContainer>
                    {record.documents ? 
                        JSON.parse(record.documents)?.map((i,index)=> 
                            <DocText key={index}>{i.proof}</DocText>
                        )
                    : null}
                </DocTextContainer>
              ),
        },
        {
            title: 'STATUS',
            dataIndex: 'accept_or_reject',
            render: (text,record) => (
                <StatusText status={record?.kyc_user?.accept_or_reject}>{record?.kyc_user?.accept_or_reject === "Pending_Upload" ? "Upload Pending" : record?.kyc_user?.accept_or_reject}</StatusText>
            ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        },
      ];
      
    // const data = [
    //     {
    //         user_id: "SG234567H",
    //         full_name: "Brad Simmons",
    //         company_name: "Intertico",
    //         company_type: "PVT Ltd",
    //         document1: "Doc1",
    //         document2: "Doc2",
    //         document3: "Doc3",
    //     },
    //     {
    //         user_id: "SG234567H",
    //         full_name: "Brad Simmons",
    //         company_name: "Intertico",
    //         company_type: "PVT Ltd",
    //         document1: "Doc1",
    //         document2: "Doc2",
    //         document3: "Doc3",
    //     },
    // ]
    const filter_options = [
        {
            value: 'All',
            label: 'All',
        },
        {
            value: 'Processing',
            label: 'Processing',
        },
        {
            value: 'Accepted',
            label: 'Accepted',
        },
        {
            value: 'Rejected',
            label: 'Rejected',
        },
    ]
  return (
    <MainContainer>
      <CustomeHeader title="KYC Verification" description="View all users verification requests and approve." />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>KYC Verification Requests</TableTitle>
                <FilterSection className='filter'>
                    <LabelItem>Filter by</LabelItem>
                    <FilterSelect options={filter_options} width="118px" value={filter_value} handleChange={handleFilterChange} />
                </FilterSection>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} />
            }
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
        <>
           <ContentContainer>
                {/* <ContentHead title="User Details"  handleClick={()=> setPageType("table")} /> */}
                <SubHeadContainer className='flex-column'>
                    <LeftHead>
                        <IconImg onClick={()=> handleBack()} src={require('../assets/images/back_page_arrow.png')} />
                        <SubHeadTitle>KYC Verification Requests</SubHeadTitle>
                    </LeftHead>
                   <UserIDContainer>
                    <UserText>USER ID</UserText>
                    <UserText className='value'>{instance?.kyc_user?.id}</UserText>
                   </UserIDContainer>
                </SubHeadContainer>
                <KYCView instance={instance} setQueryInstance={setQueryInstance} setPageType={setPageType} tab={currentTab} />
            </ContentContainer>
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default KYCVerifications

const StatusText = styled.span`
    color: ${({status})=> status === 'Accepted' ? '#08C004': status === 'Rejected'? '#FF3F3B' :status === 'Processing'? '#FFAF00' :status === 'Pending_Upload'? '#ed7437':'#3F4254' };
    font-weight: 700;
`