import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AcceptedQuoteOceanUserDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteOceanUserDetails'
import AcceptedQuoteUserDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteUserDetails'
import ContentHead from '../components/Common/ContentHead'
import CustomeHeader from '../components/Common/CustomeHeader'
import FilterSelect from '../components/Common/FilterSelect'
import RowSelectionTable from '../components/Common/RowSelectionTable'
import SimpleSelect from '../components/Common/SimpleSelect'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import ViewPage from '../components/Queries/ViewPage'
import UserDetails from '../components/UserManagement/UserDetails'
import UserDetailsAdmin from '../components/UserManagement/UserDetailsAdmin'
import { get_param_url } from '../functions/Utils'
import { updatenotificationdata } from '../store/Common/CommonSlice'
import { get_kam_list } from '../store/KAM/KAMSlice'
import { get_admin_user_list, get_admin_user_list_filter, get_user_list, kam_reassign_user } from '../store/UserManagement/UserManagementSlice'
import { CustomeButton, LeftHead, SubHeadContainer, SubHeadTitle, TablePagination } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { FilterSection, LabelItem, RightSection, SpinContainer } from '../StyledComponents/Queries'

function UserManagementAdmin() {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState("1");
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [selectedUsers, setSelectUser] = useState([])
    const [kam_reassign, setKamReassign] = useState(false)
    const [state, setState] = useState({
        filter_value: null,
        reassign_kam: null
    })
   
    const [data, setData] = useState([]);
    const [kam_list, setKamList] = useState([]);
    const [kam_reassign_list, setKamReassignList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [reAssignloading, setReassignLoading] = useState(false)
    const [call_list, setCallList] = useState(true)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=>{
        if(pageType === 'table' || call_list === true){
            setQueryInstance({})
            dispatch(get_admin_user_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.data)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })

            dispatch(get_kam_list())
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setKamList(res?.payload?.data?.data)
                    setKamReassignList(res?.payload?.data?.data)
                }
            })
            .catch(err=>{
                console.error(err);
            })
            setCallList(false)
        }
        // setIsEdit(false)
    },[pageType,call_list])

    useEffect(()=>{
        const user_id = get_param_url("user_id")
        if(user_id && data.length){
            setLoading(true)
            let record = data.filter((i)=> i.id == user_id)[0]
            console.log(data)
            handleView(record)
        }
    },[data])

    useEffect(()=>{
        if(state.filter_value && kam_list.length){
            let kam_reassign_list = kam_list.filter((i)=> i.id != state.filter_value)
            setKamReassignList(kam_reassign_list)
        }
    },[state.filter_value])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const handleFilterChange = (value) => {
        setLoading(true)
        const param = { kam_id : value}
        dispatch(get_admin_user_list_filter(param))
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.data?.statusCode === 200 ){
                setData(res?.payload?.data?.data)
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })
        setState((prevState)=> {
            return {
                ...prevState,
                filter_value: value,
                reassign_kam: null
            }
        })
    }

    const handleReassignChange = (value)=> {
        setState((prevState)=> {
            return {
                ...prevState,
                reassign_kam:value
            }
        })
    }

    const handleReassignSubmit = (value) => {
        setReassignLoading(true)
        const param = { 
            kam_id : state.reassign_kam,
            user_id : selectedUsers,
        }
        dispatch(kam_reassign_user(param))
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.data?.statusCode === 200 ){
                setReassignLoading(false)
                dispatch(updatenotificationdata(res?.payload?.data))
                const kam_id = get_param_url("kam_id")
                if(kam_id){
                    navigate(`/create-key-account-manager?kam_id=${kam_id}`)
                }else {
                    // let latestData = data.filter((i)=> !selectedUsers.includes(i.id))
                    // setData(latestData)
                    if(state.filter_value){
                        handleFilterChange(state.filter_value)
                    }else {
                        setCallList(true)
                    }
                    setSelectUser([])
                }
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }

    const handleBackArrow = () => {
        setCurrentTab("2")
        setQueryInstance({})
      }


    const columns = [
        {
            title: 'USER ID',
            dataIndex: 'id',
        },
        {
            title: 'USER FULL NAME',
            dataIndex: 'full_name',
        },
        {
            title: 'COMPANY NAME',
            dataIndex: 'company_name',
        },
        {
            title: 'KEY ACCOUNT MANAGER',
            dataIndex: 'keyaccount_manager',
            render: (text, record) => (
                <span>{record?.kam_user?.full_name}</span>
              ),
        },
        {
            title: 'CITY',
            dataIndex: 'city',
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];
      
    
  return (
    <MainContainer>
      <CustomeHeader title="User Management" description="View all users in the system and the complete action." />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>User List</TableTitle>
                <RightSection>
                    {selectedUsers.length ? 
                        <FilterSection>
                            
                            {kam_reassign ? 
                                <SimpleSelect options={kam_reassign_list} width="180px" value={state.reassign_kam} handleChange={handleReassignChange} placeholder="Select KAM to Assign" />
                            : <CustomeButton size='large' onClick={()=> setKamReassign(true)} >KAM Reassign</CustomeButton>}
                            {state.reassign_kam ? 
                                    <CustomeButton size='large' loading={reAssignloading} onClick={()=> handleReassignSubmit()} >Submit</CustomeButton>
                            : null}
                        </FilterSection>:
                     null}
                    <FilterSection>
                        <LabelItem>Filter by</LabelItem>
                        <SimpleSelect options={kam_list} width="118px" value={state.filter_value} handleChange={handleFilterChange} placeholder="Select KAM" />
                    </FilterSection>
                </RightSection>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                // <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} />
                <RowSelectionTable rowKey="id" currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} setSelectArchive={setSelectUser} checkedValues={selectedUsers} />
            }
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
        <>
            {query_instance?.queryId? 
                // <ViewPage setPageType={setPageType} instance={query_instance} parent_type="user_management" setQueryInstance={setQueryInstance} setCurrentTab={setCurrentTab} />
                <ContentContainer>
                    <SubHeadContainer className='flex-column'>
                        <LeftHead>
                            <IconImg onClick={()=> handleBackArrow()} src={require('../assets/images/back_page_arrow.png')} />
                            <SubHeadTitle>Details</SubHeadTitle>
                        </LeftHead>
                    </SubHeadContainer>
                    {userroles === "ocean_admin" ?
                        <AcceptedQuoteOceanUserDetails instance={query_instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                    :
                        <AcceptedQuoteUserDetails instance={query_instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                    }
                </ContentContainer>
            : 
            <ContentContainer>
                {/* <ContentHead title="User Details"  handleClick={()=> setPageType("table")} /> */}
                <UserDetailsAdmin instance={instance} setInstance={setInstance} setQueryInstance={setQueryInstance} tab={currentTab} setPageType={setPageType} kam_list={kam_list} />
            </ContentContainer> }
            
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default UserManagementAdmin
