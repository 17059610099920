import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { CustomeButton } from '../../StyledComponents/Common'
import { IconImg } from '../../StyledComponents/Header'

function AddButton(props) {
    let icon_img = require('../../assets/images/addplus_icon.png')
    if(props.icon_type === 'edit') {
        icon_img = require('../../assets/images/editicon.png')
    }else if (props.icon_type === 'archive'){
      icon_img = require('../../assets/images/archive-add.png')
    }else if (props.icon_type === 'download'){
      icon_img = require('../../assets/images/download_icon.png')
    }
  return (
    <div>
      {props.is_icon === false?<CustomeButton onClick={()=> props.handleClick ? props.handleClick() : null} size='large' loading={props.loading}>{props.title}</CustomeButton> :
       <CustomeButton onClick={()=> props.handleClick? props.handleClick() : null} size='large' icon={<IconImg src={icon_img} />} loading={props.loading}>{props.title}</CustomeButton> }
    </div>
  )
}

export default AddButton
