import { Col, Row, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import TabPane from 'antd/es/tabs/TabPane';
import { Formik, Form } from 'formik';
import styled from "styled-components/macro";


export const ContentContainer = styled.div`
    background-color: #fff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px 20px;
    margin-top: 35px;
`
export const TableTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ButtonHeadSection = styled.div`
    display: flex;
    /* width: 38%; */
    /* justify-content: space-between; */
    gap: 20px;
    align-items: center;
`
export const TableTitle = styled.span`
    font-size: 18px;
    color: #181C32;
    font-weight: 600;
    font-family: 'Rubik';
    font-style: normal; 
`

export const FormikForm = styled(Formik)`
    form {
        margin-top: 20px !important;
    }
`

export const FormItem = styled(Form)`
        margin-top: 25px !important;
        margin-left: 15px;
        &.no-margin {
            margin-top: 0px !important;
            margin-left: 0px;
        }
`
export const FormRow = styled(Row) `
    margin-bottom: 22px;
    &.last {
        margin-bottom: 8px;
    }
`
export const FormColumn = styled(Col)`
    display: flex;
    align-items: center;
    label {
        font-family: 'Rubik';
        font-style: normal;
        font-size: 12px;
        font-weight: 700;
        color: #B5B5C3;
    }
    input {
        :hover {
            border-color: #D9D9D9;
        }
        color: #3F4254;
        font-weight: 500;
        font-size: 14px;
        font-family: 'Rubik';
        font-style: normal;
        padding-left: 25px;
        line-height: 27px !important;
    }
    &.submit {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`

export const DetailContainer = styled.div`
    margin-top: 30px;
    &.create {
        padding: 10px;
        margin-top: 15px;
    }
    &.user-management {
        padding-bottom: 100px;
    }
`

export const TabItem = styled(Tabs)`
    border: none !important;
    div {
        &.ant-tabs-content-holder {
            border: none !important;
        }
        &.ant-tabs-nav {
            width: 300px !important;
            @media (max-width: 1312px) {
                width: 200px !important; /* change width for smaller screens */
            }
        }
    }

    & .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background: #fff !important;
    }
    
`
export const TabImg = styled.img`
    display: inline-block;
    margin-right: 15px;
    &.grey {
        filter: grayscale(100%);
    }
`
export const TabHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.user-admin {
        align-items: flex-start;
    }
`
export const TabTitle = styled.span`
    font-size: 16px;
    font-weight: ${({active}) => active ? 600 : 400};
    color: ${({active}) => active ? '#FFAF00' : '#242424'};
    font-family: 'Rubik';
    font-style: normal;
    &.user-admin {
        margin-right: 25px;
        margin-bottom: 10px;
    }
    &.sub-menu {
        font-weight: 400;
        font-weight: ${({active}) => active ? 500 : 400};
        font-size: 15px;
    }
`

export const TabPaneItem = styled(TabPane)`
    &.ant-tabs-content-holder {
        border: none !important;
    }
`
export const DetailContentContainer = styled.div`
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 22px;
    &.quote_section {
        position: relative;
    }
`

export const TableHead = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .ant-divider {
        border-inline-start: 2px solid #B5B5C3 !important;
        height: 1.4em !important;
    }
`

export const ContentRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom:${({ mb }) => mb ? mb : '12px'};
    &.last {
        margin-bottom: 0px;
    }
    &.documents {
        gap: 15px;
        width: 80%;
        @media (max-width: 1312px) {
            width: 100%; /* change width for smaller screens */
        }
    }
    &.flex-end {
        justify-content: flex-end;
    }
    
`
export const ContentTitle = styled.span`
    font-size: 12px;
    font-weight: 500;
    font-family: 'Rubik';
    font-style: normal;
    color: #B5B5C3;
    width: 200px;
    &.document-title {
        width: 30%;
    }
    &.doc-status {
        width: 15%;
    }
    &.doc-status-val {
        
    }
`
export const ContentValue = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #3F4254;
    text-align: left;
    &.status {
        color: ${({status})=> status === 'Booking Confirmed' || status === 'SUCCESS' ? '#08C004': status === 'Payment Rejected' || status === 'REJECTED'? '#FF3F3B' :status === 'Waiting for Confirmation' || status === 'PENDING'? '#FFAF00':'#3F4254' };
    }
`
export const ButtonSection = styled.div`
    margin-top: 230px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.user-management {
        margin-top: 40px;
    }
`
export const HeadSection = styled.div`
    display: flex;
    align-items: center;
`
export const TopHead = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 60%;
`
export const TableSection = styled.div``

export const ContentItem = styled(Content)`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #B5B5C3;
    &.values {
        font-weight: 500;
        font-size: 13px;
        color: #3F4254;
    }
`
export const ActionText = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #A1A5B7;
    padding: 10px 16px;
    background: #F5F8FA;
    border-radius: 9px;
    &.name {
        font-weight: 400;
        font-size: 13px;
        color: #3F4254;
        background: none;
    }
    &.cursor {
        cursor: pointer;
    }
`