import React, { useEffect } from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

function QueryBarChart() {
  var barChartData = {
    labels: ["Mon", "Tue", "Wed", "Thu"],
    datasets: [
      {
        barPercentage: 1,
        categoryPercentage: 0.3,
        label: "Minimum",
        backgroundColor: "#7A14E0",
        borderColor: "#7A14E0",
        borderWidth: 1,
        data: [5,6,8,4],
      },
      {
        barPercentage: 1,
        categoryPercentage: 0.3,
        label: "Average",
        backgroundColor: "#00FFFF",
        borderColor: "#00FFFF",
        borderWidth: 1,
        data: [9,9,11,7],
      },
      {
        barPercentage: 1,
        categoryPercentage: 0.3,
        label: "Maximum",
        backgroundColor: "#FFE500",
        borderColor: "#FFE500",
        borderWidth: 1,
        data: [20,18,17,22],
      },
    ],
  };

  var chartOptions = {
    // maintainAspectRatio: false,
    responsive: true,
    layout: {
        autoPadding: true,
        padding: {
          top: 50
        }
      },
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Growth Chart",
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Time in Hours →",
          font: {
            size: 13,
          },
        },
      },
    //   x: {
    //     title: {
    //       display: true,
    //       text: "Time →",
    //       font: {
    //         size: 13,
    //       },
    //     },
    //   },
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
      tooltip: {
        enabled: true,
        position: "average",
        yAlign: "bottom",
        bodyAlign: "center",
        intersect: true,
        backgroundColor: "#FFFFFF",
        bodyColor: "#242424",
        titleColor: "#242424",
        borderColor: "rgba(222, 200, 2)",
        borderWidth: "2",
        bodySpacing: "4",
        padding: "14",
        cornerRadius: "5",
        displayColors: false,
        // xAlign: 'bottom'
      },
    },
  };

  return (
    <ChartContainer>
      <BarItem data={barChartData} options={chartOptions} height={160} />
    </ChartContainer>
  );
}

export default QueryBarChart;

const ChartContainer = styled.div`
  height: 100%;
`;
const BarItem = styled(Bar)`
  /* height: 400px !important; */
`