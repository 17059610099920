import React, { useState } from 'react'
import { Select, Space } from 'antd';
import styled from 'styled-components';
import { IconImg } from '../../StyledComponents/Header';
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select;

function SelectSearchShiplineSurcharge(props) {
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (value) => {
        setSearchValue(value);
    };


    const filterOption = (input, option) => {
        const { value, children } = option.props;
        return (
          children.toString().toLowerCase().includes(input.toLowerCase())
        );
      };
  return (
    <SpaceItem wrap gap={props.gap} mr={props.mr} mb={props.mb}>
        <ItemSelect
            showSearch
            size={props.size ? props.size : "large"}
            placeholder={props.placeholder ?props.placeholder : "Select"}
            onChange={(value) => {
                props.setFieldValue(props.name,value)
              }}
            onSearch={handleSearch}
            filterOption={filterOption}
            value={props.value}
            bradius={props.bradius}
            width= {props.width}
        >
            {props.options?.map((i,index)=> 
                <Option key={index} value={i.shipper_name}>{i.shipper_name}</Option> 
            )}   
        </ItemSelect>
    </SpaceItem>
  )
}

export default SelectSearchShiplineSurcharge

const SpaceItem = styled(Space)`
    position: relative;
    gap: ${({gap})=> gap ? gap : '8px'};
    margin-right: ${({mr})=> mr? mr : "unset"};
    margin-bottom: ${({mb})=> mb? mb : "unset"};
    & .ant-space-item {
        width: 100%;
    }
`
const ItemSelect = styled(Select)`
    width: ${({width}) => width ? width : '230px'};
    & .ant-select-selector {
        border-radius: ${({bradius}) => bradius? bradius : "4px !important;"};
        padding-left: ${({ is_icon })=> is_icon ? "20px !important": 'unset'};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important;
    }
`