import React,{ useEffect, useState } from 'react'
import { Divider, Input, Select, Spin, Tabs } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableInput, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem, TableFilter } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { ErrorMessage } from 'formik';
import { create_rate, move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { countries_list, shipment_list, shipport_list, updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
import SelectSearchAirline from '../Common/SelectSearchAirline';
import SelectSearchPort from '../Common/SelectSearchPort';
import styled from 'styled-components';
import * as Yup from 'yup'
import { formatDate, GetContainerSizeLabel, handleKeyPress, removeDuplicatePort } from '../../functions/Utils';
import { SpinContainer, TextAreaItem } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { TableTab } from '../../StyledComponents/Common';
import SelectSearchOceanPort from '../Common/SelectSearchOceanPort';
import SelectSearchShipline from '../Common/SelectSearchShipline';
import DateInput from '../Common/DateInput';
import FormikDateInput from '../Common/FormikDateInput';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import RadioGroups from '../Common/RadioGroups';
import SelectSearchShiplineSurcharge from '../Common/SelectSearchShiplineSurcharge';
import SelectSearchCountry from '../Common/SelectSearchCountry';
import SelectSearchVASOceanPort from '../Common/SelectSearchVASOceanPort';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

const { Option } = Select;

const validationSchema = Yup.object().shape({
    POL: Yup.string().nullable().required('POL is required'),
    POD: Yup.string().nullable().required('POD is required'),
    shipperLine: Yup.string().nullable().required('Shipper Line is required'),
    country: Yup.string().nullable().required('Country required'),
    rate: Yup.string().nullable().required('Rate is required'),
    minRate: Yup.string().nullable().required('Minimum Rate is required'),
    routing: Yup.string().nullable().required('routing is required'),
    Tt: Yup.string().nullable().required('Tt is required'),
    frequency: Yup.string().nullable().required('Frequency is required'),
})

function OceanLCLDetailsPage(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [archieve_loading, setArchiveLoading] = useState(false);
    const [type, setType] = useState(props.type);
    // const [tabType, setTabType] = useState("air");
    const [state, setState] = useState({
        shipline_list: [],
        countrie: [],
        shipport_list: [],
    });

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/rate-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=> {
        setState((prevState) => {
            return {
                ...prevState,
                shipline_list: props?.shipline_list,
                shipport_list: props?.shipport_list
            }
        })
    },[props?.shipline_list,props?.shipport_list])

    useEffect(()=>{
        // dispatch(shipment_list())
        // .then(res=>{
        //     // console.log('login res => ',res);
        //     if(res?.payload?.status === 200 ){
        //         setState((prevState) => {
        //             return {
        //                 ...prevState,
        //                 shipline_list: res?.payload?.data?.data?.data
        //             }
        //         })
        //     }
        // })
        // .catch(err=>{
        //     console.error(err);
        // })

        dispatch(countries_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                const countries = res?.payload?.data?.data.filter((item, index, arr) => {
                    return arr.findIndex(t => t.country_name === item.country_name) === index;
                  });
                //   console.log(countries);
                setState((prevState) => {
                    return {
                        ...prevState,
                        countries: countries
                    }
                })
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })

        // dispatch(shipport_list())
        // .then(res=>{
        //     // console.log('login res => ',res);
        //     if(res?.payload?.status === 200 ){
        //         let shipports = res?.payload?.data?.data
        //         const indianShipports = shipports.filter(shipport => shipport.country_name === 'INDIA' || shipport.country_name === 'India');
        //         indianShipports.sort((a, b) => a.port_name.localeCompare(b.port_name));
                
        //         const foreignShipports = shipports.filter(shipport => shipport.country_name !== 'India' || shipport.country_name !== 'INDIA');
        //         foreignShipports.sort((a, b) => {
        //             if (a.country === b.country) {
        //                 return a.port_name.localeCompare(b.port_name);
        //             }
        //             return a.country.localeCompare(b.country);
        //         });
        //         const sortedShipports = indianShipports.concat(foreignShipports);
        //         setState((prevState) => {
        //             return {
        //                 ...prevState,
        //                 shipport_list: sortedShipports
        //             }
        //         })
        //         setLoading(false)
        //     }
        // })
        // .catch(err=>{
        //     console.error(err);
        // })
        // setLoading(false)
    },[])

    const handleCancel = (resetForm) => {
        setType("view")
        props.setType("view")
        resetForm()
    }

    const handleEdit = () => {
        setType("edit")
        props.setType("edit")
    }
    const handleSelectChange = (e,name,setFieldValue)=> {
        setFieldValue([name],e)
    }

    const handleArchive = () => {
        setArchiveLoading(true)
        let rate_id = props.instance._id
          const values = {
            rate_id : rate_id,
            customType: "Ocean"
          }
          dispatch(move_rate_archive(values))
          .then(res=>{
              if(res?.payload?.data?.statusCode === 200 ){
                //   console.log(res?.payload?.data?.data);
                  dispatch(updatenotificationdata(res?.payload?.data))
                  setArchiveLoading(false)
                  props.setPageType("table")
                  props.setCallList(true)
              }
          })
          .catch(err=>{
              console.error(err);
          })
      }

    const DateChange = (date, dateString,setFieldValue) => {
        setFieldValue("validity", date)
    };

    const initialValues = {
        POL: props.instance?.POL ? props.instance?.POL : null,
        POD: props.instance?.POD ? props.instance?.POD : null,
        shipperLine: props.instance?.shipperLine ? props.instance?.shipperLine : null,
        country: props.instance?.country ? props.instance?.country : null,
        rate: props.instance?.rate ? props.instance?.rate : null,
        minRate: props.instance?.minRate ? props.instance?.minRate : null,
        routing: props.instance?.routing ? props.instance?.routing : null,
        Tt: props.instance?.Tt ? props.instance?.Tt : null,
        frequency: props.instance?.frequency ? props.instance?.frequency : null,
        customType: "Ocean",
        id: props.instance?._id ? props.instance?._id : '', 
      }
   
  

    const frequencies = [
        {
            value: "DAILY",
            name: "DAILY"
        },
        {
            value: "WEEKLY",
            name: "WEEKLY"
        },{
            value: "MONTHLY",
            name: "MONTHLY"
        }
    ]

    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
        return (
            <FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async(values,{resetForm}) => {
                    setSpinner(true)
                    let param_data = {
                        "customType": "Ocean",
                        "POL": values.POL,
                        "POD": values.POD,
                        "Shipper Line": values.shipperLine,
                        "Country": values.country,
                        "Rate/CBM": values.rate,
                        "Minimum Rate": values.minRate,
                        "Routing": values.routing,
                        "T/T": values.Tt,
                        "Frequency": values.frequency,
                        "id" : values.id,
                        "oceanType": "LCL"
                    }
                    if(type === 'edit'){
                        try {
                            const result = await axios.put(
                                // `https://dev.sgate-search.2.mtlstaging.com/query/update/${values.id}`,
                                `${process.env.REACT_APP_API_BASE_URL_NODE}query/update/${values.id}`,
                                param_data,
                                { headers: { Authorization: `Bearer ${token}` } }
                                );
                                if(result.status === 200){
                                    let data = {
                                        message : result.data.message,
                                        statusCode: 200,
                                        success: result.data.success
                                    }
                                    dispatch(updatenotificationdata(data))
                                    setSpinner(false);
                                    // resetForm()
                                    props.setCallList(true)
                                    props.setPageType("table")
                                }
                        } catch (error) {
                            console.log(error);
                            setSpinner(false);
                        }
                    }else {
                        try {
                            const result = await axios.post(
                                // "https://dev.sgate-search.2.mtlstaging.com/query/manual",
                                `${process.env.REACT_APP_API_BASE_URL_NODE}query/manual`,
                                param_data,
                                { headers: { Authorization: `Bearer ${token}` } }
                                );
                                if(result.status === 200){
                                    let data = {
                                        message : result.data.message,
                                        statusCode: 200,
                                        success: result.data.status
                                    }
                                    dispatch(updatenotificationdata(data))
                                    setSpinner(false);
                                    resetForm()
                                    props.setCallList(true)
                                    props.setPageType("table")
                                }
                        } catch (error) {
                            console.log(error);
                            setSpinner(false);
                        }
                    }
                    
            }}
            >
            {({ values,setFieldValue, handleChange, handleBlur, resetForm,errors }) => (
                <FormItem>
                    <DetailContainer>
                    <HeadContainer>
                    <TableFilter>
                        <>
                        {/* {type === 'create'? 
                            <>
                                <TableTab className='first' active={props.tabType === 'air'} onClick={()=> props.setTabType('air')}>Air</TableTab>
                                <Divider type="vertical" />
                            </>
                        : null} */}
                        <TableTab className={type === 'create'? "first" : 'single-tab'} active={props.tabType === 'ocean'} onClick={()=> props.setTabType('ocean')}>Ocean</TableTab>
                        </>
                        <TableFilter className='mb-2'>
                            <RadioGroups datas={props.sub_filter} value={props.subTabType} handleClick={Object.keys(props.instance).length === 0 ? props.handleRadioClick : null} name="ocean" />
                        </TableFilter>
                    </TableFilter>
                    {/* <Title>Air</Title> */}
                    <TopButtonDiv className='mb-3'>
                            {/* {type === 'view'?
                                <AddButton title="Move To Archive" icon_type="archive" handleClick={()=> handleArchive()} loading={archieve_loading} />
                            : null} */}
                        {type === 'edit' || type === 'create' ? 
                        <>  {type === 'edit' ? <SubmitButton type='button' title="Cancel" is_icon={false} handleClick={()=> handleCancel(resetForm)} /> : null}
                            <SubmitButton type='submit' title="save" is_save={true} loading={spinner} />
                        </>
                        : <SubmitButton type="button" title="Edit" icon_type="edit" handleClick={()=> handleEdit()} /> }
                    </TopButtonDiv>
                    
            </HeadContainer>
                <TopContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>POL*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="POL" setFieldValue={setFieldValue} value={values.POL} options={state.shipport_list} width="100%" bradius="8px" placeholder="POL" />
                                <ErrorMessage name="POL" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.POL}</RowValue> }
                            
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Country*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchCountry name="country" value={values.country} setFieldValue={setFieldValue} options={state.countries} 
                                width="100%" bradius="8px" placeholder="Country" />
                                <ErrorMessage name="country" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.country}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Rate*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="rate"
                                    name="rate"
                                    value={values.rate}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Rate'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="rate" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.rate}</RowValue> }
                        </DetailRow>
                        
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Routing*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="routing"
                                    name="routing"
                                    value={values.routing}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Routing'
                                    onChange={handleChange}
                                    // onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="routing" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.routing}</RowValue> }
                        </DetailRow>
                        
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Frequency*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <ItemSelect
                                    size="large"
                                    id="frequency"
                                    name="frequency"
                                    placeholder="Frequency"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => handleSelectChange(e,"frequency",setFieldValue)}
                                    options={frequencies}
                                    value={values.frequency}
                                />
                                <ErrorMessage name="frequency" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.frequency}</RowValue> }
                        </DetailRow>
                        
                    </LeftContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>POD*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchVASOceanPort name="POD" setFieldValue={setFieldValue} value={values.POD} options={state.shipport_list} width="100%" bradius="8px" placeholder="POD" />
                                <ErrorMessage name="POD" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.POD}</RowValue> }
                            
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Shipper Line*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchShiplineSurcharge name="shipperLine" value={values.shipperLine} setFieldValue={setFieldValue} options={state.shipline_list} 
                                width="100%" bradius="8px" placeholder="Shipper Line" />
                                <ErrorMessage name="shipperLine" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.shipperLine}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Minimunm Rate*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="minRate"
                                    name="minRate"
                                    value={values.minRate}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='minRate'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="minRate" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.minRate}</RowValue> }
                        </DetailRow>
                        
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>TT*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="Tt"
                                    name="Tt"
                                    value={values.Tt}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='TT'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="Tt" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.Tt}</RowValue> }
                        </DetailRow>
                        <DetailRow></DetailRow>
                    </LeftContent>
                </TopContent>
                
            </DetailContainer>
                </FormItem>
            )}
            </FormikForm>
            
        )
    }
}

export default OceanLCLDetailsPage


const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important
    }
    /* & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    } */
`