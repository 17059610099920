import { DatePicker } from 'antd'
import React from 'react'
import moment from 'moment';
import styled from 'styled-components/macro';

function DateInput(props) {
  
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current <= moment().endOf('day').subtract(1, 'day');
  }
  return (
    <div>
      <DatePickerItem onChange={props.DateChange} placeholder={props.placeholder ? props.placeholder : "Select Date"}
       value={props.value} disabled={props.disabled? props.disabled : false} disabledDate={props.disabledate? disabledDate : null}
       />
    </div>
  )
}

export default DateInput

const DatePickerItem = styled(DatePicker)`
  :hover,:focus,:not(:focus) {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
  }
`