import React from 'react'
import { Select, Space, DatePicker } from 'antd';
import styled from 'styled-components/macro';

function FilterDatePicker(props) {
    const onChange = (date, dateString) => {
        props.setState((prevState)=> {
            return {
                ...prevState,
                [props.name]: date
            }
        })
      };
  return (
    <SpaceItem wrap>
        <DateItem size="large" format="YYYY-MM-DD" placeholder='DD MM YYYY' onChange={(date, dateString)=> onChange(date, dateString,props.name)} />
    </SpaceItem>
  )
}

export default FilterDatePicker

const SpaceItem = styled(Space)`
    position: relative;
`
const DateItem = styled(DatePicker)`
    &.ant-picker {
        border-radius: 8px !important;
        background-color: #F5F8FA !important;
        border: none !important;
        width: 135px;
    }
    input {
        color: #A1A5B7 !important;
        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        padding: 4px 0px !important;
        /* ::placeholder {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
        } */
    }

   
`