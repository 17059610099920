import { Button, Pagination, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import AcceptedQuoteDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteDetails'
import AcceptedQuoteOceanDetails from '../components/AcceptedQuoteDetails/AcceptedQuoteOceanDetails'
import CustomeHeader from '../components/Common/CustomeHeader'
import SimpleTable from '../components/Common/SimpleTable'
import ViewButton from '../components/Common/ViewButton'
import { get_accepted_list } from '../store/Queries/QuerySlice'
import { get_user_list } from '../store/UserManagement/UserManagementSlice'
import { LeftHead, SubHeadContainer, SubHeadTitle, TablePagination } from '../StyledComponents/Common'
import {  ContentContainer, TableTitle, TableTop } from '../StyledComponents/CreateKeyAccountManager'
import { MainContainer } from '../StyledComponents/Dashboard'
import { IconImg } from '../StyledComponents/Header'
import { SpinContainer } from '../StyledComponents/Queries'

function AcceptedQuoteManagement() {
    const userroles = JSON.parse(localStorage.getItem('user')).userroles;
    // const userroles = "ocean_admin"
    const dispatch = useDispatch()
    const [pageType, setPageType] = useState("table");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [instance, setInstance] = useState({});
    const [query_instance, setQueryInstance] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=>{
        if(pageType === 'table'){
            setQueryInstance({})
            let admin_role = ""
            if(userroles === 'air_admin'){
                admin_role = "Air"
            }else if(userroles === 'ocean_admin'){
                admin_role = "Ocean"
            }
            let data = {
                admin_role: admin_role
              }
            dispatch(get_accepted_list(data))
            .then(res=>{
                // console.log('login res => ',res);
                if(res?.payload?.data?.statusCode === 200 ){
                    setData(res?.payload?.data?.result)
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.error(err);
            })
        }
        // setIsEdit(false)
    },[pageType])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleView = (record) => {
        setInstance(record)
        setPageType("view_page")
    }

    const columns = [
        {
            title: 'ORDER ID',
            dataIndex: 'queryId',
            render: (text, record, index) => {
                return <span>{(index + 1) + ((currentPage - 1) * 10)}</span>;
              },
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            render: (text, record,index) => (
                <span>{record?.customer_details?.full_name}</span>
              ),
        },
        {
            title: 'COMPANY NAME',
            dataIndex: 'company_name',
            render: (text, record,index) => (
                <span>{record?.customer_details?.company_name}</span>
              ),
        },
        {
            title: 'FRIEGHT TYPE',
            dataIndex: 'carrier_type',
        },
        {
            title: userroles === 'ocean_admin'? 'SHIPLINE' : 'AIRLINE',
            dataIndex: 'airline_shipline',
            render: (text, record) => (
                userroles === 'ocean_admin'?
                <span>{record?.bookeditem ? JSON.parse(record?.bookeditem)?.line : ""}</span> : 
                <span>{record?.bookeditem ? JSON.parse(record?.bookeditem)?.airlines : ""}</span>
              ),
        },
        {
            title: 'CHARGEBLE WEIGHT',
            dataIndex: 'chargeble_weight',
            render: (text, record) => (
                userroles === 'ocean_admin'? 
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.totalWeight).toFixed(2) : "0.00"} KG</span>
                :
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.totalWeight).toFixed(2) : "0.00"} {record?.querySearch?.totalWeightUnit}</span>
              ),
        },
        {
            title: userroles === 'ocean_admin'? 'SHIPMENT RATE' : 'FRIEGHT RATE',
            dataIndex: 'freight_rate',
            render: (text, record) => (
                userroles === 'ocean_admin'? 
                <span>{record?.bookeditem ? Number(JSON.parse(record?.bookeditem)?.USD_totalShipmentRate).toFixed(2) : "0.00"}</span>
                :
                <span>{record?.bookeditem? Number(JSON.parse(record?.bookeditem)?.totalFreightRate).toFixed(2): "0.00"}</span>
              ),
        },
        {
            title: 'PAYMENT STATUS',
            dataIndex: 'payment_status',
            render: (text, record) => (
                <StatusText status={record?.payment_status}>{record?.payment_status}</StatusText>
              ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (text, record) => (
                <ViewButton handleClick={handleView} data={record} />
              ),
        }
      ];
      
    // const data1 = [
    //     {
    //         order_id: "SG234567H",
    //         name: "Devon Lane",
    //         company_name: "IBM",
    //         freight_type: "Air",
    //         airline_shipline: "Air India",
    //         chargeble_weight: "$627.50",
    //         freight_rate: "$627.50",
    //         payment_status: "Paid",
    //     },
    //     {
    //         order_id: "SG234567H",
    //         name: "Annette Black",
    //         company_name: "MasterCard",
    //         freight_type: "Ocean",
    //         airline_shipline: "MSC",
    //         chargeble_weight: "$137.73",
    //         freight_rate: "$137.73",
    //         payment_status: "Paid",
    //     },
    //     {
    //         order_id: "SG234567H",
    //         name: "Cody Fisher",
    //         company_name: "eBay",
    //         freight_type: "Air",
    //         airline_shipline: "Jet Airways",
    //         chargeble_weight: "$699.41",
    //         freight_rate: "$699.41",
    //         payment_status: "Pending",
    //     },
    // ]
  return (
    <MainContainer>
      <CustomeHeader title="Accepted Quotation Management" description="View all accepted quotation by every user one by one." />
      {pageType === 'table'? <>
        <ContentContainer>
            <TableTop>
                <TableTitle>Quotation List</TableTitle>
            </TableTop>
            {loading? 
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>: 
                <SimpleTable currentPage={currentPage} pageSize={pageSize} columns={columns} data={data} scroll={true} rowKey="queryId" showScroll={true} />
            }
        </ContentContainer>
        {data?.length ? 
            <TablePagination
                style={{ marginTop: 16, textAlign: 'center' }}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                total={data?.length? data.length : 10}
                showSizeChanger={false}
                showQuickJumper={false}
            />
        : null}
        
      </>
        :pageType === 'view_page'? 
        <>
           <ContentContainer>
                <SubHeadContainer className='flex-column'>
                    <LeftHead>
                        <IconImg onClick={()=> setPageType("table")} src={require('../assets/images/back_page_arrow.png')} />
                        <SubHeadTitle>Details</SubHeadTitle>
                    </LeftHead>
                   {/* <UserIDContainer>
                    <UserText>ORDER ID</UserText>
                    <UserText className='value'>SG234567H</UserText>
                   </UserIDContainer> */}
                </SubHeadContainer>
                {instance.carrier_type === 'Ocean Cargo'?
                    <AcceptedQuoteOceanDetails instance={instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                :
                    <AcceptedQuoteDetails instance={instance} setQueryInstance={setQueryInstance} setPageType={setPageType} />
                }
            </ContentContainer>
        </>
       
        
        : null}
      
      
    </MainContainer>
  )
}

export default AcceptedQuoteManagement

// const StatusText = styled.span`
//     font-family: 'Rubik';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     color: ${({ text }) => text === 'Pending' ? '#FF3F3B' : '#3F4254'};
// `

const StatusText = styled.span`
    color: ${({status})=> status === 'Payment Confirmed' || status === 'SUCCESS' ? '#08C004': status === 'Payment Rejected' || status === 'REJECTED'? '#FF3F3B' : status === "Refund Completed"? '#e85856' : status === 'Waiting for Confirmation' || status === 'PENDING'? '#FFAF00':'#3F4254' };
    font-weight: 700;
`