import React,{ useEffect, useState } from 'react'
import { Divider, Input, Select, Spin, Tabs } from 'antd';
import { DetailContainer, FormItem, FormikForm } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import { DetailRow, LeftContent, MidContent,BottomContent, RightContent, RowValue, TableInput, TableItem, TDItem, THeadItem, THItem, Title, TitleDiv, TitleRow, TopButtonDiv, TopContent, TRItem, TableFilter } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import { ErrorMessage } from 'formik';
import { create_rate, move_rate_archive } from '../../store/RateManagement/RateManagementSlice';
import { useDispatch } from 'react-redux';
import { countries_list, shipment_list, shipport_list, state_list, updatenotificationdata } from '../../store/Common/CommonSlice';
import axios from 'axios';
import SelectSearchAirline from '../Common/SelectSearchAirline';
import SelectSearchPort from '../Common/SelectSearchPort';
import styled from 'styled-components';
import * as Yup from 'yup'
import { formatDate, GetContainerSizeLabel, handleKeyPress } from '../../functions/Utils';
import { SpinContainer, TextAreaItem } from '../../StyledComponents/Queries';
import AddButton from '../Common/AddButton';
import { TableTab } from '../../StyledComponents/Common';
import SelectSearchOceanPort from '../Common/SelectSearchOceanPort';
import SelectSearchShipline from '../Common/SelectSearchShipline';
import DateInput from '../Common/DateInput';
import FormikDateInput from '../Common/FormikDateInput';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import RadioGroups from '../Common/RadioGroups';
import SelectSearchShiplineSurcharge from '../Common/SelectSearchShiplineSurcharge';
import SelectSearchCountry from '../Common/SelectSearchCountry';
import SelectSearchState from '../Common/SelectSearchState';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';

const { Option } = Select;

const validationSchema = Yup.object().shape({
    country: Yup.string().nullable().required('Country is required'),
    states: Yup.string().when('country', {
        is: (val) => ['India', 'INDIA'].includes(val),
        then: Yup.string().nullable().required('State is required'),
        otherwise: Yup.string().nullable()
    }),
    distance_lower_slab: Yup.string().nullable().required('Distance Low Slab is required'),
    distance_upper_slab: Yup.string().nullable().required('Distance Upper Slab is required'),
    min_rate: Yup.string().nullable().required('Min Rate required'),
    wsr1: Yup.string().nullable().required('wsr1 is required'),
    wsr2: Yup.string().nullable().required('wsr2 is required'),
    wsr3: Yup.string().nullable().required('wsr3 is required'),
    wsr4: Yup.string().nullable().required('wsr4 is required'),
    wsr5: Yup.string().nullable().required('wsr5 is required'),
    wsr6: Yup.string().nullable().required('wsr6 is required'),
    wsr7: Yup.string().nullable().required('wsr7 is required'),
    wsr8: Yup.string().nullable().required('wsr8 is required'),
    wsr9: Yup.string().nullable().required('wsr9 is required'),
    wsr10: Yup.string().nullable().required('wsr10 is required'),
    wsr11: Yup.string().nullable().required('wsr11 is required'),
    wsr12: Yup.string().nullable().required('wsr2 is required'),
    wsr13: Yup.string().nullable().required('wsr13 is required'),
    wsr14: Yup.string().nullable().required('wsr14 is required'),
})

function TransportationRateDetailPage(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken');
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState(props.type);
    const [state, setState] = useState({
        countrie: [],
        state_list: [],
    });

    const location = useLocation();
    const [isLoad, setIsLoad] = useState(true);
    useEffect(()=> {
        if(location.pathname === '/rate-management'){
        if(isLoad === false){
            props.setPageType("table")
        }else {
            setIsLoad(false)
        }
        }
    },[location])

    useEffect(()=>{
        dispatch(countries_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                const countries = res?.payload?.data?.data.filter((item, index, arr) => {
                    return arr.findIndex(t => t.country_name === item.country_name) === index;
                  });
                //   console.log(countries);
                setState((prevState) => {
                    return {
                        ...prevState,
                        countries: countries
                    }
                })
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })

        dispatch(state_list())
        .then(res=>{
            // console.log('login res => ',res);
            if(res?.payload?.status === 200 ){
                setState((prevState) => {
                    return {
                        ...prevState,
                        state_list: res?.payload?.data?.data
                    }
                })
                setLoading(false)
            }
        })
        .catch(err=>{
            console.error(err);
        })
        
    },[])

    const handleCancel = (resetForm) => {
        setType("view")
        props.setType("view")
        resetForm()
    }

    const handleEdit = () => {
        setType("edit")
        props.setType("edit")
    }
    const handleSelectChange = (e,name,setFieldValue)=> {
        setFieldValue([name],e)
    }


    const initialValues = {
        country: props.instance?.country ? props.instance?.country : null,
        states: props.instance?.state ? props.instance?.state : null,
        distance_lower_slab: props.instance?.distLowerSlab ? props.instance?.distLowerSlab : 0,
        distance_upper_slab: props.instance?.distUpperSlab ? props.instance?.distUpperSlab : 0,
        min_rate: props.instance?.minRate ? props.instance?.minRate : 0,
        wsr1: props.instance?.WSR1 ? props.instance?.WSR1 : 0,
        wsr2: props.instance?.WSR2 ? props.instance?.WSR2 : 0,
        wsr3: props.instance?.WSR3 ? props.instance?.WSR3 : 0,
        wsr4: props.instance?.WSR4 ? props.instance?.WSR4 : 0,
        wsr5: props.instance?.WSR5 ? props.instance?.WSR5 : 0,
        wsr6: props.instance?.WSR6 ? props.instance?.WSR6 : 0,
        wsr7: props.instance?.WSR7 ? props.instance?.WSR7 : 0,
        wsr8: props.instance?.WSR8 ? props.instance?.WSR8 : 0,
        wsr9: props.instance?.WSR9 ? props.instance?.WSR9 : 0,
        wsr10: props.instance?.WSR10 ? props.instance?.WSR10 : 0,
        wsr11: props.instance?.WSR11 ? props.instance?.WSR11 : 0,
        wsr12: props.instance?.WSR12 ? props.instance?.WSR12 : 0,
        wsr13: props.instance?.WSR13 ? props.instance?.WSR13 : 0,
        wsr14: props.instance?.WSR14 ? props.instance?.WSR14 : 0,
        id: props.instance?._id ? props.instance?._id : '', 
      }
   
  

    

    if(loading){
        return (
                <SpinContainer className='user-quote'>
                    <Spin />
                </SpinContainer>
            )
    }else {
        return (
            <FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async(values,{resetForm}) => {
                    setSpinner(true)
                    let states = values.states
                    if(values.country !== "India" && values.country !== "INDIA"){
                        states = ""
                    }
                    let data = {
                        "Country": values.country,
                        "State": states,
                        "Distance Lower Slab": values.distance_lower_slab,
                        "Distance Upper Slab": values.distance_upper_slab,
                        "MinRate": values.min_rate,
                        "WSR-1(0-50)": values.wsr1,
                        "WSR-2(50-100)": values.wsr2,
                        "WSR-3(100-200)": values.wsr3,
                        "WSR-4(200-300)": values.wsr4,
                        "WSR-5(300-400)": values.wsr5,
                        "WSR-6(400-500)": values.wsr6,
                        "WSR-7(500-750)": values.wsr7,
                        "WSR-8(750-1000)": values.wsr8,
                        "WSR-9(1000-2000)": values.wsr9,
                        "WSR-10(2000-3500)": values.wsr10,
                        "WSR-11(3500-5000)": values.wsr11,
                        "WSR-12(5000-7500)": values.wsr12,
                        "WSR-13(7500-9000)": values.wsr13,
                        "WSR-14(>9000)": values.wsr14,
                    }
                    try {
                        const result = await axios.post(
                            `${process.env.REACT_APP_API_BASE_URL_NODE}query/addtransport`,
                            data,
                            { headers: { Authorization: `Bearer ${token}` } }
                            );
                            if(result.status === 200){
                                let data = {
                                    message : result.data.message,
                                    statusCode: 200,
                                    success: result.data.status
                                }
                                dispatch(updatenotificationdata(data))
                                setSpinner(false);
                                resetForm()
                                props.setCallList(true)
                                props.setPageType("table")
                            }
                    } catch (error) {
                        console.log(error);
                        setSpinner(false);
                    }
                    
            }}
            >
            {({ values,setFieldValue, handleChange, handleBlur, resetForm,errors }) => (
                <FormItem>
                    <DetailContainer>
                    <HeadContainer className='flexEnd'>
                    
                        <TopButtonDiv className='mb-3'>
                            {type === 'edit' || type === 'create' ? 
                            <>  {type === 'edit' ? <SubmitButton type='button' title="Cancel" is_icon={false} handleClick={()=> handleCancel(resetForm)} /> : null}
                                <SubmitButton type='submit' title="save" is_save={true} loading={spinner} />
                            </>
                            : <SubmitButton type="button" title="Edit" icon_type="edit" handleClick={()=> handleEdit()} /> }
                        </TopButtonDiv>
                    
                    </HeadContainer>
                <TopContent>
                    <LeftContent>
                        <DetailRow>
                            <TitleDiv>
                            <TitleRow>Country*</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <SelectSearchCountry name="country" value={values.country} setFieldValue={setFieldValue} options={state.countries} 
                                width="100%" bradius="8px" placeholder="Country" />
                                <ErrorMessage name="country" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.country}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Distance Lower Slab</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="distance_lower_slab"
                                    name="distance_lower_slab"
                                    value={values.distance_lower_slab}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Distance Lower Slab'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="distance_lower_slab" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.distance_lower_slab}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Min Rate</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="min_rate"
                                    name="min_rate"
                                    value={values.min_rate}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Min Rate'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="min_rate" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.min_rate}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-2(50-100)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr2"
                                    name="wsr2"
                                    value={values.wsr2}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-2'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr2" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr2}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-4(200-300)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr4"
                                    name="wsr4"
                                    value={values.wsr4}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-4'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr4" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr4}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-6(400-500)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr6"
                                    name="wsr6"
                                    value={values.wsr6}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-6'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr6" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr6}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-8(750-1000)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr8"
                                    name="wsr8"
                                    value={values.wsr8}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-8'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr8" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr8}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-10(2000-3500)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr10"
                                    name="wsr10"
                                    value={values.wsr10}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-10'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr10" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr10}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-12(5000-7500)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr12"
                                    name="wsr12"
                                    value={values.wsr12}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-12'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr12" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr12}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>{"WSR-14(>9000)"}</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr14"
                                    name="wsr14"
                                    value={values.wsr14}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-14'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr14" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr14}</RowValue> }
                        </DetailRow>
                    </LeftContent>
                    <LeftContent>
                        {values.country === "INDIA" || values.country === "India" ?
                            <DetailRow>
                                <TitleDiv>
                                    <TitleRow>State</TitleRow>
                                </TitleDiv>
                                {type === 'edit' || type === 'create' ?
                                <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                    <SelectSearchState name="states" value={values.states} setFieldValue={setFieldValue} options={state.state_list} 
                                    width="100%" bradius="8px" placeholder="State" />
                                    <ErrorMessage name="states" component="div" style={{ color: 'red',fontSize: '12px' }} />
                                </div>
                                :
                                <RowValue>{values.states}</RowValue> }
                            </DetailRow>
                        : <DetailRow></DetailRow>}
                        
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>Distance Upper Slab</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="distance_upper_slab"
                                    name="distance_upper_slab"
                                    value={values.distance_upper_slab}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='Distance Upper Slab'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="distance_upper_slab" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.distance_upper_slab}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-1(0-50)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr1"
                                    name="wsr1"
                                    value={values.wsr1}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-1'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr1" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr1}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-3(100-200)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr3"
                                    name="wsr3"
                                    value={values.wsr3}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-3'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr3" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr3}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-5(300-400)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr5"
                                    name="wsr5"
                                    value={values.wsr5}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-5'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr5" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr5}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-7(500-750)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr7"
                                    name="wsr7"
                                    value={values.wsr7}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-7'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr7" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr7}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-9(1000-2000)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr9"
                                    name="wsr9"
                                    value={values.wsr9}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-9'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr9" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr9}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-11(3500-5000)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr11"
                                    name="wsr11"
                                    value={values.wsr11}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-11'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr11" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr11}</RowValue> }
                        </DetailRow>
                        <DetailRow>
                            <TitleDiv>
                                <TitleRow>WSR-13(7500-9000)</TitleRow>
                            </TitleDiv>
                            {type === 'edit' || type === 'create' ?
                            <div style={{ display: 'flex',flexDirection: 'column', width: '50%'}}>
                                <Input
                                    id="wsr13"
                                    name="wsr13"
                                    value={values.wsr13}
                                    onBlur={handleBlur}
                                    size="large"
                                    placeholder='WSR-13'
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <ErrorMessage name="wsr13" component="div" style={{ color: 'red',fontSize: '12px' }} />
                            </div>
                            :
                            <RowValue>{values.wsr13}</RowValue> }
                        </DetailRow>

                    </LeftContent>
                </TopContent>
                
            </DetailContainer>
                </FormItem>
            )}
            </FormikForm>
            
        )
    }
}

export default TransportationRateDetailPage


const ItemSelect = styled(Select)`
    & .ant-select-selector {
        border-radius: 8px !important;
        padding: ${({ pdg }) => pdg? pdg : "unset"};
    }
    & .ant-select-selection-placeholder {
        color: #d9d9d9 !important;
        font-size: 16px;
        font-weight: 400;
    }
    & .ant-select-arrow {
        color: #d9d9d9 !important
    }
    /* & .ant-select-selection-item {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    } */
`