import { DatePicker } from 'antd'
import React from 'react'
import moment from 'moment';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

function ContainerDate(props) {
  
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current <= moment().endOf('day').subtract(1, 'day');
  }
  let value = props.value
  const dateValue = typeof value === 'string' ? dayjs(value) : value;

  return (
    <div>
      <DatePickerItem onChange={(date,dateString)=> props.DateChange(date,dateString,props.index)} placeholder={props.placeholder ? props.placeholder : "Select Date"}
       value={dateValue} disabled={props.disabled? props.disabled : false} disabledDate={props.disabledate? disabledDate : null}
       />
    </div>
  )
}

export default ContainerDate

const DatePickerItem = styled(DatePicker)`
  :hover,:focus,:not(:focus) {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
  }
`