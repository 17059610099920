import React,{ useState } from 'react'
import { Tabs } from 'antd';
import { TabItem,DetailContainer, TabImg, TabHead, TabTitle, TabPaneItem } from '../../StyledComponents/CreateKeyAccountManager'
import { HeadContainer } from '../../StyledComponents/Dashboard';
import AddButton from '../Common/AddButton';
import { ChargesSection, DetailContent, DetailRow, LeftContent, RightContent, RowValue, Title, TitleDiv, TitleRow, UnitDiv } from '../../StyledComponents/RateManagement';
import SubmitButton from '../Common/SubmitButton';
import RadioGroups from '../Common/RadioGroups';
import SelectItem from '../Common/SelectItem';


const { TabPane } = Tabs;
function CreateRate(props) {
    const [state, setState] = useState({
        freight_type: "air"
    });
    const [currentTab, setCurrentTab] = useState("1");
    const callback = (key)=> {
        setCurrentTab(key)
    }

    const handleSelectChange = (value) => {
        console.log(`selected ${value}`);
      };

    const handleRadioClick = (value,name) => {
        setState((prevState)=> {
            return {
                ...prevState,
                freight_type: value
            }
        })
    }

    const freight_types = [{
        id: "air",
        name: "Air"
    },{
        id: "ocean",
        name: "Ocean"
    }]
    const rate_options = [
        {
        value: 'jack',
        label: 'Jack',
        },
        {
        value: 'lucy',
        label: 'Lucy',
        },
        {
        value: 'Yiminghe',
        label: 'yiminghe',
        },
        {
        value: 'disabled',
        label: 'Disabled',
        disabled: true,
        },
    ]
  return (
    <DetailContainer className='create'>
        <LeftContent className='create'>
            <DetailRow className='create'>
                <TitleDiv className='create-rate'>
                    <TitleRow>Freight Type</TitleRow>
                </TitleDiv>
                <RowValue>
                    <RadioGroups datas={freight_types} value={state.freight_type} handleClick={handleRadioClick} name="freight_type" />
                </RowValue>
            </DetailRow>
            <DetailRow className='create'>
                <TitleDiv className='create-rate'>
                    <TitleRow>Original Air <br /> Freight Charges</TitleRow>
                </TitleDiv>
                <RowValue>₹ 1,23,456.55</RowValue>
            </DetailRow>
            <DetailRow className='create'>
                <TitleDiv className='create-rate'>
                    <TitleRow>Origin Charges</TitleRow>
                </TitleDiv>
                <ChargesSection>
                    <SelectItem handleChange={handleSelectChange} options={rate_options} />
                    <UnitDiv className='unit'>
                        <RowValue>per/ Unit</RowValue>
                        <SelectItem handleChange={handleSelectChange} options={rate_options} width="170px" />
                    </UnitDiv>
                    <UnitDiv className='amount'>
                        <RowValue>Amount</RowValue>
                        <SelectItem handleChange={handleSelectChange} options={rate_options} width="140px" placeholder="0000" is_icon={true} />
                    </UnitDiv>
                    <SelectItem handleChange={handleSelectChange} options={rate_options} width="100px" placeholder=" " is_icon={true} />
                </ChargesSection>
            </DetailRow>
            <DetailRow className='create'>
                <TitleDiv className='create-rate'>
                    <TitleRow>Destination Charges</TitleRow>
                </TitleDiv>
                <ChargesSection>
                    <SelectItem handleChange={handleSelectChange} options={rate_options} />
                    <UnitDiv className='unit'>
                        <RowValue>per/ Unit</RowValue>
                        <SelectItem handleChange={handleSelectChange} options={rate_options} width="170px" />
                    </UnitDiv>
                    <UnitDiv className='amount'>
                        <RowValue>Amount</RowValue>
                        <SelectItem handleChange={handleSelectChange} options={rate_options} width="140px" placeholder="0000" is_icon={true} />
                    </UnitDiv>
                    <SelectItem handleChange={handleSelectChange} options={rate_options} width="100px" placeholder=" " is_icon={true} />
                </ChargesSection>
            </DetailRow>
        </LeftContent>
    </DetailContainer>
  )
}

export default CreateRate
