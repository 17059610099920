import React, { useState } from 'react';
import { Table, Pagination, Empty } from 'antd';
import { TableItem } from '../../StyledComponents/Common';
import styled from 'styled-components';
import { IconImg } from '../../StyledComponents/Header';
import UploadButton from './UploadButton';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updatenotificationdata } from '../../store/Common/CommonSlice';
//import { REACT_APP_API_BASE_URL_NODE } from '../../utils/secondsToTime';



const TransportationTable = (props) => {
    const token = localStorage.getItem('accessToken');
    const dispatch = useDispatch()

    const startIndex = (props.currentPage - 1) * props.pageSize;
    const endIndex = startIndex + props.pageSize;
    const currentData = props?.data?.slice(startIndex, endIndex);

    const [spinner, setSpinner] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleClose = () => {
        props.setModalOpen()
        setSelectedFile(null)
    }



    const handleChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUpload = async() => {
        setSpinner(true)
        try {
            const formData = new FormData();
            formData.append('transportratesheet', selectedFile);
            const result = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL_NODE}query/transportrate`,
            formData,
            { headers: { Authorization: `Bearer ${token}`,"Content-Type": "multipart/form-data" } }
            );
        if(result.status === 200){
                props.setCallList(true)
                let data = {
                    message : result.data.message,
                    statusCode: 200,
                    success: result.data.success
                }
                dispatch(updatenotificationdata(data))
        }
        } catch (error) {
            console.log(error);
            let data = {
                message : error.response.data.message,
                statusCode: 200,
                success: error.response.data.success
            }
            dispatch(updatenotificationdata(data))
        }
        setSpinner(false)
        
    }

    const customNoDataComponent = (
        <Container>
            <HeadDiv>
                <Title>NO DATA</Title>
            </HeadDiv>
            <UploadButton modalOpen={props.modalOpen} handleChange={handleChange} loading={spinner} file_name={selectedFile?.name} handleClick={handleUpload} button_class="mr-5" />
            <span>{selectedFile?.name}</span>
            <UploadDetail>
                Upload latest rates here. Only xls,xlsx,csv files are accepted.
            </UploadDetail>
        </Container>
    );
        
  return (
    <TableContainer>
      <TableItem rowKey={props.rowKey ? props.rowKey : "id"} name={props.name} columns={props.columns} dataSource={currentData} 
        scroll={props.scroll ? {x: 2500,} : null} pagination={false} fpadding={props.fpadding} showScroll={props.showScroll}
      locale={{
        emptyText: customNoDataComponent
      }} />
    </TableContainer>
  );
};

export default TransportationTable;

const TableContainer = styled.div`
  /* overflow-x: hidden;
  width: 100%; */
  
`


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 140px !important; */
    margin: 70px 0px;
`
const HeadDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
`
const UploadDetail = styled.span`
    display: inline-block;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #242424;
`
const Title = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: rgba(255, 175, 0, 0.38);
    /* margin-left: 10px; */
`