import React,{ useState} from 'react'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage, } from 'formik'
import TextError from '../TextErrot'
import {ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, } from 'react-redux'
import { Spin, } from 'antd'
import { useNavigate } from 'react-router-dom'
import { forgotpassword } from '../../store/Authentication/AuthSlice';
import { updatenotificationdata } from '../../store/Common/CommonSlice';

const initialValue = {
    email: '',
}
const validationSchema = Yup.object({
    email: Yup.string().required('Email ID is required').email('Please enter your valid Email ID').matches(/^(?!\s+$).*/, 'This field cannot contain only blankspaces'),
})
const ForgotPassword = () => {
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = (values, submitProps) => {
        setSpinner(true);
        dispatch(forgotpassword(values))
        .then((res)=>{
            // console.log('login res => ',res);
            dispatch(updatenotificationdata(res?.payload?.data))
            setSpinner(false);
            if(res?.payload?.data?.data?.original?.access_token){
                console.log('login success');
            }
        })
        .catch(err=>{
            console.error(err);
        })
        
    }
    return (
        <>
            <div className="bg-[url('assets/images/formbackground.svg')] bg-cover bg-center">
                <p className="font-semibold text-xl leading-6 text-black">Forgot Password </p>
                <p className="font-normal text-sm leading-6 text-black my-2">Enter registered email id.</p>
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {
                        formik =>{
                            return(
                                <Form autoComplete='off'>
                                    <div className="bg-whites my-5">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor='email' className="py-2 block text-xs font-semibold leading-5 text-formlabel">Email*</label>
                                                <Field type='text' id='email' name='email' placeholder="Enter Your Email Id" className="mt-2 leading-4 px-4 py-3 focus:border focus:outline-0 block w-full text-xs font-normal border rounded-md"/>
                                                <ErrorMessage name='email' component={TextError} />
                                            </div>
                                            <div className="col-span-6">
                                                <button type='submit' className='w-full text-white bg-primary py-3 rounded-md px-5 text-sm font-bold hover:bg-secondary shadow-loginbutton mt-3' disabled={spinner}>
                                                    {
                                                        spinner ?
                                                            <Spin/>
                                                        :
                                                            'Send Reset Link'
                                                    }
                                                </button>
                                            </div>
                                            
                                            {/* <div className="col-span-6">
                                                <button  type='button' onClick={()=> navigate('/auth/signup')} className='w-full text-primary py-3 rounded-lg px-8 text-sm font-bold border-primary hover:bg-secondary hover:text-white shadow-newuser my-5 bg-white sm:bg-transparent border flex items-center justify-between' >
                                                <p>New User? Sign up here</p>
                                                <div className="flex justify-center items-center w-[30px] h-[30px] font-black  rounded-full border-2 border-primary hover:border-white">
                                                    <ArrowRightOutlined className='font-black'/>
                                                </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </>
    )
}

export default ForgotPassword