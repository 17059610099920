import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { httpinstance } from "../../axios/api"

export const userlogin = createAsyncThunk('userlogin', async (values)=>{
    
    try{
        const url = 'auth/login'
        console.log(url,values);
        const response = await httpinstance.post(url,values)
        console.log(response);
        if(response.data?.data?.original?.access_token && response.data?.data?.original?.user){
            localStorage.setItem("accessToken", response.data?.data?.original?.access_token);
            localStorage.setItem("user", JSON.stringify(response.data?.data?.original?.user)
            
             
            );
        }
        // if(response.data?.Data?.token && response.data?.Data?.user){
        //     localStorage.setItem("accessToken", response.data?.Data?.token);
        //     localStorage.setItem("user", JSON.stringify(response.data?.Data?.user));
        // }
        return response
    }catch (err){
        return err.response
    }
})
export const sentotp = createAsyncThunk('sentotp', async (values)=>{
    try{
        const url = 'auth/sentotp'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const verifyotp = createAsyncThunk('verifyotp', async (values)=>{
    try{
        const url = 'auth/verifyloginotp'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const forgotpassword = createAsyncThunk('forgotpassword', async (values)=>{
    try{
        const url = 'forgotpassword'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})
export const setpassword = createAsyncThunk('setpassword', async (values)=>{
    try{
        const url = 'auth/passwordreset'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const checkpasseordresetlink = createAsyncThunk('checkpasseordresetlink', async (values)=>{
    try{
        const url = 'check_reset_password'
        const response = await httpinstance.post(url,values)
        return response
    }catch (err){
        return err.response
    }
})

export const userlogout = createAsyncThunk('userlogout', async (values)=>{
    try{
        const url = 'auth/logout'
        const response = await httpinstance.post(url,values)
        if(response?.data?.statusCode === 200){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
        }
        return response
    }catch (err){
        return err.response
    }
})

const initialState = {
    logedUser : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    authToken: '',
    loading:false,
    message:''
}

const loginSlice = createSlice({
    name : 'loginslice',
    initialState,
    extraReducers:{
        [userlogin.pending]: (state, action) => {
            state.loading = true;
        },
        [userlogin.fulfilled]:(state,action)=>{
            return {...state, logedUser:action.payload?.data?.data?.original?.user, authToken:action.payload?.data?.data?.original?.access_token}
        },
        [userlogin.rejected]: (state, action) => {
            state.user = null;
            state.message = action.payload;
        },
    }
})

export const authuser = (state)=> state.userlogin.logedUser
export const authtoken = (state)=> state.userlogin.authToken

export default loginSlice.reducer