import { Button, Input, Pagination, Popconfirm, Table } from "antd";
import styled from "styled-components/macro";

export const CustomeButton = styled(Button)`
    color: #FFAF00;
    border-color: #FFAF00;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 700;
    :hover {
        color: #020100c1 !important;
        border-color: #020100c1 !important;
        img {
            filter: grayscale(100%) !important;
        }
    }
    img {
        margin: 0px 10px 4px 0px;
    }
    &.short-button {
        padding: 2px 8px !important;
    }
`

export const TableItem = styled(Table)`
    margin-top: 8px;
    th {
        font-size: 12px !important;
        color: #B5B5C3 !important;
        background-color: #fff !important;
        border-start-start-radius: 0px !important;
        border-bottom: 1px dashed #E4E6EF !important;
        ::before {
            content:none !important;
        }
        padding-left: ${({ name })=> name === 'rate_management' ? '0px !important' : 'unset'};
        width: ${({ width }) => width ? width : 'unset'};
        &.custome-class {
            border-bottom: none !important;
            width : unset !important;
        }
        &.text-center {
            text-align: center;
        }
        &.text-right {
            text-align: right;
        }
        &.wdth-50 {
            width: 100% !important;
        }
    }
    th:first-child {
        width : '25%';
    }
    
    td {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #3F4254 !important;
        background-color: #fff !important;
        border-start-start-radius: 0px !important;
        border-bottom: 1px dashed #E4E6EF !important;
        font-family: 'Rubik';
        font-style: normal;
        ::before {
            content:none !important;
        }
        padding: ${({ fpadding })=> fpadding ? fpadding : "25px 16px !important"};
        padding-left: ${({ name })=> name === 'rate_management' ? '0px !important' : 'unset'}
        /* padding: 25px 16px !important; */
    }
    &.quote_section {
        th {
            width: 40% !important;
        }
        th:first-child {
            width : 18% !important;
        }
        td {
            width: 45% !important;
        }
        td:first-child {
            width : 18% !important;
        }
    }
    & .ant-table-content {
        &::-webkit-scrollbar {
            /* display: none; */
            display: ${({showScroll})=> showScroll ? 'block': 'none'};
            height: 6px !important;
        }
        & .ant-table-measure-row {
            display: none !important;
        }
    }
    & .ant-table-body {
        &::-webkit-scrollbar {
            /* display: none; */
            display: ${({showScroll})=> showScroll ? 'block': 'none'};
            width: 6px !important;
        }
        & .ant-table-measure-row {
            display: none !important;
        }
    }
    & .ant-table-header {
        table {
            table-layout: unset !important;
            & .ant-table-thead{
                th {
                    width: 50%;
                }
                & .ant-table-cell-scrollbar {
                    display: none;
                }
            }
        }
    }
`

export const ButtonView = styled(Button)`
    border-radius: 9px;
    background-color: #F5F8FA;
    color: #A1A5B7;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    border: none !important;
    :hover {
        color: #A1A5B7 !important;
    }
`

export const TablePagination = styled(Pagination)`
    li {
        background-color: #FFF !important;
        border-radius: 6px !important;
        a {
                color: #B5B5C3 !important;
                font-size: 12px !important;
                font-weight: 500 !important;
            }
        &.ant-pagination-item-active {
            background-color: #EDF7FF !important;
            color: #FFAF00 !important;
            border: 1px solid #FFAF00 !important;
            border-radius: 6px !important;
            a {
                color: #FFAF00 !important;
            }
        }
        
    }
    
`

export const SubHeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px dotted #E4E6EF; */
    border-bottom: ${({border}) => border === false ? null : '3px dotted #E4E6EF' };
    padding-bottom: ${({ pb })=> pb ? pb : '40px'};
    padding-top: ${({ pt })=> pt ? pt : 'unset'};
    &.summary-head {
        border-bottom: 1px solid #E4E6EF;
        padding: 12px 28px;
    }
    &.flex-column {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding-bottom: 20px;
    }
    &.gap-less {
        gap: 8px;
    }
`
export const LeftHead = styled.div`
    display: flex;
    align-items: center;
    &.multi-head {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
    }
    &.space-btwn {
        justify-content: space-between;
        width: 100%;
    }
`
export const TitleHead = styled.div`
    
`

export const SubHeadTitle = styled.span`
    font-size: 16px;
    color: #181C32;
    font-weight: 600;
    font-family: 'Rubik';
    font-style: normal; 
    margin-left: ${({ml}) => ml ? ml : '25px'};
    &.sub-head {
        font-size: 16px;
        margin-left: 0px;
    }
    &.title1 {
        color: #B5B5C3;
    }
    &.summary-head {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }
    &.sub-title {
        font-size: 14px;
        font-weight: 500;
        margin-left: 15px;
    }
`

export const ButtonSubmit = styled(Button)`
    background-color: #FFAF00;
    color: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px !important;
    font-weight: 700;
    padding: 6px 8px !important;
    :hover {
        border-color: #000000 !important;
        color: #FFFFFF !important;
        background-color: #000000;
    }
    img {
        margin-right: 10px;
    }
    margin-left: ${({ml}) => ml ? ml : '0px'};
    &.no-boxshadow {
        box-shadow: none !important;
    }
    &.icon-class {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-size: 16px !important;
        }
    }
    @media (max-width: 980px) {
        font-size: 13px !important;
    }
`

export const TableTab = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${({ active }) => active ? '#FFAF00' : '#B5B5C3'};
    cursor: pointer;
    margin: 0px 20px;
    &.first {
        margin-left: 0px;
    }
    &.single-tab {
        margin: 0px;
    }
    &.sub-tab {
        font-size: 14px;
    }
`

export const InputContainer = styled.div `
    width: ${({ wd })=> wd? wd : '100%'};
`

export const InputItem = styled(Input) `
    border: 1px solid #E4E6EF;
    border-radius: 4px;
    background: #FFFFFF;
`

export const ItemMenu = styled.span`
    color: #242424;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Rubik';
    font-style: normal;
`
export const StatusText = styled.span`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: ${({ text }) => (text === 'NEW' || text === 'New' || text === 'QueryPending') ? '#FFAF00' : (text === 'Won' || text === 'WON' || text === 'Booked')? '#08C004' : (text === 'LOST' || text === 'Lost' || text === 'Cancelled') ? '#FF3F3B': (text === 'QUOTED' || text === 'Quoted')? '#A39C06': '#3F4254'};
`

export const IconSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
`

export const TextInputItem = styled(Input)`
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    line-height: 1.7;
    &.ant-input {
        :hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    &.normal-placeholder {
        ::placeholder {
            color: #B5B5C3 !important;
            font-size: 13px;
            font-weight: 500;
            font-family: 'Roboto';
            font-style: normal;
        }
    }
    &.container {
        width: 40%;
    }
  
`

export const TextAreaItem = styled(Input)`
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    line-height: 1.7;
    &.ant-input {
        :hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }
    &.normal-placeholder {
        ::placeholder {
            color: #B5B5C3 !important;
            font-size: 13px;
            font-weight: 500;
            font-family: 'Roboto';
            font-style: normal;
        }
    }
    &.container {
        width: 40%;
    }
  
`

export const DocButton = styled(Button)`
    color: #FFAF00;
    border-color: #FFAF00;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    font-style: normal; 
    font-weight: 700;
    :hover {
        color: #FFAF00 !important;
        border-color: #FFAF00 !important;
    }
    img {
        margin: 0px 10px 4px 0px;
    }
    &.active-button {
        color: #FFFFFF;
        background-color: #FFAF00;
        :hover {
            color: #FFFFFF !important;
        }
    }
`
export const SubTabDiv = styled.div`
    display: flex;
    flex-direction: column;
`
export const CustomePopConfirm = styled(Popconfirm)`
    & .ant-btn-primary {
        color: red !important;
        background: #fff !important;
        border: 1px solid red !important;
    }
    
`