import React,{useEffect,lazy,Suspense} from 'react'
import { Route, Routes, Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { menus } from '../routes/routes';
// import Navigation from '../routes/Navigation';
// import DashboardPage from '../pages/DashboardPage';
// import CreateKeyAccountManager from '../pages/CreateKeyAccountManager';
// import RateManagement from '../pages/RateManagement';
// import UserManagement from '../pages/UserManagement';
// import UserManagementAdmin from '../pages/UserManagementAdmin';
// import KYCVerifications from '../pages/KYCVerifications';
// import AcceptedQuoteManagement from '../pages/AcceptedQuoteManagement';
// import Queries from '../pages/Queries';
// import Archives from '../pages/Archives';
// import ExchangeRate from './ExchangeRate';
// import PaymentRequest from './PaymentRequest';
// import TransportationRateManagement from './TransportationRateManagement';
// import RejectedQuoteManagement from './RejectedQuoteManagement';
// import Report from './Report';
// import CustomerViewPage from '../components/report/CustomerViewPage';

const Navigation = lazy(()=> import('../routes/Navigation'))
const DashboardPage = lazy(()=> import('../pages/DashboardPage'))
const CreateKeyAccountManager = lazy(()=> import('../pages/CreateKeyAccountManager'))
const RateManagement = lazy(()=> import('../pages/RateManagement'))
const UserManagement = lazy(()=> import('../pages/UserManagement'))
const UserManagementAdmin = lazy(()=> import('../pages/UserManagementAdmin'))
const Queries = lazy(()=> import('../pages/Queries'))
const KYCVerifications = lazy(()=> import('../pages/KYCVerifications'))
const AcceptedQuoteManagement = lazy(()=> import('../pages/AcceptedQuoteManagement'))
const Archives = lazy(()=> import('../pages/Archives'))
const ExchangeRate = lazy(()=> import('./ExchangeRate'))
const PaymentRequest = lazy(()=> import('./PaymentRequest'))
const TransportationRateManagement = lazy(()=> import('./TransportationRateManagement'))
const RejectedQuoteManagement = lazy(()=> import('./RejectedQuoteManagement'))
const Report = lazy(()=> import('./Report'))
const CustomerViewPage = lazy(()=> import('../components/report/CustomerViewPage'))

const Dashboard = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation();
  const user_role = JSON.parse(localStorage.getItem('user'))?.userroles
  useEffect(() => {
    if(pathname === '/'){
      if(user_role === 'kam'){
        navigate('/user-management')
      }else {
        navigate('/dashboard')
      }
    }
}, []);
  return (
    <div>
        {/* <Dashbooard/> */}
        {/* <Navigation /> */}
        <Routes>
          <Route path='/*' element={<Suspense fallback={<Skeleton active />}><Navigation/></Suspense>}>
            {/* {menus?.map((item,index)=> {
              if(item?.role?.includes(user_role)){
                return <Route
                  key={index}
                  path={item.path}
                  element={item.component}
                />
              }else {
                return <Route
                    key={index}
                    path="*"
                    element={<Navigate to="/" replace />}
                />
              }
            })} */}

              <Route
                  path="dashboard"
                  element={<Suspense fallback={<Skeleton active />}><DashboardPage/></Suspense>}
              />
              <Route
                  path="create-key-account-manager"
                  element={<Suspense fallback={<Skeleton active />}><CreateKeyAccountManager/></Suspense>}
              />
              <Route
                  path="rate-management"
                  element={<Suspense fallback={<Skeleton active />}><RateManagement/></Suspense>}
              />
              <Route
                  path="user-management"
                  element={<Suspense fallback={<Skeleton active />}><UserManagement/></Suspense>}
              />
              <Route
                  path="queries"
                  element={<Suspense fallback={<Skeleton active />}><Queries/></Suspense>}
              />
              <Route
                  path="user-management-admin"
                  element={<Suspense fallback={<Skeleton active />}><UserManagementAdmin/></Suspense>}
              />
              <Route
                  path="kyc-verification"
                  element={<Suspense fallback={<Skeleton active />}><KYCVerifications/></Suspense>}
              />
              <Route
                  path="accepted-quotation-management"
                  element={<Suspense fallback={<Skeleton active />}><AcceptedQuoteManagement/></Suspense>}
              />
              <Route
                  path="archives"
                  element={<Suspense fallback={<Skeleton active />}><Archives/></Suspense>}
              />
              <Route
                  path="exchange-rate"
                  element={<Suspense fallback={<Skeleton active />}><ExchangeRate/></Suspense>}
              />
              <Route
                  path="payment-request"
                  element={<Suspense fallback={<Skeleton active />}><PaymentRequest/></Suspense>}
              />
              <Route
                  path="transportation-rate-management"
                  element={<Suspense fallback={<Skeleton active />}><TransportationRateManagement/></Suspense>}
              />
              <Route
                  path="rejected-quotation-management"
                  element={<Suspense fallback={<Skeleton active />}><RejectedQuoteManagement/></Suspense>}
              />
              <Route
                  path="reports"
                  element={<Suspense fallback={<Skeleton active />}><Report/></Suspense>}
              />
              <Route
                  path="reports/customer-view"
                  element={<Suspense fallback={<Skeleton active />}><CustomerViewPage/></Suspense>}
              />
          </Route>
        </Routes>
    </div>
  )
}

export default Dashboard