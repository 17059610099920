import { Button, Drawer, Avatar, Skeleton,Empty } from "antd";
import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  IconImg,
  NameDiv,
  NameSpan,
  ProfileDiv,
  ProfileSpan,
} from "../../StyledComponents/Header";
import { BellOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  notificationlist,
  updatenotificationCount,
  updatenotificationneworold,
  updatenotificationread,
  updatenotificationreadall,
} from "../../store/Common/CommonSlice";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import shipline from "../../assets/images/active-ship-icon.svg";
import airline from "../../assets/images/flight_icon.svg";
import kamLogo from "../../assets/images/kam_logo.png";
import { SpinContainer } from "../../StyledComponents/Queries";

function AppDrawer(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userroles = user.userroles;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [newCount, setNewCount] = useState(props.count);
  const [defaultLogo, setDefaultLogo] = useState(kamLogo);
  const dispatch = useDispatch();

  useEffect(() => {
    let admin_role = "";
    let username = user?.full_name;
    if (userroles === "air_admin") {
      admin_role = "Air";
      username = "Air Administrator";
      setDefaultLogo(airline);
    } else if (userroles === "ocean_admin") {
      admin_role = "Ocean";
      username = "Ocean Administrator";
      setDefaultLogo(shipline);
    }
  }, []);

  useEffect(() => {
    setList(props.notificationData?.data);
    setLoading(false);
  }, [props.notificationData]);

  const onLoadMore = () => {
    setLoading(true);
    let countvalue = props.count + newCount;
    setNewCount(countvalue);
    dispatch(notificationlist(countvalue))
      .then((res) => {
        if (res?.payload?.data?.statusCode === 200) {
          const newData = res?.payload?.data?.data;
          setList(newData);
          dispatch(updatenotificationCount(res?.payload?.data?.newCount));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const readAll = () => {
    dispatch(updatenotificationreadall());
    props.setOpen(false);
  };
  const handleClose = (id) => {
    props.setOpen(false);
    if (id) {
      dispatch(updatenotificationread(id));
    }
  };
  const ReadNotification = (item) => {
    if (item.id) {
      dispatch(updatenotificationread(item.id));
    }
    if (userroles === "kam" && item.notification_user === "KAM") {
      if (
        item.notification_type === "Quote" ||
        item.notification_type === "Booking"
      ) {
        let queryId = item.queryId;
        navigate(`/queries?queryId=${queryId}`);
      }
    } else {
      if (item.notification_type === "KYC") {
        let kyc_id = item.redirect_id;
        navigate(`/kyc-verification?kyc_id=${kyc_id}`);
      }
      // else if(item.notification_type === "Quote" || item.notification_type === "Payment"){
      //     navigate('/accepted-quotation-management')
      // }
      else if (
        item.notification_type === "Payment" ||
        item.notification_type === "Repayment" ||
        item.notification_type === "Payment Rejected"
      ) {
        let payment_id = item.redirect_id;
        if(item.notification_type === "Repayment"){
          navigate(`/payment-request?repayment_id=${payment_id}`);
        }else {
          navigate(`/payment-request?payment_id=${payment_id}`);
        }
        
      }
    }
    props.setOpen(false);
  };

  return (
    <div>
      <DrawerItem
        header={null}
        placement="right"
        onClose={props.onClose}
        open={props.open}
      >
        {/* <HeaderSection>
          <LeftSpace></LeftSpace>
          <Avatar
            shape="circle"
            onClick={() => props.setOpen(!props.open)}
            size="large"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              padding: "4px",
            }}
            icon={<BellOutlined />}
            className="bg-primary hover:bg-secondary cursor-pointer"
          />
          <NameDiv>
            <NameSpan>{props.userName}</NameSpan>
          </NameDiv>
          <ProfileDiv>
            <IconImg src={defaultLogo} />
          </ProfileDiv>
        </HeaderSection>
        <hr className="border-[#F5F5F5] my-2" /> */}
        {loading? 
        <div className="mt-4 p-4">
            <Skeleton active 
            paragraph={{
                rows: 10,
            }} />
        </div>
        : 
        <div>
          {list && list.length > 0 ? 
          <ContentSection>
          {list?.map((i, index) => (
            <Contents
              index={index}
              reading_status={i.reading_status}
              onClick={() => ReadNotification(i)}
            >
              <div className="flex items-center justify-between gap-1">
                <ContentTitle
                  className="head"
                  reading_status={i.reading_status}
                >
                  {i.message}
                </ContentTitle>
                <div className="h-[24px] w-24px]">
                <RightOutlined />
                </div>
              </div>
              <ContentTitle>{i.notification_type}</ContentTitle>
            </Contents>
          ))}
        </ContentSection>
          :
          <ContentSection>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
           </ContentSection>
          
          }
        
         <hr className="border-[#F5F5F5] my-2" />
         <div className="flex p-5 gap-5 items-center justify-center flex-wrap">
           {newCount < props.notificationData?.notificationCount && (
             <button
               type="button"
               onClick={() => onLoadMore()}
               className="text-white bg-primary hover:bg-secondary font-normal leading-4 rounded-lg text-sm p-2 text-center  flex items-center justify-center gap-2 shadow-searchbox"
             >
               <span>Load More</span>
             </button>
           )}
           {list?.length > 0 && (
             <button
               type="button"
               onClick={readAll}
               className="text-white bg-primary hover:bg-secondary font-normal leading-4 rounded-lg text-sm p-2 text-center  flex items-center justify-center gap-2 shadow-searchbox"
             >
               <span>Mark all as read</span>
             </button>
           )}
         </div>
        </div>
        }
      </DrawerItem>
    </div>
  );
}

export default AppDrawer;

const DrawerItem = styled(Drawer)`
  & .ant-drawer-wrapper-body {
    /* background: #1E1E1E; */
    background: #ffffff;
  }
  & .ant-drawer-header {
    /* display: none; */
  }
  & .ant-drawer-body {
    padding: 0px !important;
  }
`;
const ContentTitle = styled.span`
  font-family: "Rubik";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 11px;
  /* color: #546182; */
  color: rgba(0, 0, 0, 0.88);
  &.head {
    /* font-weight: 600; */
    font-size: 14px;
    /* color: #FFFFFF; */
    /* color: ${({ reading_status }) =>
      reading_status === "unread" ? "#f2b7a2" : "#FFFFFF"}; */
  }
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: ${({ index }) => (index === 0 ? "30px" : "4px")}; */
  margin-top: 4px;
  cursor: pointer;
  padding: 10px 22px;
  background-color: ${({ reading_status }) =>
    reading_status === "unread" ? "#ffaf0014" : "unset"};
  :hover {
    background-color: #f3f3f3;
  }
`;
const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`;
const Title = styled.span`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* color: #FFFFFF; */
`;

const ContentSection = styled.div`
  padding: 24px;
  /* margin-top: 50px; */
`;

const LeftSpace = styled.div`
  width: 30%;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  /* width: 100%; */
  margin-top: 20px;
  padding: 0px 20px;
`;
