import AcceptedQuoteManagement from "../pages/AcceptedQuoteManagement"
import Archives from "../pages/Archives"
import CreateKeyAccountManager from "../pages/CreateKeyAccountManager"
import DashboardPage from "../pages/DashboardPage"
import ExchangeRate from "../pages/ExchangeRate"
import KYCVerifications from "../pages/KYCVerifications"
import Queries from "../pages/Queries"
import RateManagement from "../pages/RateManagement"
import RejectedQuoteManagement from "../pages/RejectedQuoteManagement"
import Report from "../pages/Report"
import TransportationRateManagement from "../pages/TransportationRateManagement"
import UserManagement from "../pages/UserManagement"
import UserManagementAdmin from "../pages/UserManagementAdmin"





export const menus = [
    {
        icon: require("../assets/images/SideBarIcons/dashboard.png"),
        active_icon: require("../assets/images/SideBarIcons/dashboard_active.png"),
        label: "Dashboard",
        path: "/dashboard",
        role: ['admin','air_admin','ocean_admin'],
        component: DashboardPage
    },
    {
        icon: require("../assets/images/SideBarIcons/user_management.png"), 
        active_icon: require("../assets/images/SideBarIcons/user_management_active.png"),
        label: "User Management",
        path: "/user-management-admin",
        role: ['admin','air_admin','ocean_admin'],
        component: UserManagementAdmin
    },
    {
        icon: require("../assets/images/SideBarIcons/user_management.png"),
        active_icon: require("../assets/images/SideBarIcons/user_management_active.png"),
        label: "User Management",
        path: "/user-management",
        role: ['kam'],
        component: UserManagement
    },
    {
        icon: require("../assets/images/SideBarIcons/Vectorkyc.png"),
        active_icon: require("../assets/images/SideBarIcons/Vectorkyc_active.png"),
        label: "KYC Verification",
        path: "/kyc-verification",
        role: ['admin','air_admin','ocean_admin'],
        component: KYCVerifications
    },
    {
        icon: require("../assets/images/SideBarIcons/accepted_quotation.png"),
        active_icon: require("../assets/images/SideBarIcons/accepted_quotation_active.png"),
        label: "Accepted Quotation Management",
        path: "/accepted-quotation-management",
        role: ['admin','air_admin','ocean_admin'],
        component: AcceptedQuoteManagement
    },
    {
        icon: require("../assets/images/SideBarIcons/rejectedicon.png"),
        active_icon: require("../assets/images/SideBarIcons/rejectedactive.png"),
        label: "Rejected Quotation Management",
        path: "/rejected-quotation-management",
        role: ['admin','air_admin','ocean_admin'],
        component: RejectedQuoteManagement
    },
    {
        icon: require("../assets/images/SideBarIcons/rate_management.png"),
        active_icon: require("../assets/images/SideBarIcons/rate_management_active.png"),
        label: "Rate Management",
        path: "/rate-management",
        role: ['admin','air_admin','ocean_admin'],
        component: RateManagement
    },
    {
        icon: require("../assets/images/SideBarIcons/transportation.png"),
        active_icon: require("../assets/images/SideBarIcons/transportationActive.png"),
        label: "Transportation Rate Management",
        path: "/transportation-rate-management",
        role: ['admin','air_admin','ocean_admin'],
        component: TransportationRateManagement
    },
    {
        icon: require("../assets/images/SideBarIcons/create_key_account.png"),
        active_icon: require("../assets/images/SideBarIcons/create_key_account_active.png"),
        label: "KAM Management",
        path: "/create-key-account-manager",
        role: ['admin','air_admin','ocean_admin'],
        component: CreateKeyAccountManager
    },
    {
        icon: require("../assets/images/SideBarIcons/archives.png"),
        active_icon: require("../assets/images/SideBarIcons/archives_active.png"),
        label: "Archives",
        path: "/archives",
        role: ['admin','air_admin','ocean_admin'],
        component: Archives
    },
    {
        icon: require("../assets/images/SideBarIcons/queries_icon.png"),
        active_icon: require("../assets/images/SideBarIcons/queries_active.png"),
        label: "Queries",
        path: "/queries",
        role: ['kam'],
        component: Queries
    },
    {
        icon: require("../assets/images/SideBarIcons/exchange_rate.png"),
        active_icon: require("../assets/images/SideBarIcons/exchange_rate.png"),
        label: "Exchange Rate",
        path: "/exchange-rate",
        role: ['admin','air_admin','ocean_admin'],
        component: ExchangeRate
    },
    {
        icon: require("../assets/images/SideBarIcons/payment_request.png"),
        active_icon: require("../assets/images/SideBarIcons/payment_req_active.png"),
        label: "Payment Requests",
        path: "/payment-request",
        role: ['admin','air_admin','ocean_admin'],
        component: PaymentRequest
    }
    // ,
    // {
    //     icon: require("../assets/images/SideBarIcons/reports.png"),
    //     active_icon: require("../assets/images/SideBarIcons/report_active.png"),
    //     label: "Reports",
    //     path: "/reports",
    //     role: ['admin','air_admin','ocean_admin'],
    //     component: Report
    // }
    
]